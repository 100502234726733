import { DeleteStatus, UpdateStatus } from "../types/scheduling/meetings";
import {
  MM,
  MMAssignmentInput,
  MMAutoFillKind,
  MMCoreInput,
  MMs,
  MidweekMeetingInfo,
  MidweekMeetingSubstitution,
  MidweekMeetingSupplemental,
  MidweekScheduleAssignment,
  MidweekScheduleInfo,
} from "../types/scheduling/midweek";
import { api, schedulingPath } from "./api";

class MidweekAPI {
  static base = schedulingPath("/mm");

  async getMeetingInfo(date: string): Promise<MM> {
    const m_infoUrl = `${MidweekAPI.base}/meeting/${date}`;
    const s_infoUrl = `${MidweekAPI.base}/schedule/${date}`;
    const [meeting, schedule] = await Promise.all([
      api.get(m_infoUrl).json<MidweekMeetingInfo>(),
      api.get(s_infoUrl).json<MidweekScheduleInfo>(),
    ]);
    if (meeting.date === "") {
      return { not_available: true };
    }
    return { schedule, meeting };
  }

  async getMeetingsInfo(from: string, to: string, lgroup: number, noSubs: boolean): Promise<MMs> {
    const m_infoUrl = `${MidweekAPI.base}/meeting/${from}_${to}`;
    const s_infoUrl = `${MidweekAPI.base}/schedule/${from}_${to}`;
    const commonParams = { lgroup };
    const [meetings, schedules] = await Promise.all([
      api.get(m_infoUrl, { searchParams: { ...commonParams, no_subs: noSubs } }).json<MidweekMeetingInfo[]>(),
      api.get(s_infoUrl, { searchParams: commonParams }).json<MidweekScheduleInfo[]>(),
    ]);
    return { schedules, meetings, not_available: meetings === null };
  }

  async deleteSchedule(date: string, lgroup: number): Promise<MidweekScheduleInfo> {
    const url = `${MidweekAPI.base}/schedule/${date}`;
    return api.delete(url, { searchParams: { lgroup } }).json();
  }

  async setAssignment(date: string, input: MMAssignmentInput, lgroup: number): Promise<MidweekScheduleAssignment> {
    const url = `${MidweekAPI.base}/schedule/${date}/asmt`;
    return api.put(url, { json: input, searchParams: { lgroup } }).json();
  }

  async setCoreAssignment(date: string, input: MMCoreInput, lgroup: number): Promise<UpdateStatus> {
    const url = `${MidweekAPI.base}/schedule/${date}/core`;
    return api.put(url, { json: input, searchParams: { lgroup } }).json();
  }

  async autoSetAssignments(date: string, kind: MMAutoFillKind, lgroup: number): Promise<MidweekScheduleInfo> {
    const url = `${MidweekAPI.base}/schedule/${date}/auto/${kind}`;
    return api.post(url, { searchParams: { lgroup } }).json();
  }

  async autoSetAssignment(
    date: string,
    partID: string,
    classroom: string,
    hh: boolean,
    lgroup: number,
  ): Promise<MidweekScheduleAssignment> {
    const url = `${MidweekAPI.base}/schedule/${date}/auto/${partID}_${classroom}${hh ? "_hh" : ""}`;
    return api.post(url, { searchParams: { lgroup } }).json();
  }

  async autoSetCoreAssignment(date: string, coreType: string, lgroup: number): Promise<MidweekScheduleInfo> {
    const url = `${MidweekAPI.base}/schedule/${date}/auto/${coreType}`;
    return api.post(url, { searchParams: { lgroup } }).json();
  }

  async setSubstitution(sub: MidweekMeetingSubstitution, lgroup: number): Promise<UpdateStatus> {
    const url = `${MidweekAPI.base}/meeting/subs`;
    return api.put(url, { json: sub, searchParams: { lgroup } }).json();
  }

  async getSubstitutions(from: string, to: string, lgroup: number): Promise<MidweekMeetingSubstitution[]> {
    const url = `${MidweekAPI.base}/meeting/subs/${from}_${to}`;
    return api.get(url, { searchParams: { lgroup } }).json();
  }

  async deleteSubstitution(sub: MidweekMeetingSubstitution, lgroup: number): Promise<DeleteStatus> {
    const url = `${MidweekAPI.base}/meeting/subs`;
    return api.delete(url, { json: sub, searchParams: { lgroup } }).json();
  }

  async setSupplemental(supp: MidweekMeetingSupplemental, lgroup: number): Promise<MidweekMeetingSupplemental> {
    const url = `${MidweekAPI.base}/meeting/supplemental`;
    return api.put(url, { json: supp, searchParams: { lgroup } }).json();
  }

  async deleteSupplemental(supp: MidweekMeetingSupplemental, lgroup: number): Promise<DeleteStatus> {
    const url = `${MidweekAPI.base}/meeting/supplemental`;
    return api.delete(url, { json: supp, searchParams: { lgroup } }).json();
  }
}

export const midweekApi = new MidweekAPI();
