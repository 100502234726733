import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { HGContext } from "../helpers/globals";
import { Button, Modal } from "react-bootstrap";

//FeatureModal is when we want to show a modal about a new feature.
export function FeatureModal() {
  const { t } = useTranslation();
  const ctx = useContext(HGContext);

  const handleClose = () => {
    ctx.setGlobals({ ...ctx.globals, featureDismissed: true });
  };

  const flags = ctx.globals.flags;

  return (
    <Modal size="lg" show={flags.feature && !ctx.globals.featureDismissed && !flags.tour} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("general.new")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          <h4>{t("schedules.scheduling")}</h4>
          <ul>
            <li>{t("www.overview.scheduling.body")}</li>
            <li>{t("www.overview.assignments")}</li>
          </ul>
        </div>
        <div className="d-flex flex-column align-items-center">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/r1cvWxwoxYM"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("popup.error.button.ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
