import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { Tag, TagCategory, TagLink, TagLinkUnlink } from "../types/scheduling/tags";
import { api, schedulingPath } from "./api";
import QueryKeys from "./queryKeys";

class TagsAPI {
  base = schedulingPath("/tags");

  async getTags(category: TagCategory): Promise<Tag[]> {
    const url = `${this.base}/${category}`;
    return api.get(url).json();
  }

  async upsert(tag: Tag): Promise<Tag> {
    const url = `${this.base}`;
    return api.put(url, { json: tag }).json();
  }

  async delete(tag_id: number): Promise<Tag> {
    const url = `${this.base}/${tag_id}`;
    return api.delete(url).json();
  }

  // TODO: does this really return a TagLink?
  async linkUnlink(tlul: TagLinkUnlink): Promise<TagLink> {
    const url = `${this.base}`;
    return api.post(url, { json: tlul }).json();
  }
}

export function useTags(options?: UseQueryOptions<Tag[], Error>) {
  return useQuery<Tag[], Error>({
    queryKey: [QueryKeys.Tags],
    queryFn: () => tagsAPI.getTags(TagCategory.All),
    ...options,
  });
}

export const tagsAPI = new TagsAPI();
