import { compareTags } from "../components/congregation/tags";
import { PseudoTag, Tag } from "../types/scheduling/tags";
import { Territory, TerritoryAddress, TerritoryRecord } from "../types/scheduling/territory";
import User from "../types/user";

export type Taggable = Territory | User | TerritoryAddress | TerritoryRecord;

export function isTerritory(t: Taggable): boolean {
  return (t as Territory).locality !== undefined;
}

export function isUser(t: Taggable): boolean {
  return (t as User).lastname !== undefined;
}

export function isAddress(t: Taggable): boolean {
  return (t as TerritoryAddress).line1 !== undefined;
}
export function isTerritoryRecord(t: Taggable): boolean {
  return (t as TerritoryRecord).end !== undefined;
}


export const filterTerritoryByTags = (t: Territory, tags: (Tag | PseudoTag)[]) => {
  if (!tags) return true;
  if (tags.some((tag) => tag === PseudoTag.all)) return true;
  if (tags.some((tag) => tag === PseudoTag.untagged)) return t.tags.length < 1;
  if (tags.some((tag) => tag === PseudoTag.non_business))
    return !t.tags.some((t) => t.global && t.name === "business");
  return t.tags.some((tt) => tags.some((tag) => compareTags(tt, tag)));
};