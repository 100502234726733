import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import useInterval from "../helpers/useInterval";

type PopupParams = {
  url: string;
  title: string;
  onComplete: (message: string) => void;
  onClick?: () => void;
  height: number;
  width: number;
  setUrlAfter?: boolean;
  completeOnClose?: boolean;
};
type PopupMessage = {
  result: string;
  params: string;
};

const createPopup = (url: string, title: string, height: number, width: number, setUrlAfter = false): Window | null => {
  const left = window.screenX + (window.outerWidth - width) / 2;
  const top = window.screenY + (window.outerHeight - height) / 2.5;
  const features = `toolbar=no,menubar=no,width=${width},height=${height},left=${left},top=${top}`;
  if (setUrlAfter) {
    const popup = window.open("/", title, features);
    if (popup) {
      popup.location = url;
      popup.focus();
    }
    return popup;
  } else return window.open(url, title, features);
};

//this was inspired somewhat by https://github.com/XD2Sketch/react-oauth-popup but modified to use window.postMessage
//instead of just looking for "code" in the params, which is not indicative of success
export function HGOauthPopup(props: PropsWithChildren<PopupParams>) {
  const [popupWindow, setPopupWindow] = useState<Window | null>(null);

  const onComplete = props.onComplete;
  const receiveMessage = useCallback(
    (ev: MessageEvent<PopupMessage>) => {
      if (ev.origin !== window.location.origin || ev.source !== popupWindow || !popupWindow) {
        return;
      }
      if (ev.data.result === "success") {
        popupWindow.close();
        setPopupWindow(null);
        onComplete(ev.data.params);
      }
    },
    [popupWindow, onComplete],
  );

  useInterval(() => {
    if (props.completeOnClose && popupWindow && popupWindow.closed) {
      setPopupWindow(null);
      onComplete("");
    }
  }, 700);

  useEffect(() => {
    if (!popupWindow) {
      window.removeEventListener("message", receiveMessage);
    } else {
      window.addEventListener("message", receiveMessage);
    }
    return () => window.removeEventListener("message", receiveMessage);
  }, [popupWindow, receiveMessage]);

  const onContainerClick = () => {
    if (popupWindow && !popupWindow.closed) {
      return;
    }
    setPopupWindow(createPopup(props.url, props.title, props.height, props.width, props.setUrlAfter));
    props.onClick?.();
  };

  return <div onClick={onContainerClick}>{props.children}</div>;
}
