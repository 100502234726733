import { QueryClient } from "@tanstack/react-query";
import QueryKeys from "../api/queryKeys";
import { Tag } from "../types/scheduling/tags";

export const updateTagCache = (queryClient: QueryClient, oldTag: Tag, newTag: Tag) => {
  if (oldTag.id === 0) {
    //we have a new tag to add
    queryClient.setQueryData<Tag[]>([QueryKeys.Tags], (old) => (old ? [...old, newTag] : [newTag]));
    return;
  }
  queryClient.setQueryData<Tag[]>([QueryKeys.Tags], (old) =>
    old ? old.map((tag) => (tag.id === newTag.id && tag.global === newTag.global ? newTag : tag)) : [newTag],
  );
};

export function deleteFromTagCache(queryClient: QueryClient, tag: Tag) {
  queryClient.setQueriesData<Tag[]>({ queryKey: [QueryKeys.Tags] }, (old) =>
    old ? old.filter((t) => `${t.id}_${t.global}` !== `${tag.id}_${tag.global}`) : [],
  );
}
