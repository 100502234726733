import { UserId } from "./user";

type Report = {
  id: number;
  year: number;
  month: number;
  placements?: number;
  minutes?: number;
  videoshowings?: number;
  returnvisits?: number;
  studies?: number;
  credithours?: number;
  remarks?: string;
  pioneer?: string;
  reported_at?: string;
  submitted_month?: string;
  reported_by?: number;
  user: UserId;
};

export default Report;

export enum ReportPioneer {
  Auxiliary = "Auxiliary",
  Regular = "Regular",
  Special = "Special",
}

export type ReportsRange = {
  startMonth: string;
  endMonth: string;
  reports: Report[];
};

export type FutureAP = {
  user_id: number;
  month: number;
  year: number;
};
