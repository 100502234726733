/**
 * Set theme
 * @param {"dark"|"light"} theme
 * @param {boolean} persist
 */
export function setTheme(theme: string, persist: boolean) {
  document.documentElement.setAttribute("data-bs-theme", theme);

  if (persist) {
    try {
      localStorage.setItem("theme", theme);
    } catch (err: any) {
      console.warn("error using localStorage", err);
    }
  }
}

export function isDarkMode(): boolean {
  return document.documentElement.getAttribute("data-bs-theme") === "dark";
}
