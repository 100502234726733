import { Startup } from "../types/startup";
import { api, fsReportPath } from "./api";

class StartupAPI {
  static path = fsReportPath("/startup");

  async get(): Promise<Startup> {
    return api.get(StartupAPI.path).json();
  }
}

export const startupApi = new StartupAPI();
