import { Job } from "../types/job";
import { api, fsReportPath } from "./api";

class JobAPI {
  static path = fsReportPath("/jobs");

  async sendReminders(userId?: number): Promise<Job> {
    const reminderBase = `${JobAPI.path}/reminders`;
    const url = userId ? `${reminderBase}/${userId}` : reminderBase;
    return api.post(url).json();
  }

  async getJob(jobId: string): Promise<Job> {
    const url = `${JobAPI.path}/${jobId}`;
    return api.get(url).json();
  }
}

export const jobApi = new JobAPI();
