import {
  AlignmentType,
  BorderStyle,
  convertInchesToTwip as inches,
  Document,
  Footer,
  Header,
  HeightRule,
  Packer,
  PageOrientation,
  Paragraph,
  ShadingType,
  Table,
  TableCell,
  TableLayoutType,
  TableRow,
  TextRun,
  VerticalAlign,
  WidthType,
} from "docx";
import { saveAs } from "file-saver";
import User from "../../../types/user";
import { t } from "../../../helpers/locale";
import {
  dateToString,
  localizedDayOfWeek,
  localizedTime,
  Month,
  stringToLongLocaleDate,
  wmDate,
} from "../../../helpers/dateHelpers";
import { Cong } from "../../../types/cong";
import { CongSettings } from "../../../types/scheduling/settings";
import { Events, EventTypes, ScheduledEvent } from "../../../types/scheduling/events";
import { PublicTalkAssignment, TalkMod, WMSchedule, wmScheduleIsBlank } from "../../../types/scheduling/weekend";
import { songNum } from "../mm/mm_pdf";
import { Song } from "../../../types/scheduling/midweek";
import { nameOfUser } from "../../../helpers/user";
import { CongName } from "../common";
import i18n from "i18next";
import { ISODateString } from "../../../types/date";
import { FSGroup } from "../../../types/fsgroup";
import { getGroupDisplayName } from "../../../helpers/fsgroup";
import { pageDimensionsTwip } from "../../../helpers/docx";
import { congPaperSize } from "../../../helpers/paper";

type thing = {
  data: string | Table;
  bold?: boolean;
  tiny?: boolean;
};

export class WMDocx {
  chairVersion = false;
  cong = {} as Cong;
  userMap = {} as Map<number, User>;
  settings = {} as CongSettings;
  events: ScheduledEvent[] = [];
  fsGroups: FSGroup[] = [];

  constructor(
    userMap: Map<number, User>,
    cong: Cong,
    settings: CongSettings,
    events: ScheduledEvent[],
    fsGroups: FSGroup[],
  ) {
    this.cong = cong;
    this.userMap = userMap;
    this.settings = settings;
    this.events = events;
    this.fsGroups = fsGroups;
  }

  // font sizes in docx are half-points
  private fontSize = (points: number) => {
    return points * 2;
  };

  private rowPaddingFromFont = (half_points: number) => {
    return half_points * 16;
  };

  meetingDate = (date: ISODateString): ISODateString => {
    return this.settings.wm_print_date === "dayof" ? wmDate(date, this.cong, this.events) : date;
  };

  weekDate = (date: string) => {
    return stringToLongLocaleDate(this.meetingDate(date));
  };

  FONT = "Arial";
  BASE_FONT_SIZE = this.fontSize(10);
  PAGE_HEADER_FONT_SIZE = this.fontSize(12);

  ROW_HEIGHT = this.rowPaddingFromFont(this.BASE_FONT_SIZE);

  MARGIN = inches(0.5);
  PADDING = inches(0.1);
  VERT_PADDING = 40;

  dimensions = pageDimensionsTwip(congPaperSize(this.settings.paper_size));
  TABLE_WIDTH = this.dimensions.width - this.MARGIN * 2;
  COL1_WIDTH = this.TABLE_WIDTH * 0.55;
  COL2_WIDTH = this.TABLE_WIDTH * 0.15;
  COL3_WIDTH = this.TABLE_WIDTH * 0.3;

  NO_BORDER = {
    top: { size: 0, style: BorderStyle.NONE },
    bottom: { size: 0, style: BorderStyle.NONE },
    left: { size: 0, style: BorderStyle.NONE },
    right: { size: 0, style: BorderStyle.NONE },
    insideHorizontal: { size: 0, style: BorderStyle.NONE },
    insideVertical: { size: 0, style: BorderStyle.NONE },
  };

  private width = (width: number) => {
    return { size: width, type: WidthType.DXA };
  };

  // top of the page
  private headerTable = (congName: string) => {
    return new Header({
      children: [
        new Table({
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  children: [this.headerParagraph(congName, "left")],
                  verticalAlign: VerticalAlign.BOTTOM,
                }),
                new TableCell({
                  children: [this.headerParagraph(t("conganalysis.attendance.weekend"), "right")],
                  verticalAlign: VerticalAlign.BOTTOM,
                }),
              ],
            }),
          ],
          width: this.width(this.TABLE_WIDTH), // doesn't seem to effect anything
          layout: TableLayoutType.FIXED,
          columnWidths: [this.COL1_WIDTH, this.COL2_WIDTH, this.COL3_WIDTH],
          borders: {
            top: { size: 0, style: BorderStyle.NONE },
            bottom: { style: BorderStyle.SINGLE, color: "000000", size: 8 },
            left: { size: 0, style: BorderStyle.NONE },
            right: { size: 0, style: BorderStyle.NONE },
            insideHorizontal: { size: 0, style: BorderStyle.NONE },
            insideVertical: { size: 0, style: BorderStyle.NONE },
          },
        }),
      ],
    });
  };

  // top of the page
  private headerParagraph = (text?: string | null, align: "left" | "right" | "center" = "left") => {
    const headerText = new TextRun({
      text: text ?? "",
      bold: true,
      font: this.FONT,
      allCaps: false,
      color: "000000",
      size: this.PAGE_HEADER_FONT_SIZE,
    });

    return new Paragraph({
      children: [headerText],
      alignment: align,
    });
  };

  private eventTable = (event: ScheduledEvent, date: string) => {
    const txt = t(EventTypes[event.event]);

    return new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph({ keepLines: true, keepNext: true, children: [new TextRun({ text: "" })] })],
            }),
          ],
        }),
        this.row({ data: this.weekDate(date), bold: true }, null, null, true),
        new TableRow({
          height: { value: this.ROW_HEIGHT * 5, rule: HeightRule.EXACT },
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  keepLines: true,
                  children: [
                    new TextRun({
                      text: txt,
                      bold: true,
                      font: this.FONT,
                      allCaps: true,
                      color: "777777",
                      size: 40,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
          ],
        }),
      ],
      width: this.width(this.TABLE_WIDTH), // doesn't seem to effect anything
      layout: TableLayoutType.FIXED,
      columnWidths: [this.TABLE_WIDTH, 0, 0],
      borders: this.NO_BORDER,
    });
  };

  private normalParagraph = (
    text?: string | null,
    align: "left" | "right" | "center" | "end" = "left",
    tiny = false,
    last?: boolean,
  ) => {
    const headerText = new TextRun({
      text: text ?? "",
      bold: false,
      font: this.FONT,
      allCaps: false,
      color: "000000",
      size: tiny ? this.BASE_FONT_SIZE - 4 : this.BASE_FONT_SIZE,
    });

    return new Paragraph({
      children: [headerText],
      alignment: align,
      keepLines: true,
      keepNext: !last,
    });
  };

  /* standard row for data */
  private row = (col1: thing | null, col2: thing | null, col3: thing | null, header?: boolean, lastRow?: boolean) => {
    const c1 =
      col1?.data instanceof Table
        ? col1.data
        : new Paragraph({
            keepLines: true,
            keepNext: !lastRow,
            children: [
              new TextRun({
                text: col1?.data as string,
                bold: col1?.bold,
                font: this.FONT,
                size: col1?.tiny ? this.BASE_FONT_SIZE - 4 : this.BASE_FONT_SIZE,
                color: header ? "ffffff" : "000000",
              }),
            ],
          });

    let c2: Table | Paragraph = this.normalParagraph("");
    let c3: Table | Paragraph = this.normalParagraph("");

    if (col2) {
      c2 =
        col2.data instanceof Table
          ? col2.data
          : new Paragraph({
              keepLines: true,
              keepNext: !lastRow,
              children: [
                new TextRun({
                  text: col2?.data as string,
                  bold: col2?.bold,
                  font: this.FONT,
                  size: this.BASE_FONT_SIZE - 4,
                }),
              ],
              alignment: AlignmentType.RIGHT,
            });
    }

    if (col3) {
      c3 =
        col3.data instanceof Table
          ? col3.data
          : new Paragraph({
              keepLines: true,
              keepNext: !lastRow,
              children: [
                new TextRun({
                  text: col3?.data as string,
                  bold: col3?.bold,
                  font: this.FONT,
                  size: this.BASE_FONT_SIZE,
                }),
              ],
            });
    }

    const shading = header ? { color: "5D719E", type: ShadingType.SOLID } : undefined;

    return new TableRow({
      tableHeader: false,
      cantSplit: true,
      children: [
        new TableCell({
          children: [c1],
          columnSpan: header ? 3 : 1,
          shading: shading,
          margins: { left: this.PADDING },
        }),
        ...(header ? [] : [new TableCell({ children: [c2], shading: shading, margins: { right: this.PADDING } })]),
        ...(header ? [] : [new TableCell({ children: [c3], shading: shading, margins: { left: this.PADDING } })]),
      ],
    });
  };

  private extractTables = (tables: { table: Table; date: ISODateString }[]): Table[] => {
    return tables.map((t) => t.table);
  };

  songStyle = (s: Song) => {
    return `${songNum(s.number)}: ${s.title}`;
  };

  congTime = (out: PublicTalkAssignment): string => {
    if (!out.congregation) return "";
    return `${localizedTime(
      out.congregation.wm_time,
      i18n.language,
      out.congregation.timezone_name,
    )} ${localizedDayOfWeek(out.congregation.wm_dow, i18n.language)}`;
  };

  outTalks = (outs: PublicTalkAssignment[]) => {
    return new Table({
      rows: outs.map((out) => {
        return new TableRow({
          children: [
            new TableCell({
              margins: { left: this.PADDING },
              children: [this.normalParagraph(out.speaker ? nameOfUser(out.speaker) : "", "left", true, true)],
            }),
            new TableCell({
              margins: { left: this.PADDING },
              children: [this.normalParagraph(out.public_talk ? `#${out.public_talk.number}` : "", "left", true, true)],
            }),
            new TableCell({
              margins: { left: this.PADDING },
              children: [
                this.normalParagraph(
                  out.congregation ? `${CongName(out.congregation)} ${this.congTime(out)}` : "",
                  "left",
                  true,
                  true,
                ),
              ],
            }),
          ],
        });
      }),
      width: this.width(this.COL1_WIDTH),
      columnWidths: [this.COL1_WIDTH * 0.3, this.COL1_WIDTH * 0.1, this.COL1_WIDTH * 0.6],
      borders: this.NO_BORDER,
    });
  };

  publish = async (wms: WMSchedule[], startMonth: Date, monthsCount: number) => {
    const tables: { table: Table; date: ISODateString }[] = [];
    const lastMonth = Month.fromDate(startMonth);
    if (monthsCount > 1) lastMonth.addMonths(monthsCount - 1);

    wms.forEach((s) => {
      if (this.settings.hide_outgoing_speakers) s.out = [];
      const isCustomEvent = !!s.event && s.event.event === Events.custom;
      if (!!s.event) {
        if (s.event.event !== Events.co && s.event.event !== Events.custom) {
          // we are showing only the event - meeting is canceled (e.g. convention, memorial, assembly)
          tables.push({ table: this.eventTable(s.event, s.date), date: s.date });
          return;
        }
      }

      // skip unscheduled weeks after lastMonth
      if (wmScheduleIsBlank(s) && Month.fromDateString(this.meetingDate(s.date)).after(lastMonth)) {
        return;
      }

      const date = this.weekDate(s.date);

      const coVisit = s.talk_mod === TalkMod.CO;

      const speakerName = (): string => {
        if (coVisit) return this.settings.circuit_overseer_name || t("general.circuit-overseer");
        if (s.talk_mod === TalkMod.Other) return this.settings.other_speaker_name;
        if (s.talk_mod === TalkMod.TBD && s.speaker.firstname === TalkMod.TBD) return t("schedules.weekend.tbd");
        if (s.talk_mod === TalkMod.Stream) return "";
        if (!s.speaker || (!!s.speaker && !s.speaker.id)) return "";
        const speaker1Name = nameOfUser(s.speaker);
        if (s.speaker2) {
          const speaker2User = this.userMap.get(s.speaker2);
          if (speaker2User) return `${speaker1Name} | ${nameOfUser(speaker2User)}`;
        }
        return speaker1Name;
      };

      const congName = (): string => {
        // if it's a CO and we have the CO's name, the back end sends us english "Circuit Overseer"
        // instead, put in the localized text here
        if (s.talk_mod === TalkMod.CO && this.settings.circuit_overseer_name) return t("general.circuit-overseer");
        if (s.talk_mod === TalkMod.Stream) return t("schedules.stream");
        const speakerCong = CongName(s.speaker.congregation);
        if (speakerCong && speakerCong !== TalkMod.TBD) return speakerCong;
        const tempCong = CongName(s.temp_cong);
        if (tempCong && tempCong !== TalkMod.TBD) return tempCong;
        if (s.talk_mod === TalkMod.TBD && s.speaker.firstname !== TalkMod.TBD) return t("schedules.weekend.tbd");
        return "";
      };

      const talkName = (): string => {
        if (s.event?.event === Events.co) {
          return this.settings.circuit_overseer_wm_talk_title;
        }
        if (s.talk_mod === TalkMod.TBD && s.public_talk?.title === TalkMod.TBD) return t("schedules.weekend.tbd");
        if (s.talk_mod === TalkMod.Stream) return s.public_talk?.number ? s.public_talk.title : t("schedules.stream");
        return s.public_talk?.title ?? "";
      };
      const speakerAndCong = `${speakerName()}${!!speakerName() && !!congName() ? " — " : ""}${congName()}`;

      const left: thing[] = [];
      const center: thing[] = [];
      const right: thing[] = [];

      /**
       * LEFT
       */
      if (isCustomEvent && s.event?.custom_title) {
        left.push({ data: s.event?.custom_title });
      }
      if (s.opening_song) {
        left.push({ data: this.songStyle(s.opening_song), tiny: true });
      }
      left.push({ data: talkName(), bold: true });
      left.push({ data: speakerAndCong });

      if (coVisit) {
        left.push({
          data: this.settings.circuit_overseer_wm_svc_talk_title || t("schedules.service-talk"),
          bold: true,
        });
        left.push({
          data: this.settings.circuit_overseer_name,
          bold: false,
        });
      }

      if (s.closing_song) {
        left.push({ data: this.songStyle(s.closing_song), tiny: true });
      }

      if (s.out.length) {
        left.push({ data: t("schedules.weekend.outgoing") });
        left.push({ data: this.outTalks(s.out) });
      }

      /**
       * CENTER + RIGHT
       */
      if (s.openprayer) {
        center.push({ data: t("schedules.assignment.opening-prayer") });
        right.push({ data: this.userMap.get(s.openprayer)?.displayName ?? "" });
      }
      if (s.wm_chairman) {
        center.push({ data: t("schedules.chairman") });
        right.push({ data: this.userMap.get(s.wm_chairman)?.displayName ?? "" });
      }
      if (s.wt_conductor) {
        center.push({ data: t("schedules.weekend.wt-conductor") });
        right.push({ data: this.userMap.get(s.wt_conductor)?.displayName ?? "" });
      }
      if (this.settings.show_wt_reader && s.wm_reader) {
        center.push({ data: t("schedules.weekend.wt-reader") });
        right.push({ data: this.userMap.get(s.wm_reader)?.displayName ?? "" });
      }
      if (this.settings.show_interpreter && s.interpreter) {
        center.push({ data: t("schedules.interpreter.title") });
        right.push({ data: this.userMap.get(s.interpreter)?.displayName ?? "" });
      }
      if (s.closeprayer || this.settings.wm_speaker_closing_prayer) {
        const haveSpeaker = !!s.speaker.id || s.talk_mod === TalkMod.CO;
        const text = s.closeprayer
          ? this.userMap.get(s.closeprayer)?.displayName || ""
          : haveSpeaker
            ? speakerName()
            : "";
        center.push({ data: t("schedules.assignment.closing-prayer") });
        right.push({ data: text });
      }
      if (this.settings.enable_hospitality && s.host) {
        let host = this.userMap.get(s.host)?.displayName ?? "";
        if (this.settings.hospitality_by_group) {
          const group = this.fsGroups.find((g) => g.overseer_id === s.host);
          if (group) {
            host = getGroupDisplayName(group, this.userMap, this.settings.congregation_groups_use_label);
          }
        }
        center.push({ data: t("schedules.privileges.hospitality") });
        right.push({ data: host });
      }

      const rowCount = left.length > right.length ? left.length : right.length;
      const rows: TableRow[] = [];
      for (let i = 0; i < rowCount; i++) {
        rows.push(this.row(left[i] ?? null, center[i] ?? null, right[i] ?? null, undefined, i === rowCount - 1));
      }

      tables.push({
        table: new Table({
          rows: [
            this.row(null, null, null), // padding above header
            this.row({ data: date, bold: true }, null, null, true), // header
            ...rows, // data
          ],
          width: this.width(this.TABLE_WIDTH), // doesn't seem to affect anything
          layout: TableLayoutType.FIXED,
          columnWidths: [this.COL1_WIDTH, this.COL2_WIDTH, this.COL3_WIDTH],
          borders: this.NO_BORDER,
        }),
        date: s.date,
      });
    });

    const doc = new Document({
      sections: [
        {
          headers: {
            default: this.headerTable(this.settings.congregation_display_name || this.cong.name),
          },
          children: [...this.extractTables(tables)],
          footers: {
            default: new Footer({
              children: [
                this.normalParagraph(`${dateToString(new Date())}, ${new Date().toLocaleTimeString()}`, "right", true),
              ],
            }),
          },
          properties: {
            page: {
              margin: {
                top: this.MARGIN,
                right: this.MARGIN + inches(0.1), // I don't understand why we need to do this to center everything
                bottom: this.MARGIN,
                left: this.MARGIN - inches(0.1), // I don't understand why we need to do this to center everything
              },
              size: {
                width: this.dimensions.width,
                height: this.dimensions.height,
                orientation: PageOrientation.PORTRAIT,
              },
            },
          },
        },
      ],
    });

    const meetingMonth = (): { from: Month; to: Month | null } => {
      try {
        const from = Month.fromDate(startMonth);
        return { from: from, to: lastMonth.equals(from) ? null : lastMonth };
      } catch {
        return { from: Month.fromDate(startMonth), to: null };
      }
    };

    const blob = await Packer.toBlob(doc);
    saveAs(blob, `weekend_${meetingMonth().from.toString()}${meetingMonth().to ? `_${meetingMonth().to}` : ""}.docx`);
  };
}
