import { HGBugsnagNotify } from "../../helpers/bugsnag";
import { Territory, TerritoryAddress, TerritoryRecord } from "../../types/scheduling/territory";
import { updateTerritoryAddressCache, updateTerritoryCache, updateTerritoryRecordCache } from "../../query/territory";
import { QueryClient, UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Modal,
  Row,
  Stack,
} from "react-bootstrap";
import { ArrowLeftSquare, ArrowRightSquare, Globe2, PaintBucket, PlusLg, Tags, TagsFill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { BsStack } from "react-icons/bs";
import { ReactSVG } from "react-svg";
import QueryKeys from "../../api/queryKeys";
import { tagsAPI } from "../../api/tags";
import publishersIcon from "../../assets/publishers.svg";
import { getStatusCode } from "../../helpers/errors";
import { t } from "../../helpers/locale";
import { userCompare } from "../../helpers/user";
import { NBSP } from "../../helpers/util";
import { deleteFromTagCache, updateTagCache } from "../../query/tags";
import { updateUsersCache } from "../../query/user";
import { CongSettings } from "../../types/scheduling/settings";
import {
  GlobalTagMap,
  GlobalTagsNotUserLinkable,
  PseudoTag,
  Tag,
  TagCategory,
  TagColors,
  TagDefault,
  TagLink,
  TagLinkUnlink,
} from "../../types/scheduling/tags";
import User from "../../types/user";
import { DeleteConfirmModal } from "../confirmationModals";
import { ShowStatus, Status } from "../scheduling/common";
import { formatTerritoryRecord, terrLabel, territoryCompare, territoryRecordCompare } from "./territory/common";
import { isAddress, isTerritory, isTerritoryRecord, isUser, Taggable } from "../../helpers/tags";
import { addressCompare, addressToString } from "../../helpers/address";

export function TagEditButton(props: { className?: string; tags: Tag[]; category: TagCategory; size?: "sm" | "lg" }) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  return (
    <>
      <TagEditModal tags={props.tags} show={show} setShow={setShow} category={props.category} />
      <Button
        className={props.className}
        title={t("tags.title")}
        variant="secondary"
        onClick={() => setShow(true)}
        size={props.size}
      >
        <Tags size={20} />
      </Button>
    </>
  );
}

export function BulkAssignButton(props: {
  className?: string;
  tag: Tag | PseudoTag;
  items: Taggable[];
  category: TagCategory;
  callback?: any;
  disabled?: boolean;
}) {
  const [show, setShow] = useState(false);
  if (typeof props.tag === "number") return;
  return (
    <>
      <BulkAssignModal tag={props.tag} show={show} setShow={setShow} items={props.items} category={props.category} />
      <Button
        className="d-print-none p-2"
        variant="secondary"
        onClick={() => setShow(true)}
        disabled={
          !props.tag ||
          props.disabled ||
          (GlobalTagsNotUserLinkable.some((d) => d === (props.tag as Tag).name) && props.tag.global)
        }
        size="sm"
      >
        {props.category === TagCategory.User && <ReactSVG src={publishersIcon} className="hg-svg-icon ms-1" />}
        {props.category === TagCategory.Territory && <BsStack size={20} />}
        {props.category === TagCategory.Address && <BsStack size={20} />}
        {props.category === TagCategory.TerritoryRecord && <BsStack size={20} />}
      </Button>
    </>
  );
}

export function TagLinkButton(props: {
  tags: Tag[];
  className?: string;
  item: Taggable;
  category: TagCategory;
  callback?: any;
  disabled?: boolean;
}) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  return (
    <>
      <TagLinkModal
        item={props.item}
        tags={props.tags.filter((t) => t.category === props.category)}
        show={show}
        setShow={setShow}
        callback={props.callback}
        category={props.category}
      />
      <Button
        className={props.className}
        title={t("tags.title")}
        variant="secondary"
        onClick={() => setShow(true)}
        disabled={props.disabled}
      >
        <TagsFill />
      </Button>
    </>
  );
}

const emptyTag = (category: TagCategory): Tag => {
  return {
    id: 0,
    name: "",
    category: category,
    color: null,
    allow_multi: false,
    global: false,
  };
};

export function TagEditModal(props: {
  tags: Tag[];
  category: TagCategory;
  show: boolean;
  setShow: (set: boolean) => void;
}) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<Tag | PseudoTag>(PseudoTag.untagged);
  const handleClose = () => {
    setSelected(PseudoTag.untagged);
    props.setShow(false);
  };

  let title = "";
  switch (props.category) {
    case TagCategory.Territory:
      title = ` (${t("schedules.territory.territories")})`;
      break;
    case TagCategory.User:
      title = ` (${t("conganalysis.heading.publishers")})`;
      break;
  }

  return (
    <Modal size="lg" show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{`${t("tags.title")}${title}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={3}>
            <TagButtonStack tags={props.tags} setSelected={setSelected} category={props.category} />
          </Col>
          <Col>
            {typeof selected === "number" ? (
              <></>
            ) : (
              <TagForm existing={selected ?? emptyTag(props.category)} category={props.category} />
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

type TaggedNotTagged = {
  tagged: Taggable[];
  untagged: Taggable[];
};

const initTnt = (tag: Tag, items: Taggable[]): TaggedNotTagged => {
  return {
    tagged: items.filter((i) => i.tags.some((t) => compareTags(tag, t))),
    untagged: items.filter((i) => !i.tags.some((t) => compareTags(tag, t))),
  };
};

export function BulkAssignModal(props: {
  tag: Tag;
  items: Taggable[];
  category: TagCategory;
  show: boolean;
  setShow: (set: boolean) => void;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const tagRef = useRef<HTMLSelectElement>(null);
  const utagRef = useRef<HTMLSelectElement>(null);
  const [tnt, setTnt] = useState<TaggedNotTagged>(initTnt(props.tag, props.items));
  const [status, setStatus] = useState(Status.Null);
  const tagLinkSaveMutation = useMutation({ mutationFn: (tl: TagLinkUnlink) => tagsAPI.linkUnlink(tl) });

  useEffect(() => {
    setTnt(initTnt(props.tag, props.items));
  }, [setTnt, props.tag, props.items]);

  const handleClose = () => {
    setStatus(Status.Null);
    setTnt(initTnt(props.tag, props.items));
    props.setShow(false);
  };

  const taggedMap = new Map<number, Taggable>();
  props.items.forEach((i) => taggedMap.set(i.id, i));

  const moveToTagged = () => {
    if (!utagRef.current) return;
    const newTNT = { ...tnt };
    Array.from(utagRef.current.selectedOptions).forEach((o) => {
      const item = taggedMap.get(parseInt(o.value));
      if (!item) return;
      const id = parseInt(o.value);
      newTNT.tagged.push(item);
      const idx = newTNT.untagged.findIndex((u) => u.id === id);
      newTNT.untagged.splice(idx, 1);
    });
    setTnt(newTNT);
  };

  const moveToUntagged = () => {
    if (!tagRef.current) return;
    const newTNT = { ...tnt };
    Array.from(tagRef.current.selectedOptions).forEach((o) => {
      const item = taggedMap.get(parseInt(o.value));
      if (!item) return;
      const id = parseInt(o.value);
      newTNT.untagged.push(item);
      const idx = newTNT.tagged.findIndex((u) => u.id === id);
      newTNT.tagged.splice(idx, 1);
    });
    setTnt(newTNT);
  };

  const mapTaggableToLink = (t: Taggable): TagLink => {
    return {
      tag_id: props.tag.global ? undefined : props.tag.id,
      global_tag_id: props.tag.global ? props.tag.id : undefined,
      terr_id: props.category === TagCategory.Territory ? t.id : undefined,
      user_id: props.category === TagCategory.User ? t.id : undefined,
      addr_id: props.category === TagCategory.Address ? t.id : undefined,
      trec_id: props.category === TagCategory.TerritoryRecord ? t.id : undefined,
    };
  };

  const save = async () => {
    setStatus(Status.Pending);
    const orig = initTnt(props.tag, props.items);
    const tlul: TagLinkUnlink = {
      link: tnt.tagged.filter((t) => orig.tagged.findIndex((o) => o.id === t.id) < 0).map(mapTaggableToLink),
      unlink: tnt.untagged.filter((t) => orig.untagged.findIndex((o) => o.id === t.id) < 0).map(mapTaggableToLink),
    };
    try {
      await tagLinkSaveMutation.mutateAsync(tlul);
      tnt.tagged.forEach((ti) => {
        const newItem = { ...ti, tags: Array.from(new Set([...ti.tags, props.tag])) };
        if (isTerritory(newItem)) {
          updateTerritoryCache(queryClient, newItem as Territory);
        }
        if (isUser(newItem)) {
          updateUsersCache(queryClient, ti as User, newItem as User);
        }
        if (isAddress(newItem)) {
          updateTerritoryAddressCache(
            queryClient,
            (newItem as TerritoryAddress).territoryId,
            newItem as TerritoryAddress,
          );
        }
        if (isTerritoryRecord(newItem)) {
          updateTerritoryRecordCache(queryClient, newItem as TerritoryRecord);
        }
      });
      tnt.untagged.forEach((ti) => {
        const newItem = { ...ti };
        const idx = newItem.tags.findIndex((t) => compareTags(props.tag, t));
        if (idx >= 0) {
          newItem.tags.splice(idx, 1);
          if (isTerritory(newItem)) {
            updateTerritoryCache(queryClient, newItem as Territory);
          }
          if (isUser(newItem)) {
            updateUsersCache(queryClient, ti as User, newItem as User);
          }
          if (isAddress(newItem)) {
            updateTerritoryAddressCache(
              queryClient,
              (newItem as TerritoryAddress).territoryId,
              newItem as TerritoryAddress,
            );
          }
          if (isTerritoryRecord(newItem)) {
            updateTerritoryRecordCache(queryClient, newItem as TerritoryRecord);
          }
        }
      });
      setStatus(Status.Success);
    } catch (err: any) {
      console.error("error bulks saving tag", err);
      HGBugsnagNotify("saveTags", err);
      setStatus(Status.Failure);
    }
  };

  return (
    <Modal size="lg" show={props.show && !!props.tag} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <TagRendered tag={props.tag} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="d-flex justify-content-center align-items-center">
          <Col xs={5}>
            {t("tags.untagged")}
            <Form.Select ref={utagRef} htmlSize={10} multiple>
              {tnt.untagged.sort(sortTaggables).map((i) => (
                <option key={i.id} value={i.id}>
                  {taggableName(i)}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col xs={1} className="d-flex justify-content-center align-items-center">
            <div>
              <Button variant="secondary" onClick={moveToUntagged}>
                <ArrowLeftSquare size="18pt" />
              </Button>
              <br />
              <Button variant="secondary" onClick={moveToTagged}>
                <ArrowRightSquare size="18pt" />
              </Button>
            </div>
          </Col>
          <Col xs={5}>
            {t("tags.tagged")}
            <Form.Select ref={tagRef} htmlSize={10} multiple>
              {tnt.tagged.sort(sortTaggables).map((i) => (
                <option key={i.id} value={i.id}>
                  {taggableName(i)}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <ShowStatus status={status} />
        <Button variant="secondary" onClick={save}>
          {t("general.save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function TagButtonStack(props: {
  tags: Tag[];
  setSelected: (set: Tag) => void;
  category: TagCategory;
  className?: string;
  enableGlobal?: boolean;
  disableAdd?: boolean;
}) {
  return (
    <Stack gap={2} className={props.className}>
      {props.tags.map((tag, i) => (
        <Button
          key={i}
          className="w-100"
          size="sm"
          onClick={() => props.setSelected(tag)}
          variant={tag.color ?? "secondary"}
          disabled={tag.global && !props.enableGlobal}
        >
          {tag.global && <Globe2 />} <TagRendered tag={tag} />
        </Button>
      ))}
      {!props.disableAdd && (
        <Button
          variant="secondary"
          size="sm"
          className="w-100"
          onClick={() => props.setSelected(emptyTag(props.category))}
        >
          <PlusLg />
        </Button>
      )}
    </Stack>
  );
}

function TagForm(props: { existing: Tag; category: TagCategory }) {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [color, setColor] = useState("");
  const [status, setStatus] = useState(Status.Null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const tagSaveMutation = useMutation({ mutationFn: (tag: Tag) => tagsAPI.upsert(tag) });
  const tagDeleteMutation = useMutation({ mutationFn: (tag: Tag) => tagsAPI.delete(tag.id) });
  const queryClient = useQueryClient();

  const saveTag = async () => {
    if (!name && !color) return;
    setStatus(Status.Pending);
    try {
      const tag: Tag = {
        id: props.existing.id,
        name: name || props.existing.name,
        category: props.category,
        color: color || props.existing.color,
        allow_multi: true,
        global: false,
      };
      const newTag = await tagSaveMutation.mutateAsync(tag);
      updateTagCache(queryClient, props.existing, newTag);
      setStatus(Status.Success);
      setName("");
      setColor("");
    } catch (err: any) {
      setStatus(Status.Failure);
      console.error("error saving tag", err);
      HGBugsnagNotify("saveTag", err);
    }
  };

  const deleteTag = async () => {
    setStatus(Status.Pending);
    try {
      await tagDeleteMutation.mutateAsync(props.existing);
      deleteFromTagCache(queryClient, props.existing);
      setStatus(Status.Success);
      setName("");
      setColor("");
      props.existing.name = "";
      props.existing.color = "";
      props.existing.id = 0;
    } catch (err: any) {
      setStatus(Status.Failure);
      console.error("error deleting tag", err);
      HGBugsnagNotify("deleteTag", err);
    }
    setShowDeleteModal(false);
  };

  return (
    <>
      <DeleteConfirmModal
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        onConfirm={deleteTag}
        message={props.existing.name}
      />
      <div>
        <InputGroup>
          <Form.Control
            type="text"
            id="tagName"
            value={name || props.existing?.name || ""}
            onChange={(e) => setName(e.target.value)}
            placeholder={t("general.name")}
          />
          <DropdownButton
            className="w-100"
            variant={(color || props.existing?.color) ?? "secondary"}
            id="tagColor"
            title={<PaintBucket />}
          >
            {TagColors.map((tagColor) => {
              return (
                <Dropdown.Item
                  key={tagColor.name}
                  onClick={() => setColor(tagColor.name)}
                  className={tagColor.className}
                >
                  {NBSP}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
        </InputGroup>
      </div>
      <Modal.Footer>
        {status === Status.Null ? "" : <ShowStatus status={status} />}
        <Button variant="outline-danger" onClick={() => setShowDeleteModal(true)} disabled={!props.existing?.id}>
          {t("general.delete")}
        </Button>
        <Button
          variant="primary"
          onClick={saveTag}
          disabled={!name && !(!!props.existing?.id && props.existing?.color !== color && color !== "")}
        >
          {t("general.save")}
        </Button>
      </Modal.Footer>
    </>
  );
}

export function compareTags(t?: Tag | PseudoTag, tt?: Tag | PseudoTag) {
  if (!t || !tt || typeof t === "number" || typeof tt === "number") return false;
  return t.id === tt.id && t.global === tt.global && t.category === tt.category;
}

export function filterTags(item: Taggable, tagsFilter: (Tag | PseudoTag)[]) {
  if (!tagsFilter) return true;
  if (tagsFilter.some((tag) => tag === PseudoTag.all)) return true;
  if (tagsFilter.some((tag) => tag === PseudoTag.untagged)) return item.tags.length < 1;
  if (tagsFilter.some((tag) => tag === PseudoTag.non_business)) {
    return !item.tags.some((t) => t.global && t.name === "business");
  }
  return item.tags.some((tt) => tagsFilter.some((t) => compareTags(tt, t)));
}

export function newTagLink(item: Taggable, t: Tag): TagLink {
  return {
    tag_id: t.global ? undefined : t.id,
    global_tag_id: t.global ? t.id : undefined,
    terr_id: isTerritory(item) ? item.id : undefined,
    user_id: isUser(item) ? item.id : undefined,
    addr_id: isAddress(item) ? item.id : undefined,
    trec_id: isTerritoryRecord(item) ? item.id : undefined,
  };
}

export function TagLinkModal(props: {
  item: Taggable;
  tags: Tag[];
  show: boolean;
  setShow: (set: boolean) => void;
  category: TagCategory;
  callback?: any;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const tagLinkSaveMutation = useMutation({ mutationFn: (tl: TagLinkUnlink) => tagsAPI.linkUnlink(tl) });
  const [slated, setSlated] = useState(props.item.tags);
  const [inFlight, setInFlight] = useState(false);

  const handleClose = () => {
    props.setShow(false);
  };

  const handleReset = () => {
    setSlated(props.item.tags);
  };

  return (
    <Modal size="sm" show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("tags.title")}</Modal.Title>
      </Modal.Header>
      <TagLinkForm
        item={props.item}
        tags={props.tags}
        category={props.category}
        slated={slated}
        setSlated={setSlated}
        disabled={inFlight}
      />
      <Modal.Footer className="d-flex justify-content-between">
        <div>
          <TagEditButton tags={props.tags} category={props.category} />
        </div>
        <div>
          <Button onClick={handleReset} variant="secondary">
            {t("general.reset")}
          </Button>
          <Button
            onClick={() =>
              handleSaveLinkUnlink(
                props.tags,
                slated,
                props.item,
                setInFlight,
                queryClient,
                tagLinkSaveMutation,
                props.callback,
              )
            }
            variant="primary"
            disabled={inFlight}
          >
            {t("general.save")}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export function TagLinkForm(props: {
  item: Taggable;
  tags: Tag[];
  category: TagCategory;
  slated: Tag[];
  setSlated: (set: Tag[]) => void;
  disabled?: boolean;
}) {
  // required for user page
  useEffect(() => {
    props.setSlated(props.item.tags || []);
    // there's probably a better and more appropriate way.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item.tags]);

  const link = (tag: Tag) => {
    if (props.slated?.some((t) => compareTags(t, tag))) return;
    props.setSlated([...props.slated, tag]);
  };

  const unlink = (tag: Tag) => {
    const newSlated = [...props.slated];
    const idx = newSlated.findIndex((t) => compareTags(t, tag));
    if (idx < 0) return;
    newSlated.splice(idx, 1);
    props.setSlated(newSlated);
  };

  return (
    <Card>
      <Modal.Body>
        <div>
          {props.tags.map((t, i) => (
            <TagLinkCheckbox
              key={i}
              itemTags={props.slated}
              tag={t}
              link={link}
              unlink={unlink}
              disabled={props.disabled}
            />
          ))}
        </div>
      </Modal.Body>
    </Card>
  );
}

// handleSaveLinkUnlink deals with saving tags to the back end. if it returns false,
// the local cache needs to be updated.
export const handleSaveLinkUnlink = async (
  tags: Tag[],
  slated: Tag[],
  item: Taggable,
  setInFlight: (set: boolean) => void,
  queryClient: QueryClient,
  tagLinkSaveMutation: UseMutationResult<TagLink, Error, TagLinkUnlink, unknown>,
  callback?: any,
): Promise<boolean> => {
  setInFlight(true);
  const tlul: TagLinkUnlink = {
    link: [],
    unlink: [],
  };
  if (!slated) slated = [];
  tags.forEach((t) => {
    const desired = slated?.some((tt) => compareTags(tt, t));
    const previous = item.tags?.some((tt) => compareTags(tt, t));
    if (desired && !previous) {
      tlul.link.push(newTagLink(item, t));
    }
    if (!desired && previous) {
      tlul.unlink.push(newTagLink(item, t));
    }
  });

  if (tlul.link.length === 0 && tlul.unlink.length === 0) {
    // noop, no reason to make the api call
    return false;
  }

  try {
    await tagLinkSaveMutation.mutateAsync(tlul);
    if (isTerritory(item)) {
      const terr = { ...item, tags: [...slated] } as Territory;
      updateTerritoryCache(queryClient, terr);
      !!callback && callback(terr);
    }
    if (isUser(item)) {
      const newUser = { ...item, tags: [...slated] } as User;
      updateUsersCache(queryClient, item as User, newUser);
      !!callback && callback(newUser);
    }
    if (isAddress(item)) {
      const newAddr = { ...item, tags: [...slated] } as TerritoryAddress;
      dncTagHelper(tags, newAddr);
      updateTerritoryAddressCache(queryClient, newAddr.territoryId, newAddr);
      !!callback && callback(newAddr);
    }
    if (isTerritoryRecord(item)) {
      const newRec = { ...item, tags: [...slated] } as TerritoryRecord;
      updateTerritoryRecordCache(queryClient, newRec);
      !!callback && callback(newRec);
    }
  } catch (err: any) {
    console.error("error linking/unlinking tags", err);
    if (getStatusCode(err) === 409) await queryClient.invalidateQueries({ queryKey: [QueryKeys.Tags] });
    HGBugsnagNotify("tagLinkUnlink", err);
  } finally {
    setInFlight(false);
  }

  return true;
};

const TagLinkCheckbox = (props: {
  itemTags: Tag[];
  tag: Tag;
  link: (tag: Tag) => void;
  unlink: (tag: Tag) => void;
  disabled?: boolean;
}) => {
  const linked = props.itemTags?.some((it) => compareTags(it, props.tag)) ?? false;

  return (
    <Form.Check
      id={`${props.tag.id}_${props.tag.global}`}
      checked={linked}
      label={<TagRendered tag={props.tag} badge />}
      onChange={() => (linked ? props.unlink(props.tag) : props.link(props.tag))}
      disabled={props.disabled || (props.tag.global && GlobalTagsNotUserLinkable.some((d) => d === props.tag.name))}
    />
  );
};

export function TagsDDM(props: {
  tags: Tag[];
  secondaryTags?: Tag[];
  selected: (Tag | PseudoTag)[];
  setSelected: (set: (Tag | PseudoTag)[]) => void;
  secondarySelected?: (Tag | PseudoTag)[];
  setSecondarySelected?: (set: (Tag | PseudoTag)[]) => void;
  showUntaggedTag?: boolean;
  showAllTag?: boolean;
  showNonBusinessTag?: boolean;
  primaryLabel?: string;
  secondaryLabel?: string;
  className?: string;
}) {
  const { t } = useTranslation();

  function addSelectedTag(tag: Tag | PseudoTag) {
    if (Array.isArray(props.selected) && props.selected.some((t) => t === tag || compareTags(t, tag))) return;
    props.setSelected([...props.selected, tag]);
  }

  function removeSelectedTag(tag: Tag | PseudoTag) {
    props.setSelected(props.selected.filter((t) => !(typeof t === "number" ? t === tag : compareTags(t, tag))));
  }

  function addSecondarySelectedTag(tag: Tag | PseudoTag) {
    if (
      Array.isArray(props.setSecondarySelected) &&
      props.setSecondarySelected.some((t) => t === tag || compareTags(t, tag))
    )
      return;
    if (!props.setSecondarySelected) return;
    props.setSecondarySelected([...(props.secondarySelected ?? []), tag]);
  }

  function removeSecondarySelectedTag(tag: Tag | PseudoTag) {
    if (!props.setSecondarySelected) return;
    props.setSecondarySelected(
      props.secondarySelected?.filter((t) => !(typeof t === "number" ? t === tag : compareTags(t, tag))) ?? [],
    );
  }

  return (
    <Dropdown className={props.className}>
      <Dropdown.Divider />
      <Dropdown.Toggle
        size="sm"
        variant="primary"
        title={t("tags.title")}
        style={{ padding: ".25em" /** to make same height as other buttons */ }}
      >
        <TagList
          className={`d-inline-block mx-1`}
          tags={Array.isArray(props.selected) ? props.selected : []}
          showAllTag={props.showAllTag}
          showUntaggedTag={props.showUntaggedTag}
          showNonBusinessTag={props.showNonBusinessTag}
          onRemove={removeSelectedTag}
          showRemove
        />
        {props.secondaryTags !== undefined && (
          <>
            {" | "}
            <TagList
              className={`d-inline-block mx-1`}
              tags={Array.isArray(props.secondarySelected) ? (props.secondarySelected ?? []) : []}
              showAllTag={props.showAllTag}
              showUntaggedTag={props.showUntaggedTag}
              onRemove={removeSecondarySelectedTag}
              showRemove
            />
          </>
        )}
        <Tags />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {props.primaryLabel && <Dropdown.Header>{props.primaryLabel}</Dropdown.Header>}
        <Dropdown.Item
          hidden={!props.showUntaggedTag}
          key={PseudoTag.untagged}
          onClick={() => addSelectedTag(PseudoTag.untagged)}
        >
          <TagRendered tag={PseudoTag.untagged} badge />
        </Dropdown.Item>
        <Dropdown.Item hidden={!props.showAllTag} key={PseudoTag.all} onClick={() => addSelectedTag(PseudoTag.all)}>
          <TagRendered tag={PseudoTag.all} badge />
        </Dropdown.Item>
        <Dropdown.Item
          hidden={!props.showNonBusinessTag}
          key={PseudoTag.non_business}
          onClick={() => addSelectedTag(PseudoTag.non_business)}
        >
          <TagRendered tag={PseudoTag.non_business} badge />
        </Dropdown.Item>
        {props.tags?.map((t, i) => (
          <Dropdown.Item key={i} onClick={() => addSelectedTag(t)}>
            <TagRendered tag={t} badge />
          </Dropdown.Item>
        ))}
        {props.secondaryTags !== undefined && (
          <>
            {props.secondaryLabel && <Dropdown.Header>{props.secondaryLabel}</Dropdown.Header>}
            <Dropdown.Item
              hidden={!props.showAllTag}
              key={PseudoTag.all}
              onClick={() => addSecondarySelectedTag(PseudoTag.all)}
            >
              <TagRendered tag={PseudoTag.all} badge />
            </Dropdown.Item>
            <Dropdown.Item
              hidden={!props.showUntaggedTag}
              key={PseudoTag.untagged}
              onClick={() => addSecondarySelectedTag(PseudoTag.untagged)}
            >
              <TagRendered tag={PseudoTag.untagged} badge />
            </Dropdown.Item>
            {props.secondaryTags?.map((t, i) => (
              <Dropdown.Item key={i} onClick={() => addSecondarySelectedTag(t)}>
                <TagRendered tag={t} badge />
              </Dropdown.Item>
            ))}
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export function TagList(props: {
  tags: (Tag | PseudoTag)[];
  showUntaggedTag?: boolean;
  showAllTag?: boolean;
  showNonBusinessTag?: boolean;
  showRemove?: boolean;
  className?: string;
  onRemove?: (tag: Tag | PseudoTag) => void;
  mini?: boolean;
}) {
  return (
    <div className={props.className + " d-inline-block"}>
      {Array.isArray(props.tags) &&
        props.tags.map((t) => {
          const key = typeof t === "number" ? t : t.id;
          return (
            <TagRendered
              key={key}
              tag={t}
              badge
              showRemove={props.showRemove}
              onRemove={() => props.onRemove?.(t)}
              mini={props.mini}
            />
          );
        })}
    </div>
  );
}

// an individual tag
export function TagRendered(props: {
  tag: Tag | PseudoTag;
  badge?: boolean;
  showRemove?: boolean;
  onRemove?: () => void;
  mini?: boolean;
}) {
  const name = tagName(props.tag);
  const color = typeof props.tag === "number" ? "black" : (props.tag.color ?? "secondary");
  const closeClass = color === "black" ? "btn-close-white" : "";
  let css = "";
  if (props.tag === PseudoTag.all) {
    css = "border border-danger text-danger";
  } else if (props.tag === PseudoTag.untagged) {
    css = "border border-warning text-warning";
  } else if (props.tag === PseudoTag.non_business) {
    css = "border border-info text-info";
  }

  return !props.badge ? (
    name
  ) : (
    <Badge bg={color ?? "secondary"} className={`mx-1 ${css}`} text={color === "light" ? "dark" : undefined}>
      <span className={`d-flex align-items-center gap-1 fw-lighter ${props.mini ? "fw-light" : ""}`}>
        {name}
        {props.showRemove && (
          <div
            className={`btn-close btn-sm ${closeClass}`}
            onClick={(e) => {
              e.stopPropagation();
              props.onRemove?.();
            }}
          />
        )}
      </span>
    </Badge>
  );
}

export function tagName(tag?: Tag | PseudoTag): string {
  if (!tag) return "";
  if (tag === PseudoTag.untagged) return t("tags.untagged");
  if (tag === PseudoTag.all) return t("schedules.fill.all");
  if (tag === PseudoTag.non_business) return t("tags.non-business");
  if (typeof tag === "number") return "";
  let name = tag.name;
  if (tag.global && GlobalTagMap.get(tag.name)) {
    name = t(GlobalTagMap.get(tag.name)!);
  }
  return name;
}

export function taggableName(t: Taggable, userMap?: Map<number, User>, territoryMap?: Map<number, Territory>): string {
  if (isTerritory(t)) return terrLabel(t as Territory);
  if (isUser(t)) return (t as User).displayName;
  if (isAddress(t)) return addressToString(t as TerritoryAddress);
  if (isTerritoryRecord(t) && userMap && territoryMap) {
    return formatTerritoryRecord(t as TerritoryRecord, userMap);
  }

  return `${t.id}`;
}

function sortTaggables(a: Taggable, b: Taggable) {
  if (isTerritory(a)) return territoryCompare(a as Territory, b as Territory);
  if (isUser(a)) return userCompare(a as User, b as User);
  if (isAddress(a)) return addressCompare(a as TerritoryAddress, b as TerritoryAddress);
  if (isTerritoryRecord(a)) return territoryRecordCompare(a as TerritoryRecord, b as TerritoryRecord);
  return a.id > b.id ? 1 : -1;
}

export function terr_checkout_default_tags(settings: CongSettings): TagDefault[] {
  let raw: any = settings.terr_checkout_default_tag;
  const unknownDefault: TagDefault[] = [{ id: PseudoTag.untagged, global: false, pseudo: true }];
  if (!raw) return unknownDefault;
  if (typeof raw === "string") raw = JSON.parse(raw);
  if (Array.isArray(raw)) {
    if (raw.length === 0) return unknownDefault;
    // already TagDefaults
    if (raw.every((v) => typeof v === "object" && v.hasOwnProperty("pseudo"))) return raw;
    if (raw.every((v) => typeof v === "object" || typeof v === "number")) {
      return raw.flatMap((t: Tag | PseudoTag) => tagToDefault(t) ?? []);
    }
  } else if (typeof raw === "number") {
    return [{ id: raw, global: false, pseudo: true }];
  }

  return unknownDefault;
}

// dncTagHelper adds or removes the DNC tag as needed as it is pseudo-linked;
// while the backend takes care of this typically, this handles that job
// to do the work when the DNC flag is altered to allow for a quick refresh.
const dncTagHelper = (tags: Tag[], addr: TerritoryAddress) => {
  const isDncTag = (t: Tag) => {
    return t.global && t.name === "dnc" && t.category === TagCategory.Address;
  };

  if (addr.dnc) {
    if (!addr.tags.some((t) => isDncTag(t))) {
      const dncTag = tags.find((t) => isDncTag(t));
      if (dncTag) addr.tags.push(dncTag);
    }
  } else {
    if (addr.tags.some((t) => isDncTag(t))) {
      const idx = addr.tags.findIndex((t) => isDncTag(t));
      if (idx >= 0) {
        addr.tags.splice(idx, 1);
      }
    }
  }
};

export function tagDefaultToTag(td: TagDefault, tags: Tag[], category: TagCategory): (Tag | PseudoTag) | undefined {
  if (td.pseudo) {
    if (td.id && Object.values(PseudoTag).includes(td.id)) return td.id;
    return;
  }
  return tags.find((t) => t.global === td.global && t.category === category && t.id === td.id);
}

export function tagToDefault(tag: Tag | PseudoTag): TagDefault | undefined {
  if (typeof tag === "number") {
    if (Object.values(PseudoTag).includes(tag)) return { id: tag, global: false, pseudo: true };
    return;
  }
  return { id: tag.id, global: tag.global, pseudo: false };
}
