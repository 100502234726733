// https://goenning.net/2018/11/16/how-to-retry-dynamic-import-with-react-lazy/

import { ComponentType } from "react";

export default function retryChunkLoader(
  fn: () => Promise<any>,
  retriesLeft = 5,
  interval = 1000
): Promise<{ default: ComponentType<any> }> {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retryChunkLoader(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}
