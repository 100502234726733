import Report, { ReportPioneer } from "../types/report";
import User, { UserStatus } from "../types/user";
import { Month } from "./dateHelpers";

export function blankReport(user: User, month: number, year: number): Report {
  const rpt: Report = { id: 0, month: month, year: year, user: { id: user.id } };
  switch (user.status) {
    case UserStatus.ContinuousAuxiliary:
      rpt.pioneer = ReportPioneer.Auxiliary;
      break;
    case UserStatus.RegularPioneer:
      rpt.pioneer = ReportPioneer.Regular;
      break;
    case UserStatus.SpecialPioneer:
      rpt.pioneer = ReportPioneer.Special;
      break;
  }
  return rpt;
}

export function reportId(report: Report): string {
  return `${report.user.id}-${report.year}-${report.month}`;
}

export function parseHours(minutesAsHours: string): number | null {
  if (minutesAsHours === "") return null;

  const hours = Number(minutesAsHours);
  if (isNaN(hours)) return null;
  return hours * 60;
}

export function reportCompareDesc(a: Report, b: Report): number {
  if (a.year === b.year) {
    if (a.month > b.month) return -1;
    if (a.month < b.month) return 1;
    return 0;
  }
  if (a.year > b.year) return -1;
  return 1;
}

export function addBlankReports(user: User, reports: Report[], start: Month, end: Month): Report[] {
  const curMonth = new Month(start.year, start.month);
  while (!curMonth.after(end)) {
    const haveReportForMonth = reports.some((r) => {
      const rptMonth = new Month(r.year, r.month);
      return rptMonth.equals(curMonth);
    });
    if (!haveReportForMonth) {
      reports.push(blankReport(user, curMonth.month, curMonth.year));
    }
    curMonth.addMonth();
  }

  return reports;
}

// whether or not this month is after the 2023 annual meeting announcement takes effect.
// when this returns false, the placements/videos/return visits are not displayed, and hours is only displayed for pioneers.
export function showDetailReportFields(month: Month): boolean {
  return month.year < 2023 || (month.year === 2023 && month.month < 10);
}
