import { Permission } from "../types/permission";
import HourglassGlobals from "./globals";

export function canGetSummaryTotals(): boolean {
  return (
    HourglassGlobals.permissions.has(Permission.ViewSummary) &&
    (HourglassGlobals.permissions.has(Permission.ViewSummaryDetailsGroup) ||
      HourglassGlobals.permissions.has(Permission.ViewSummaryDetails))
  );
}
