import { ISODateString } from "../date";

export type OtherSchedule = {
  id: number;
  assigneeUserId: number | null;
  assigneeText?: string;
  kind: OtherScheduleKind;
  date: ISODateString;
  time?: string;
  slot: number;
  weekly: boolean;
  notes?: string;
  fsMeetingLocationId?: number;
  individual: boolean;
};

export enum OtherScheduleKind {
  FieldMinistry = "fm",
  Cleaning = "clean",
}
