import React from "react";
import retryChunkLoader from "../../helpers/chunkLoadRetry";

export const MeetingAttendanceLazy = React.lazy(() =>
  retryChunkLoader(() =>
    import(/* webpackPrefetch: true */ "./index" /* webpackChunkName: "cong" */).then((m) => ({
      default: m.MeetingAttendance,
    })),
  ),
);

export const CongProfileLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.CongProfile }))),
);

export const ManageFSGroupsLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.ManageFSGroups }))),
);

export const ManageLangGroupsLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.ManageLanguageGroups }))),
);

export const HourglassImportLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.HourglassImport }))),
);

export const ExportModalLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.ExportModal }))),
);

export const E2EMigrationLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.E2EMigration }))),
);

export const TerritoryLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.Territories }))),
);

export const ActivityLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.Activity }))),
);
