import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useRef, useState } from "react";
import { Col, Dropdown, Row, Spinner } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import { avAttendantApi } from "../../../api/avAttendant";
import QueryKeys from "../../../api/queryKeys";
import { hasAbsence } from "../../../helpers/absence";
import { HGBugsnagNotify } from "../../../helpers/bugsnag";
import { getDayjs, mmDate, stringToDate, weekOf, weekOfString, wmDate } from "../../../helpers/dateHelpers";
import { getStatusCode } from "../../../helpers/errors";
import HourglassGlobals from "../../../helpers/globals";
import { selectedCong } from "../../../helpers/langGroups";
import { containsFuzzy, t } from "../../../helpers/locale";
import { userCompare } from "../../../helpers/user";
import { NBSP } from "../../../helpers/util";
import { deleteFromAVACaches, updateAVACaches } from "../../../query/avAttendant";
import { ISODateString } from "../../../types/date";
import { Permission } from "../../../types/permission";
import { Absence } from "../../../types/scheduling/absence";
import {
  AVAttendantAsset,
  AVAttendantAssignment,
  AVAttendantAssignmentType,
} from "../../../types/scheduling/avAttendant";
import { Events, ScheduledEvent } from "../../../types/scheduling/events";
import { AssignmentNotification, NotificationType, UserPrivilegeMap } from "../../../types/scheduling/meetings";
import { MidweekMeetingPartAsset, MidweekMeetingPartType } from "../../../types/scheduling/midweek";
import { CongSettings } from "../../../types/scheduling/settings";
import { WMCorePartTitleAsset, WMCoreParts } from "../../../types/scheduling/weekend";
import User from "../../../types/user";
import { AssignmentSummary, AssignmentSummaryType, SearchAssigneeModal } from "../RecentAssignments";
import { UserAssignmentDropdowns } from "../UserAssignmentDropdowns";
import { DropdownFilter, OverlappingAssignment } from "../common";
import { EventBadge } from "../eventAlert";
import { NotificationIcon, NotificationOptions, notificationStatePart } from "../notificationStatus";
import { DateHeading, avAssignmentCount } from "./common";

//force the list in this order
const assignmentTypes = [
  AVAttendantAssignmentType.Console,
  AVAttendantAssignmentType.Stage,
  AVAttendantAssignmentType.Mics,
  AVAttendantAssignmentType.Attendant,
];

export default function AvAttendantDate(props: {
  dateHeading: DateHeading;
  users: User[];
  privilegesMap: UserPrivilegeMap;
  avAssignments: AVAttendantAssignment[];
  startDate: string;
  notificationStartDate: string;
  endDate: string;
  settings: CongSettings;
  notifications: AssignmentNotification[];
  noSchedule: boolean;
  assignmentMap: Map<number, AssignmentSummary[]>;
  absences?: Absence[];
  langGroupId: number;
  events?: ScheduledEvent[];
}) {
  const canUpdate = HourglassGlobals.permissions.has(Permission.UpdateAVAttendantSchedules);
  const customEvent = props.events?.find(
    (ev) =>
      ev.week === weekOfString(props.dateHeading.date) && ev.event === Events.custom && ev.customShowOnMeetingSchedule,
  );
  const { i18n } = useTranslation();

  return (
    <Card className="mb-3">
      <Card.Body className="d-flex flex-row gap-4">
        <div>
          <Card className="calendar-day">
            <Card.Header className="bg-primary text-white text-center text-uppercase fw-bold py-1 px-4">
              {props.dateHeading.month.toLocaleMonthName(i18n.language)}
            </Card.Header>
            <Card.Body className="py-1 px-4 text-center">
              <span className="fs-1">{props.dateHeading.day}</span>
            </Card.Body>
          </Card>
        </div>
        <div className="d-flex flex-column flex-grow-1 ">
          <h4 className="fw-bold">
            {props.dateHeading.heading}
            {!!customEvent && <EventBadge context={props.dateHeading.meetingType} event={customEvent} />}
          </h4>
          <div className="d-flex flex-wrap mt-2">
            {(!props.noSchedule ||
              props.avAssignments.filter((ava) => ava.date === props.dateHeading.date).length > 0) &&
              assignmentTypes.map((avaType) => (
                <AVADropDowns
                  key={avaType}
                  type={avaType}
                  startDate={props.startDate}
                  notificationStartDate={props.notificationStartDate}
                  endDate={props.endDate}
                  schedulingDate={props.dateHeading.date}
                  assignDate={props.dateHeading.assignmentDate}
                  users={props.users}
                  privilegesMap={props.privilegesMap}
                  avAssignments={props.avAssignments}
                  notifications={props.notifications}
                  settings={props.settings}
                  canUpdate={canUpdate}
                  assignmentMap={props.assignmentMap}
                  absences={props.absences}
                  langGroupId={props.langGroupId}
                  events={props.events}
                />
              ))}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

// See if the user has an assignment on the midweek or weekend meeting for the given date, or another attendant assignment.
// This can then be made visible to alert the scheduler to the possible conflict.
// It returns the localization key for the meeting type (midweek or weekend) which has the conflict.
function haveConflictingAssignment(
  userId: number,
  date: ISODateString,
  assignmentMap: Map<number, AssignmentSummary[]>,
  settings: CongSettings,
  avaType: AVAttendantAssignmentType,
): string | undefined {
  // if date is a monday, we're looking for midweek assignments
  // if it's a sunday, we're looking for weekend assignments
  // if ava_schedule_weekly is enabled, we are looking for either one
  const dow = getDayjs(date).isoWeekday();
  const monday = weekOfString(date);

  const isWeekend = (type: AssignmentSummaryType): boolean => {
    if (type === "publicTalk" || type === "publicTalkOut") return true;
    return Object.values(WMCoreParts).includes(type as WMCoreParts);
  };

  const isCorrectType = (type: AssignmentSummaryType): boolean => {
    switch (dow) {
      case 1:
        if (settings.ava_schedule_weekly && isWeekend(type)) return true;
        return Object.values(MidweekMeetingPartType).includes(type as MidweekMeetingPartType);
      case 7:
        return isWeekend(type);
      default:
        const err = new Error("haveMeetingAssignment: unexpected day of week");
        console.error(err, dow);
        HGBugsnagNotify("avaUnexpectedDow", err);
        return false;
    }
  };

  const isAVAType = (type: AssignmentSummaryType): boolean => {
    return Object.values(AVAttendantAssignmentType).includes(type as AVAttendantAssignmentType) && type !== avaType;
  };

  const meetingAssignment = assignmentMap.get(userId)?.find((as) => isCorrectType(as.type) && as.date === monday);
  if (meetingAssignment) {
    // they have a midweek or weekend assignment. return the correct string depending on which one it is
    // it's possible that they have multiple; we just return the first one found
    return isWeekend(meetingAssignment.type)
      ? WMCorePartTitleAsset[meetingAssignment.type as WMCoreParts]
      : MidweekMeetingPartAsset[meetingAssignment.type as MidweekMeetingPartType];
  }
  // if they don't have a meeting assignment, see if they have another attendant assignment
  const avaAssignmentType = assignmentMap.get(userId)?.find((as) => isAVAType(as.type) && as.date === date)
    ?.type as AVAttendantAssignmentType;
  if (avaAssignmentType) {
    return AVAttendantAsset[avaAssignmentType];
  }
  return;
}

// this could probably be refactored to move the computation logic up into the parent, and constrain this component
// to just the things we need to display
function AVADropDowns(props: {
  type: AVAttendantAssignmentType;
  schedulingDate: ISODateString;
  startDate: ISODateString;
  notificationStartDate: ISODateString;
  assignDate: ISODateString;
  endDate: ISODateString;
  avAssignments: AVAttendantAssignment[];
  users: User[];
  privilegesMap: UserPrivilegeMap;
  notifications: AssignmentNotification[];
  settings: CongSettings;
  canUpdate: boolean;
  assignmentMap: Map<number, AssignmentSummary[]>;
  absences?: Absence[];
  langGroupId: number;
  events?: ScheduledEvent[];
}) {
  type SlotType = {
    slot: number;
    type?: AVAttendantAssignmentType;
  };

  const { t } = useTranslation();
  // this shows the assignment modal for a given slot and type
  const [showAssignmentModalSlot, setShowAssignmentModalSlot] = useState<SlotType>({ slot: -1 });
  const saving = useRef<SlotType[]>([]);
  const queryClient = useQueryClient();
  const assignMutation = useMutation({
    mutationFn: (ava: AVAttendantAssignment) => avAttendantApi.saveAssignment(ava, props.langGroupId),
  });
  const deleteMutation = useMutation({
    mutationFn: (assignmentId: number) => avAttendantApi.deleteAssignment(assignmentId, props.langGroupId),
  });
  const [assigneeFilter, setAssigneeFilter] = useState("");
  const [needSetFocus, setNeedSetFocus] = useState(false);
  const [triggerRender, setTriggerRender] = useState(false);
  const cong = selectedCong(props.langGroupId);

  type Dropdown = {
    assignmentSlot: number;
    element: JSX.Element;
    label?: string;
  };
  const dropdowns: Dropdown[] = [];
  const isPastWeek = getDayjs(props.schedulingDate).isBefore(weekOf(new Date()), "day");

  const assign = async (
    type: AVAttendantAssignmentType,
    userId: number,
    assignmentId: number,
    assignDate: string,
    slot: number,
  ) => {
    saving.current = [...saving.current, { slot: slot, type: type }];
    const ava: AVAttendantAssignment = {
      id: assignmentId,
      date: assignDate,
      type: type,
      assignee: userId,
      slot: slot,
    };
    try {
      const savedAva = await assignMutation.mutateAsync(ava);
      updateAVACaches(queryClient, props.langGroupId, [savedAva]);
    } catch (err: any) {
      if (getStatusCode(err) === 409) {
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.AVAttendantAssignment, props.langGroupId, props.startDate, props.endDate],
        });
      }
      console.error("error setting av/attendant assignment", err);
      HGBugsnagNotify("avaSave", err);
    } finally {
      saving.current = saving.current.filter((s) => !(s.slot === slot && s.type === type));
      // need to force a re-render, since updating the Ref doesn't do it
      setTriggerRender(!triggerRender);
    }
  };

  const deleteAssignment = async (assignment: AVAttendantAssignment) => {
    saving.current = [...saving.current, { slot: assignment.slot, type: assignment.type }];
    try {
      await deleteMutation.mutateAsync(assignment.id);
      deleteFromAVACaches(queryClient, props.langGroupId, assignment.id);
    } catch (err: any) {
      console.error("error deleting av/attendant assignment", err);
      HGBugsnagNotify("avaDelete", err);
    } finally {
      saving.current = saving.current.filter((s) => !(s.slot === assignment.slot && s.type === assignment.type));
      setTriggerRender(!triggerRender);
    }
  };

  const heading = (): string => {
    switch (props.type) {
      case AVAttendantAssignmentType.Console:
        return t("schedules.privileges.av-console");
      case AVAttendantAssignmentType.Stage:
        return t("schedules.privileges.stage");
      case AVAttendantAssignmentType.Mics:
        return t("schedules.privileges.microphones");
      case AVAttendantAssignmentType.Attendant:
        return t("schedules.privileges.attendant");
    }
    return "?";
  };

  const getAssignees = (type: AVAttendantAssignmentType): User[] => {
    //get the assignments of this type for this date, and create a set of all the userIds
    const assigned = new Set(
      props.avAssignments
        .filter((ava) => ava.type === type && ava.date === props.schedulingDate && ava.assignee)
        .map((ava) => ava.assignee),
    );

    return props.users.filter((u) => assigned.has(u.id));
  };

  const assignees = getAssignees(props.type);

  //if there are no assignments for this type, skip. e.g. no mics or stage during covid
  //if anyone has been assigned, even if the setting for # of assignments is 0, we still want to show them
  const count = avAssignmentCount(props.settings, props.type) || assignees.length;
  const videoCount =
    props.type === AVAttendantAssignmentType.Console
      ? avAssignmentCount(props.settings, AVAttendantAssignmentType.Video) || assignees.length
      : 0;

  if (!count && !videoCount) return null;

  // everyone who has this privilege and isn't already assigned
  const allPossibleUsers = (type: AVAttendantAssignmentType): User[] => {
    const privileged = new Set<number>(props.privilegesMap[type]);
    const assigned = new Set<number>(getAssignees(type).map((u) => u.id));
    return props.users
      .filter(
        (u) =>
          privileged.has(u.id) &&
          !assigned.has(u.id) &&
          (assigneeFilter ? containsFuzzy(u.displayName, assigneeFilter) : true),
      )
      .sort(userCompare);
  };

  const userMap = new Map<number, User>(props.users.map((u) => [u.id, u]));

  const findAssignment = (type: AVAttendantAssignmentType, slot: number): AVAttendantAssignment | undefined => {
    return props.avAssignments.find(
      (ava) => ava.type === type && ava.slot === slot && ava.date === props.schedulingDate,
    );
  };

  const avaHasAbsence = (userId: number): boolean => {
    if (props.settings.ava_schedule_weekly && !!cong) {
      // need to check absence for both of the meeting dates; assignDate is Monday
      const mm = mmDate(props.assignDate, cong, props.events);
      const wm = wmDate(props.assignDate, cong, props.events);
      return (
        hasAbsence(userId, stringToDate(mm), props.absences) || hasAbsence(userId, stringToDate(wm), props.absences)
      );
    }
    // assignDate is the meeting date here
    return hasAbsence(userId, stringToDate(props.assignDate), props.absences);
  };

  const maxSlot = (type: AVAttendantAssignmentType): number => {
    const assignments = props.avAssignments.filter(
      (a) => a.type === type && a.date === props.schedulingDate && !!a.assignee,
    );
    return Math.max(...assignments.map((a) => a.slot));
  };

  const uiLabel = (settings: CongSettings, type: AVAttendantAssignmentType, slot: number): string => {
    const label = avaLabel(settings, type, slot);
    if (!!label) return label;
    if (slot !== 0) return "";
    switch (type) {
      case AVAttendantAssignmentType.Attendant:
      case AVAttendantAssignmentType.Mics:
      case AVAttendantAssignmentType.Stage:
        return NBSP;
      default:
        return "";
    }
  };

  const addDropdowns = (type: AVAttendantAssignmentType) => {
    // find the largest of the number of slots configured by settings and the highest slot already assigned
    for (let slot = 0; slot < Math.max(avAssignmentCount(props.settings, type), maxSlot(type) + 1); slot++) {
      const avAssignment = findAssignment(type, slot);

      // for ava, the part field of the notification response is the assignment id.
      // this could probably be improved, perhaps by having the assignment id be its own field
      const notification = props.notifications.find(
        (n) =>
          n.type === NotificationType.AVAttendant && n.part === avAssignment?.id && n.date === props.schedulingDate,
      );
      const notificationState = avAssignment?.assignee
        ? notificationStatePart(avAssignment.assignee, notification)
        : undefined;

      const label = uiLabel(props.settings, type, slot);
      const overlappingAssignment = haveConflictingAssignment(
        avAssignment?.assignee ?? 0,
        props.schedulingDate,
        props.assignmentMap,
        props.settings,
        type,
      );
      const dropdownToggle = () => {
        if (saving.current.some((st) => st.slot === slot && st.type === type))
          return <Spinner animation="border" size="sm" />;

        return (
          <>
            {!!avAssignment?.assignee && !isPastWeek && (
              <span className="me-1">
                <NotificationIcon nstate={notificationState} hasAbsence={avaHasAbsence(avAssignment.assignee)} />
                {!!overlappingAssignment && <OverlappingAssignment className="ms-1" label={t(overlappingAssignment)} />}
              </span>
            )}
            {userMap.get(avAssignment?.assignee ?? 0)?.displayName ?? <i>{t("general.none-selected")}</i>}
          </>
        );
      };
      dropdowns.push({
        label: label,
        assignmentSlot: slot,
        element: (
          <Dropdown
            key={slot}
            className="dropdown-bounded"
            onToggle={(nextShow: boolean) => {
              if (!nextShow) setAssigneeFilter("");
              setNeedSetFocus(nextShow);
            }}
          >
            <AVAssignmentModal
              date={props.assignDate}
              assignee={props.users.find((u) => u.id === avAssignment?.assignee)}
              heading={heading()}
              label={label}
              possibleAssignees={allPossibleUsers(type)}
              partType={type}
              show={showAssignmentModalSlot.slot === slot && showAssignmentModalSlot.type === type}
              setShow={(show: boolean) =>
                show
                  ? setShowAssignmentModalSlot({
                      slot: slot,
                      type: type,
                    })
                  : setShowAssignmentModalSlot({ slot: -1 })
              }
              assign={(userId: number) =>
                assign(type, userId, avAssignment ? avAssignment.id : 0, props.schedulingDate, slot)
              }
              assignmentMap={props.assignmentMap}
              userMap={userMap}
            />
            <Dropdown.Toggle
              className="w-100"
              variant="secondary"
              disabled={!props.canUpdate || saving.current.some((st) => st.slot === slot && st.type === type)}
            >
              {dropdownToggle()}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-bounded">
              {!!overlappingAssignment && (
                <Dropdown.Item disabled>
                  <OverlappingAssignment className="me-1 mb-1" label={t(overlappingAssignment)} />
                  {t(overlappingAssignment)}
                </Dropdown.Item>
              )}
              {!!avAssignment?.id && (
                <>
                  <NotificationOptions
                    startDate={props.notificationStartDate}
                    endDate={props.endDate}
                    nstate={notificationState}
                    notification={notification}
                  />
                  <Dropdown.Item onClick={() => deleteAssignment(avAssignment)}>
                    ❌ <i>{t("schedules.fill.clear")}</i>
                  </Dropdown.Item>
                </>
              )}
              <Dropdown.Item onClick={() => setShowAssignmentModalSlot({ slot: slot, type: type })}>
                <Search /> <i>{t("schedules.assignment.list-title")}</i>
              </Dropdown.Item>
              <Dropdown.Divider />
              <DropdownFilter filter={assigneeFilter} setFilter={setAssigneeFilter} setFocus={needSetFocus} />
              <UserAssignmentDropdowns
                users={allPossibleUsers(type)}
                assignDate={props.assignDate}
                langGroupId={props.langGroupId}
                assign={(uid: number) =>
                  assign(type, uid, avAssignment ? avAssignment.id : 0, props.schedulingDate, slot)
                }
                assigneeFilter={assigneeFilter}
                privilegeAsset={AVAttendantAsset[type]}
                hasAbsence={avaHasAbsence}
              />
            </Dropdown.Menu>
          </Dropdown>
        ),
      });
    }
  };

  addDropdowns(props.type);
  //combine audio and video into the same column
  if (props.type === AVAttendantAssignmentType.Console) addDropdowns(AVAttendantAssignmentType.Video);
  // combine the various attendant assignments together
  if (props.type === AVAttendantAssignmentType.Attendant) {
    addDropdowns(AVAttendantAssignmentType.ZoomAttendant);
    addDropdowns(AVAttendantAssignmentType.SecurityAttendant);
  }

  return (
    <div className="col-12 col-sm-6 col-lg-3 text-center">
      <div>
        <b>{heading()}</b>
      </div>
      <div className="d-flex flex-column gap-1 pe-1 pb-3">
        {dropdowns.map((dd, idx) => (
          <React.Fragment key={idx}>
            {!!dd.label && (
              <div>
                <i>{dd.label}</i>
              </div>
            )}
            {dd.element}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export function avaLabel(settings: CongSettings, type: AVAttendantAssignmentType, slot: number): string {
  const defaultAudioLabel = t("schedules.console.audio");
  const defaultVideoLabel = t("schedules.console.video");
  const defaultZoomAttendantLabel = t("schedules.zoom_attendant.default_label");
  const defaultSecurityAttendantLabel = t("schedules.entrance-attendant.title");

  switch (type) {
    case AVAttendantAssignmentType.Attendant:
      switch (slot) {
        case 0:
          return settings.attendant_label_1;
        case 1:
          return settings.attendant_label_2;
        case 2:
          return settings.attendant_label_3;
        case 3:
          return settings.attendant_label_4;
        case 4:
          return settings.attendant_label_5;
        case 5:
          return settings.attendant_label_6;
      }
      break;
    case AVAttendantAssignmentType.ZoomAttendant:
      switch (slot) {
        case 0:
          return settings.zoom_attendant_label_1 || defaultZoomAttendantLabel;
        case 1:
          return settings.zoom_attendant_label_2 || defaultZoomAttendantLabel;
        case 2:
          return settings.zoom_attendant_label_3 || defaultZoomAttendantLabel;
      }
      break;
    case AVAttendantAssignmentType.SecurityAttendant:
      switch (slot) {
        case 0:
          return settings.security_attendant_label_1 || defaultSecurityAttendantLabel;
        case 1:
          return settings.security_attendant_label_2 || defaultSecurityAttendantLabel;
        case 2:
          return settings.security_attendant_label_3 || defaultSecurityAttendantLabel;
        case 3:
          return settings.security_attendant_label_4 || defaultSecurityAttendantLabel;
        case 4:
          return settings.security_attendant_label_5 || defaultSecurityAttendantLabel;
        case 5:
          return settings.security_attendant_label_6 || defaultSecurityAttendantLabel;
      }
      break;
    case AVAttendantAssignmentType.Console:
      switch (slot) {
        case 0:
          return settings.console_label_1 || defaultAudioLabel;
        case 1:
          return settings.console_label_2 || defaultAudioLabel;
        case 2:
          return settings.console_label_3 || defaultAudioLabel;
      }
      break;
    case AVAttendantAssignmentType.Video:
      switch (slot) {
        case 0:
          return settings.video_label_1 || defaultVideoLabel;
        case 1:
          return settings.video_label_2 || defaultVideoLabel;
        case 2:
          return settings.video_label_3 || defaultVideoLabel;
      }
      break;
    case AVAttendantAssignmentType.Mics:
      switch (slot) {
        case 0:
          return settings.mic_label_1;
        case 1:
          return settings.mic_label_2;
        case 2:
          return settings.mic_label_3;
        case 3:
          return settings.mic_label_4;
        case 4:
          return settings.mic_label_5;
        case 5:
          return settings.mic_label_6;
      }
      break;
    case AVAttendantAssignmentType.Stage:
      switch (slot) {
        case 0:
          return settings.stage_label_1;
        case 1:
          return settings.stage_label_2;
        case 2:
          return settings.stage_label_3;
      }
      break;
  }
  return "";
}

function AVAssignmentModal(props: {
  date: string;
  heading: string;
  label?: string;
  possibleAssignees: User[];
  partType: AVAttendantAssignmentType;
  show: boolean;
  setShow: (show: boolean) => void;
  assign: (userId: number) => Promise<void>;
  assignmentMap: Map<number, AssignmentSummary[]>;
  assignee?: User;
  userMap: Map<number, User>;
}) {
  return (
    <SearchAssigneeModal
      date={props.date}
      assignee={props.assignee}
      possibleAssignees={props.possibleAssignees}
      partType={props.partType}
      show={props.show}
      setShow={props.setShow}
      assign={props.assign}
      assignmentMap={props.assignmentMap}
      userMap={props.userMap}
      heading={
        <>
          <Row>
            <Col xs="auto" className="mx-auto">
              <h5>{props.heading}</h5>
            </Col>
          </Row>
          {!!props.label && <p className="text-center mx-5">{props.label}</p>}
        </>
      }
    />
  );
}
