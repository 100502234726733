import { FSGroup } from "../types/fsgroup";
import User from "../types/user";
import { CollatorSingleton } from "./locale";

export function groupIdMap(groups: FSGroup[]): Map<number, FSGroup> {
  const groupMap = new Map<number, FSGroup>();
  groups.forEach((group) => groupMap.set(group.id, group));
  return groupMap;
}

export type FSGroupInfo = {
  id: number;
  displayName: string;
  overseerId: number;
};

export function sortedFSGroups(fsgroups: FSGroup[], usersById: Map<number, User>, useLabels = false): FSGroupInfo[] {
  if (!Array.isArray(fsgroups)) return [];
  return fsgroups
    .map((group) => {
      return {
        id: group.id,
        displayName: getGroupDisplayName(group, usersById, useLabels),
        overseerId: group.overseer_id,
      };
    })
    .sort((a, b) => CollatorSingleton.getInstance().compare(a.displayName, b.displayName));
}

export function getGroupDisplayName(group: FSGroup, users: Map<number, User>, useLabel = false): string {
  const overseerName = users.get(group.overseer_id)?.displayName || "";
  if (useLabel) {
    return group.notes ? `${group.notes} (${overseerName})` : `(${overseerName})`;
  }
  return group.name ? group.name : overseerName;
}
