import { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import { Question } from "react-bootstrap-icons";
import { RegisterCong } from "../../types/register/registerCong";
import { getLocaleWithCode } from "../../helpers/locale";
import { OverlayTrigger, Popover } from "react-bootstrap";
import HourglassGlobals from "../../helpers/globals";

export function meetingSearchWindow(localeSymbol: string) {
  const baseUrl = "https://apps.jw.org/";
  const appendUrl = "_SRCHMTGMAPS";
  window.open(baseUrl + localeSymbol + appendUrl, "_blank");
}

export function congShareHelp() {
  window.open("https://youtu.be/-qzaCARU-Ls", "_blank");
}

function CongInfo(props: {
  cong: RegisterCong;
  onCongChange: (name: keyof RegisterCong, value: any) => void;
  formValidated: boolean;
}) {
  const { t, i18n } = useTranslation();
  const [triedFindCong, setTriedFindCong] = useState(false);
  const handleShareChange = (e: any) => {
    //verify that we have valid share text: look for the guid
    const guidRegex = /w=[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i;
    if (e.target.value && typeof e.target.value === "string") {
      if (!guidRegex.test(e.target.value)) {
        e.currentTarget.setCustomValidity(t("popup.error.title.invalid-input"));
      } else {
        e.currentTarget.setCustomValidity("");
      }
    }

    props.onCongChange(e.currentTarget.name, e.currentTarget.value);
  };

  const handleCheckChange = (e: any) => {
    props.onCongChange(e.currentTarget.name, e.currentTarget.checked);
  };

  const handleNumberChange = (e: any) => {
    const numberValue = parseInt(e.currentTarget.value);
    if (isNaN(numberValue) || numberValue < 1) return;
    props.onCongChange(e.currentTarget.name, numberValue);
  };

  const timezonesForCountry = (): Timezone[] => {
    const selectedCountry =
      window.HGGlobal?.localeData.countries.find((country) => country.id === props.cong.countriesId) ??
      HourglassGlobals.countries()[0]!;
    if (!props.cong.countriesId || !selectedCountry) {
      return [];
    }
    const countryTimezones =
      window.HGGlobal?.localeData.timezones.filter((tz) => tz.countrycode === selectedCountry.code) ??
      HourglassGlobals.timezones()[0]!;
    if (countryTimezones.length > 1) {
      countryTimezones.sort((a, b) => {
        if (a.offset > b.offset) return 1;
        if (b.offset > a.offset) return -1;
        return 0;
      });
      countryTimezones.unshift({ id: 0, name: "", offset: "", countrycode: selectedCountry.code });
    }
    return countryTimezones;
  };

  const countries = useRef<Country[]>(new Array<Country>());
  const languages = useRef<Locale[]>(new Array<Locale>());

  const curLocale = getLocaleWithCode(i18n.language);
  const selectLanguage = () => {
    props.onCongChange("localesId", curLocale.id);
  };

  useEffect(() => {
    const tzs = timezonesForCountry();
    if (tzs.length === 1) {
      //if there's only one time zone, select it
      // @ts-ignore noUncheckedIndexedAccess
      props.onCongChange("timezonesId", tzs[0].id);
    }
    // eslint-disable-next-line
  }, [props.cong.countriesId]);

  //one-time sort of the countries & languages
  useEffect(() => {
    const coll = new Intl.Collator(i18n.language);
    countries.current =
      window.HGGlobal?.localeData.countries.sort((a, b) => {
        return coll.compare(a.name, b.name);
      }) ?? HourglassGlobals.countries();
    languages.current =
      window.HGGlobal?.localeData.locales.sort((a, b) => {
        return coll.compare(a.name, b.name);
      }) ?? HourglassGlobals.locales();

    //select the current language
    selectLanguage();
    // eslint-disable-next-line
  }, [curLocale]);

  return (
    <Card>
      <Card.Header>
        <h5>{t("list.congregation.title")}</h5>
      </Card.Header>

      <Card.Body className="hg-user-edit">
        <Row>
          <Col md={4}>
            <Form.Group controlId="number">
              <Form.Label>{t("register.cong-number")}</Form.Label>
              <Form.Control
                className="mt-1"
                required
                name="number"
                type="number"
                min="1"
                max="9999999"
                value={props.cong.number || ""}
                autoComplete="off"
                isInvalid={props.cong.congNumInvalid}
                onChange={handleNumberChange}
              />
            </Form.Group>
          </Col>
          <Col lg={8} sm={12}>
            <Form.Group controlId="shareText">
              <Form.Label>
                {t("register.cong-share")}
                <Button
                  className="ms-2"
                  variant="info"
                  size="sm"
                  onClick={() => {
                    setTriedFindCong(true);
                    congShareHelp();
                  }}
                >
                  <Question color="white" size={16} />
                </Button>
                <Button
                  className="ms-2"
                  size="sm"
                  variant="primary"
                  onClick={() => {
                    setTriedFindCong(true);
                    meetingSearchWindow(curLocale.symbol);
                  }}
                >
                  {t("search.hint")}
                </Button>
                {triedFindCong && (
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={
                      <Popover>
                        <Popover.Body>{t("register.cong-not-listed.help")}</Popover.Body>
                      </Popover>
                    }
                  >
                    <Button className="ms-2" size="sm" variant="secondary">
                      {t("register.cong-not-listed.title")}
                    </Button>
                  </OverlayTrigger>
                )}
              </Form.Label>
              <Form.Control
                required
                as="textarea"
                name="shareText"
                rows={6}
                value={props.cong.shareText}
                isInvalid={props.cong.shareTextInvalid}
                onChange={handleShareChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm={6} md={4}>
            <Form.Group controlId="countryId">
              <Form.Label>{t("congprofile.country")}</Form.Label>
              <Form.Select required name="countriesId" value={props.cong.countriesId} onChange={handleNumberChange}>
                <option key={0} value="" />
                {countries.current.map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col sm={6} md={4}>
            <Form.Group controlId="timezoneId">
              <Form.Label>{t("congprofile.time-zone")}</Form.Label>
              <Form.Select required name="timezonesId" value={props.cong.timezonesId} onChange={handleNumberChange}>
                {timezonesForCountry().map((tz) => (
                  <option key={tz.id} value={tz.id > 0 ? tz.id : ""}>
                    {tz.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col sm={6} md={4}>
            <Form.Group controlId="localeId">
              <Form.Label>{t("congprofile.language")}</Form.Label>
              <Form.Select required name="localesId" value={props.cong.localesId} onChange={handleNumberChange}>
                <option key={0} value="" />
                {languages.current.map((lc) => (
                  <option key={lc.id} value={lc.id}>
                    {lc.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Form.Check
              id="check-confirm-svc-cmte"
              required
              type="checkbox"
              name="approved"
              label={
                <span>
                  {t("register.confirm.service-committee")}{" "}
                  <a href="https://www.hourglass-app.com/en/tos/" target="_blank" rel="noreferrer">
                    {t("register.confirm.tos")}
                  </a>
                </span>
              }
              checked={props.cong.approved}
              onChange={handleCheckChange}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default CongInfo;
