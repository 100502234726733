import { ScheduledEvent } from "./events";

export type MM = {
  schedule?: MidweekScheduleInfo;
  meeting?: MidweekMeetingInfo;
  not_available?: boolean;
};

export type MMs = {
  schedules: MidweekScheduleInfo[];
  meetings: MidweekMeetingInfo[];
  not_available?: boolean;
};

export type MidweekMeetingInfo = {
  id: number;
  date: string;
  lang: string;
  song1: Song;
  song2: Song;
  song3: Song;
  weekly_br: string;
  tgw: MidweekMeetingPart[];
  fm: MidweekMeetingPart[];
  lac: MidweekMeetingPart[];
};

export type MidweekScheduleInfo = {
  id: number;
  date: string;
  chairman: number;
  chairman2?: number;
  chairman3?: number;
  openprayer: number;
  closeprayer: number;
  tgw: MidweekScheduleAssignment[];
  fm: MidweekScheduleAssignment[];
  lac: MidweekScheduleAssignment[];
  cbs_reader: number;
  event?: ScheduledEvent;
};

//create a type that has a subset of the MidweekScheduleInfo keys, just for core parts
export type MidweekCorePart = "chairman" | "chairman2" | "chairman3" | "openprayer" | "closeprayer" | "cbs_reader";

export type MidweekScheduleAssignment = {
  id: number;
  assignee: number;
  assistant: number;
  classroom: number;
  part: number;
};

export type Song = {
  number: number;
  title: string;
};

export type MidweekMeetingPart = {
  id: number;
  title: string;
  info: string;
  time: string;
  type: MidweekMeetingPartType;
  counsel_point: MidweekMeetingCounselPoint | null;
  counsel_point_txt?: string;
  sub_part: boolean;
  canceled: boolean;
};

export type MidweekMeetingSubstitution = {
  date: string;
  part_id: number;
  title: string;
  info: string;
  time: string;
  type: MidweekMeetingPartType;
  counsel_point: number;
};

export type MidweekMeetingSupplemental = {
  id: number;
  date: string;
  before_part_id: number;
  meeting_id: number;
  title: string;
  info: string;
  time: string;
  type: MidweekMeetingPartType;
};

export enum MidweekMeetingPartType {
  Treasures = "treasures",
  DFG = "dfg",
  Reading = "reading",
  FMDiscussion = "fm_discussion",
  InitialCall = "initcall",
  RV = "rv",
  StudentBibleStudy = "study",
  StudentTalk = "stutalk",
  LocalNeeds = "localneeds",
  LAC = "lac",
  CBS = "cbs",
  Chairman = "chairman",
  Chairman2 = "chairman2",
  Chairman3 = "chairman3",
  AuxChairman = "aux_chairman",
  HH = "hh",
  OpenPrayer = "openprayer",
  ClosePrayer = "closeprayer",
  Prayer = "prayer",
  CBSReader = "cbs_reader",
  Stream = "stream",
  None = "none",
}

//MidweekMeetingPartAsset maps part types to translated assets
export const MidweekMeetingPartAsset: Record<MidweekMeetingPartType, string> = {
  treasures: "schedules.midweek.treasures",
  dfg: "schedules.midweek.gems",
  reading: "schedules.midweek.bible-reading",
  fm_discussion: "schedules.midweek.fm-discussion",
  initcall: "schedules.midweek.initial-call",
  rv: "schedules.midweek.return-visit",
  study: "schedules.midweek.bible-study",
  stutalk: "schedules.midweek.student-talk",
  localneeds: "schedules.midweek.local-needs",
  lac: "schedules.midweek.lac",
  cbs: "schedules.midweek.cbs",
  chairman: "schedules.chairman",
  chairman2: "schedules.midweek.aux-chairman-2",
  chairman3: "schedules.midweek.aux-chairman-3",
  aux_chairman: "schedules.midweek.aux-chairman-2",
  hh: "schedules.midweek.assistant",
  openprayer: "schedules.assignment.opening-prayer",
  closeprayer: "schedules.assignment.closing-prayer",
  prayer: "schedules.prayer",
  cbs_reader: "schedules.midweek.cbs-reader",
  stream: "schedules.stream",
  none: "?",
};

export enum MidweekMeetingSections {
  TGW = "tgw",
  FM = "fm",
  LAC = "lac",
}

export type MidweekMeetingCounselPoint = {
  number: number;
  title: string;
};

export type MMAssignmentInput = {
  assignee: number;
  assistant: boolean;
  classroom: number;
  part: number;
};

export type MMCoreInput = {
  assignee: number;
  part_type: MidweekCorePart;
};

export enum WeekendNotificationFlag {
  WMSpeakerFlag = 200,
  WMOutSpeaker = 201,
  WMChairmanFlag = 220,
  WMWTConductorFlag = 225,
  WMReaderFlag = 230,
  WMOpeningPrayerFlag = 233,
  WMClosingPrayerFlag = 235,
  WMHospitalityFlag = 240,
  WMInterpreterFlag = 245,
}

export enum AVAttendantNotificationFlag {
  AVAConsole = 300,
  AVAStage = 301,
  AVAMics = 302,
  AVAAttendant = 303,
}

export enum MidweekNotificationFlag {
  MMChairmanFlag = 10,
  MMChairman2Flag = 11,
  MMChairman3Flag = 12,
  MMOpenPrayerFlag = 20,
  MMAssignmentFlag = 30,
  MMAssistantFlag = 40,
  MMReaderFlag = 50,
  MMClosePrayerFlag = 60,
}

export enum MMAutoFillKind {
  All = "all",
  Student = "students",
  NonStudent = "nonstudents",
}
