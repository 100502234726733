import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { FSGroup } from "../types/fsgroup";
import { api, fsReportPath } from "./api";
import QueryKeys from "./queryKeys";

class FSGroupAPI {
  static path = fsReportPath("/fsgroups");

  static groupIDPath(groupID: number): string {
    return `${FSGroupAPI.path}/${groupID}`;
  }

  async getAll(): Promise<FSGroup[]> {
    return api.get(FSGroupAPI.path).json();
  }

  async save(group: FSGroup): Promise<FSGroup> {
    const url = group.id ? FSGroupAPI.groupIDPath(group.id) : FSGroupAPI.path;
    const method = group.id ? "put" : "post";
    return api(url, { method, json: group }).json();
  }

  async delete(groupID: number) {
    await api.delete(FSGroupAPI.groupIDPath(groupID));
  }
}

export default function useFSGroups(options?: Partial<UseQueryOptions<FSGroup[], Error>>) {
  return useQuery({
    queryKey: [QueryKeys.FSGroups],
    queryFn: () => fsGroupApi.getAll(),
    ...options,
  });
}

export const fsGroupApi = new FSGroupAPI();
