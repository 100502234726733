import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { Question } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { congregationApi } from "../../../api/cong";
import { daysOfMonth } from "../../../helpers/dateHelpers";
import { getStoredDate, getStoredNumber, getStoredView, storeView } from "../../../helpers/dateStored";
import { getStatusCode } from "../../../helpers/errors";
import HourglassGlobals, { HGContext } from "../../../helpers/globals";
import { getLocaleWithCode } from "../../../helpers/locale";
import { guidRegex } from "../../../helpers/util";
import { Cong } from "../../../types/cong";
import { Permission } from "../../../types/permission";
import { CongSettingTypes } from "../../../types/scheduling/settings";
import { WMSection } from "../../../types/scheduling/weekend";
import { congShareHelp, meetingSearchWindow } from "../../register/congInfo";
import { CongSpeakers } from "./wm_cong_speakers";
import { CRH } from "./wm_crh";
import { Data } from "./wm_exchange";
import { Incoming } from "./wm_incoming";
import WMMenu from "./wm_menu";
import { OutgoingMonthView as Outgoing } from "./wm_outgoing";
import { Stats } from "./wm_stats";
import { WMTalks } from "./wm_talks";
import { Warning } from "../../alerts";
import { getStoredLangGroupId } from "../../../helpers/langGroups";
import { HGBugsnagNotify } from "../../../helpers/bugsnag";

export default function WeekendMeeting(): JSX.Element {
  const myCong = useContext(HGContext).globals.cong;
  const [month, setMonth] = useState(getStoredDate(CongSettingTypes.WM) || new Date());
  const [monthCount, setMonthCount] = useState(getStoredNumber("wm_months") ?? 1);
  const [WM, setWM] = useState<WMSection>((getStoredView(CongSettingTypes.WM) as WMSection) || "incoming");
  const [langGroupId, setLangGroupId] = useState(getStoredLangGroupId());

  const weeks: string[] = [];
  for (let i = 0; i < monthCount; i++) {
    weeks.push(...daysOfMonth(new Date(new Date(month.getTime()).setMonth(month.getMonth() + i)), myCong.wmdow));
  }
  const startWeek = weeks[0] ?? "";
  const endWeek = weeks[weeks.length - 1] ?? "";

  const updateWM = (section: WMSection) => {
    storeView(CongSettingTypes.WM, section);
    setWM(section);
  };

  if (!myCong.guid) {
    return <CongAddGuid cong={myCong} />;
  }

  const getTab = () => {
    switch (WM) {
      case "incoming":
        return (
          <Incoming
            key={langGroupId}
            from={startWeek}
            to={endWeek}
            langGroupId={langGroupId}
            month={month}
            setMonth={setMonth}
            monthCount={monthCount}
            setMonthCount={setMonthCount}
          />
        );

      case "outgoing":
        return (
          <Outgoing
            key={langGroupId}
            from={startWeek}
            to={endWeek}
            langGroupId={langGroupId}
            month={month}
            setMonth={setMonth}
            monthCount={monthCount}
            setMonthCount={setMonthCount}
          />
        );

      case "crh":
        return (
          <CRH
            from={startWeek}
            to={endWeek}
            langGroupId={langGroupId}
            month={month}
            setMonth={setMonth}
            monthCount={monthCount}
            setMonthCount={setMonthCount}
          />
        );

      case "cong_speakers":
        return <CongSpeakers key={langGroupId} langGroupId={langGroupId} />;

      case "stats":
        return <Stats langGroupId={langGroupId} />;

      case "data":
        return <Data langGroupId={langGroupId} />;

      case "talks":
        return <WMTalks langGroupId={langGroupId} />;

      default:
        return <h1>this one isn&apos;t ready yet</h1>;
    }
  };

  return (
    <>
      <WMMenu
        WM={WM}
        from={startWeek}
        to={endWeek}
        setWM={updateWM}
        month={month}
        monthsCount={monthCount}
        langGroupId={langGroupId}
        setLangGroupId={setLangGroupId}
      />
      {getTab()}
    </>
  );
}

function CongAddGuid(props: { cong: Cong }) {
  const { t, i18n } = useTranslation();
  const canUpdate = HourglassGlobals.permissions.has(Permission.UpdateCongregation);
  const [inputText, setInputText] = useState(!canUpdate ? "🔒 " + t("role.congadmin") : "");

  const [validShare, setValidShare] = useState(true);
  const [show, setShow] = useState(false);

  const curLocale = getLocaleWithCode(i18n.language);
  const guid = getGUID(inputText);

  return (
    <div className="mt-4">
      <ConfirmModal key={guid} show={show} setShow={setShow} shareText={inputText} guid={guid} congID={props.cong.id} />
      <h3 className="mb-3">{t("schedules.weekend.cong-not-linked")}</h3>
      <Form.Group>
        <Button variant="info" size="sm" onClick={congShareHelp}>
          <Question color="white" size={16} />
        </Button>
        <Button variant="primary" size="sm" className="ms-2" onClick={() => meetingSearchWindow(curLocale.symbol)}>
          {t("search.hint")}
        </Button>
        <Form.Control
          as="textarea"
          isInvalid={!validShare}
          className="mt-2"
          value={inputText}
          disabled={!canUpdate}
          onChange={(e) => {
            const input = e.currentTarget.value;
            setInputText(input);
            if (!input) return;
            setValidShare(guidRegex.test(input));
          }}
          style={{ height: "10em" }}
          placeholder={t("register.cong-share")}
        />
      </Form.Group>
      <Button
        type="submit"
        variant="primary"
        className="mt-2"
        disabled={!getGUID(inputText)}
        onClick={() => setShow(true)}
      >
        {t("general.save")}
      </Button>
      <p className="text-error"></p>
    </div>
  );
}

const ConfirmModal = (props: {
  show: boolean;
  setShow: (show: boolean) => void;
  congID: number;
  guid: string;
  shareText: string;
}) => {
  const { t } = useTranslation();
  const handleClose = () => props.setShow(false);
  const ctx = useContext(HGContext);
  const [error, setError] = useState("");

  const jworgQuery = useQuery({
    queryKey: [props.guid],
    queryFn: () => congregationApi.getCongInfoFromJWOrg(props.guid),
    enabled: props.show,
    retry: (failureCount, err) => {
      switch (getStatusCode(err)) {
        case 404:
          return false;
      }
      return failureCount < 2;
    },
  });

  useEffect(() => {
    if (!jworgQuery.isError) return;
    if (getStatusCode(jworgQuery.error) === 404) {
      setError(t("warning.cong-not-found"));
    } else {
      setError(t("general.unknown-error"));
    }
  }, [jworgQuery.isError, jworgQuery.error, t]);

  const handleSubmit = async () => {
    try {
      const resp = await congregationApi.addGuid(props.congID, props.shareText);
      const flags = { ...ctx.globals.flags, congNotLinked: false };
      ctx.setGlobals({ ...ctx.globals, cong: resp, flags: flags });
    } catch (err: any) {
      switch (getStatusCode(err)) {
        case 409:
          setError(t("register.warning.duplicate-congregations"));
          break;
        case 404:
          setError(t("warning.cong-not-found"));
          break;
        default:
          setError(t("general.unknown-error"));
          HGBugsnagNotify("addCongGUID", err);
          console.error("error adding cong guid", err);
      }
    }
  };

  const body = () => {
    if (jworgQuery.isLoading) {
      return <Spinner />;
    }

    if (error) {
      return <div className="warn_text">{error}</div>;
    }

    return (
      <div>
        <p className="h3">{t("schedules.weekend.your-congregation")}</p>
        <Warning text={jworgQuery.data?.properties.orgName ?? ""} className="fw-bold" />
      </div>
    );
  };

  const footer = () => {
    if (!!error) {
      return (
        <Button variant="primary" onClick={handleClose}>
          {t("popup.error.button.ok")}
        </Button>
      );
    }

    if (!!jworgQuery.data) {
      return (
        <>
          <Button variant="secondary" onClick={handleClose}>
            {t("popup.confirm.button.no")}
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {t("popup.confirm.button.yes")}
          </Button>
        </>
      );
    }
  };

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("popup.confirm.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body()}</Modal.Body>
      <Modal.Footer>{footer()}</Modal.Footer>
    </Modal>
  );
};

export const getGUID = (inputText: string): string => {
  const result = guidRegex.exec(inputText);
  if (!result || result.length !== 1) return "";
  return result[0];
};
