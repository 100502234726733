import { useState } from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import HourglassGlobals from "../../helpers/globals";
import { Permission } from "../../types/permission";
import { RecordCardsModalLazy } from "../publishers/async";
import { RemindersModal } from "../publishers/reminders";

export function NavPublishers() {
  const { t } = useTranslation();
  const [showReminders, setShowReminders] = useState(false);
  const [showRecordCards, setShowRecordCards] = useState(false);

  return (
    <Nav className="flex-column hg-nav">
      <RemindersModal show={showReminders} setShow={setShowReminders} />
      {HourglassGlobals.permissions.has(Permission.ViewReportsAll) && (
        <RecordCardsModalLazy show={showRecordCards} setShow={setShowRecordCards} />
      )}
      {HourglassGlobals.permissions.has(Permission.UpdateUsers) && (
        <NavDropdown.Item as={Link} to="/user/edit/0">
          {t("nav.publishers.add")}
        </NavDropdown.Item>
      )}
      {HourglassGlobals.permissions.has(Permission.SendPush) && (
        <NavDropdown.Item onClick={() => setShowReminders(true)}>{t("nav.publishers.send-reminders")}</NavDropdown.Item>
      )}
      {HourglassGlobals.permissions.has(Permission.ViewReportsAll) && (
        <NavDropdown.Item onClick={() => setShowRecordCards(true)}>{t("nav.publishers.record-cards")}</NavDropdown.Item>
      )}
      {HourglassGlobals.permissions.has(Permission.ViewUsersNotPublishing) && (
        <NavDropdown.Item as={Link} to="/notpublishing">
          {t("nav.publishers.not-publishing")}
        </NavDropdown.Item>
      )}
      {HourglassGlobals.permissions.has(Permission.UpdateUsers) && (
        <NavDropdown.Item as={Link} to="/transfer">
          {t("nav.publishers.transfer")}
        </NavDropdown.Item>
      )}
      {HourglassGlobals.permissions.has(Permission.UpdateUsers) &&
        HourglassGlobals.permissions.has(Permission.UpdateReports) && (
          <NavDropdown.Item as={Link} to="/publisher/import">
            {t("nav.congregation.import")}
          </NavDropdown.Item>
        )}
      {HourglassGlobals.permissions.has(Permission.UpdateUsers) && (
        <NavDropdown.Item as={Link} to="/publisher/tags">
          {t("tags.title")}
        </NavDropdown.Item>
      )}
      <NavDropdown.Divider />
      <NavDropdown.Header>{t("nav.publishers.contact-lists")}</NavDropdown.Header>
      <NavDropdown.Item as={Link} to="/contactList">
        {t("nav.publishers.contacts.all-publishers")}
      </NavDropdown.Item>
      {HourglassGlobals.permissions.has(Permission.ViewEmergencycontacts) && (
        <NavDropdown.Item as={Link} to="/emergencylist">
          {t("nav.publishers.contacts.emergency")}
        </NavDropdown.Item>
      )}
    </Nav>
  );
}
