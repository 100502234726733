import { t } from "./locale";

export const getWMTemplateHTML = (setting: string | null) => {
  if (setting) return setting;
  return t("email.templates.wm.public-talk-speaker-reminder.body", {
    speaker: "{{speaker}}",
    talkTitle: "{{talkTitle}}",
    date: "{{date}}",
    time: "{{time}}",
    congregation: "{{congregation}}",
    sender: "{{sender}}",
  });
};

export const renderWMTemplate = (
  setting: string | null,
  data: {
    speaker: string;
    talkTitle: string;
    date: string;
    time: string;
    congregation: string;
    sender: string;
  },
) => {
  const body = getWMTemplateHTML(setting);
  return body
    .replace(/{{speaker}}/g, data.speaker)
    .replace(/{{talkTitle}}/g, data.talkTitle)
    .replace(/{{date}}/g, data.date)
    .replace(/{{time}}/g, data.time)
    .replace(/{{congregation}}/g, data.congregation)
    .replace(/{{sender}}/g, data.sender);
};
