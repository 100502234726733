import React from "react";
import retryChunkLoader from "../../helpers/chunkLoadRetry";

export const MidweekMeetingLazy = React.lazy(() =>
  retryChunkLoader(() =>
    import(/* webpackPrefetch: true */ "./index" /* webpackChunkName: "scheduling" */).then((m) => ({
      default: m.MidweekMeeting,
    })),
  ),
);

export const WeekendMeetingLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.WeekendMeeting }))),
);

export const AVAttendantLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.AVAttendant }))),
);

export const CleaningScheduleLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.CleaningSchedule }))),
);

export const FSMeetingsLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.FSMeetings }))),
);

export const PublicWitnessingLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.PublicWitnessing }))),
);

export const PrivilegeMatrixLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.PrivilegeMatrix }))),
);

export const SchedulingAbsencesLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.SchedulingAbsence }))),
);

export const SchedulingEventsLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.SchedulingEvents }))),
);

export const SchedulingSettingsLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.Settings }))),
);

export const PrivilegesLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.Privileges }))),
);

export const MemorialLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.MemorialSchedules }))),
);

export const MaintenanceLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.MaintenanceSchedules }))),
);
