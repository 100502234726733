enum QueryKeys {
  Activity = "activity",
  Whoami = "whoami",
  Startup = "startup",
  FSGroups = "fsGroups",
  Addresses = "addresses",
  Attendance = "attendance",
  Users = "users",
  NPUsers = "npusers",
  ReportTotals = "reportTotals",
  UserPrivileges = "userPrivileges",
  UserPrivilegesAll = "userPrivilegesAll",
  Reports = "reports",
  Missing = "missing",
  SummaryAP = "summaryAP",
  SummaryRP = "summaryRP",
  PermissionUserGroups = "permissionUserGroups",
  CongregationSettings = "congregationSettings",
  WMCongregations = "WMCongregations",
  WMCSchedules = "WMCSchedules",
  WMCSchedule = "WMCSchedule",
  WMSpeakers = "WMSpeakers",
  WMAllSpeakers = "WMAllSpeakers",
  WMTalks = "WMTalks",
  WMOutSpeakers = "WMOutSpeakers",
  WMTalksByCong = "WMTalksByCong",
  Transfers = "transfers",
  E2EMigration = "e2eMigration",
  SummaryMonth = "summaryMonth",
  SummaryMonthDetail = "monthDetail",
  SummaryMonthStatistics = "monthStatistics",
  CongAverages = "congAverages",
  GroupAverages = "groupAverages",
  //we're keeping publisher averages separate from reports because it's bounded to 6 months
  PublisherAverages = "pubAverages",
  ServiceYearTotals = "svcYearTotals",
  CongAnalysis = "congAnalysis",
  CongGUID = "congGUID",
  SchedulingEvents = "meetings/events",
  MidweekInfo = "meetings/info",
  MidweekSubs = "meetings/subs",
  SchedulingUserAssignments = "userAssignments",
  AllUserPrivileges = "allUserPrivileges",
  AssignmentNotifications = "assignmentNotifications",
  AVAttendantAssignment = "AVAttendantAssignment",
  InviteCode = "inviteCode",
  Absences = "absences",
  OtherSchedules = "otherSchedules",
  PublicWitnessingSchedules = "publicWitnessingSchedules",
  PublicWitnessingLocations = "publicWitnessingLocations",
  PublicWitnessingDefaults = "publicWitnessingDefaults",
  PublicWitnessingPairings = "publicWitnessingPairings",
  PublicWitnessingAvailabilities = "publicWitnessingAvailabilities",
  PublicWitnessingPreferences = "publicWitnessingPreferences",
  PublicWitnessingAllUsers = "publicWitnessingAllUsers",
  Territories = "territories",
  Territory = "territory",
  TerritoryRecordsAll = "territoryRecordsAll",
  TerritoryRecordsLatest = "territoryRecordsLatest",
  TerritoryRequests = "territoryRequests",
  TerritoryAddresses = "territoryAddresses",
  TerritoryCong = "territoryCong",
  FSMeetingLocations = "fsMeetingLocations",
  FSMeetingAvailabilities = "fsMeetingAvailabilities",
  FSMeetingTemplates = "fsMeetingTemplates,",
  LanguageGroups = "LanguageGroups",
  MemorialSchedules = "memorialSchedules",
  Tags = "Tags",
  Passkeys = "passkeys",
  MaintenanceTasks = "maintTasks",
  MaintenanceAssignments = "maintAssignments",
}

export default QueryKeys;
