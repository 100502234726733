import { useQueryClient } from "@tanstack/react-query";
import { parse } from "csv-parse/browser/esm/sync";
import { stringify } from "csv-stringify/browser/esm/sync";
import { useCallback, useState } from "react";
import { Button, Form, Modal, Tab, Tabs } from "react-bootstrap";
import { FileEarmarkSpreadsheet, FiletypeCsv } from "react-bootstrap-icons";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import urljoin from "url-join";
import { weekendApi } from "../../../api/weekend";
import { HGBugsnagNotify } from "../../../helpers/bugsnag";
import { getErrorType } from "../../../helpers/errors";
import { PythonI18nInterpolation } from "../../../helpers/globals";
import { updateSpeakerCache } from "../../../query/meetings";
import { Congregation, PublicSpeakerInput, Speaker } from "../../../types/scheduling/weekend";
import { CongName, ShowStatus, Status } from "../common";
import { ImportSpeakerData } from "./wm_exchange";

export const AddSpeakerModal = (props: {
  cong: Congregation;
  show: boolean;
  setShow: (show: boolean) => void;
  refresh: () => void;
  newSpeaker: (s: Speaker, force?: boolean) => void;
  langGroupId: number;
}) => {
  const { t } = useTranslation();
  const [key, setKey] = useState("single");

  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <Tabs activeKey={key} onSelect={(k) => setKey(k as string)} className="mb-3">
        <Tab eventKey="single" title={t("schedules.weekend.tab-speaker-single")}>
          <AddSingleSpeaker
            cong={props.cong}
            show={props.show}
            setShow={props.setShow}
            newSpeaker={props.newSpeaker}
            langGroupId={props.langGroupId}
          />
        </Tab>
        <Tab eventKey="multi" title={t("schedules.weekend.tab-speaker-multiple")}>
          <AddMultiSpeaker
            cong={props.cong}
            show={props.show}
            setShow={props.setShow}
            refresh={props.refresh}
            langGroupId={props.langGroupId}
          />
        </Tab>
        <Tab eventKey="exchange" title={t("schedules.weekend.exchange")}>
          <ImportSpeakerData cong={props.cong} langGroupId={props.langGroupId} />
        </Tab>
      </Tabs>
    </Modal>
  );
};
const AddSingleSpeaker = (props: {
  show: boolean;
  setShow: (set: boolean) => void;
  cong: Congregation;
  newSpeaker: (s: Speaker, force?: boolean) => void;
  langGroupId: number;
}) => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [suffixName, setSuffixName] = useState("");
  const [showError, setShowError] = useState(false);
  const [status, setStatus] = useState(Status.Null);
  const queryClient = useQueryClient();

  const handleClose = () => props.setShow(false);

  const handleUpdate = async () => {
    if (firstName && lastName) {
      setStatus(Status.Pending);
      const input: PublicSpeakerInput = {
        id: 0,
        congregation: props.cong.id,
        congregation_name: null,
        firstname: firstName,
        lastname: lastName,
        middlename: middleName ?? null,
        suffix: suffixName ?? null,
        email: null,
        cellphone: null,
        homephone: null,
        otherphone: null,
        appt: null,
        //we're adding a speaker from another congregation, so assume they can go out
        out: true,
        public_talks: [],
      };
      try {
        const savedSpeaker = await weekendApi.setSpeaker(input, undefined, props.langGroupId);
        setStatus(Status.Success);
        updateSpeakerCache(queryClient, props.cong.id, props.langGroupId, { id: 0 } as Speaker, savedSpeaker);
        props.newSpeaker(savedSpeaker, true);
        handleClose();
      } catch (err: any) {
        setStatus(Status.Failure);
        console.error("error adding single speaker", err);
        HGBugsnagNotify("addWMSpeaker", err);
      }
    } else {
      setShowError(true);
    }
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{t("schedules.weekend.add-speaker")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-4">
          <Form.Label>{t("general.first-name")}</Form.Label>
          <Form.Control value={firstName} onChange={(e) => setFirstName(e.target.value)} autoFocus={!firstName} />
        </Form.Group>

        <Form.Group className="mb-4">
          <Form.Label>{t("userinfo.name.middle")}</Form.Label>
          <Form.Control value={middleName} onChange={(e) => setMiddleName(e.target.value)} />
        </Form.Group>

        <Form.Group className="mb-4">
          <Form.Label>{t("userinfo.name.last")}</Form.Label>
          <Form.Control required value={lastName} onChange={(e) => setLastName(e.target.value)} />
        </Form.Group>

        <Form.Group className="mb-4">
          <Form.Label>{t("userinfo.name.suffix")}</Form.Label>
          <Form.Control required value={suffixName} onChange={(e) => setSuffixName(e.target.value)} />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Form.Text hidden={!showError} className="warn_text">
          {t("schedules.weekend.add-speaker-first-last-required")}
        </Form.Text>
        <Form.Text>{CongName(props.cong)}</Form.Text>
        <Button variant="primary" onClick={handleUpdate}>
          {t("general.save")}
        </Button>
        <ShowStatus status={status} />
      </Modal.Footer>
    </>
  );
};

type NWSSpeakerCSV = {
  Congregation: string;
  FirstName: string;
  LastName: string;
  Privilege: string;
  PhoneHome: string;
  PhoneMobile: string;
  Email: string;
  PublicTalksCanGive: string;
  PublicNotes: string;
};

type HGPublicSpeakerCSV = {
  First: string;
  Middle?: string;
  Last: string;
  Suffix?: string;
  Email: string;
  Cellphone: string;
  Homephone: string;
  Otherphone?: string;
  Appointment: string;
  Out?: boolean;
  Talks: string;
};

const AddMultiSpeaker = (props: {
  show: boolean;
  setShow: (set: boolean) => void;
  cong: Congregation;
  refresh: () => void;
  langGroupId: number;
}) => {
  const { t } = useTranslation();
  const [file, setFile] = useState({} as File);
  const [fileName, setFileName] = useState("");
  const [showError, setShowError] = useState(false);
  const handleClose = () => {
    props.refresh();
    props.setShow(false);
  };

  const handleUpdate = async () => {
    const formData = new FormData();
    formData.append("payload", file);
    try {
      const resp = await weekendApi.multSpeakerUpload(props.cong.id, formData, props.langGroupId);
      if (resp.Updated) {
        handleClose();
      } else {
        setShowError(true);
      }
    } catch (err: any) {
      setShowError(true);
      console.error("error adding multi speakers", err);
      if ((await getErrorType(err)) !== "invalid") {
        HGBugsnagNotify("WMmultiSpeakerUpload", err);
      }
    }
  };

  const processCSV = (text: string): string => {
    // if this is in the NWS format, convert it to ours
    // otherwise, just return it as it was
    const nwsSpeakers = parse(text, {
      columns: true,
      skipEmptyLines: true,
      skipRecordsWithError: true,
    }) as NWSSpeakerCSV[];
    if (nwsSpeakers.some((s) => s.FirstName && s.LastName && s.PublicTalksCanGive)) {
      // convert to our format and return the string
      const hgSpeakers = nwsSpeakers.map((s): HGPublicSpeakerCSV => {
        return {
          First: s.FirstName,
          Last: s.LastName,
          Appointment: s.Privilege,
          Homephone: s.PhoneHome,
          Cellphone: s.PhoneMobile,
          Email: s.Email,
          Talks: s.PublicTalksCanGive,
          Out: true,
        };
      });
      return stringify(hgSpeakers, { header: true });
    } else {
      return text;
    }
  };

  const onDrop = useCallback((acceptedFiles: ConcatArray<File>) => {
    if (acceptedFiles.length < 1) return;
    const speakerFile = acceptedFiles[0];
    if (!speakerFile) return;
    setFileName(speakerFile.name);
    const reader = new FileReader();
    reader.onabort = () => console.log("wmspeaker csv file reading was aborted");
    reader.onerror = () => console.error("wmspeaker csv file reading has failed");
    reader.onload = async () => {
      const textStr = reader.result as string;
      try {
        const processed = processCSV(textStr);
        setFile(new File([processed], speakerFile.name));
      } catch (err: any) {
        console.error("unexpected error with WM Speaker CSV", err);
        setShowError(true);
      }
    };
    reader.readAsText(speakerFile);
  }, []);

  const { getRootProps: getRootProps, getInputProps: getInputProps } = useDropzone({
    onDrop: onDrop,
  });

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{t("schedules.weekend.add-speaker-multiple")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>
            <p>{t("schedules.weekend.add-speaker-csv")}</p>
            <a href={urljoin(import.meta.env.VITE_PUBLIC_URL, "static/public_speakers.csv")}>
              <FileEarmarkSpreadsheet /> {t("schedules.weekend.add-speaker-csv-template")}
            </a>
          </Form.Label>
          <section className="container mt-4 mb-4">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              {!fileName && (
                <div>
                  {t("import.drop-here %(filename)s", { filename: "CSV", interpolation: PythonI18nInterpolation })}
                  <FiletypeCsv size="2em" className="mb-1 ms-2" />
                </div>
              )}{" "}
              {fileName}
            </div>
          </section>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Form.Text hidden={!showError} className="warn_text">
          {t("error.invalid-upload-content")}
        </Form.Text>
        <Form.Text>{CongName(props.cong)}</Form.Text>
        <Button variant="primary" onClick={handleUpdate}>
          {t("general.upload")}
        </Button>
      </Modal.Footer>
    </>
  );
};
