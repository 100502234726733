import { UseQueryResult } from "@tanstack/react-query";
import { Spinner } from "react-bootstrap";

//this helps with react-query: it's a centralized place to return a spinner if any query is loading, and to return
//any error messages if any queries fail. Otherwise, it returns null.
//components that use a query should call this, and if doesn't return null, return its value.
export function QueryStatus(...queries: UseQueryResult[]) {
  if (queries.every((q) => q.isSuccess)) return null;

  if (queries.some((q) => q.isLoading)) {
    return <Spinner animation="border" variant="primary" />;
  }

  return (
    <div>
      {queries
        .filter((q: UseQueryResult) => !!q.error)
        .map((q, idx) => (
          //@ts-ignore: q.error is known enough to have a message property
          <span key={idx}>{q.error.message}</span>
        ))}
    </div>
  );
}
