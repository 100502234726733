import { useQueryClient } from "@tanstack/react-query";
import { Col, Dropdown, Row } from "react-bootstrap";
import {
  CheckCircleFill,
  Envelope,
  ExclamationCircleFill,
  ExclamationTriangle,
  QuestionCircleFill,
  XCircleFill,
} from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { meetingsApi } from "../../api/meetings";
import { updateAssignmentNotificationCache } from "../../query/notification";
import {
  AssignmentNotification,
  NotificationState,
  NotificationStatus,
  NotificationType,
} from "../../types/scheduling/meetings";
import { OverlappingAssignment, notificationStatusToState } from "./common";
import { HGBugsnagNotify } from "../../helpers/bugsnag";
import SlashCircleFillReversed from "../common/SlashCircleFillReversed";

export function NotificationStatusLegend() {
  const { t } = useTranslation();

  return (
    <div>
      <Row xs="auto">
        <Col>
          <div>
            <span>
              <NotificationIcon nstate={NotificationState.Created} /> {t("schedules.assignment.status.created")}
            </span>
          </div>
          <div>
            <span>
              <NotificationIcon nstate={NotificationState.Pending} /> {t("schedules.assignment.status.pending")}
            </span>
          </div>
          <div>
            <span>
              <NotificationIcon nstate={NotificationState.Accepted} /> {t("schedules.assignment.status.accepted")}
            </span>
          </div>
        </Col>
        <Col>
          <div>
            <span>
              <NotificationIcon nstate={NotificationState.Mismatch} /> {t("schedules.assignment.status.mismatch")}
            </span>
          </div>
          <div>
            <span>
              <NotificationIcon nstate={NotificationState.Declined} /> {t("schedules.assignment.status.declined")}
            </span>
          </div>
          <div>
            <span>
              <SlashCircleFillReversed /> {t("schedules.absence.conflicting")}
            </span>
          </div>
          <div>
            <span>
              <OverlappingAssignment /> {t("schedules.potential-conflict")}
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export function NotificationIcon(props: {
  nstate?: NotificationState;
  hasAbsence?: boolean;
  scheduleConflict?: boolean;
  label?: string;
}) {
  const { t } = useTranslation();
  if (props.hasAbsence) {
    return <SlashCircleFillReversed label={t("schedules.absence.absence")} />;
  }
  if (props.scheduleConflict) {
    return <OverlappingAssignment label={t(props.label ?? "schedules.potential-conflict")} />;
  }
  switch (props.nstate) {
    case NotificationState.Accepted:
      return <CheckCircleFill color="green" aria-label={t("schedules.assignment.status.accepted")} />;
    case NotificationState.Declined:
      return <XCircleFill color="red" aria-label={t("schedules.assignment.status.declined")} />;
    case NotificationState.Pending:
      return <QuestionCircleFill color="blue" aria-label={t("schedules.assignment.status.pending")} />;
    case NotificationState.Mismatch:
      return <ExclamationTriangle aria-label={t("schedules.assignment.status.mismatch")} />;
    default:
      return <ExclamationCircleFill className="text-muted" aria-label={t("schedules.assignment.status.created")} />;
  }
}

export function notificationStatePart(
  assignedUserId: number,
  notification?: AssignmentNotification,
): NotificationState | undefined {
  if (!notification) return;
  if (notification.assignee !== assignedUserId) return NotificationState.Mismatch;
  return notificationStatusToState(notification.status);
}

type StateDesc = {
  state: NotificationState;
  desc: string;
};

export function NotificationOptions(props: {
  startDate: string;
  endDate: string;
  nstate?: NotificationState;
  notification?: AssignmentNotification;
  notificationType?: NotificationType;
  mailto?: string;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  if (!props.nstate || !props.notification) return null;

  const notificationStateDescription: StateDesc[] = [
    { state: NotificationState.Created, desc: t("schedules.assignment.status.re-send") },
    { state: NotificationState.Accepted, desc: t("schedules.assignment.status.accepted") },
    { state: NotificationState.Declined, desc: t("schedules.assignment.status.declined") },
  ];

  const getStatusForState = (state: NotificationState): NotificationStatus | undefined => {
    switch (state) {
      case NotificationState.Accepted:
        return NotificationStatus.Complete;
      case NotificationState.Declined:
        return NotificationStatus.Declined;
      default:
        return undefined;
    }
  };

  const setStatus = async (newState: NotificationState) => {
    if (!props.notification) return;
    const newNotification: AssignmentNotification = { ...props.notification, status: getStatusForState(newState) };
    try {
      const resp = await meetingsApi.updateNotificationStatus([newNotification]);
      const notification = resp ? resp[0] : undefined;
      if (notification) {
        updateAssignmentNotificationCache(
          queryClient,
          props.startDate,
          props.endDate,
          props.notificationType ?? newNotification.type,
          props.notification,
          notification,
        );
      }
    } catch (err: any) {
      console.error("error setting notification status", err);
      HGBugsnagNotify("setNotificationStatus", err);
    }
  };

  return (
    <>
      {notificationStateDescription.flatMap((stateDesc) => {
        if (props.nstate === stateDesc.state) return null;
        return (
          <Dropdown.Item key={stateDesc.state} onClick={() => setStatus(stateDesc.state)}>
            <NotificationIcon nstate={stateDesc.state} /> {stateDesc.desc}
          </Dropdown.Item>
        );
      })}
      {!!props.mailto && (
        <Dropdown.Item
          onClick={() => {
            window.open(props.mailto, "_self");
          }}
        >
          <Envelope /> {t("userinfo.email")}
        </Dropdown.Item>
      )}
      <Dropdown.Divider />
    </>
  );
}
