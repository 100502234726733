import { HTTPError } from "ky";

export enum ErrType {
  Validation = "validation",
  InvalidUserIDs = "InvalidUserIDs",
  SameCongregation = "SameCongregation",
  InvalidAddressID = "InvalidAddressID",
  MissingAttendance = "missingAttendance",
  SummaryReportsChanged = "summaryReportsChanged",
  InvalidRetention = "invalidRetention",
  CongShare = "congShare",
  NoSelfRemove = "NoSelfRemove",
  CongAdminMinimum = "CongAdminMinimum",
  MissingState = "missingState",
  GoogleSub = "googleSub",
  GoogleEmail = "googleEmail",
}

export async function getErrorType(err: any): Promise<string | undefined> {
  if (err instanceof HTTPError) {
    try {
      const data = (await err.response.clone().json()) as any;
      return data.errorType;
    } catch (respErr: any) {
      console.error("error response is not json", respErr);
    }
  }
}

export function getStatusCode(err: any): number {
  if (err instanceof HTTPError) {
    return err.response.status;
  }
  return 0;
}

export async function dumpError(err: any) {
  if (err instanceof HTTPError) {
    try {
      return err.response.clone().text();
    } catch (respErr: any) {
      console.error("error dumping error response", respErr);
    }
  }
}
