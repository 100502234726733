import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { reportsQueryKey } from "../query/report";
import Report, { FutureAP, ReportsRange } from "../types/report";
import { api, fsReportPath } from "./api";

class ReportAPI {
  static base = fsReportPath("/reports");

  static urlFromId(id: number): string {
    return `${ReportAPI.base}/${id}`;
  }

  static urlWithUserId(report: Report): string {
    return `${ReportAPI.base}/${report.user.id}/${report.id}`;
  }

  async getAll(userId: number, extra: boolean): Promise<Report[]> {
    const searchParams = extra ? { extra: 12 } : undefined;
    const resp = await api.get(`${ReportAPI.base}/${userId}`, { searchParams }).json<any>();
    return resp.reports;
  }

  //this calls getAllCong and getLastInactive simultaneously and returns their reports together.
  //it's used when we are creating the congregation S21
  async getAllCongWithInactive(svcYear: number): Promise<Report[]> {
    const timeout = 60 * 1000;

    const [all, inactive] = await Promise.all([
      api.get(`${ReportAPI.base}/all/${svcYear}`, { timeout }).json<Report[]>(),
      api.get(`${ReportAPI.base}/lastinactive`, { timeout }).json<Report[]>(),
    ]);
    return all.concat(inactive);
  }

  async getAllSixMonths(): Promise<ReportsRange> {
    return api.get(`${ReportAPI.base}/all/sixMonths`).json();
  }

  async save(report: Report): Promise<Report> {
    //always put the userId in the path, since we could be adding reports for different users
    const base = `${ReportAPI.base}/${report.user.id}`;
    const url = report.id ? `${base}/${report.id}` : base;
    const method = report.id ? "put" : "post";
    return api(url, { method, json: report }).json();
  }

  async delete(report: Report) {
    await api.delete(ReportAPI.urlWithUserId(report));
  }

  async markSubmitted(report: Report, undo: boolean): Promise<Report> {
    const urlPart = undo ? "unsubmitted" : "submitted";
    const url = `${ReportAPI.base}/${urlPart}/${report.id}`;
    return await api.put(url).json();
  }

  async futureAP(futureAP: FutureAP, del: boolean) {
    const baseUrl = `${ReportAPI.base}/futureap`;
    const url = del ? `${baseUrl}/delete` : baseUrl;
    await api.post(url, { json: futureAP }).json();
  }
}

export function useReports(userId: number, extra = false, options?: Partial<UseQueryOptions<Report[], Error>>) {
  return useQuery({
    queryKey: reportsQueryKey(userId),
    queryFn: () => reportApi.getAll(userId, extra),
    ...options,
  });
}

export const reportApi = new ReportAPI();
