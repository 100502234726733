import { ImportResponse } from "../types/import";
import { api, fsReportPath } from "./api";

export class ImportAPI {
  static basePath = fsReportPath("/import");
  static importTimeout = 60 * 1000;

  async postFiles(url: string, files: File[]): Promise<ImportResponse> {
    if (files.length === 0) return Promise.reject("no files");

    const formData = new FormData();
    files.forEach((f) => formData.append("file", f));

    return api
      .post(url, {
        body: formData,
        timeout: ImportAPI.importTimeout,
      })
      .json();
  }

  async khs(files: File[]): Promise<ImportResponse> {
    return await this.postFiles(`${ImportAPI.basePath}/khs`, files);
  }

  async tswin(file: File): Promise<ImportResponse> {
    return await this.postFiles(`${ImportAPI.basePath}/tswin`, [file]);
  }

  async jws(file: File): Promise<ImportResponse> {
    return await this.postFiles(`${ImportAPI.basePath}/jws`, [file]);
  }

  async khsJson(file: File): Promise<ImportResponse> {
    return await this.postFiles(`${ImportAPI.basePath}/khsjson`, [file]);
  }

  async excel(file: File): Promise<ImportResponse> {
    return await this.postFiles(`${ImportAPI.basePath}/excel`, [file]);
  }

  async publisherJws(file: File): Promise<ImportResponse> {
    return await this.postFiles(`${ImportAPI.basePath}/publisher/jws`, [file]);
  }
}

export const importApi = new ImportAPI();
