import { ScheduledEvent } from "./events";
import { ISODateString } from "../date";
import { Song } from "./midweek";

// hourglass-go RedactedCongregation
export type Congregation = {
  id: number;
  name: string;
  custom_name: string | null;
  address: string;
  phone: string;
  wm_dow: number;
  wm_time: string;
  mm_dow: number;
  mm_time: string;
  future_wm_dow: number;
  future_wm_time: string;
  future_mm_dow: number;
  future_mm_time: string;
  future_start_date: ISODateString;
  guid: string;
  notes: string;
  timezone_offset: string;
  timezone_name: string;
  direct_exchange: SpeakerDirectExchange;
};

export enum SpeakerDirectExchange {
  No = "no",
  Pending = "pending",
  Proposed = "proposed",
  Yes = "yes",
}

//this is RedactedUser in the backend
export type Speaker = {
  id: number;
  userId?: number;
  firstname: string;
  lastname: string;
  descriptor?: string;
  middlename: string | null;
  suffix: string | null;
  appt: string | null;
  email: string | null;
  cellphone: string | null;
  homephone: string | null;
  otherphone: string | null;
  congregation: Congregation | null;
  congregation_name: string | null;
  public_talks: PublicTalk[];
  out: boolean;
  languageGroupId: number | null;
};

export type PublicTalk = {
  number: number;
  title: string;
  last_date_given: string;
};

export type WMSchedule = {
  id: number;
  date: string;
  opening_song?: Song;
  closing_song?: Song;
  wm_chairman: number;
  temp_cong: Congregation | null;
  speaker: Speaker;
  speaker2?: number;
  public_talk: PublicTalk | null;
  talk_mod: TalkMod | null;
  confirmed: boolean;
  wt_conductor?: number;
  wm_reader: number;
  interpreter: number | null;
  closeprayer?: number;
  openprayer?: number;
  host: number;
  event: ScheduledEvent | null;
  out: PublicTalkAssignment[];
};

export function wmScheduleIsBlank(wms: WMSchedule): boolean {
  if (!wms.id) return true;
  return (
    !wms.wm_chairman &&
    (!wms.speaker || !wms.speaker.id) &&
    !wms.wm_reader &&
    !wms.public_talk?.number &&
    !wms.event?.id &&
    wms.out.length === 0 &&
    !wms.talk_mod
  );
}

export type PublicTalkAssignment = {
  id: number;
  date: ISODateString;
  speaker?: Speaker;
  congregation?: Congregation;
  public_talk?: PublicTalk;
};

// don't make anything optional in WMScheduleInput. This will force us to deal with it when setting the schedule,
// so new items aren't overlooked.
export type WMScheduleInput = {
  date: string;
  opening_song: number | null;
  wm_chairman: number;
  temp_cong: number | null;
  speaker: number;
  speaker2: number | null;
  public_talk: number;
  talk_mod: string;
  confirmed: boolean;
  wt_conductor: number | null;
  wm_reader: number;
  openprayer: number | null;
  closeprayer: number | null;
  host: number;
  interpreter: number | null;
};

export enum TalkMod {
  CO = "co",
  Other = "other",
  TBD = "tbd",
  Stream = "stream",
}

export enum TalkModNumber {
  TBD = -2,
  Other = -3,
  Stream = -4,
}

export enum WMCoreParts {
  Chairman = "wm_chairman",
  WTConductor = "wt_conductor",
  Reader = "wm_reader",
  OpeningPrayer = "wm_openprayer",
  ClosingPrayer = "wm_closeprayer",
  Host = "host",
  Interpreter = "interpreter",
}

//this maps a weekend meeting part type to the translatable asset which describes it
export const WMCorePartTitleAsset = {
  [WMCoreParts.Chairman]: "schedules.chairman",
  [WMCoreParts.WTConductor]: "schedules.weekend.wt-conductor",
  [WMCoreParts.Reader]: "schedules.weekend.wt-reader",
  [WMCoreParts.OpeningPrayer]: "schedules.assignment.opening-prayer",
  [WMCoreParts.ClosingPrayer]: "schedules.assignment.closing-prayer",
  [WMCoreParts.Host]: "schedules.privileges.hospitality",
  [WMCoreParts.Interpreter]: "schedules.interpreter.title",
};

export interface PublicTalkAssignmentInput {
  id: number;
  date: string;
  assignee: number;
  congregation: number;
  talk: number;
}

export interface PublicTalkAssignmentInputWithDate {
  date: string;
  id: number;
  assignee: number;
  congregation: number;
  talk: number;
}

export type WMSection = "incoming" | "outgoing" | "stats" | "crh" | "cong_speakers" | "talks" | "data";

export type PublicSpeakerInput = {
  id: number;
  congregation: number | null;
  congregation_name: string | null;
  firstname: string;
  lastname: string;
  middlename: string | null;
  suffix: string | null;
  descriptor?: string;
  email: string | null;
  cellphone: string | null;
  homephone: string | null;
  otherphone: string | null;
  appt: string | null;
  out: boolean;
  public_talks: PublicTalk[];
};

export type CongregationMeetingDetails = {
  weekday: number;
  time: string;
};

export type NewCongInput = {
  guid: string;
  name: string;
  weekendMeeting: CongregationMeetingDetails;
  notListed: boolean;
};

export type CongregationTradeResponse = {
  congregationId: number;
};

export type TradesWithOtherCong = {
  inbound: PublicTalkAssignment[];
  outbound: PublicTalkAssignment[];
};

export type SpeakerExchangeFormat = {
  Congregation: Congregation;
  Speakers: Speaker[];
};
