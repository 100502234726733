import Bugsnag, { Breadcrumb } from "@bugsnag/js";
import { dumpError, getStatusCode } from "./errors";

export function onBugsnagBreadcrumb(breadcrumb: Breadcrumb) {
  if (breadcrumb.metadata.targetText) {
    breadcrumb.metadata.targetText = "#REDACTED#";
  }
}

export function HGBugsnagNotify(context: string, err: any, extra?: any) {
  const statusCode = getStatusCode(err);
  if (statusCode === 401) return;
  Bugsnag.notify(err, async function (event) {
    event.context = context;
    event.addMetadata("hg", { code: statusCode, data: await dumpError(err), extra: extra });
  });
}
