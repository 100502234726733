import { ISODateString } from "../../../types/date";
import { CongSettings } from "../../../types/scheduling/settings";
import { AVAttendantAssignmentType } from "../../../types/scheduling/avAttendant";
import { Month } from "../../../helpers/dateHelpers";
import Bugsnag from "@bugsnag/js";

export type DateHeading = {
  date: ISODateString;
  heading: string;
  day: number;
  month: Month;
  noSchedule: boolean;
  assignmentDate: ISODateString;
  meetingType: "mm" | "wm";
};

export function avAssignmentCount(settings: CongSettings, type: AVAttendantAssignmentType): number {
  switch (type) {
    case AVAttendantAssignmentType.Mics:
      return settings.number_of_mic_assignments;
    case AVAttendantAssignmentType.Console:
      return settings.number_of_console_assignments;
    case AVAttendantAssignmentType.Video:
      return settings.number_of_video_assignments;
    case AVAttendantAssignmentType.Stage:
      return settings.number_of_stage_assignments;
    case AVAttendantAssignmentType.Attendant:
      return settings.number_of_attendant_assignments;
    case AVAttendantAssignmentType.ZoomAttendant:
      return settings.number_of_zoom_attendant_assignments;
    case AVAttendantAssignmentType.SecurityAttendant:
      return settings.number_of_security_attendant_assignments;
    default:
      console.error("unhandled AVAttendantAssignmentType:", type);
      Bugsnag.notify(new Error("unhandled AVA type: " + type));
      return 0;
  }
}
