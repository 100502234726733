import { UserAppt, UserGender, UserStatus } from "./user";

type NPUser = {
  id: number;
  firstname: string;
  lastname: string;
  middlename?: string;
  displayName: string;
  email?: string;
  suffix?: string;
  descriptor?: string;
  anointed?: boolean;
  cellphone?: string;
  homephone?: string;
  otherphone?: string;
  address_id?: number;
  birth?: string;
  baptism?: string;
  sex: UserGender;
  appt?: UserAppt;
  status?: UserStatus;
  rpquota?: number;
  group_id?: number;
  familycontact: boolean;
  piiconsentdate?: string;
  comments?: string;
  firstmonth?: string;
  reportstobranch: boolean;
  pioneerid?: number;
  npreason?: NPReason;
  created_at: string;
};

export function blankNPUser(): NPUser {
  return {
    id: 0,
    sex: UserGender.Male,
    firstname: "",
    lastname: "",
    displayName: "",
    familycontact: false,
    reportstobranch: false,
    created_at: "",
  };
}

export enum NPReason {
  Moved = "moved",
  Disfellowshipped = "df",
  Other = "other",
}

export default NPUser;
