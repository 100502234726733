import { E2EKey } from "./e2e";

export type Cong = {
  id: number;
  guid: string;
  name: string;
  number: number;
  locale: Locale;
  country: Country;
  timezone: Timezone;
  lastreminder?: string;
  e2ekey?: E2EKey;
  wmdow: number;
  wmtime: string;
  mmdow: number;
  mmtime: string;
  wmdow_future: number;
  wmtime_future: string;
  mmdow_future: number;
  mmtime_future: string;
  future_start_date: string;
  jworg_skip: boolean;
};

//CongUpdate is used when updating congregation information from the profile page
export type CongUpdate = {
  id: number;
  name: string;
  number: number;
  timezones_id: number;
  countries_id: number;
  locales_id: number;
  wmdow: number;
  wmtime: string;
  mmdow: number;
  mmtime: string;
};

// CongPatch is currently only used when adding a GUID
export type CongPatch = {
  congShareText: string;
};

export type CountryState = {
  fullname: string;
  abbr?: string;
};

export enum NameFormat {
  Last,
  First,
}

export type LanguageGroup = {
  id: number;
  name: string;
  localeId: number;
  address: string;
  wmdow: number;
  wmtime: string;
  mmdow: number;
  mmtime: string;
};

export type CongSearchResponse = {
  guid: string;
  name: string;
};
