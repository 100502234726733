import Bugsnag from "@bugsnag/js";
import { QueryCache, QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import { Suspense, useEffect, useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ShepherdTour } from "react-shepherd";
import "shepherd.js/dist/css/shepherd.css";
import { AddressAPI } from "./api/address";
import { XSRFHeader } from "./api/const";
import QueryKeys from "./api/queryKeys";
import { startupApi } from "./api/startup";
import { UserAPI } from "./api/user";
import { AuthRouter, RouterErrorBoundary } from "./components/authRouter";
import { MeetingAttendanceLazy } from "./components/congregation/async";
import { E2EMasterPassword } from "./components/e2e";
import ForgotPWCode from "./components/forgotpwCode";
import { InviteAccept } from "./components/inviteAccept";
import HourglassLogin from "./components/login";
import { HourglassUserNav } from "./components/nav/nav";
import { ManageUserAuthLazy } from "./components/publishers/async";
import { CongRegistration } from "./components/register/registrationStep";
import ReportEntry from "./components/reportEntry";
import { getTourSteps, tourOptions } from "./components/tour";
import { SuspenseFallback } from "./components/utils";
import "./css/App.css";
import LoadFixtures from "./fixtures/loader";
import { Month } from "./helpers/dateHelpers";
import { getStatusCode } from "./helpers/errors";
import HourglassGlobals, { GlobalContext, HGContext, HGGlobals, emptyGlobals } from "./helpers/globals";
import { storeLangGroupId } from "./helpers/langGroups";
import { localeForUser, stringToNameFormat } from "./helpers/locale";
import { setTheme } from "./helpers/setTheme";
import { nameOfUser } from "./helpers/user";
import { clearSignOutFlag, hasSignOutFlag, haveCookie, supportsCookies } from "./helpers/util";
import { reportsQueryKey } from "./query/report";
import { rqShouldRetry } from "./query/retry";
import "./scss/App.scss";
import Address from "./types/address";
import { FSGroup } from "./types/fsgroup";
import Report from "./types/report";
import User from "./types/user";
import { UnsupportedMessage, unsupportedBrowser } from "./unsupported";

type InitialLoadProps = {
  haveToken: boolean;
  onTokenChange: (have: boolean) => void;
};

const noBackend = import.meta.env.VITE_NO_BACKEND
  ? import.meta.env.VITE_NO_BACKEND.toLowerCase().trim().startsWith("t")
  : false;
const queryClient = getQueryClient();

function getQueryClient(): QueryClient {
  //if we're running in front-end dev mode, with no backend, staleTime is Infinity so nothing ever gets fetched
  //we just use the data loaded from LoadFixtures in App below
  const staleTime = noBackend ? Infinity : 15 * 1000;
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: staleTime,
        refetchOnWindowFocus: false,
        retry: rqShouldRetry,
      },
    },
    queryCache: new QueryCache({
      onError: (error, query) => {
        const errorCode = getStatusCode(error);
        if (errorCode === 404 && query.meta?.clearStoredLangGroupOn404) {
          storeLangGroupId(-1);
        }
      },
    }),
  });
}

function App() {
  const [haveToken, setHaveToken] = useState<boolean>(initialSetup);

  if (noBackend) {
    LoadFixtures(queryClient);
  }

  const handleTokenChange = (have: boolean) => {
    setHaveToken(have);
  };

  const getTheme = (): string => {
    const prefersDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
    const fallback = prefersDarkTheme ? "dark" : "light";
    try {
      return localStorage.getItem("theme") ?? fallback;
    } catch (err: any) {
      // can get security exception if browser disallows localStorage
      return fallback;
    }
  };

  setTheme(getTheme(), false);

  return (
    <Suspense fallback={<SuspenseFallback />}>
      <QueryClientProvider client={queryClient}>
        <HourglassInitialLoad haveToken={haveToken} onTokenChange={handleTokenChange} />
      </QueryClientProvider>
    </Suspense>
  );
}

function HourglassInitialLoad(props: InitialLoadProps) {
  const { t } = useTranslation();

  // if the browser doesn't support cookies, bail
  if (!supportsCookies()) {
    return <h5>{t("unsupported-browser.no-cookies.body")}</h5>;
  }

  if (unsupportedBrowser()) {
    return <UnsupportedMessage />;
  }

  if (!props.haveToken) {
    return <HourglassNotAuthenticated haveToken={false} onTokenChange={props.onTokenChange} />;
  }

  return <HourglassAuthenticated />;
}

function HourglassNotAuthenticated(props: InitialLoadProps) {
  useEffect(() => {
    clearSignOutFlag();
  }, []);

  return (
    <BrowserRouter basename={HourglassGlobals.AppBase}>
      <Routes>
        <Route
          path="/invite/admin/accept/:code"
          element={<InviteAccept admin />}
          errorElement={<RouterErrorBoundary />}
        />
        <Route path="/invite/accept/:code" element={<InviteAccept />} errorElement={<RouterErrorBoundary />} />
        <Route path="/forgotpw/:code" element={<ForgotPWCode />} errorElement={<RouterErrorBoundary />} />
        <Route path="/register" element={<CongRegistration />} errorElement={<RouterErrorBoundary />} />
        {/*make sure to this path is *, not /. It allows deep links to keep working when somebody needs to log in.*/}
        <Route
          path="*"
          element={<HourglassLogin onTokenChange={props.onTokenChange} />}
          errorElement={<RouterErrorBoundary />}
        />
      </Routes>
    </BrowserRouter>
  );
}

function HourglassAuthenticated() {
  const { i18n, t } = useTranslation();
  const [gotStartup, setGotStartup] = useState(false);
  const [populatedUsers, setPopulatedUsers] = useState(false);
  const [ctxState, setCtxState] = useState<GlobalContext>({
    globals: emptyGlobals(),
    setGlobals: () => {
      return;
    },
  });

  const updateGlobals = (newGlobals: HGGlobals) => {
    HourglassGlobals.setGlobals(newGlobals);
    setCtxState({ globals: newGlobals, setGlobals: updateGlobals });
  };

  const startupQuery = useQuery({
    queryKey: [QueryKeys.Startup],
    queryFn: () => startupApi.get(),
    staleTime: Infinity,
  });

  //not using QueryStatus here because we want isLoading to be SuspenseFallback, not a spinner
  if (startupQuery.isError) {
    return <span>{startupQuery.error.message}</span>;
  }
  if (startupQuery.isLoading) {
    return <SuspenseFallback />;
  }

  if (startupQuery.isSuccess && startupQuery.data && !gotStartup) {
    setGotStartup(true);
    const startup = startupQuery.data;
    const whoami = startup.whoami;
    if (!whoami || !whoami.user || !whoami.user.id) {
      Bugsnag.notify(new Error("startup whoami"), function (event) {
        event.context = "startupFailure";
        event.addMetadata("hg", {
          startup: !!startup,
          whoami: !!whoami,
          whoamiUser: !!whoami?.user,
          whoamiUserId: whoami?.user?.id,
        });
      });
      return (
        <span>
          {t("form.error.try-later")}
          <pre>error: whoami</pre>
        </span>
      );
    }
    const nameFmt = stringToNameFormat(whoami.congregation.country.namefmt);

    Bugsnag.setUser(whoami.user.id.toString());

    const globals: HGGlobals = {
      cong: whoami.congregation,
      staticUrl: startup.staticUrl,
      nameFmt: nameFmt,
      authUser: whoami.user,
      delegateFor: whoami.delegate_for
        ? whoami.delegate_for.map((du) => {
          return { ...du, displayName: nameOfUser(du, nameFmt) };
        })
        : [],
      permissions: new Set<string>(whoami.permissions),
      permissionGroups: startup.permissionGroups,
      oldestReport: startup.oldestReport,
      lastSubmittedMonth: startup.lastSubmittedMonth,
      workingMonth: startup.workingMonth,
      reportableServiceYears: startup.reportableServiceYears,
      flags: startup.flags,
      warningsDismissed: false,
      featureDismissed: false,
      featureFlags: whoami.featureFlags || {},
      missingCongTerritory: false,
      language_groups: whoami.language_groups || [],
      labelAsPreferredName: startup.labelAsPreferredName,
      whoamiSettings: whoami.settings,
      autoSubmitReportsBefore: new Map<number, Month>(),
    };

    HourglassGlobals.setGlobals(globals);
    UserAPI.userFromResponse(whoami.user).then((u) => {
      globals.authUser = u;
      HourglassGlobals.setGlobals(globals);
    });

    const uiLocale = localeForUser(whoami.congregation.locale.code, globals.authUser.locales_id);
    if (i18n.language !== uiLocale && globals.authUser.locales_id) {
      // if the detected language (based on the user's browser settings) doesn't match the configured language for the user,
      // and they have set a specific user locale, then use it.
      i18n.changeLanguage(uiLocale).then();
    }

    setCtxState({
      globals: globals,
      setGlobals: updateGlobals,
    });

    //cache the data we received
    queryClient.setQueryData<Report[]>(reportsQueryKey(whoami.user.id), whoami.reports);
    if (!globals.cong.e2ekey) {
      //we don't have the unwrapped key yet - we're prompting the user for the password
      //a future optimization could save the encrypted info to a separate cache key then decrypt it once we
      //unwrap, but not needed right now
      if (startup.users) {
        const userPromises: Promise<User>[] = startup.users.map((u) => UserAPI.userFromResponse(u));
        Promise.all(userPromises).then((users) => {
          queryClient.setQueryData<User[]>([QueryKeys.Users], users);
          setPopulatedUsers(true);
        });
      }

      if (startup.addresses) {
        const addrPromises: Promise<Address>[] = startup.addresses.map((a) => AddressAPI.addressFromResponse(a));
        Promise.all(addrPromises).then((addresses) =>
          queryClient.setQueryData<Address[]>([QueryKeys.Addresses], addresses),
        );
      }
    } else {
      //MUST set this here - otherwise an e2e login just spins forever
      setPopulatedUsers(true);
    }
    queryClient.setQueryData<FSGroup[]>([QueryKeys.FSGroups], startup.fsGroups);
  }

  if (ctxState.globals.permissions.size <= 1) {
    //regular user, or possibly can enter attendance
    return (
      <HGContext.Provider value={ctxState}>
        <BrowserRouter basename={HourglassGlobals.AppBase}>
          <HourglassUserNav />
          <Routes>
            <Route path="/attendance" element={<MeetingAttendanceLazy />} errorElement={<RouterErrorBoundary />} />
            <Route path="/user/auth/:id" element={<ManageUserAuthLazy />} errorElement={<RouterErrorBoundary />} />
            <Route path="/" element={<ReportEntry />} errorElement={<RouterErrorBoundary />} />
            <Route path="*" element={<Navigate to="/" />} errorElement={<RouterErrorBoundary />} />
          </Routes>
        </BrowserRouter>
      </HGContext.Provider>
    );
  }

  if (ctxState.globals.cong.e2ekey && !ctxState.globals.e2eKey) {
    //e2e but we haven't unwrapped the key. prompt for master password
    return (
      <HGContext.Provider value={ctxState}>
        <E2EMasterPassword />
      </HGContext.Provider>
    );
  }

  //because the Promise.all above for populating users may not have resolved yet, we stay in suspense until it's done
  //this prevents us from getting the users list right after startup, which is wasteful since all users are included in
  //the startup data
  if (!gotStartup || !populatedUsers) {
    return <SuspenseFallback />;
  }

  //this is the main/primary app: when a user with permissions logs in
  return (
    <HGContext.Provider value={ctxState}>
      <ShepherdTour steps={getTourSteps(ctxState.globals.authUser.id)} tourOptions={tourOptions}>
        <AuthRouter />
      </ShepherdTour>
    </HGContext.Provider>
  );
}

function initialSetup(): boolean {
  let haveToken = haveCookie(XSRFHeader);
  if (!haveToken && import.meta.env.VITE_DEV_USER && import.meta.env.VITE_DEV_PASSWORD) {
    //make local dev easier by not requiring login all the time
    haveToken = true;
  }

  if (hasSignOutFlag()) {
    haveToken = false;
  }
  return haveToken;
}

export default App;
