import { stringify } from "csv-stringify/browser/esm/sync";
import { saveAs } from "file-saver";

const utf8BOM = new Uint8Array([0xef, 0xbb, 0xbf]);

export function saveCSV(header: string[], data: string[][], filename: string) {
  for (let i = 0; i < data.length; i++) {
    // @ts-ignore noUncheckedIndexedAccess
    if (header.length !== data[i].length) throw new Error("header/data length mismatch");
  }
  data.unshift(header);
  //need the BOM so that non-ascii characters are properly decoded
  const blob = new Blob([utf8BOM, stringify(data)], {
    type: "text/csv;charset=utf-8",
  });
  saveAs(blob, filename);
}
