import { keepPreviousData, useMutation, useQueryClient } from "@tanstack/react-query";
import { FocusEvent, KeyboardEvent, MouseEvent, useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Modal,
  Overlay,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { ArrowDown, ArrowUp, Check, QuestionCircleFill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import QueryKeys from "../../api/queryKeys";
import { reportApi, useReports } from "../../api/reports";
import { HGBugsnagNotify } from "../../helpers/bugsnag";
import { Month, YYYYmm, dateToLocaleFormat, getDayjs } from "../../helpers/dateHelpers";
import { getStatusCode } from "../../helpers/errors";
import HourglassGlobals, { HGContext } from "../../helpers/globals";
import { parseHours, showDetailReportFields } from "../../helpers/report";
import { shallowEqualObjects } from "../../helpers/util";
import { updateMonthDetailReports } from "../../query/monthDetail";
import { deleteReportFromCache, reportsQueryKey, updateReportsCache } from "../../query/report";
import { Permission } from "../../types/permission";
import Report, { FutureAP, ReportPioneer } from "../../types/report";
import User, { UserStatus } from "../../types/user";
import { DeleteConfirmModal, ManualSubmitBlockedModal, UndoSubmittedReportConfirmModal } from "../confirmationModals";
import { QueryStatus } from "../queryStatus";
import { PioneerButton } from "../reports/common";
import { SaveResult, SaveStatus } from "../saveStatus";
import { HourglassDatePicker, MenuType } from "../scheduling/date";

export function ReportList(props: { user: User }) {
  const { t } = useTranslation();
  const ctx = useContext(HGContext);
  const [getExtra, setGetExtra] = useState(false);
  const [gotExtra, setGotExtra] = useState(false);
  const [showOldestReportModal, setShowOldestReportModal] = useState(false);
  const [oldestMonth, setOldestMonth] = useState<Month>();
  const queryClient = useQueryClient();

  const userId = props.user.id;
  useEffect(() => {
    if (!getExtra) return;
    //remove the query so we request extra from the backend
    //calling invalidate seems to refetch without changing the extra parameter, so we use remove
    queryClient.removeQueries({ queryKey: reportsQueryKey(userId) });
  }, [getExtra, queryClient, userId]);
  const reportsQuery = useReports(props.user.id, getExtra, { placeholderData: keepPreviousData });

  useEffect(() => {
    if (!getExtra) return;
    // here, the length changed, and we asked to get older reports, so we should scroll to the bottom
    setGotExtra(true);
    window.scrollTo(0, document.body.scrollHeight);
  }, [reportsQuery.data?.length, getExtra]);

  const queryStatus = QueryStatus(reportsQuery);
  if (queryStatus !== null) return queryStatus;
  if (!reportsQuery.data) return null;

  const canGetExtra = (): boolean => {
    if (!props.user.firstmonth || reportsQuery.data.length < 1) return true;
    const earliestReport = reportsQuery.data[reportsQuery.data.length - 1];
    if (earliestReport) {
      const earliestMonth = new Month(earliestReport.year, earliestReport.month).toDate();
      const firstMonth = Month.fromString(props.user.firstmonth).toDate();
      return firstMonth.getTime() < earliestMonth.getTime();
    }
    return false;
  };

  const onSaveReport = (report: Report) => {
    const rptMonth = new Month(report.year, report.month);

    // if this isn't the oldest month among all reports for the user, then we don't need to do anything
    if (reportsQuery.data.filter((r) => r.id > 0).some((r) => new Month(r.year, r.month).before(rptMonth))) return;

    const twoMonthsAgo = Month.fromDate(getDayjs(new Date()).add(-2, "months").toDate());
    // if it's recent, skip. we are interested in someone entering historical reports
    if (rptMonth.after(twoMonthsAgo)) return;

    // skip if it's the user's first month
    if (props.user.firstmonth && Month.fromString(props.user.firstmonth).equals(rptMonth)) return;
    const submitBefore = ctx.globals.autoSubmitReportsBefore.get(userId);
    if (!submitBefore) {
      setShowOldestReportModal(true);
      setOldestMonth(rptMonth);
    }
  };

  const updateShowOldestReportModal = (show: boolean) => {
    setShowOldestReportModal(show);
    if (!show) setOldestMonth(undefined);
  };

  const setSubmitBefore = (month: Month) => {
    ctx.globals.autoSubmitReportsBefore.set(userId, month);
  };

  return (
    <>
      {!!oldestMonth && (
        <OldestReportModal
          show={showOldestReportModal}
          setShow={updateShowOldestReportModal}
          user={props.user}
          setSubmitBefore={setSubmitBefore}
          reportMonth={oldestMonth}
        />
      )}
      <Table size="sm" className="mt-4 hg-reports" id="reports-list-table">
        {/* Need to show detail still while we have mixed reports. ReportItem assumes we have all columns.*/}
        <ReportTableHeader showDetail={true} />
        {!gotExtra && canGetExtra() && (
          <tfoot>
            <tr>
              <td colSpan={10} style={{ textAlign: "center", borderBottom: "none" }}>
                <Button size="sm" variant="secondary" onClick={() => setGetExtra(true)}>
                  {t("reportlist.button.older-reports")} <ArrowDown />
                </Button>
              </td>
            </tr>
          </tfoot>
        )}
        <tbody>
          {reportsQuery.data.map((rpt, idx) => {
            return (
              <ReportItem
                // We need a key like this to ensure a different key when userid changes, and for each month
                // report id is there so that if it changes (due to fetching after a 409 for example) a new component
                // is used
                key={`${props.user.id}_${rpt.year}_${rpt.month}_${rpt.pioneer}`}
                user={props.user}
                report={rpt}
                listIndex={idx}
                onSaveReport={onSaveReport}
              />
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

//common component to display the <thead> for a list of reports
export function ReportTableHeader(props: { showDetail: boolean }) {
  const { t } = useTranslation();
  return (
    <thead className="text-center">
      <tr>
        <th>{t("general.month")}</th>
        <th>{t("reportlist.reported")}</th>
        {props.showDetail && (
          <>
            <th>{t("reportlist.placements")}</th>
            <th>{t("report16.videoshowings")}</th>
          </>
        )}
        <th>{t("report.hours")}</th>
        {props.showDetail && <th>{t("reportlist.rv")}</th>}
        <th>{t("reportlist.bs")}</th>
        <th>{t("reportlist.credit")}</th>
        <th>{t("general.pioneer")}</th>
        <th>{t("report16.remarks")}</th>
      </tr>
    </thead>
  );
}

export function ReportHours(min?: number, participatedOnly = false) {
  if (min === 1 || (participatedOnly && (min ?? 0) > 0)) {
    return <Check color="green" size={30} />;
  }
  if (min === undefined || min === null) return null;
  return min / 60 || 0;
}

//common component to show a non-editable <tbody> with a row for each report
export function ReportTableBody(props: { reports: Report[]; showDetail: boolean }) {
  const ctx = useContext(HGContext);

  return (
    <tbody className="text-center">
      {props.reports.map((r) => (
        <tr key={reportTrKey(r)}>
          <td>{YYYYmm(r.year, r.month)}</td>
          <td>
            {!!r.reported_at ? dateToLocaleFormat(new Date(r.reported_at), ctx.globals.cong.country.datefmt) : null}
          </td>
          {props.showDetail && (
            <>
              <td>{r.placements}</td>
              <td>{r.videoshowings}</td>
            </>
          )}
          <td>{ReportHours(r.minutes)}</td>
          {props.showDetail && <td>{r.returnvisits}</td>}
          <td>{r.studies}</td>
          <td>{r.credithours}</td>
          <td>
            <PioneerButton pioneer={r.pioneer} />
          </td>
          <td>{r.remarks}</td>
        </tr>
      ))}
    </tbody>
  );
}

function reportTrKey(r: Report): string {
  return `${r.user.id}_${r.year}_${r.month}`;
}

type ReportAction = {
  description: string;
  action: () => void;
  disabled: boolean;
};

export function validateMinutes(minutes: number | undefined | null): boolean {
  if (minutes === undefined || minutes === null) return false;
  if (minutes === 1 || minutes === 15 || minutes === 30 || minutes === 45) return true;
  return !(minutes % 60 !== 0 || minutes / 60 > 500);
}

const hoursValue = (minutes: number | undefined): string | number => {
  if (minutes === 0) return "0";
  if (!minutes) return "";
  if (minutes === 1) return "0.02";
  return minutes / 60;
};

export function ReportItem(props: {
  report: Report;
  user?: User;
  monthDetailView?: boolean;
  monthDetailMonth?: string;
  listIndex: number;
  omitDetailColumns?: boolean;
  onSaveReport: (report: Report) => void;
}) {
  const { t } = useTranslation();
  const ctx = useContext(HGContext);
  const queryClient = useQueryClient();
  //set the initial state from props. subsequent changes are only kept here locally
  const [report, setReport] = useState(props.report);
  const reportSaveMutation = useMutation({ mutationFn: (report: Report) => reportApi.save(report) });
  const reportDeleteMutation = useMutation({ mutationFn: (report: Report) => reportApi.delete(report) });
  const reportSubmitMutation = useMutation({ mutationFn: (report: Report) => reportApi.markSubmitted(report, false) });
  const reportUnSubmitMutation = useMutation({ mutationFn: (report: Report) => reportApi.markSubmitted(report, true) });
  const [isDirty, setIsDirty] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [saveResult, setSaveResult] = useState(SaveResult.None);
  const [showUnsubmitConfirm, setShowUnsubmitConfirm] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showSubmitBlocked, setShowSubmitBlocked] = useState(false);
  const [invalidMinutes, setInvalidMinutes] = useState(false);
  const addAPMutation = useMutation({ mutationFn: (futureAP: FutureAP) => reportApi.futureAP(futureAP, false) });
  const delAPMutation = useMutation({ mutationFn: (futureAP: FutureAP) => reportApi.futureAP(futureAP, true) });
  const [lastSaveAt, setLastSaveAt] = useState(new Date(2022, 0, 1));
  const [wantAutoSave, setWantAutoSave] = useState(false);

  const autoSubmitBefore = ctx.globals.autoSubmitReportsBefore.get(report.user.id);
  useEffect(() => {
    if (!autoSubmitBefore || report.submitted_month || !report.id) return;
    const rptMonth = new Month(report.year, report.month);
    const setSubmitted = async () => {
      try {
        const savedReport = await reportSubmitMutation.mutateAsync(report);
        setReport(savedReport);
        updateReportsCache(queryClient, report, savedReport);
      } catch (err: any) {
        console.error("error changing submitted(auto)", err);
        HGBugsnagNotify("reportChangeSubmitted", err);
      }
    };
    if (rptMonth.before(autoSubmitBefore)) {
      setSubmitted().then();
    }
    // we only want this to run when autoSubmitBefore changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSubmitBefore]);

  if (report.id !== props.report.id) {
    // report was saved for the first time
    setReport(props.report);
  }

  const onBlur = (e: FocusEvent<HTMLTableRowElement>) => {
    //there is probably a better way to handle this using react refs
    //but we do want to handle the window/browser completely losing focus

    const targetId = e.currentTarget.id;
    setTimeout(() => {
      const curFocus = document.activeElement?.closest("tr")?.id;
      if (curFocus && curFocus === targetId) return;
      saveReport(report).then();
    }, 100);
  };

  const onKeyDown = (e: KeyboardEvent<HTMLTableRowElement>) => {
    if (e.key === "Enter") saveReport(report).then();
  };

  const saveReport = async (report: Report) => {
    if (!isDirty) return;
    // poor man's debounce. it seems that after hitting enter, the row loses focus, so we were getting
    // onKeyDown followed by onBlur and it was causing 409s
    if (new Date().getTime() - lastSaveAt.getTime() < 750) return;
    if (!validateMinutes(report.minutes)) {
      if (report.minutes !== null || !!report.id) {
        setInvalidMinutes(true);
      }
      return;
    }

    // see if we need to automatically mark this report as submitted
    const rptMonth = new Month(report.year, report.month);
    const autoSubmitBefore = ctx.globals.autoSubmitReportsBefore.get(report.user.id);
    if (autoSubmitBefore && rptMonth.before(autoSubmitBefore)) {
      report.submitted_month = rptMonth.toString();
    }

    setSaveInProgress(true);
    setLastSaveAt(new Date());
    try {
      const savedReport = await reportSaveMutation.mutateAsync(report);
      setReport(savedReport);
      updateReportsCache(queryClient, report, savedReport);
      if (props.monthDetailView && props.monthDetailMonth) {
        updateMonthDetailReports(queryClient, props.monthDetailMonth, report, savedReport);
      }
      // invalidate summary queries that are likely affected
      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.SummaryMonth, YYYYmm(savedReport.year, savedReport.month)],
      });
      if (savedReport.pioneer === ReportPioneer.Regular) {
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.SummaryRP],
        });
      }
      setIsDirty(false);
      setSaveResult(SaveResult.Success);
      props.onSaveReport(savedReport);
      if (
        document.activeElement instanceof HTMLElement &&
        document.activeElement?.closest("tr")?.id === reportTrKey(savedReport)
      ) {
        document.activeElement.blur();
      }
    } catch (err: any) {
      setSaveResult(SaveResult.Failure);
      const statusCode = getStatusCode(err);
      if (statusCode === 409 || statusCode === 404) {
        await queryClient.invalidateQueries({ queryKey: reportsQueryKey(report.user.id) });
      } else {
        console.error("save report error (reportList)", err);
        HGBugsnagNotify("saveReport", err, { targetUserId: props.user?.id, report: report });
      }
    } finally {
      setSaveInProgress(false);
    }
  };

  useEffect(() => {
    if (wantAutoSave) {
      setWantAutoSave(false);
      saveReport(report).then();
    }
    // we really just want this to run when wantAutoSave is true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wantAutoSave]);

  const rptChange = (key: keyof Report) => {
    return (e: any) => {
      const inputVal: string = e.currentTarget.value;
      let value: string | number | null;
      if (key === "remarks") {
        value = inputVal !== "" ? inputVal : null;
      } else {
        if (key === "minutes") {
          value = parseHours(inputVal);
          setInvalidMinutes(false);
        } else {
          value = parseInt(inputVal);
          if (isNaN(value) || value < 0) value = null;
        }
      }

      if (key === "credithours" && value === null) value = 0;

      updateReport(key, value).then();
    };
  };

  const updateReport = async (key: keyof Report, value: string | number | null) => {
    const newReport = { ...report, [key]: value };

    if (props.listIndex <= 1 && !report.id && key === "pioneer") {
      // handle future AP
      const futureAP: FutureAP = {
        user_id: report.user.id,
        month: report.month,
        year: report.year,
      };
      const queryKey = [QueryKeys.SummaryAP, YYYYmm(report.year, report.month)];
      if (report.pioneer === ReportPioneer.Auxiliary && newReport.pioneer !== ReportPioneer.Auxiliary) {
        // delete future AP
        try {
          await delAPMutation.mutateAsync(futureAP);
          await Promise.all([
            queryClient.invalidateQueries({
              queryKey: queryKey,
            }),
            queryClient.invalidateQueries({ queryKey: reportsQueryKey(report.user.id) }),
          ]);
          setReport(newReport);
        } catch (err: any) {
          console.error("error removing future AP", err);
          HGBugsnagNotify("delFutureAP", err, { futureAP: futureAP });
        }
        return;
      } else if (report.pioneer !== ReportPioneer.Auxiliary && newReport.pioneer === ReportPioneer.Auxiliary) {
        // add future AP
        try {
          await addAPMutation.mutateAsync(futureAP);
          await Promise.all([
            queryClient.invalidateQueries({
              queryKey: queryKey,
            }),
            queryClient.invalidateQueries({ queryKey: reportsQueryKey(report.user.id) }),
          ]);
          setReport(newReport);
        } catch (err: any) {
          const statusCode = getStatusCode(err);
          if (statusCode === 409) {
            await Promise.all([
              queryClient.invalidateQueries({
                queryKey: queryKey,
              }),
              queryClient.invalidateQueries({ queryKey: reportsQueryKey(report.user.id) }),
            ]);
          } else {
            console.error("error adding future AP", err);
            HGBugsnagNotify("addFutureAP", err, { futureAP: futureAP });
          }
        }
        return;
      }
    } else {
      setIsDirty(true);
    }

    if (!shallowEqualObjects(report, newReport)) {
      setReport(newReport);
    }
  };

  const setActive = async (active: boolean) => {
    await updateReport("minutes", active ? 1 : 0);
    setWantAutoSave(true);
  };

  const clickUnsubmit = () => {
    setShowUnsubmitConfirm(true);
  };

  const changeSubmitted = (undo: boolean) => {
    return async () => {
      if (undo) {
        setShowUnsubmitConfirm(false);
      } else if (HourglassGlobals.manuallySubmittedUserIds.size >= 5) {
        setShowSubmitBlocked(true);
        return;
      }
      if (!undo && isDirty) {
        // we need to save it first - otherwise the changes are lost
        await saveReport(report);
      }
      const mutation = undo ? reportUnSubmitMutation : reportSubmitMutation;
      try {
        const savedReport = await mutation.mutateAsync(report);
        setReport(savedReport);
        updateReportsCache(queryClient, report, savedReport);
        if (!undo) HourglassGlobals.manuallySubmittedUserIds.add(savedReport.user.id);
      } catch (err: any) {
        console.error("error changing submitted", err);
        HGBugsnagNotify("reportChangeSubmitted", err);
      }
    };
  };

  const creditMax = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const maxCredit = 55;
    const hours = report.minutes ? report.minutes / 60 : 0;
    if (hours >= maxCredit) return;
    updateReport("credithours", maxCredit - hours).then();
  };

  const deleteReport = async () => {
    try {
      await reportDeleteMutation.mutateAsync(report);
      if (props.user) setReport(deleteReportFromCache(queryClient, report, props.user));
    } catch (err: any) {
      switch (getStatusCode(err)) {
        case 404:
          await queryClient.invalidateQueries({ queryKey: reportsQueryKey(report.user.id) });
          break;
        default:
          console.error("error deleting report", err);
          HGBugsnagNotify("deleteReport", err);
      }
    } finally {
      setShowDeleteConfirm(false);
    }
  };

  const canUpdateReport = (): boolean => {
    if (HourglassGlobals.permissions.has(Permission.UpdateReports)) return true;
    if (HourglassGlobals.authUser.id === props.report.user.id) return true;
    if (HourglassGlobals.delegateFor?.some((du) => du.id === props.report.user.id)) return true;

    const groupMatch =
      !!HourglassGlobals.authUser.group_id && props.user?.group_id === HourglassGlobals.authUser.group_id;

    if (HourglassGlobals.permissions.has(Permission.UpdateReportsGroup) && groupMatch) return true;
    return (
      HourglassGlobals.permissions.has(Permission.UpdateReportsGroupSgo) && groupMatch && HourglassGlobals.authUser.sgo
    );
  };

  const hasUpdateReports = HourglassGlobals.permissions.has(Permission.UpdateReports);
  const disabled = !!report.submitted_month || !canUpdateReport();
  // disable the AP button if there's no report and it's a past month - can't set future AP on a past month
  // also, setting AP requires the Update Reports permission
  const apDisabled = (props.listIndex > 1 && !report.id && !report.minutes) || !hasUpdateReports;

  const showDetailedFields = showDetailReportFields(new Month(report.year, report.month));
  const showHours = showDetailedFields || !!report.pioneer;

  const actions = ((): ReportAction[] => {
    const act: ReportAction[] = [];
    if (!report.id) {
      act.push({ description: t("general.active"), action: () => setActive(true), disabled: false });
    } else {
      if (report.submitted_month) {
        act.push({ description: t("report.action.undo-submitted"), action: clickUnsubmit, disabled: false });
      } else {
        act.push({
          description: t("report.action.mark-submitted"),
          action: changeSubmitted(false),
          disabled: !hasUpdateReports,
        });
        act.push({ description: t("general.delete"), action: () => setShowDeleteConfirm(true), disabled: false });
      }
    }
    return act;
  })();

  return (
    <tr onBlur={onBlur} onKeyDown={onKeyDown} id={`${props.user?.id}_${props.report.year}_${props.report.month}`}>
      {props.monthDetailView && (
        <td style={{ verticalAlign: "middle" }}>
          <span className="ms-2">{props.user?.displayName}</span>
        </td>
      )}
      <td>
        <UndoSubmittedReportConfirmModal
          show={showUnsubmitConfirm}
          setShow={setShowUnsubmitConfirm}
          onConfirm={changeSubmitted(true)}
        />
        <ManualSubmitBlockedModal
          show={showSubmitBlocked}
          setShow={setShowSubmitBlocked}
          onConfirm={() => {
            return;
          }}
        />
        <DeleteConfirmModal show={showDeleteConfirm} setShow={setShowDeleteConfirm} onConfirm={deleteReport} />
        <DropdownButton size="sm" variant="secondary" title={YYYYmm(props.report.year, props.report.month)}>
          {actions.map((act) => (
            <Dropdown.Item key={act.description} onClick={act.action} disabled={act.disabled}>
              {act.description}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </td>
      <td className="text-center">
        <StatusReported
          saveInProgress={saveInProgress}
          result={saveResult}
          report={report}
          setSaveResult={setSaveResult}
        />
      </td>
      {showDetailedFields ? (
        <td>
          <Form.Control
            size="sm"
            type="number"
            className="text-center"
            value={report.placements || ""}
            disabled={disabled}
            onChange={rptChange("placements")}
            min={0}
          />
        </td>
      ) : (
        !props.omitDetailColumns && <td />
      )}
      {/* this is hard to read, and there's probably a better way, but we can't have a component defined above
      (e.g. as a const) to perform the logic here, because it gets recreated on each render, meaning that focus goes
      away with each keypress*/}
      {showDetailedFields ? (
        <>
          <td>
            <Form.Control
              size="sm"
              type="number"
              className="text-center"
              value={report.videoshowings || ""}
              disabled={disabled}
              onChange={rptChange("videoshowings")}
              min={0}
            />
          </td>
          <td>
            <HoursInput
              disabled={disabled}
              invalidMinutes={invalidMinutes}
              report={report}
              rptChange={rptChange}
              setActive={setActive}
              showDetail={showDetailedFields}
            />
          </td>
          <td>
            <Form.Control
              size="sm"
              type="number"
              className="text-center"
              value={report.returnvisits || ""}
              disabled={disabled}
              onChange={rptChange("returnvisits")}
              min={0}
            />
          </td>
        </>
      ) : // we aren't showing detailed fields. how many columns do we need to show?
      // if we are in detail edit view with omitted columns, it's just one
      props.omitDetailColumns ? (
        <td>
          <HoursInput
            disabled={disabled}
            invalidMinutes={invalidMinutes}
            report={report}
            rptChange={rptChange}
            setActive={setActive}
            showDetail={showDetailedFields}
          />
        </td>
      ) : (
        // otherwise, it's 3, but if we're not showing hours, we span the participated view across 3 columns
        <>
          {showHours && <td />}
          <td colSpan={showHours ? 1 : 3}>
            <HoursInput
              disabled={disabled}
              invalidMinutes={invalidMinutes}
              report={report}
              rptChange={rptChange}
              setActive={setActive}
              showDetail={showDetailedFields}
            />
          </td>
          {showHours && <td />}
        </>
      )}
      <td>
        <Form.Control
          size="sm"
          type="number"
          className="text-center"
          value={report.studies || ""}
          disabled={disabled}
          onChange={rptChange("studies")}
          min={0}
        />
      </td>
      <td>
        {(report.pioneer === ReportPioneer.Regular || report.pioneer === ReportPioneer.Special) && (
          <InputGroup>
            <Form.Control
              size="sm"
              type="number"
              className="text-center"
              value={report.credithours || ""}
              onChange={rptChange("credithours")}
              min={0}
            />
            {!report.credithours && (
              <Button size="sm" variant="secondary" onMouseDown={creditMax}>
                <ArrowUp />
              </Button>
            )}
          </InputGroup>
        )}
      </td>
      <td>
        <PioneerIndicator
          pioneer={report.pioneer}
          status={props.user?.status}
          disabled={disabled || !hasUpdateReports}
          apDisabled={disabled || apDisabled}
          updateReport={updateReport}
        />
      </td>
      <td>
        <Form.Control size="sm" type="text" value={report.remarks || ""} onChange={rptChange("remarks")} />
      </td>
    </tr>
  );
}

function StatusReported(props: {
  report: Report;
  saveInProgress: boolean;
  result: SaveResult;
  setSaveResult: (result: SaveResult) => void;
}) {
  const ctx = useContext(HGContext);
  const saveKey = `${props.report.user.id}_${props.report.year}_${props.report.month}`;
  if (props.saveInProgress) {
    return <Spinner animation="border" size="sm" />;
  } else {
    switch (props.result) {
      case SaveResult.Success:
      case SaveResult.Failure:
        return <SaveStatus saveResult={props.result} saveKey={saveKey} setSaveResult={props.setSaveResult} />;
      default:
        const reportedAt = props.report.reported_at
          ? dateToLocaleFormat(new Date(props.report.reported_at), ctx.globals.cong.country.datefmt)
          : "";
        return <span>{reportedAt}</span>;
    }
  }
}

export function PioneerIndicator(props: {
  pioneer?: string;
  disabled: boolean;
  apDisabled: boolean;
  status?: UserStatus;
  updateReport: (key: keyof Report, value: string | number | null) => void;
}) {
  const { t } = useTranslation();

  if (!props.status || props.status === UserStatus.Unbaptized) return null;

  if (props.pioneer === ReportPioneer.Special) {
    return (
      <Button size="sm" disabled variant="info" className="px-1 py-1 disabled-full-color">
        {t("report-list.tag.special-pioneer")}
      </Button>
    );
  }

  const clickAP = (e: MouseEvent<HTMLButtonElement>) => {
    //safari apparently doesn't focus the element you click on. we do it so the onBlur saving works.
    e.currentTarget.focus();
    props.updateReport("pioneer", props.pioneer === ReportPioneer.Auxiliary ? null : ReportPioneer.Auxiliary);
  };

  const clickRP = (e: MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.focus();
    props.updateReport("pioneer", props.pioneer === ReportPioneer.Regular ? null : ReportPioneer.Regular);
  };

  return (
    <ButtonGroup>
      <Button
        size="sm"
        disabled={props.apDisabled || props.status === UserStatus.RegularPioneer}
        className="px-1 py-1 disabled-full-color"
        onClick={clickAP}
        variant={props.pioneer === ReportPioneer.Auxiliary ? "info" : "outline-secondary"}
      >
        {t("report-list.tag.auxiliary-pioneer")}
      </Button>
      <Button
        size="sm"
        className="px-1 py-1 disabled-full-color"
        disabled={props.disabled}
        onClick={clickRP}
        variant={props.pioneer === ReportPioneer.Regular ? "info" : "outline-secondary"}
      >
        {t("report-list.tag.regular-pioneer")}
      </Button>
    </ButtonGroup>
  );
}

function HoursInput(props: {
  report: Report;
  showDetail: boolean;
  disabled: boolean;
  invalidMinutes: boolean;
  rptChange: (key: keyof Report) => (e: any) => void;
  setActive: (active: boolean) => void;
}) {
  const { t } = useTranslation();
  const invalidMinutesTarget = useRef(null);

  const hideActiveCheck = !props.showDetail && !!props.report.pioneer;

  // the regular hours input box
  if (props.showDetail || !!props.report.pioneer) {
    if (props.report.minutes === 1 && !hideActiveCheck) return <Check color="green" size={30} />;
    return (
      <>
        <Form.Control
          className="text-center"
          ref={invalidMinutesTarget}
          size="sm"
          type="number"
          value={hoursValue(props.report.minutes)}
          disabled={props.disabled}
          onChange={props.rptChange("minutes")}
          min={0}
        />
        <Overlay target={invalidMinutesTarget.current} show={props.invalidMinutes} placement="right">
          <Tooltip id="invalid-minutes">{t("popup.error.title.invalid-input")}</Tooltip>
        </Overlay>
      </>
    );
  }

  // the "did you participate" input
  return (
    <>
      {t("report.participated")}
      <div>
        <ButtonGroup>
          <Button
            size="sm"
            className="px-1 py-1 disabled-full-color"
            onClick={() => props.setActive(true)}
            disabled={props.disabled}
            variant={props.report.minutes ?? 0 > 0 ? "info" : "outline-secondary"}
          >
            {t("popup.confirm.button.yes")}
          </Button>
          <Button
            size="sm"
            className="px-1 py-1 disabled-full-color"
            disabled={props.disabled}
            onClick={() => props.setActive(false)}
            variant={props.report.minutes === 0 ? "info" : "outline-secondary"}
          >
            {t("popup.confirm.button.no")}
          </Button>
        </ButtonGroup>
      </div>
    </>
  );
}

function OldestReportModal(props: {
  show: boolean;
  setShow: (show: boolean) => void;
  user: User;
  setSubmitBefore: (month: Month) => void;
  reportMonth: Month;
}) {
  const { t } = useTranslation();
  const [month, setMonth] = useState<Month>(props.reportMonth);
  const [wizardStep, setWizardStep] = useState(1);
  const handleClose = () => {
    setWizardStep(1);
    props.setShow(false);
  };
  const dismiss = () => {
    // we don't want anything auto-submitted and we don't want this modal to appear again
    props.setSubmitBefore(new Month(2000, 1));
    handleClose();
  };
  const currentMonth = Month.fromDate(new Date()).toString();

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("reportlist.button.older-reports")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {wizardStep === 1 && (
          <div className="d-flex align-items-center flex-column">
            <QuestionCircleFill color="blue" size="3em" />
            <p className="mt-3">{t("reports.historical.entering")}</p>
          </div>
        )}
        {wizardStep === 2 && (
          <div className="d-flex align-items-center flex-column">
            <p className="mb-3">{t("reports.historical.select-month")}</p>
            <HourglassDatePicker
              menuType={MenuType.Month}
              max={currentMonth}
              date={month.toDateString()}
              onDateChange={(date: string) => {
                setMonth(Month.fromString(date));
              }}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {wizardStep === 1 && (
          <>
            <Button onClick={() => setWizardStep(2)}>{t("popup.confirm.button.yes")}</Button>
            <Button variant="secondary" onClick={() => dismiss()}>
              {t("popup.confirm.button.no")}
            </Button>
          </>
        )}
        {wizardStep === 2 && (
          <>
            <Button
              onClick={() => {
                props.setSubmitBefore(month);
                handleClose();
              }}
            >
              {t("general.proceed")}
            </Button>
            <Button variant="secondary" onClick={() => dismiss()}>
              {t("general.cancel")}
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}
