import { useTranslation } from "react-i18next";
import Dropdown from "react-bootstrap/Dropdown";
import { localizedLanguageNames } from "../helpers/dateHelpers";

export const hiddenLocales = new Set(["ase", "ise", "asf"]);

export function LanguagePicker(props: { onChange?: (code: string, id: number) => void; enableAll?: boolean }) {
  const { i18n } = useTranslation();

  // we don't show ASL as an option here to avoid confusion with browsers that likely will specify english
  const possibleLocales = localizedLanguageNames(i18n.language).filter((l) => !hiddenLocales.has(l.code));
  let myLang = i18n.language;
  switch (myLang) {
    case "ase":
    case "asf":
      myLang = "en";
      break;
    case "ise":
      myLang = "it";
      break;
  }
  const myLocale = possibleLocales.find((l) => l.code === myLang);

  const selectLang = async (eventKey: string | null) => {
    if (eventKey) {
      await i18n.changeLanguage(eventKey);
      const locale = possibleLocales.find((l) => l.code === eventKey);
      if (props.onChange) props.onChange(eventKey, locale?.id || 0);
    }
  };

  return (
    <>
      {possibleLocales.map((l) => (
        <Dropdown.Item
          key={l.code}
          eventKey={l.code}
          disabled={!props.enableAll && myLocale?.code === l.code}
          onClick={() => selectLang(l.code)}
        >
          {l.native}
          {l.native !== l.localized && <p className="text-muted hg-small-label ms-1 mb-0 lh-1">{l.localized}</p>}
        </Dropdown.Item>
      ))}
    </>
  );
}
