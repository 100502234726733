import { useTranslation } from "react-i18next";
import Report, { ReportPioneer } from "../../types/report";
import { Button } from "react-bootstrap";
import React from "react";

export function PioneerButton(props: { pioneer?: string }) {
  const { t } = useTranslation();
  if (!props.pioneer) return null;

  let text = "";
  switch (props.pioneer) {
    case ReportPioneer.Auxiliary:
      text = t("report-list.tag.auxiliary-pioneer");
      break;
    case ReportPioneer.Regular:
      text = t("report-list.tag.regular-pioneer");
      break;
    case ReportPioneer.Special:
      text = t("report-list.tag.special-pioneer");
      break;
  }

  return (
    <Button size="sm" disabled variant="info" className="px-1 py-1">
      {text}
    </Button>
  );
}

export type MonthDetailSectionData = {
  heading: string;
  reports: Report[];
};
