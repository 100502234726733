import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { ReportTotals } from "../types/reportTotals";
import { api, fsReportPath } from "./api";
import QueryKeys from "./queryKeys";

class ReportTotalsAPI {
  static path = fsReportPath("/summary/monthreporttotals");

  async get(): Promise<ReportTotals> {
    return api.get(ReportTotalsAPI.path).json();
  }
}

//a small custom hook to get the users
export default function useReportTotals(options?: Partial<UseQueryOptions<ReportTotals, Error>>) {
  return useQuery({
    queryKey: [QueryKeys.ReportTotals],
    queryFn: () => reportTotalsApi.get(),
    ...options,
  });
}

export const reportTotalsApi = new ReportTotalsAPI();
