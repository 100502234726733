import { useQueryClient } from "@tanstack/react-query";
import { TFunction } from "i18next";
import { ChangeEvent, FormEvent, useContext, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { ExclamationOctagonFill, ExclamationTriangleFill, LockFill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { e2eApi } from "../api/e2e";
import { npUserApi } from "../api/npuser";
import QueryKeys from "../api/queryKeys";
import userApi, { useUsers } from "../api/user";
import { HGBugsnagNotify } from "../helpers/bugsnag";
import { Month, addMonth, serviceYearStart, stringToLocaleDate, supportsMonthInput } from "../helpers/dateHelpers";
import {
  EncryptionError,
  arrayBufferToBase64,
  createSecretKey,
  e2ePasswordStrong,
  uint8ArrayToBase64,
} from "../helpers/e2e";
import { getErrorType, getStatusCode } from "../helpers/errors";
import HourglassGlobals, { HGContext } from "../helpers/globals";
import { userCompare } from "../helpers/user";
import { E2EKey } from "../types/e2e";
import { NPReason } from "../types/npuser";
import { EventTypes, ScheduledEvent } from "../types/scheduling/events";
import { MonthRetention } from "../types/summary";
import User from "../types/user";
import { Warning } from "./alerts";

type UserConfirmProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  user: User;
  removeUserFromCache: (u: User) => void;
  updateUserInCache: (u: User) => void;
};

//confirm setting someone as not publishing
export function NPConfirmModal(props: UserConfirmProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [processing, setProcessing] = useState(false);
  const queryClient = useQueryClient();

  const handleClose = () => {
    props.setShow(false);
    setReason(NPReason.Other);
    setErrorMessage("");
    setProcessing(false);
  };

  const [reason, setReason] = useState<NPReason>(NPReason.Other);

  const changeReason = (e: ChangeEvent<HTMLSelectElement>) => {
    setReason(e.currentTarget.value as NPReason);
  };

  const confirm = async () => {
    setProcessing(true);
    try {
      await npUserApi.notPublishing(props.user, reason);
      props.setShow(false);
      navigate("/");
      props.removeUserFromCache(props.user);
    } catch (err: any) {
      const errType = await getErrorType(err);
      let knownError = false;
      if (errType) {
        const errMessage = deleteErrTypeToMessage(t, errType);
        if (errMessage) {
          knownError = true;
          setErrorMessage(errMessage);
        } else {
          setErrorMessage(t("user.delete.error.unknown"));
        }
      }
      if (!knownError) {
        const statusCode = getStatusCode(err);
        if (statusCode === 409 || statusCode === 404) {
          await queryClient.invalidateQueries({
            queryKey: [QueryKeys.Users],
          });
          await queryClient.invalidateQueries({
            queryKey: [QueryKeys.NPUsers],
          });
        } else {
          console.error("error making npuser", err);
          HGBugsnagNotify("createNPUser", err);
        }
      }
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("user.actions.not-publishing")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!!errorMessage && <p className="text-danger">{errorMessage}</p>}
        {!errorMessage && (
          <>
            <p>
              {t("popup.not-publishing.body.1")} <b>{props.user.displayName}</b>
            </p>
            <p>{t("popup.not-publishing.body.2")}</p>
            <Form.Group controlId="npReason">
              <Form.Label>{t("notpublishing.reason.0")}</Form.Label>
              <Form.Select value={reason} onChange={changeReason}>
                <option value={NPReason.Other}>{t("notpublishing.reason.other")}</option>
                <option value={NPReason.Moved}>{t("notpublishing.reason.moved")}</option>
                <option value={NPReason.Disfellowshipped}>{t("notpublishing.reason.df")}</option>
              </Form.Select>
            </Form.Group>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!errorMessage && (
          <Button variant="danger" onClick={confirm} disabled={processing}>
            {t("user.actions.not-publishing")}
          </Button>
        )}
        <Button variant="primary" onClick={handleClose}>
          {t("general.cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

//confirm revoking someone's access
export function RevokeConfirmModal(props: UserConfirmProps) {
  const { t } = useTranslation();
  const handleClose = () => props.setShow(false);
  const [pending, setPending] = useState(false);

  const confirm = async () => {
    setPending(true);
    try {
      const resp = await userApi.revoke(props.user);
      props.updateUserInCache(resp);
      props.setShow(false);
    } catch (err: any) {
      console.error("error revoking", err);
      HGBugsnagNotify("revokeUser", err);
    } finally {
      setPending(false);
    }
  };

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("user.actions.revoke-access")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t("user.actions.revoke.confirm.1")}</p>
        <p>{t("user.actions.revoke.confirm.2")}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={confirm} disabled={pending}>
          {t("general.proceed")}
        </Button>
        <Button variant="primary" onClick={handleClose}>
          {t("general.cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

//confirm restoring someone's access
export function UnrevokeConfirmModal(props: UserConfirmProps) {
  const { t } = useTranslation();
  const handleClose = () => props.setShow(false);
  const [pending, setPending] = useState(false);

  const confirm = async () => {
    setPending(true);
    try {
      const resp = await userApi.unrevoke(props.user);
      props.updateUserInCache(resp);
      props.setShow(false);
    } catch (err: any) {
      console.error("error unrevoking", err);
      HGBugsnagNotify("unrevokeUser", err);
    } finally {
      setPending(false);
    }
  };

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("user.actions.restore-access")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t("user.actions.restore.confirm.1")}</p>
        <p>{t("user.actions.restore.confirm.2")}</p>
        <p>{t("user.actions.restore.confirm.3")}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={confirm} disabled={pending}>
          {t("general.proceed")}
        </Button>
        <Button variant="primary" onClick={handleClose}>
          {t("general.cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function deleteErrTypeToMessage(t: TFunction, errType: string): string | undefined {
  switch (errType) {
    case "SelfDelete":
      return t("user.delete.error.selfdelete");
    case "CongAdminMinimum":
      return t("user.delete.error.admin-minimum");
    case "PendingTransfer":
      return t("user.delete.error.pending-transfer");
    case "GroupOverseer":
      return t("user.delete.error.sgo");
  }
}

//confirm deleting a user
export function DeleteUserConfirmModal(props: UserConfirmProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [confirmFirstname, setConfirmFirstname] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [pending, setPending] = useState(false);

  const handleClose = () => {
    props.setShow(false);
    setConfirmFirstname("");
    setErrorMessage("");
  };
  const requiredMatch = props.user.firstname.toLocaleLowerCase().trim();

  const confirm = async (e: FormEvent<HTMLFormElement>) => {
    setPending(true);
    e.preventDefault();
    e.stopPropagation();

    try {
      await userApi.delete(props.user);
      props.removeUserFromCache(props.user);
      props.setShow(false);
      navigate("/");
    } catch (err: any) {
      const errType = await getErrorType(err);
      const statusCode = getStatusCode(err);
      let knownError = false;
      if (errType) {
        const errMessage = deleteErrTypeToMessage(t, errType);
        if (errMessage) {
          knownError = true;
          setErrorMessage(errMessage);
        } else {
          setErrorMessage(t("user.delete.error.unknown"));
        }
      } else {
        if (statusCode === 404) {
          knownError = true;
          props.removeUserFromCache(props.user);
          props.setShow(false);
          navigate("/");
        }
      }
      if (!knownError) {
        console.error("error deleting user", err);
        HGBugsnagNotify("deleteUser", err);
      }
    } finally {
      setPending(false);
    }
  };

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("user.actions.delete")}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={confirm}>
        <Modal.Body>
          {!!errorMessage && <p className="text-danger">{errorMessage}</p>}
          {!errorMessage && (
            <>
              <p>
                {t("popup.delete-confirm.body.1")} <b>{props.user.displayName}</b>
              </p>
              <p>{t("popup.delete-confirm.body.2")}</p>
              {t("popup.delete-confirm.body.3")}
              <Form.Control
                required
                type="text"
                autoComplete="off"
                value={confirmFirstname}
                onChange={(e) => setConfirmFirstname(e.currentTarget.value)}
                placeholder={props.user.firstname}
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!errorMessage && (
            <Button
              variant="danger"
              type="submit"
              disabled={confirmFirstname.toLocaleLowerCase().trim() !== requiredMatch || pending}
            >
              {t("user.actions.delete")}
            </Button>
          )}
          <Button variant="primary" onClick={handleClose}>
            {t("general.cancel")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

type AddrChangeModalProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  saveAddrChangeAll: (selection: boolean) => void;
  user: User;
  usersWithAddress: User[];
};

//confirm whether to update a changed address for everyone or just this user
export function AddressChangeModal(props: AddrChangeModalProps) {
  const { t } = useTranslation();
  const handleClose = () => props.setShow(false);

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("newaddress.modal.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t("newaddress.modal.body1")}</p>
        <ul>
          {props.usersWithAddress.map((u: User) => {
            if (u.id !== props.user.id) return <li key={u.id}>{u.displayName}</li>;
            return null;
          })}
        </ul>
        <p>{t("newaddress.modal.body2")}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => props.saveAddrChangeAll(false)}>
          {t("newaddress.modal.button.just-them")}
        </Button>
        <Button variant="primary" onClick={() => props.saveAddrChangeAll(true)}>
          {t("general.everyone")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

type StandardConfirmProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  pending?: boolean;
  onConfirm: () => void;
  message?: string;
  messageJSX?: JSX.Element;
  deleteButtonLabel?: string;
};

//confirm undoing submission of a publisher's report
export function UndoSubmittedReportConfirmModal(props: StandardConfirmProps) {
  const { t } = useTranslation();
  const handleClose = () => props.setShow(false);

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("report.unsubmit.confirm.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center">
          <ExclamationTriangleFill className="warning-color" size="3em" />
        </div>
        <p>{t("report.unsubmit.confirm.1")}</p>
        <p>{t("report.unsubmit.confirm.2")}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onConfirm} disabled={props.pending}>
          {t("general.proceed")}
        </Button>
        <Button variant="primary" onClick={handleClose}>
          {t("general.cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

//confirm undoing submission of a publisher's report
export function ManualSubmitBlockedModal(props: StandardConfirmProps) {
  const { t } = useTranslation();
  const handleClose = () => props.setShow(false);

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("report.action.mark-submitted")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center">
          <ExclamationOctagonFill color="red" size="3em" />
        </div>
        <p className="mt-3">{t("report.mark-submitted.warning")}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          {t("popup.error.button.ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

type MultipleSaveECProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  onConfirm: () => void;
  userIds: Set<number>;
  currentUserId: number;
};

//confirm saving an emergency contact which is linked to multiple users
export function MultipleSaveEC(props: MultipleSaveECProps) {
  const { t } = useTranslation();
  const usersQuery = useUsers();

  const handleClose = () => props.setShow(false);

  const otherUsers = usersQuery.data
    ?.filter((u) => u.id !== props.currentUserId && props.userIds.has(u.id))
    .sort(userCompare);

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("userinfo.tab.emergency-contacts")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t("emergencycontact.popup.multiple.0")}</p>
        <ul>{otherUsers && otherUsers.map((u) => <li key={u.id}>{u.displayName}</li>)}</ul>
        <p>{t("emergencycontact.popup.multiple.confirm")}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onConfirm}>
          {t("general.proceed")}
        </Button>
        <Button variant="primary" onClick={handleClose}>
          {t("general.cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

//confirm deleting an attendance group
export function DeleteAttendanceGroupConfirmModal(props: StandardConfirmProps) {
  const { t } = useTranslation();
  const handleClose = () => props.setShow(false);

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("attendance.group.delete-confirm-title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t("attendance.group.delete-confirm-body")}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.onConfirm}>
          {t("general.delete")}
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          {t("general.cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

type DeleteCongConfirmProps = StandardConfirmProps & { congNum: number };

//confirm deleting the entire congregation
export function DeleteCongConfirmModal(props: DeleteCongConfirmProps) {
  const { t } = useTranslation();
  const [congNumConfirm, setCongNumConfirm] = useState("");
  const handleClose = () => {
    setCongNumConfirm("");
    props.setShow(false);
  };

  const changeCongNum = (e: ChangeEvent<HTMLInputElement>) => {
    setCongNumConfirm(e.currentTarget.value);
  };

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("general.delete")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center mb-3">
          <ExclamationOctagonFill color="red" size="3em" />
        </div>
        <p>{t("deletecong.confirm.0")}</p>
        {t("deletecong.confirm.cong-number")}
        <Form.Control required type="number" value={congNumConfirm} onChange={changeCongNum} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.onConfirm} disabled={parseInt(congNumConfirm) !== props.congNum}>
          {t("general.delete")}
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          {t("general.cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function E2EEnableConfirmModal(props: StandardConfirmProps) {
  enum E2EConfirmStep {
    Step1,
    Step2,
  }

  const { t } = useTranslation();
  const ctx = useContext(HGContext);
  const [masterPassword, setMasterPassword] = useState("");
  const [masterPasswordConfirm, setMasterPasswordConfirm] = useState("");
  const masterPasswordRef = useRef<HTMLInputElement>(null);
  const [saveError, setSaveError] = useState("");
  const [step, setStep] = useState(E2EConfirmStep.Step1);
  const [step1Acknowledge, setStep1Acknowledge] = useState(false);

  const e2eEnableVideoId = "bkTrL9tAr20";

  const enableE2E = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    if (!form.checkValidity()) return;

    //actually perform e2e enablement operation
    try {
      const masterKey = await createSecretKey(masterPassword);
      const e2eKey: E2EKey = {
        id: 0,
        wrappedKey: arrayBufferToBase64(masterKey.wrappedKey.key),
        salt: uint8ArrayToBase64(masterKey.wrappedKey.salt),
        fingerprint: arrayBufferToBase64(masterKey.fingerprint),
      };
      const resp = await e2eApi.save(e2eKey);
      //set the e2ekey on the cong and set the master key
      const newCong = { ...ctx.globals.cong, e2eKey: resp };
      ctx.setGlobals({ ...ctx.globals, e2eKey: masterKey.secretKey, cong: newCong });
      handleClose();
    } catch (err: any) {
      if (err instanceof EncryptionError) {
        setSaveError(t("e2e.error.failed-to-create"));
      } else {
        setSaveError(t("e2e.error.failed-to-save"));
      }
    }
  };

  const handleClose = () => {
    setMasterPassword("");
    setMasterPasswordConfirm("");
    setStep(E2EConfirmStep.Step1);
    setStep1Acknowledge(false);
    props.setShow(false);
  };

  const changeMasterPassword = (e: ChangeEvent<HTMLInputElement>) => {
    setMasterPassword(e.currentTarget.value);
    if (!e2ePasswordStrong(masterPassword)) {
      masterPasswordRef.current?.setCustomValidity(t("password-error.insecure"));
    } else {
      masterPasswordRef.current?.setCustomValidity("");
    }
  };

  const changeMasterPasswordConfirm = (e: ChangeEvent<HTMLInputElement>) => {
    setMasterPasswordConfirm(e.currentTarget.value);
  };

  return (
    <Modal show={props.show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("nav.e2e")}</Modal.Title>
      </Modal.Header>
      {step === E2EConfirmStep.Step1 && (
        <>
          <Modal.Body>
            <iframe
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/${e2eEnableVideoId}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <div>
              <Form.Check
                className="mt-3"
                id="doE2EProceed"
                inline
                label={t("e2e.enable.video-confirm")}
                type="checkbox"
                onChange={() => setStep1Acknowledge(!step1Acknowledge)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" disabled={!step1Acknowledge} onClick={() => setStep(E2EConfirmStep.Step2)}>
              {t("general.proceed")}
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              {t("general.cancel")}
            </Button>
          </Modal.Footer>
        </>
      )}
      {step === E2EConfirmStep.Step2 && (
        <Form onSubmit={enableE2E}>
          <Modal.Body>
            <Warning
              text={
                <>
                  <div className="d-flex justify-content-center mb-3">
                    <ExclamationTriangleFill className="text-danger" size="4em" />
                  </div>
                  <p className="h3">{t("e2e.enable.prompt-and-warning")}</p>
                  <p className="h3">
                    <b>{t("e2e.enable.password-share")}</b>
                  </p>
                </>
              }
            />
            <Form.Group>
              <Form.Label>{t("e2e.congregation-master-password")}</Form.Label>
              <Form.Control
                ref={masterPasswordRef}
                required
                type="password"
                value={masterPassword}
                onChange={changeMasterPassword}
              />
            </Form.Group>
            <Form.Group className="mt-2">
              <Form.Label>{t("new-password.tip.new-password-again")}</Form.Label>
              <Form.Control
                required
                type="password"
                value={masterPasswordConfirm}
                onChange={changeMasterPasswordConfirm}
              />
            </Form.Group>
            {!!saveError && <p className="text-danger">{saveError}</p>}
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="danger"
              disabled={!masterPassword || masterPassword !== masterPasswordConfirm}
            >
              <LockFill /> {t("e2e.enable.0")}
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              {t("general.cancel")}
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  );
}

//confirm deleting a transfer
export function DeleteTransferConfirmModal(props: StandardConfirmProps) {
  const { t } = useTranslation();
  const handleClose = () => props.setShow(false);

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("nav.publishers.transfer")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t("pubtransfer.delete.confirm")}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.onConfirm} disabled={props.pending}>
          {t("general.delete")}
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          {t("general.cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

//confirm undo submitted on the month summary
export function UndoSubmittedMonthConfirmModal(props: StandardConfirmProps) {
  const { t } = useTranslation();
  const handleClose = () => props.setShow(false);

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("monthsummary.undo-submitted.0")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t("monthsummary.undo-submitted.popup.1")}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onConfirm} disabled={props.pending}>
          {t("general.proceed")}
        </Button>
        <Button variant="primary" onClick={handleClose}>
          {t("general.cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

type SubmitMonthConfirmProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  onConfirm: (retention: MonthRetention) => void;
  month: Month;
  showSpinner: boolean;
  setShowSpinner: (show: boolean) => void;
};

//confirm submitted on the month summary
export function SubmitMonthConfirmModal(props: SubmitMonthConfirmProps) {
  const { t } = useTranslation();
  const [performDelete, setPerformDelete] = useState(false);
  const syStart = serviceYearStart(props.month.toDate());
  const maxActiveMonth = Month.fromDate(addMonth(syStart, -12));
  const [activeMonth, setActiveMonth] = useState(maxActiveMonth);
  const maxCongMonth = Month.fromDate(addMonth(props.month.toDate(), -36));
  const [congMonth, setCongMonth] = useState(maxCongMonth);
  type ValidMonths = {
    active: boolean;
    cong: boolean;
  };
  const [valid, setValid] = useState<ValidMonths>({ active: true, cong: true });

  const handleClose = () => {
    setPerformDelete(false);
    props.setShowSpinner(false);
    props.setShow(false);
  };

  const validateMonths = (): ValidMonths => {
    const valid: ValidMonths = {
      active: false,
      cong: false,
    };
    if (!activeMonth.after(maxActiveMonth)) valid.active = true;
    if (!congMonth.after(maxCongMonth)) valid.cong = true;
    return valid;
  };

  const proceed = () => {
    const retention: MonthRetention = {
      performDeletion: performDelete,
      activeReports: activeMonth.toString(),
      monthlyCong: congMonth.toString(),
    };

    if (performDelete) {
      const valid = validateMonths();
      setValid(valid);
      if (!valid.active || !valid.cong) return;
    }
    props.setShowSpinner(true);
    props.onConfirm(retention);
  };

  const retentionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPerformDelete(e.currentTarget.checked);
  };

  const activeMonthChange = (e: ChangeEvent<HTMLInputElement>) => {
    setActiveMonth(Month.fromString(e.currentTarget.value));
  };

  const congMonthChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCongMonth(Month.fromString(e.currentTarget.value));
  };

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("monthsummary.button.submitted")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t("monthsummary.submit.popup.1")}</p>
        <Form.Check
          className="mb-3"
          id="doRetention"
          inline
          label={t("monthsummary.submit.retention")}
          type="checkbox"
          onChange={retentionChange}
        />

        {performDelete && (
          <>
            <Form.Group controlId="activeMonth">
              <Row>
                <Col xs="auto">
                  <Form.Label>{t("monthsummary.submit.retention-reports-active")}</Form.Label>
                </Col>
              </Row>
              <Row>
                <Col xs="auto">
                  <Form.Control
                    type={supportsMonthInput ? "month" : "date"}
                    value={supportsMonthInput ? activeMonth.toString() : activeMonth.toDateString()}
                    min="2016-09"
                    max={supportsMonthInput ? maxActiveMonth.toString() : maxActiveMonth.toDateString()}
                    onChange={activeMonthChange}
                    isInvalid={!valid.active}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group controlId="congMonth">
              <Row className="mt-3">
                <Col xs="auto">
                  <Form.Label>{t("monthsummary.submit.retention-attendance-summaries")}</Form.Label>
                </Col>
              </Row>
              <Row>
                <Col xs="auto">
                  <Form.Control
                    type={supportsMonthInput ? "month" : "date"}
                    value={supportsMonthInput ? congMonth.toString() : congMonth.toDateString()}
                    min="2016-09"
                    max={supportsMonthInput ? maxCongMonth.toString() : maxCongMonth.toDateString()}
                    onChange={congMonthChange}
                    isInvalid={!valid.cong}
                  />
                </Col>
              </Row>
            </Form.Group>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {props.showSpinner && <Spinner animation="border" size="sm" />}
        <Button variant="secondary" onClick={proceed} disabled={props.showSpinner}>
          {t("general.proceed")}
        </Button>
        <Button variant="primary" onClick={handleClose}>
          {t("general.cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function DeleteEventConfirmModal(props: {
  event: ScheduledEvent;
  show: boolean;
  setShow: (show: boolean) => void;
  onConfirm: (event: ScheduledEvent, close: () => void) => void;
}) {
  const { t } = useTranslation();

  const handleClose = () => {
    props.setShow(false);
  };

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("general.delete")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center mb-3">
          <ExclamationOctagonFill color="red" size="3em" />
        </div>
        <h3 className="center">
          {stringToLocaleDate(props.event.date, HourglassGlobals.cong.country.datefmt)} -{" "}
          {t(EventTypes[props.event.event])}
        </h3>
        <p>{t("schedules.event.delete-confirm")}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => props.onConfirm(props.event, handleClose)}>
          {t("general.delete")}
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          {t("general.cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function DeleteConfirmModal(props: StandardConfirmProps) {
  const { t } = useTranslation();

  const handleClose = () => {
    props.setShow(false);
  };

  const deleteLabel = props.deleteButtonLabel || t("general.delete");

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("general.delete")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center mb-3">
          <ExclamationOctagonFill color="red" size="3em" />
        </div>
        <h5 className="center">{!!props.message ? props.message : t("popup.confirm.general")}</h5>
        {!!props.messageJSX && props.messageJSX}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => props.onConfirm()}>
          {deleteLabel}
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          {t("general.cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
