import { jsPDF } from "jspdf";
import autoTable, { CellHookData, CellInput, RowInput } from "jspdf-autotable";
import { Month, mmDate, stringToLongLocaleDate } from "../../../helpers/dateHelpers";
import { t } from "../../../helpers/locale";
import {
  PDFHeader,
  boldFont,
  halfInch,
  largestFontSize,
  newPDFWithHeader,
  normalFont,
  space,
} from "../../../helpers/pdf";
import { Cong } from "../../../types/cong";
import { ISODateString } from "../../../types/date";
import { EventTypes, Events, ScheduledEvent } from "../../../types/scheduling/events";
import {
  MMs,
  MidweekMeetingInfo,
  MidweekMeetingPart,
  MidweekMeetingPartType,
  MidweekScheduleAssignment,
  MidweekScheduleInfo,
  Song,
} from "../../../types/scheduling/midweek";
import { CongSettingMap, CongSettings } from "../../../types/scheduling/settings";
import User from "../../../types/user";
import { HGBugsnagNotify } from "../../../helpers/bugsnag";

const margin = halfInch;
const leftPadding = 0.07;
const bodyFontSize = 7.5;

export const songNum = (sn: number): string => {
  const snStr = sn ? sn.toString() : "";
  return t("schedules.song %d").replaceAll("%d", snStr);
};

export function labelCol(s: string): CellInput {
  return {
    content: s,
    styles: { fontSize: 6, halign: "right" },
  };
}

export function assigneeCol(s: string, mainFontSize: number = bodyFontSize): CellInput {
  return {
    content: s,
    styles: { fontSize: mainFontSize, halign: "left" },
  };
}

export function songStyle(s: Song): CellInput {
  if (!s) return null;
  return {
    content: `${songNum(s.number)}: ${s.title}`,
  };
}

export async function midweekPDF(
  weeks: ISODateString[],
  mms: MMs,
  myCong: Cong,
  userMap: Map<number, User>,
  events: ScheduledEvent[],
  settings: CongSettings,
) {
  const { doc, initY } = await newPDFWithHeader(t("conganalysis.attendance.midweek"), myCong.name, myCong.locale.code);
  let curY = initY;
  let weeksOnPage = 0;
  let fullWeeksOnPage = 0;
  for (const week of weeks) {
    if (fullWeeksOnPage === 2) {
      // if we have 2 full schedules on a page already, create a new page
      doc.addPage();
      curY = 0;
      curY += PDFHeader(doc, t("conganalysis.attendance.midweek"), myCong.name);
      weeksOnPage = 0;
      fullWeeksOnPage = 0;
    } else if (weeksOnPage > 0) {
      doc.setDrawColor("black");
      doc.line(margin, curY + space + 0.02 / 2, doc.internal.pageSize.width - margin, curY + space + 0.02 / 2);
      curY += space + 0.1;
    }

    const meeting = mms.meetings?.find((m) => m.date === week);
    const schedule = mms.schedules?.find((s) => s.date === week);
    const weekEvents = events.filter((e) => e.week === week);
    const [lastY, skipped] = await midweekSchedule(
      doc,
      curY,
      userMap,
      week,
      weekEvents,
      myCong,
      settings,
      meeting,
      schedule,
    );
    curY = lastY;
    if (!skipped) fullWeeksOnPage++;
    weeksOnPage++;
  }

  const meetingMonth = (): Month => {
    try {
      // @ts-ignore noUncheckedIndexedAccess
      return Month.fromDateString(mms.meetings[0].date);
    } catch {
      return Month.fromDate(new Date());
    }
  };

  doc.save(`midweek_${meetingMonth().toString()}.pdf`);
}

// calculate how wide the classroom column needs to be to fit the text which will go in it.
// we want to use the rest of the space for the participants to minimize line wrapping
export function getClassroomColWidth(doc: jsPDF): number {
  doc.setFont(normalFont);
  doc.setFontSize(bodyFontSize);

  const assets = [
    "schedules.midweek.main-hall",
    "schedules.midweek.auxiliary-classroom",
    "schedules.chairman",
    "schedules.midweek.aux-chairman-2",
    "schedules.midweek.aux-chairman-3",
    "schedules.prayer",
  ];
  let max = 1;
  assets.forEach((asset) => {
    const width = doc.getTextWidth(t(asset));
    if (width > max) max = width;
  });

  return max;
}

async function midweekSchedule(
  doc: jsPDF,
  initialY: number,
  userMap: Map<number, User>,
  week: ISODateString,
  events: ScheduledEvent[],
  cong: Cong,
  settings: CongSettings,
  meeting?: MidweekMeetingInfo,
  schedule?: MidweekScheduleInfo,
): Promise<[number, boolean]> {
  const userNameFromMap = (userId: number): string => {
    return userMap.get(userId)?.displayName || "";
  };

  let lastEndY = 0;
  const headerFontSize = 8;
  const tableWidth = doc.internal.pageSize.width - margin * 2;
  const tableSpace = 0.1;

  const partColWidth = (tableWidth * 2) / 5;
  const classroomColWidth = getClassroomColWidth(doc);
  const colWidths = [partColWidth, classroomColWidth, tableWidth - partColWidth - classroomColWidth];

  const skipEvents = new Set<Events>([Events.rc, Events.ca, Events.mmem]);
  const skipWeek = events.some((e) => skipEvents.has(e.event)) || !meeting || !schedule;
  const heading: RowInput[] = weekHeading(
    doc,
    week,
    events,
    cong,
    settings,
    colWidths,
    meeting,
    schedule,
    userNameFromMap(schedule?.chairman || 0),
  );

  if (schedule?.chairman2) {
    heading.push([
      "",
      labelCol(t("schedules.midweek.aux-chairman-2")),
      assigneeCol(userNameFromMap(schedule.chairman2)),
    ]);
  }

  if (schedule?.chairman3) {
    heading.push([
      "",
      labelCol(t("schedules.midweek.aux-chairman-3")),
      assigneeCol(userNameFromMap(schedule.chairman3)),
    ]);
  }

  const introBody: RowInput[] = !skipWeek
    ? [
        [songStyle(meeting?.song1), labelCol(t("schedules.prayer")), userNameFromMap(schedule?.openprayer)],
        [t("schedules.midweek.opening-comments"), null, null],
      ]
    : [];

  const drawTable = (
    doc: jsPDF,
    startY: number,
    head: RowInput[],
    body: RowInput[],
    willDrawCell?: (hookData: CellHookData) => void,
  ) => {
    autoTable(doc, {
      theme: "plain",
      startY: startY,
      head: head,
      body: body,
      margin: {
        left: margin,
        bottom: 0,
        right: margin,
      },
      pageBreak: "avoid",
      rowPageBreak: "avoid",
      bodyStyles: {
        font: normalFont,
        fontSize: bodyFontSize,
        cellPadding: { top: 0.04, bottom: 0.04, left: leftPadding, right: 0.07 },
      },
      headStyles: {
        font: normalFont,
        fontSize: headerFontSize,
        cellPadding: { top: 0.02, bottom: 0, left: leftPadding, right: 0.07 },
      },
      columnStyles: {
        0: {
          cellWidth: colWidths[0],
        },
        1: {
          cellWidth: colWidths[1],
        },
        2: {
          cellWidth: colWidths[2],
        },
      },
      willDrawCell: willDrawCell,
      didDrawPage: (d) => {
        if (d.cursor) lastEndY = d.cursor.y;
      },
    });
  };

  //draw the intro: has chairman, song, opening comments. appears above treasures
  drawTable(doc, initialY, heading, introBody);

  // if we don't have a meeting,
  // produce placeholder field
  if (skipWeek) {
    let content = "";
    const meetingEvent = events.find((e) => e.event === Events.mmem || e.event === Events.ca || e.event === Events.rc);
    if (meetingEvent) {
      content = t(EventTypes[meetingEvent.event]);
    }

    const body: RowInput[] = [
      [
        {
          content: content,
          colSpan: 3,
          styles: { minCellHeight: 4, valign: "middle", halign: "center", fontSize: 20, textColor: "#777" },
        },
      ],
    ];

    drawTable(doc, initialY, heading, body);
    return [lastEndY, false];
  }

  if (!meeting.tgw) meeting.tgw = [];
  const tgwBody = meeting.tgw.map((tgwPart) => tgwRow(tgwPart, schedule, userNameFromMap));
  drawTable(
    doc,
    lastEndY + tableSpace,
    headerRow(t("schedules.midweek.treasures"), "#3A6773", colWidths.length, headerFontSize),
    tgwBody.flat(),
  );

  // field ministry
  const fmRow = (part: MidweekMeetingPart, assignments: MidweekScheduleAssignment[]): RowInput[] => {
    if (part.type === MidweekMeetingPartType.Chairman) {
      // in this case, there will not be an "assignment" since it falls to the chairman by rule
      // so we will create a psudo-assignment
      assignments.push({
        part: part.id,
        classroom: 0,
      } as MidweekScheduleAssignment);
    }

    const [main, aux, aux2] = classroomAssignments(assignments, part);
    const classrooms = [main, aux, aux2];
    const outputRows: RowInput[] = [];

    classrooms.map(
      (asmt, i) =>
        asmt &&
        outputRows.push([
          i === 0 ? `${part.title} (${part.time})` : "",
          labelCol(
            (i === 0 && (aux || aux2) && t("schedules.midweek.main-hall")) ||
              (i === 1 && t("schedules.midweek.auxiliary-classroom")) ||
              (i === 2 && t("schedules.midweek.auxiliary-classroom-2")) ||
              "",
          ),
          asmt
            ? assignText(
                userNameFromMap,
                schedule,
                asmt.assignee,
                asmt.assistant,
                part.type === MidweekMeetingPartType.Chairman,
                part.type === MidweekMeetingPartType.Stream,
              )
            : null,
        ]),
    );

    return outputRows;
  };

  if (!meeting.fm) meeting.fm = [];
  const fmBody = meeting.fm.map((fmPart) => fmRow(fmPart, schedule.fm));
  drawTable(
    doc,
    lastEndY + tableSpace,
    headerRow(t("schedules.midweek.fm"), "#946F2B", colWidths.length, headerFontSize),
    fmBody.flat(),
  );

  // living as christians
  // if it's CO visit, set a flag
  const coVisit = events.some((e) => e.event === Events.co);
  const lacRow = (part: MidweekMeetingPart, assignments: MidweekScheduleAssignment[]): RowInput | null => {
    if (coVisit && (part.type === MidweekMeetingPartType.CBSReader || part.type === MidweekMeetingPartType.CBS))
      return null;
    const assignment = assignments.find((a) => a.part === part.id);
    let assigned = assignment ? `${userNameFromMap(assignment.assignee)}` : "";
    if (part.type === MidweekMeetingPartType.CBS && schedule.cbs_reader && assignment) {
      assigned = assignText(userNameFromMap, schedule, assignment.assignee, schedule.cbs_reader);
    }
    if (part.type === MidweekMeetingPartType.Stream) {
      assigned = assignText(userNameFromMap, schedule, 0, 0, false, true);
    }
    return [{ content: `${part.title} (${part.time})`, colSpan: 2 }, assigned];
  };

  if (!meeting.lac) meeting.lac = [];
  const lacBody = meeting.lac.map((part) => lacRow(part, schedule.lac)).filter((row): row is RowInput => row !== null);
  lacBody.unshift([songStyle(meeting.song2), null, null]);
  drawTable(
    doc,
    lastEndY + tableSpace,
    headerRow(t("schedules.midweek.lac"), "#842F2A", colWidths.length, headerFontSize),
    lacBody,
  );

  const footerHeading: RowInput[] = [];
  const buildFooter = (): RowInput[] => {
    if (coVisit) {
      const talkTitle = settings.circuit_overseer_mm_talk_title || t(CongSettingMap["circuit_overseer_mm_talk_title"]);
      const coName = settings.circuit_overseer_name || t(CongSettingMap["circuit_overseer_name"]);
      return [
        [t("schedules.midweek.concluding-comments"), null, null],
        [talkTitle, null, coName],
        [songStyle(meeting.song3), labelCol(t("schedules.prayer")), userNameFromMap(schedule.closeprayer)],
      ];
    }
    return [
      [t("schedules.midweek.concluding-comments"), null, null],
      [songStyle(meeting.song3), labelCol(t("schedules.prayer")), userNameFromMap(schedule.closeprayer)],
    ];
  };
  const footerBody: RowInput[] = buildFooter();

  drawTable(doc, lastEndY, footerHeading, footerBody);
  return [lastEndY, false];
}

export function weekHeading(
  doc: jsPDF,
  week: string,
  events: ScheduledEvent[],
  cong: Cong,
  settings: CongSettings,
  colWidths: number[],
  meeting?: MidweekMeetingInfo,
  schedule?: MidweekScheduleInfo,
  chairman?: string,
  mainFontSize: number = bodyFontSize,
): RowInput[] {
  if (!!meeting && !!schedule) {
    //most common: regular week
    const meetingDate = settings.mm_print_date === "dayof" ? mmDate(week, cong, events) : week;
    const dateBR = `${stringToLongLocaleDate(meetingDate)} | ${meeting.weekly_br}`;
    const firstColumn: CellInput = {
      content: dateBR,
      styles: {
        font: boldFont,
        fontSize: largestFontSize(doc, 10, 6, dateBR, (colWidths[0] ?? 1) - leftPadding * 2),
      },
      colSpan: 0,
    };

    const skipEvent = events.find((e) => e.event === Events.ca || e.event === Events.rc);
    if (skipEvent) {
      // assembly or convention: add to the first column
      firstColumn.content += `  |  ${t(EventTypes[skipEvent.event])}`;
      firstColumn.colSpan = 3;
      return [[firstColumn, null, null]];
    }
    return [[firstColumn, labelCol(t("schedules.chairman")), assigneeCol(chairman || "", mainFontSize)]];
  }

  // we have no meeting. most likely situation is midweek memorial
  const mmem = events.find((e) => e.event === Events.mmem);
  if (mmem) {
    return [
      [
        {
          content: `${stringToLongLocaleDate(mmem.date)}  |  ${t("schedules.events.memorial")}`,
          styles: { font: boldFont },
        },
        null,
        null,
      ],
    ];
  }

  // this is unexpected
  const err = new Error("midweek schedule PDF: no meeting, not memorial");
  console.error(err);
  HGBugsnagNotify("midweekSchedulePDF", err, { week: week });

  return [
    [
      {
        content: `${stringToLongLocaleDate(week)}`,
        styles: { font: boldFont },
      },
      null,
      null,
    ],
  ];
}

export function classroomAssignments(
  assignments: MidweekScheduleAssignment[],
  part: MidweekMeetingPart,
): (MidweekScheduleAssignment | undefined)[] {
  const blankAssignment: MidweekScheduleAssignment = {
    id: 0,
    part: part.id,
    classroom: 0,
    assignee: 0,
    assistant: 0,
  };

  const assignment = assignments.filter((a) => a.part === part.id);
  //make sure we at least have main, so that we show the parts, even if there's no assignment
  const main: MidweekScheduleAssignment = assignment.find((a) => a.classroom === 0) || blankAssignment;

  const aux = assignment.find((a) => a.classroom === 1 && (a.assignee || a.assistant));
  const aux2 = assignment.find((a) => a.classroom === 2 && (a.assignee || a.assistant));
  return [main, aux, aux2];
}

// this is just for the regular schedule. chairman's schedule is different
function tgwRow(
  part: MidweekMeetingPart,
  schedule: MidweekScheduleInfo,
  userNameFromMap: (userId: number) => string,
): RowInput[] {
  const assignments = schedule.tgw;
  const [main, aux, aux2] = classroomAssignments(assignments, part);
  const classrooms = [main, aux, aux2];
  const outputRows: RowInput[] = [];

  switch (part.type) {
    case MidweekMeetingPartType.Treasures:
    case MidweekMeetingPartType.DFG:
      outputRows.push([
        {
          content: `${part.title} (${part.time})`,
          colSpan: 2,
        },
        main ? userNameFromMap(main.assignee) : null,
      ]);
      break;
    default:
      classrooms.forEach((asmt, i) => {
        if (!asmt) return;
        outputRows.push([
          {
            content: i === 0 ? `${part.title} (${part.time})` : "",
          },
          labelCol(
            (i === 0 && (aux || aux2) && t("schedules.midweek.main-hall")) ||
              (i === 1 && t("schedules.midweek.auxiliary-classroom")) ||
              (i === 2 && t("schedules.midweek.auxiliary-classroom-2")) ||
              "",
          ),
          asmt
            ? assignText(
                userNameFromMap,
                schedule,
                asmt.assignee,
                asmt.assistant,
                part.type === MidweekMeetingPartType.Chairman,
                part.type === MidweekMeetingPartType.Stream,
              )
            : null,
        ]);
      });
  }

  return outputRows;
}

export function assignText(
  userNameFromMap: (userId: number) => string,
  schedule: MidweekScheduleInfo,
  assignee: number,
  assistant: number,
  defaultToChairman = false,
  stream = false,
): string {
  if (stream) {
    return t("schedules.stream");
  }
  let text = userNameFromMap(assignee);
  const assist = userNameFromMap(assistant);
  if (text) {
    if (assist) text += ` / ${assist}`;
  } else if (defaultToChairman) {
    text = userNameFromMap(schedule.chairman);
  }

  return text;
}

//template header row for all 3 meeting sections (tgw, fm, lac):
export function headerRow(
  heading: string,
  color: string,
  colCount: number,
  headerFontSize: number,
  lastCol?: string,
): RowInput[] {
  const row: RowInput = [
    {
      content: heading.toLocaleUpperCase(),
      colSpan: lastCol ? colCount - 1 : colCount,
      styles: {
        fillColor: color,
        textColor: "white",
        fontSize: headerFontSize,
        font: boldFont,
        valign: "middle",
        minCellHeight: 0.2,
      },
    },
  ];
  if (lastCol)
    row.push({
      content: lastCol,
      styles: {
        fillColor: color,
        textColor: "white",
        fontSize: bodyFontSize,
        font: normalFont,
        cellPadding: {},
        valign: "middle",
        halign: "center",
      },
    });
  return [row];
}
