import { CongSettings } from "../../../types/scheduling/settings";

export function cleaningLabel(settings: CongSettings, slot: number): string {
  switch (slot) {
    case 0:
      return settings.cleaning_label_1;
    case 1:
      return settings.cleaning_label_2;
    case 2:
      return settings.cleaning_label_3;
    case 3:
      return settings.cleaning_label_4;
    case 4:
      return settings.cleaning_label_5;
    case 5:
      return settings.cleaning_label_6;
    case 6:
      return settings.cleaning_label_7;
    case 7:
      return settings.cleaning_label_8;
  }

  return "";
}
