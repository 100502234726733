import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { Absence } from "../types/scheduling/absence";
import { api, schedulingPath } from "./api";
import QueryKeys from "./queryKeys";

export class AbsenceAPI {
  static base = schedulingPath("/absence");

  async get(): Promise<Absence[]> {
    return api.get(AbsenceAPI.base).json();
  }

  async save(absence: Absence): Promise<Absence> {
    const url = absence.id ? `${AbsenceAPI.base}/${absence.id}` : AbsenceAPI.base;
    const method = absence.id ? "put" : "post";
    return api(url, { json: absence, method }).json();
  }

  async delete(absence: Absence) {
    await api.delete(`${AbsenceAPI.base}/${absence.id}`);
  }
}

export function useAbsence(options?: UseQueryOptions<Absence[], Error>) {
  return useQuery({
    queryKey: [QueryKeys.Absences],
    queryFn: () => absenceApi.get(),
    ...options,
  });
}

export const absenceApi = new AbsenceAPI();
