import User from "../types/user";
import QueryKeys from "../api/queryKeys";
import { QueryClient } from "@tanstack/react-query";
import userApi from "../api/user";

export const updateUsersCache = (queryClient: QueryClient, oldUser: User, newUser: User) => {
  if (oldUser.id === 0) {
    //we have a new user to add
    queryClient.setQueryData<User[]>([QueryKeys.Users], (old) => (old ? [...old, newUser] : [newUser]));
    return;
  }
  queryClient.setQueryData<User[]>([QueryKeys.Users], (old) =>
    old ? old.map((user) => (user.id === newUser.id ? newUser : user)) : [newUser]
  );
};

//getUser retrieves the user from the server and updates the cache.
//it's used when we need to do an immediate refresh, such as when we know something has changed as a side effect
//of another operation. For example, making someone a group overseer changes that user and the former group overseer
export async function getUser(queryClient: QueryClient, user: User) {
  try {
    const resp = await userApi.get(user.id);
    updateUsersCache(queryClient, user, resp);
  } catch (err) {
    console.error("getUser: error", err);
  }
}
