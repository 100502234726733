import { get } from "@github/webauthn-json/browser-ponyfill";
import { ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Form, Modal, Row, Spinner } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { authApi } from "../api/auth";
import PasskeyIcon from "../assets/PasskeyIcon";
import logo from "../assets/hourglass-logo-brown.svg";
import "../css/googleSignin.css";
import { getAppleAuthUrl } from "../helpers/appleSignin";
import { HGBugsnagNotify } from "../helpers/bugsnag";
import { ErrType, getErrorType, getStatusCode } from "../helpers/errors";
import HourglassGlobals from "../helpers/globals";
import { getGoogleAuthUrl } from "../helpers/googleSignin";
import { passkeysSupported } from "../helpers/passkeys";
import useInterval from "../helpers/useInterval";
import { getCookie } from "../helpers/util";
import {
  LoginCredentials,
  TwoFactorChallenge,
  TwoFactorChallengeResponse,
  TwoFactorChallengeStatusRequest,
} from "../types/auth";
import { ForgotPWRequest } from "../types/forgotpw";
import { ValidationErrorDetail } from "../types/validation";
import { Danger } from "./alerts";
import { AppleSignInButton } from "./utils";

type LoginProps = {
  onTokenChange: (have: boolean) => void;
};

export type LoginLocationState = {
  email: string;
  googleAuth: boolean;
  appleAuth: boolean;
};

//backend state times out after 20 minutes
export const StateTimeoutSeconds = 19 * 60 + 30;

export default function HourglassLogin(props: LoginProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginFailureMessage, setLoginFailureMessage] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [recaptchaRequired, setRecaptchaRequired] = useState(false);
  const [recaptchaResponse, setRecaptchaResponse] = useState("");
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [showStateTimeout, setShowStateTimeout] = useState(false);
  const [passkeySupport, setPasskeySupport] = useState(false);
  const [twoFactorRequired, setTwoFactorRequired] = useState(false);
  const [twoFactorChallenge, setTwoFactorChallenge] = useState<TwoFactorChallenge>();

  useEffect(() => {
    passkeysSupported().then((supported) => setPasskeySupport(supported));
  }, []);

  if (!email && location.state) {
    if (location.state.email && !location.state.googleAuth && !location.state.appleAuth) {
      setEmail(location.state.email);
    }
  }

  const updateEmail = (e: any) => {
    setEmail(e.target.value);
    setLoginFailureMessage("");
  };
  const updatePassword = (e: any) => {
    setPassword(e.target.value);
    setLoginFailureMessage("");
  };

  useEffect(() => {
    const timer = setTimeout(() => setShowStateTimeout(true), StateTimeoutSeconds * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const doPasskeyLogin = useCallback(
    async (conditional = true) => {
      if (!passkeySupport) return;
      const abortController = new AbortController();

      try {
        const credentialRequestOptions = await authApi.webauthnBeginLogin();
        credentialRequestOptions.mediation = conditional ? "conditional" : "optional";
        credentialRequestOptions.signal = abortController.signal;
        const credential = await get(credentialRequestOptions);
        await authApi.webauthnFinishLogin(credential);
        props.onTokenChange(true);
      } catch (err: any) {
        switch (err.name) {
          case "NotAllowedError":
            // user canceled
            break;
          case "OperationError":
            // usually seen in dev when component is mounted multiple times
            break;
          default:
            const statusCode = getStatusCode(err);
            switch (statusCode) {
              case 401:
                console.warn("webauthn 401", err);
                setLoginFailureMessage(t("login.error.bad-credentials"));
                break;
              case 429:
                setLoginFailureMessage(t("form.error.try-later"));
                break;
              default:
                console.error("webauthn login error", err, err.name);
                HGBugsnagNotify("loginWebAuthn", err);
            }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [passkeySupport],
  );

  const doAutoLogin = () => {
    // auto-login: check for cookies that are hints to try google or apple login
    // if they aren't there, then attempt passkey
    try {
      const googleSub = getCookie(HourglassGlobals.GoogleSubCookie);
      if (googleSub && googleSub.length > 1) {
        window.location.href = getGoogleAuthUrl(
          window.HGGlobal?.state || "state",
          HourglassGlobals.oAuthRedirectURI,
          window.HGGlobal.nonce || "nonce",
          null,
          googleSub,
        );
      } else {
        const appleSub = getCookie(HourglassGlobals.AppleSubCookie);
        if (appleSub && appleSub.length > 1) {
          window.location.href = getAppleAuthUrl(
            window.HGGlobal?.state || "state",
            HourglassGlobals.appleAuthRedirectURI,
            window.HGGlobal.nonce || "nonce",
          );
        } else {
          // don't invoke the passkey conditional flow - let's rely on the button for now
          // doPasskeyLogin(true).then();
        }
      }
    } catch (err) {
      console.warn("error checking for auto-google-signin cookie", err);
    }
  };

  useEffect(() => {
    // start auto-login after a short delay
    // this helps smooth out bursty requests
    const timer = setTimeout(doAutoLogin, 150);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passkeySupport]); // we only want this to run once

  const submitLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!e.currentTarget.checkValidity()) return;

    const credentials: LoginCredentials = {
      email: email,
      password: password,
      recaptchaResponse: recaptchaResponse,
    };
    setTwoFactorChallenge(undefined);
    setTwoFactorRequired(false);

    try {
      const loginResp = await authApi.passwordLogin(credentials);
      if (loginResp.twoFactorChallenge) {
        setTwoFactorChallenge(loginResp.twoFactorChallenge);
        setTwoFactorRequired(true);
      } else {
        props.onTokenChange(true);
      }
    } catch (err: any) {
      const statusCode = getStatusCode(err);
      switch (statusCode) {
        case 403:
          // this means we've tried too often and need to solve a recaptcha
          setRecaptchaRequired(true);
          setLoginFailureMessage(t("form.recaptcha-error"));
          break;
        case 429:
          // rate limiting has been hit
          setLoginFailureMessage(t("form.error.try-later"));
          break;
        default:
          if (recaptchaRequired) recaptchaRef.current?.reset();
          setLoginFailureMessage(t("login.error.bad-credentials"));
      }
    }
  };

  return (
    <Container fluid="md">
      <StateTimeoutModal show={showStateTimeout} setShow={setShowStateTimeout} />
      <ForgotPasswordModal show={showForgotPassword} setShow={setShowForgotPassword} />
      {!!twoFactorChallenge && (
        <TwoFactorModal
          challenge={twoFactorChallenge}
          show={twoFactorRequired}
          setShow={(show: boolean) => {
            setTwoFactorRequired(show);
            if (!show) {
              setTwoFactorChallenge(undefined);
              setTwoFactorRequired(false);
            }
          }}
          setSuccess={(success: boolean) => {
            setTwoFactorChallenge(undefined);
            setTwoFactorRequired(false);
            if (success) {
              props.onTokenChange(true);
            }
          }}
          setChallenge={setTwoFactorChallenge}
          loginEmail={email}
        />
      )}
      <Row className="justify-content-md-center mt-3 mb-4">
        <Col lg={4} md={5} sm={8}>
          <img src={logo} className="App-logo" alt="logo" />
          <h5 className="App-logo-text">{t("login.message.signin")}</h5>
        </Col>
      </Row>

      <Row className="justify-content-md-center mb-2">
        <Col lg={4} md={5} sm={8}>
          <a
            href={getGoogleAuthUrl(
              window.HGGlobal?.state || "state",
              HourglassGlobals.oAuthRedirectURI,
              window.HGGlobal?.nonce || "nonce",
            )}
          >
            <div className="mb-2" id="googleSignInButton">
              <span className="googleSignInIcon" />
              <span className="googleSignInButtonText">{t("userauth.current-auth.method.google")}</span>
            </div>
          </a>
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col lg={4} md={5} sm={8}>
          <AppleSignInButton />
        </Col>
      </Row>

      <Form onSubmit={submitLogin}>
        <Row className="justify-content-md-center mt-2">
          <Col lg={4} md={5} sm={8}>
            <hr />
            <Form.Group controlId="email">
              <Form.Label>{t("form.label.email-address")}</Form.Label>
              <Form.Control
                required
                type="email"
                name="email"
                value={email}
                onChange={updateEmail}
                autoComplete="username webauthn"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-md-center mt-2">
          <Col lg={4} md={5} sm={8}>
            <Form.Group controlId="password">
              <Form.Label>
                {t("form.label.password")}
                <Button size="sm" variant="secondary" className="ms-2" onClick={() => setShowForgotPassword(true)}>
                  {t("form.label.i-forgot")}
                </Button>
              </Form.Label>
              <Form.Control
                required
                type="password"
                name="password"
                value={password}
                onChange={updatePassword}
                autoComplete="current-password webauthn"
              />
              {!!loginFailureMessage && (
                <Form.Text className="text-danger">
                  <b>{loginFailureMessage}</b>
                </Form.Text>
              )}
            </Form.Group>
            {recaptchaRequired && (
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={window.HGGlobal?.recaptchaSiteKey || "key"}
                onChange={(value: string | null) => setRecaptchaResponse(value || "")}
              />
            )}
            <Button className="mt-3" variant="primary" type="submit" disabled={recaptchaRequired && !recaptchaResponse}>
              {t("form.button.login")}
            </Button>
            {passkeySupport && (
              <Button className="mt-3 ms-2" variant="primary" onClick={() => doPasskeyLogin(false)}>
                <PasskeyIcon size="1.5em" className="me-2" />
                {t("login.sign-in-with-passkey")}
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

type ForgotPasswordModalProps = {
  show: boolean;
  setShow: (show: boolean) => void;
};

function ForgotPasswordModal(props: ForgotPasswordModalProps) {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const emailFormRef = useRef<HTMLFormElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const [recaptchaResponse, setRecaptchaResponse] = useState("");
  const [isGoogle, setIsGoogle] = useState(false);
  const [isApple, setIsApple] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleClose = () => {
    props.setShow(false);
    setEmail("");
    setRecaptchaResponse("");
    setIsGoogle(false);
    setIsApple(false);
    setSuccess(false);
  };

  const changeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
    e.currentTarget.setCustomValidity("");
  };

  const recaptchaChange = (value: string | null) => {
    setRecaptchaResponse(value || "");
  };

  const doForgot = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;

    if (!form.checkValidity()) return;
    if (!recaptchaResponse) return;

    const req: ForgotPWRequest = {
      email: email,
      recaptchaResponse: recaptchaResponse,
    };
    try {
      const resp = await authApi.forgotPassword(req);
      if (resp.google) {
        setIsGoogle(true);
      } else if (resp.apple) {
        setIsApple(true);
      } else {
        setSuccess(true);
      }
    } catch (err: any) {
      const errType = await getErrorType(err);
      if (errType !== ErrType.Validation) return;
      const respData = err.response?.data;
      if (Array.isArray(respData?.errorDetails)) {
        if (respData.errorDetails.find((ve: ValidationErrorDetail) => ve.name === "email")) {
          //client was ok with email, but server didn't like it (slightly different validation logic)
          emailRef.current?.setCustomValidity(t("userauth.feedback.invalid-email"));
          emailFormRef.current?.reportValidity();
        }
      }
    }
  };

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("form.reset-password")}</Modal.Title>
      </Modal.Header>
      <Form ref={emailFormRef} onSubmit={doForgot}>
        <Modal.Body>
          {!success && !isGoogle && !isApple && (
            <div>
              <p>{t("login.modal.body.reset-password.0")}</p>
              <Form.Control ref={emailRef} required type="email" value={email} onChange={changeEmail} />
              <div className="mt-3 d-flex justify-content-center">
                <ReCAPTCHA sitekey={window.HGGlobal?.recaptchaSiteKey || "key"} onChange={recaptchaChange} />
              </div>
            </div>
          )}
          {success && t("login.modal.body.reset-password.success")}
          {isGoogle && t("login.modal.body.reset-password.google")}
          {isApple && t("login.sign-in-apple")}
        </Modal.Body>
        <Modal.Footer>
          {!success && !isGoogle && (
            <Button variant="primary" type="submit" disabled={!email || !recaptchaResponse}>
              {t("form.reset-password")}
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export function StateTimeoutModal(props: { show: boolean; setShow: (show: boolean) => void }) {
  const { t } = useTranslation();

  const handleClose = () => {
    window.location.reload();
  };

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>{t("session-timeout.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t("session-timeout.body")}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          {t("popup.error.button.ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function TwoFactorModal(props: {
  show: boolean;
  setShow: (show: boolean) => void;
  challenge: TwoFactorChallenge;
  setSuccess: (success: boolean) => void;
  setChallenge: (challenge?: TwoFactorChallenge) => void;
  loginEmail: string;
}) {
  const { t } = useTranslation();
  const [isEmail, setIsEmail] = useState(false);
  const email1CodeBoxRef = useRef<HTMLInputElement>(null);
  const [email1, setEmail1] = useState("");
  const email2CodeBoxRef = useRef<HTMLInputElement>(null);
  const [email2, setEmail2] = useState("");
  const email3CodeBoxRef = useRef<HTMLInputElement>(null);
  const [email3, setEmail3] = useState("");
  const email4CodeBoxRef = useRef<HTMLInputElement>(null);
  const [email4, setEmail4] = useState("");
  const email5CodeBoxRef = useRef<HTMLInputElement>(null);
  const [email5, setEmail5] = useState("");
  const email6CodeBoxRef = useRef<HTMLInputElement>(null);
  const [email6, setEmail6] = useState("");
  const [emailCodeEntered, setEmailCodeEntered] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [emailCodeError, setEmailCodeError] = useState(false);

  useInterval(async () => {
    if (!props.show || !props.challenge || isEmail) return;
    // poll for updates to the challenge
    const req: TwoFactorChallengeStatusRequest = {
      token: props.challenge.token,
    };
    try {
      const resp = await authApi.twoFactorChallengeCompleted(req);
      if (resp.isEmail) {
        setIsEmail(true);
        return;
      }
      if (resp.expires && resp.xsrfToken) {
        // if successful, we have completed the 2fa login flow
        props.setSuccess(true);
        handleClose();
      }
    } catch (err: any) {
      switch (getStatusCode(err)) {
        case 403:
        case 404:
          // challenge expired or was never valid
          handleClose();
          break;
        default:
          console.error("get 2fa challenge status error", err);
      }
    }
  }, 1.5 * 1000);

  useEffect(() => {
    if (isEmail) {
      // if isEmail was set, set focus to the first input box
      email1CodeBoxRef.current?.focus();
    }
  }, [isEmail]);
  // these all serve to auto-focus on the next email code input box
  useEffect(() => {
    if (email1 && !email2) email2CodeBoxRef.current?.focus();
  }, [email1, email2]);
  useEffect(() => {
    if (email2 && !email3) email3CodeBoxRef.current?.focus();
  }, [email2, email3]);
  useEffect(() => {
    if (email3 && !email4) email4CodeBoxRef.current?.focus();
  }, [email3, email4]);
  useEffect(() => {
    if (email4 && !email5) email5CodeBoxRef.current?.focus();
  }, [email4, email5]);
  useEffect(() => {
    if (email5 && !email6) email6CodeBoxRef.current?.focus();
  }, [email5, email6]);

  const emailCode = (): string => {
    return email1 + email2 + email3 + email4 + email5 + email6;
  };

  const sendEmailCode = async (code: string) => {
    setShowSpinner(true);
    const req: TwoFactorChallengeResponse = {
      token: props.challenge.token,
      emailCode: code,
      uiCode: 0,
      loginEmail: props.loginEmail,
    };
    try {
      const resp = await authApi.twoFactorValidateEmail(req);
      if (resp.expires && resp.xsrfToken) {
        // if successful, we have completed the 2fa login flow
        props.setSuccess(true);
        handleClose();
      }
    } catch (err: any) {
      console.error("error validating email code", err);
      setEmailCodeError(true);
    } finally {
      setShowSpinner(false);
    }
  };

  if (isEmail && emailCode().length > 5 && !emailCodeEntered) {
    setEmailCodeEntered(true);
    sendEmailCode(emailCode()).then();
  } else if (isEmail && emailCodeEntered && emailCode().length <= 5) {
    setEmailCodeEntered(false);
    setEmailCodeError(false);
  }

  const sendEmail = async () => {
    try {
      const newChallenge = await authApi.twoFactorChallengeEmail(props.challenge);
      props.setChallenge(newChallenge);
      setIsEmail(true);
    } catch (err: any) {
      console.error("2fa challenge via email error", err);
      HGBugsnagNotify("2faEmail", err);
    }
  };

  const handleClose = () => {
    props.setChallenge(undefined);
    props.setShow(false);
    setIsEmail(false);
    setEmail1("");
    setEmail2("");
    setEmail3("");
    setEmail4("");
    setEmail5("");
    setEmail6("");
    setEmailCodeEntered(false);
    setShowSpinner(false);
    setEmailCodeError(false);
  };

  return (
    <Modal show={props.show} onHide={handleClose} backdrop="static">
      <Modal.Header>
        <Modal.Title>{t("form.button.login")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isEmail ? (
          <>
            <Card>
              <Card.Header className="text-center">{t("login.2fa.enter-email-code")}</Card.Header>
              <Card.Body className="d-flex justify-content-between mx-4">
                <Form.Control
                  onPaste={(e) => {
                    try {
                      e.clipboardData.items[0]?.getAsString((pasted) => {
                        if (pasted.length === 6) {
                          setEmail1(pasted[0] ?? "");
                          setEmail2(pasted[1] ?? "");
                          setEmail3(pasted[2] ?? "");
                          setEmail4(pasted[3] ?? "");
                          setEmail5(pasted[4] ?? "");
                          setEmail6(pasted[5] ?? "");
                        }
                      });
                    } catch {}
                  }}
                  autoComplete="off"
                  size="lg"
                  className="w-1em text-center"
                  ref={email1CodeBoxRef}
                  value={email1}
                  maxLength={1}
                  onChange={(e) => setEmail1(e.currentTarget.value)}
                />
                <Form.Control
                  autoComplete="off"
                  size="lg"
                  className="w-1em text-center"
                  ref={email2CodeBoxRef}
                  value={email2}
                  maxLength={1}
                  onChange={(e) => setEmail2(e.currentTarget.value)}
                />
                <Form.Control
                  autoComplete="off"
                  size="lg"
                  className="w-1em text-center"
                  ref={email3CodeBoxRef}
                  value={email3}
                  maxLength={1}
                  onChange={(e) => setEmail3(e.currentTarget.value)}
                />
                <Form.Control
                  autoComplete="off"
                  size="lg"
                  className="w-1em text-center"
                  ref={email4CodeBoxRef}
                  value={email4}
                  maxLength={1}
                  onChange={(e) => setEmail4(e.currentTarget.value)}
                />
                <Form.Control
                  autoComplete="off"
                  size="lg"
                  className="w-1em text-center"
                  ref={email5CodeBoxRef}
                  value={email5}
                  maxLength={1}
                  onChange={(e) => setEmail5(e.currentTarget.value)}
                />
                <Form.Control
                  autoComplete="off"
                  size="lg"
                  className="w-1em text-center"
                  ref={email6CodeBoxRef}
                  value={email6}
                  maxLength={1}
                  onChange={(e) => setEmail6(e.currentTarget.value)}
                />
              </Card.Body>
              <Card.Footer>
                <div className="mt-3">{t("email.not-received.add-contacts")}</div>
              </Card.Footer>
            </Card>
          </>
        ) : (
          <Card>
            <Card.Header className="text-center">{t("login.2fa.enter-mobile-code")}</Card.Header>
            <Card.Body className="text-center h1">{props.challenge.uiCode}</Card.Body>
          </Card>
        )}
      </Modal.Body>
      <Modal.Footer>
        {emailCodeError && <Danger text={t("userauth.feedback.validate-email-failure")} />}
        {showSpinner && <Spinner animation="border" />}
        <Button variant="secondary" onClick={handleClose}>
          {t("general.cancel")}
        </Button>
        {!isEmail && (
          <Button variant="secondary" onClick={sendEmail}>
            {t("userauth.form.label.email-code")}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
