import { useTranslation } from "react-i18next";
import { PythonI18nInterpolation } from "./helpers/globals";
import { browserVersion, isChrome, isFirefox, isIE, isMobileSafari, isSafari } from "react-device-detect";
import { Button } from "react-bootstrap";

export function unsupportedBrowser(): boolean {
  if (isIE) return true;

  const browserVer = parseInt(browserVersion);
  if (!browserVer) return false;

  if (isMobileSafari && browserVer < 13) return true;
  if (isSafari && browserVer < 14) return true;
  if (isChrome && browserVer < 78) return true;
  if (isFirefox && browserVer < 65) return true;

  return false;
}

export function UnsupportedMessage() {
  const { t } = useTranslation();

  return (
    <div className="mt-2 ms-3">
      <h6>
        {t("unsupported-browser.body %(chrome)s", {
          interpolation: PythonI18nInterpolation,
          chrome: "Google Chrome",
        })}
      </h6>
      <Button variant="secondary">
        <a href="https://www.google.com/chrome/">
          <img src="https://www.google.com/chrome/static/images/chrome-logo.svg" alt="Google Chrome" />
        </a>
      </Button>
    </div>
  );
}
