import { t } from "i18next";
import { Button, Dropdown } from "react-bootstrap";
import { Gear, List, PencilSquare, PrinterFill, Trash } from "react-bootstrap-icons";
import { BsCopy } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { PropsWithChildren } from "react";

export const DeleteButton = (props: {
  size?: "sm" | "lg";
  onClick?: () => void;
  className?: string;
  text?: string;
  disabled?: boolean;
}) => {
  let iconSize;
  switch (props.size) {
    case "sm":
      iconSize = "1.5em";
      break;

    case "lg":
      iconSize = "1.8em";
      break;

    default:
      iconSize = "1.3em";
  }

  return (
    <Button
      size={props.size}
      variant="danger"
      onClick={props.onClick}
      className={props.className}
      title={t("general.delete")}
      disabled={props.disabled}
    >
      <Trash size={iconSize} />
      {!!props.text && <span className="ms-2">{props.text}</span>}
    </Button>
  );
};

export const EditButton = (props: {
  size?: "sm" | "lg";
  onClick?: (e?: any) => void;
  className?: string;
  disabled?: boolean | undefined;
  variant?: "transparent" | "secondary";
}) => {
  let iconSize;
  switch (props.size) {
    case "sm":
      iconSize = "1.5em";
      break;

    case "lg":
      iconSize = "1.8em";
      break;

    default:
      iconSize = "1.3em";
  }

  return (
    <Button
      className={props.className}
      size={props.size}
      variant={props.variant}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      <PencilSquare size={iconSize} />
    </Button>
  );
};

export const CopyButton = (props: {
  size?: "sm" | "lg";
  onClick?: (e?: any) => void;
  className?: string;
  disabled?: boolean | undefined;
  variant?: "transparent" | "secondary";
}) => {
  let iconSize;
  switch (props.size) {
    case "sm":
      iconSize = "1.5em";
      break;

    case "lg":
      iconSize = "1.8em";
      break;

    default:
      iconSize = "1.3em";
  }

  return (
    <Button
      className={props.className}
      size={props.size}
      variant={props.variant}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      <BsCopy size={iconSize} />
    </Button>
  );
};

export function SettingsButton(props: { className?: string; onClick: (e?: any) => void }) {
  const { t } = useTranslation();

  return (
    <Button
      className={props.className ?? "py-2 me-1"}
      variant="secondary"
      onClick={props.onClick}
      aria-label={t("Jcy-FH-fbr.title")}
    >
      <Gear size="1.3em" />
    </Button>
  );
}

type ActionsDropdownProps = {
  className?: string;
  disabled?: boolean;
};

export function ActionsDropdown(props: PropsWithChildren<ActionsDropdownProps>) {
  const { t } = useTranslation();

  return (
    <Dropdown aria-label={t("userinfo.button.actions")}>
      <Dropdown.Toggle className={props.className ?? "py-2 me-1"} variant="secondary" disabled={props.disabled}>
        <List />
      </Dropdown.Toggle>
      {props.children}
    </Dropdown>
  );
}

export function PrintDropdown(props: PropsWithChildren<ActionsDropdownProps>) {
  const { t } = useTranslation();

  return (
    <Dropdown aria-label={t("general.print")}>
      <Dropdown.Toggle className={props.className ?? "py-2"} variant="secondary" disabled={props.disabled}>
        <PrinterFill className="me-1" size={18} />
        <span>{t("general.print")}</span>
      </Dropdown.Toggle>
      {props.children}
    </Dropdown>
  );
}
