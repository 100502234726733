import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { getGoogleAuthUrl } from "../../helpers/googleSignin";
import { RegisterUser } from "../../types/register/registerUser";
import { congregationApi } from "../../api/cong";
import { HGOauthPopup } from "../oauthPopup";
import { CheckCircleFill } from "react-bootstrap-icons";
import { getAppleAuthUrl } from "../../helpers/appleSignin";
import { AppleSignInButton } from "../utils";

type UserProps = {
  user: RegisterUser;
  formValidated: boolean;
  onUserChange: (name: keyof RegisterUser, value: any) => void;
  onValidatedChange: (validated: boolean) => void;
  requireGoogleSignin: boolean;
  requireAppleSignin: boolean;
};

export function UserInfo(props: UserProps) {
  const { t } = useTranslation();
  const [emailPasswordEnabled, setEmailPasswordEnabled] = useState(false);

  const handleChange = (e: any) => {
    props.onUserChange(e.currentTarget.name, e.currentTarget.value);
  };

  const recaptchaChange = async (value: any) => {
    //update server state with the recaptcha response
    //if the recaptcha changed from valid to invalid/timeout, don't bother verifying
    if (!value) return;

    try {
      await congregationApi.registerRecaptcha(value, window.HGGlobal.state);
      props.onUserChange("gRecaptchaSubmitted", true);
      props.onUserChange("gRecaptchaSuccess", true);
    } catch (error: any) {
      console.warn("recaptcha failure", error);
      props.onUserChange("gRecaptchaSubmitted", true);
      props.onUserChange("gRecaptchaSuccess", false);
    }
  };

  const SigninHeading = () => {
    if (props.user.googleLoggedIn) {
      return <p>{t("register.success.google")}</p>;
    }
    if (props.user.appleLoggedIn) {
      return (
        <div>
          {t("login.sign-in-apple")} <CheckCircleFill color="green" />
        </div>
      );
    }

    if (props.user.requireGoogle) {
      return <p className="text-danger bold">{t("login.modal.body.reset-password.google")} </p>;
    }
    if (props.user.requireApple) {
      return <p className="text-danger bold">{t("login.sign-in-apple")} </p>;
    }

    return <p>{t("register.login-setup")}</p>;
  };

  const requireOAuth = props.user.requireGoogle || props.user.requireApple;
  const oAuthSignedIn = props.user.googleLoggedIn || props.user.appleLoggedIn;
  const showEmailPassword = emailPasswordEnabled && !requireOAuth && !oAuthSignedIn;

  return (
    <Card className="mt-2">
      <Card.Header>
        <h5>{t("login.message.signin")}</h5>
      </Card.Header>
      <Card.Body>
        <SigninHeading />

        <Row className="mb-2">
          <Col>
            {props.formValidated && !(oAuthSignedIn || props.user.gRecaptchaSuccess) && (
              <p className="text-danger bold">{t("register.error.sign-in-method")}</p>
            )}
            {!props.user.requireApple && !oAuthSignedIn && (
              <div id="googleSignInButton">
                <HGOauthPopup
                  url={getGoogleAuthUrl(
                    window.HGGlobal?.state,
                    window.HGGlobal?.redirectUri,
                    window.HGGlobal?.nonce,
                    null,
                    null,
                    true,
                  )}
                  title="Google"
                  onComplete={() => props.onUserChange("googleLoggedIn", true)}
                  height={500}
                  width={400}
                >
                  <span className="googleSignInIcon" />
                  <span className="googleSignInButtonText">{t("userauth.current-auth.method.google")}</span>
                </HGOauthPopup>
              </div>
            )}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            {!props.user.requireGoogle && !oAuthSignedIn && (
              <div>
                <HGOauthPopup
                  url={getAppleAuthUrl(
                    window.HGGlobal?.state,
                    window.HGGlobal?.appleRedirectUri,
                    window.HGGlobal?.nonce,
                  )}
                  title="Apple"
                  onComplete={() => props.onUserChange("appleLoggedIn", true)}
                  height={500}
                  width={400}
                >
                  <AppleSignInButton noLink />
                </HGOauthPopup>
              </div>
            )}
          </Col>
        </Row>

        {!emailPasswordEnabled && !oAuthSignedIn && (
          <Button variant="outline-secondary" size="sm" onClick={() => setEmailPasswordEnabled(true)}>
            {t("register.email-and-password")}
          </Button>
        )}

        {showEmailPassword && (
          <div className="hg-user-edit">
            <Row>
              <Col>
                <Form.Group controlId="email">
                  <Form.Label>{t("form.label.email-address")}</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={props.user.email}
                    isInvalid={props.user.invalidEmail}
                    onChange={handleChange}
                  />
                  {props.user.invalidEmail && (
                    <Form.Text className="text-danger">{t("userauth.feedback.invalid-email")}</Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="password">
                  <Form.Label>{t("form.label.password")}</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    maxLength={56}
                    onChange={handleChange}
                    isInvalid={props.user.weakPassword}
                  />
                  {props.user.weakPassword && (
                    <Form.Text className="text-danger">{t("password-error.insecure")}</Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <Form.Group controlId="firstname">
                  <Form.Label>{t("register.label.first-name")}</Form.Label>
                  <Form.Control type="text" name="firstName" value={props.user.firstName} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="lastname">
                  <Form.Label>{t("register.label.last-name")}</Form.Label>
                  <Form.Control type="text" name="lastName" value={props.user.lastName} onChange={handleChange} />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <ReCAPTCHA sitekey={window.HGGlobal?.recaptchaSiteKey} onChange={recaptchaChange} />
                {(props.formValidated || props.user.gRecaptchaSubmitted) && !props.user.gRecaptchaSuccess && (
                  <span className="text-danger">{t("form.recaptcha-error")}</span>
                )}
              </Col>
            </Row>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}
