import { useQuery } from "@tanstack/react-query";
import { decryptObject, E2E_IV } from "../helpers/e2e";
import HourglassGlobals from "../helpers/globals";
import { nameOfUser } from "../helpers/user";
import NPUser, { NPReason } from "../types/npuser";
import User from "../types/user";
import { api } from "./api";
import { UserAPI } from "./user";

class NPUsersAPI {
  static async npuserFromResponse(npuser: any): Promise<NPUser> {
    if (HourglassGlobals.e2eKey && npuser[E2E_IV]) {
      try {
        npuser = await decryptObject(npuser, HourglassGlobals.e2eKey);
      } catch (err) {
        console.error("error decrypting npuser", npuser.id, err);
      }
    }
    npuser.displayName = nameOfUser(npuser, HourglassGlobals.nameFmt);
    return npuser;
  }

  async getAll(): Promise<NPUser[]> {
    const url = `${UserAPI.base}/notpublishing`;
    const data = await api.get(url).json<any[]>();
    return await Promise.all(data.map((npu: NPUser) => NPUsersAPI.npuserFromResponse(npu)));
  }

  async notPublishing(user: User, reason: NPReason): Promise<NPUser> {
    const url = `${UserAPI.base}/notpublishing/${user.id}`;
    const npRequest = { npreason: reason };
    return api.post(url, { json: npRequest }).json();
  }

  async publishing(npUserID: number): Promise<User> {
    const url = `${UserAPI.base}/publishing/${npUserID}`;
    const data = await api.post(url).json();
    return UserAPI.userFromResponse(data);
  }
}

export default function useNPUsers() {
  return useQuery({
    queryKey: ["npusers"],
    queryFn: () => npUserApi.getAll(),
  });
}

export const npUserApi = new NPUsersAPI();
