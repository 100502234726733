import { PropsWithChildren, useEffect, useState } from "react";

type ExpireProps = {
  removeDelay: number;
  beginFadeDelay?: number;
  onRemoved?: () => void;
};

function Expire(props: PropsWithChildren<ExpireProps>) {
  const [visible, setVisible] = useState(true);
  const [transitionVisible, setTransitionVisible] = useState(true);

  const onExpired = props.onRemoved;
  const transitionDelay = props.beginFadeDelay ? props.beginFadeDelay : props.removeDelay;

  useEffect(() => {
    const transitionTimeout = setTimeout(() => {
      setTransitionVisible(false);
    }, transitionDelay);

    return () => {
      clearTimeout(transitionTimeout);
    };
  }, [transitionDelay, onExpired]);

  return visible ? (
    <span
      className={`${transitionVisible ? "" : "alert-hidden"}`}
      onTransitionEnd={() => {
        setVisible(false);
        if (props.onRemoved) props.onRemoved();
      }}
    >
      {props.children}
    </span>
  ) : null;
}

export default Expire;
