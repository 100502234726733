import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TFunction } from "i18next";
import { useContext, useState } from "react";
import { Card, Dropdown, DropdownButton, Form, Spinner } from "react-bootstrap";
import { ArrowClockwise, Search } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useAbsence } from "../../../api/absence";
import { useCongSettings } from "../../../api/cong";
import useFSGroups from "../../../api/fsgroup";
import { jobApi } from "../../../api/job";
import { meetingsApi, useNotifications, usePrivilegeMap } from "../../../api/meetings";
import QueryKeys from "../../../api/queryKeys";
import { useWMSchedules, weekendApi } from "../../../api/weekend";
import { hasAbsence } from "../../../helpers/absence";
import { HGBugsnagNotify } from "../../../helpers/bugsnag";
import {
  Month,
  dateStringCompare,
  getDayjs,
  isBeforeToday,
  stringToDate,
  weekOf,
  weekOfString,
  wmDate,
} from "../../../helpers/dateHelpers";
import { getStatusCode } from "../../../helpers/errors";
import { sortedFSGroups } from "../../../helpers/fsgroup";
import HourglassGlobals, { HGContext } from "../../../helpers/globals";
import { selectedCong } from "../../../helpers/langGroups";
import { containsFuzzy } from "../../../helpers/locale";
import useInterval from "../../../helpers/useInterval";
import { nameOfUser, userCompare } from "../../../helpers/user";
import { updateWMSchedulesCache } from "../../../query/meetings";
import { assignmentNotificationQueryKey } from "../../../query/notification";
import { DateRange, ISODateString, NotificationDates } from "../../../types/date";
import { JobStatus } from "../../../types/job";
import { Permission } from "../../../types/permission";
import { Absence } from "../../../types/scheduling/absence";
import { Events } from "../../../types/scheduling/events";
import { AssignmentNotification, NotificationType, UserPrivilegeMap } from "../../../types/scheduling/meetings";
import { WeekendNotificationFlag } from "../../../types/scheduling/midweek";
import { CongSettingTypes, CongSettings } from "../../../types/scheduling/settings";
import {
  Speaker,
  TalkMod,
  WMCorePartTitleAsset,
  WMCoreParts,
  WMSchedule,
  WMScheduleInput,
} from "../../../types/scheduling/weekend";
import User from "../../../types/user";
import { ActionsDropdown } from "../../buttons";
import { QueryStatus } from "../../queryStatus";
import { AssignmentSummary, SearchAssigneeModal, useAssignmentMap } from "../RecentAssignments";
import { UserAssignmentDropdowns } from "../UserAssignmentDropdowns";
import {
  DropdownFilter,
  OverlappingAssignment,
  SendAssignmentsReminder,
  UserMap,
  haveAVAAssignment,
  CongName,
} from "../common";
import { MonthScheduleMenu } from "../date";
import { EventBadge } from "../eventAlert";
import {
  NotificationIcon,
  NotificationOptions,
  NotificationStatusLegend,
  notificationStatePart,
} from "../notificationStatus";
import { canUpdateWeekendSchedules, needSendAssignments, noMeetingEvents } from "./wm_common";

export function CRH(props: {
  from: ISODateString;
  to: ISODateString;
  langGroupId: number;
  month: Date;
  setMonth: (d: Date) => void;
  monthCount: number;
  setMonthCount: (c: number) => void;
}) {
  const { t } = useTranslation();
  const [showSpinner, setShowSpinner] = useState(false);
  const [jobId, setJobId] = useState("");
  const userMap = UserMap();
  const scheduleQuery = useWMSchedules(props.from, props.to, props.langGroupId);
  const userAssignmentMapQuery = usePrivilegeMap(props.langGroupId);
  const absencesQuery = useAbsence();
  const notificationDates: NotificationDates = { start: weekOfString(props.from), end: props.to };
  const scheduleDates: DateRange = { from: props.from, to: props.to };
  const notificationsQuery = useNotifications(
    notificationDates.start,
    notificationDates.end,
    NotificationType.WeekendCRH,
  );
  const queryClient = useQueryClient();
  const autoFillMutation = useMutation({
    mutationFn: (range: DateRange) => weekendApi.autoRangeCRH(range.from, range.to, props.langGroupId),
  });
  const canUpdate = HourglassGlobals.permissions.has(Permission.UpdateWeekendSchedules);
  const assignmentMap = useAssignmentMap({ from: props.from, to: props.to }, canUpdate, props.langGroupId);
  const congSettingsQuery = useCongSettings(props.langGroupId);

  useInterval(async () => {
    if (!jobId) return;
    try {
      const resp = await jobApi.getJob(jobId);
      switch (resp.status) {
        case JobStatus.Pending:
          return;
        case JobStatus.Done:
        case JobStatus.Failed:
          setJobId("");
          setShowSpinner(false);
          break;
      }
      await queryClient.invalidateQueries({
        queryKey: assignmentNotificationQueryKey(
          NotificationType.WeekendCRH,
          notificationDates.start,
          notificationDates.end,
        ),
      });
    } catch (err: any) {
      if (getStatusCode(err) === 404) {
        setShowSpinner(false);
        setJobId("");
      } else {
        console.error("wm crh: error fetching job status", err);
        HGBugsnagNotify("wmCRHFetchJob", err);
      }
    }
  }, 3 * 1000);

  const queryStatus = QueryStatus(scheduleQuery, userAssignmentMapQuery, congSettingsQuery);
  if (queryStatus !== null) return queryStatus;
  if (!scheduleQuery.data || !userAssignmentMapQuery.data || !congSettingsQuery.data) return null;

  const autoFill = async () => {
    setShowSpinner(true);
    const range: DateRange = { from: props.from, to: props.to };
    try {
      const resp = await autoFillMutation.mutateAsync(range);
      queryClient.setQueryData([QueryKeys.WMCSchedules, props.langGroupId, range.from, range.to], resp);
    } catch (err: any) {
      console.error("wm autofill all error", err);
      HGBugsnagNotify("wmAutofill", err);
    } finally {
      setShowSpinner(false);
    }
  };

  const sendAssignments = async () => {
    setShowSpinner(true);
    try {
      const job = await meetingsApi.sendAllNotifications(
        notificationDates.start,
        notificationDates.end,
        NotificationType.WeekendCRH,
        props.langGroupId,
      );
      setJobId(job.id);
      await queryClient.invalidateQueries({
        queryKey: assignmentNotificationQueryKey(
          NotificationType.WeekendCRH,
          notificationDates.start,
          notificationDates.end,
        ),
      });
    } catch (err: any) {
      setShowSpinner(false);
      console.error("wm crh: error sending notifications", err);
      HGBugsnagNotify("wmCRHSendAssignments", err);
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between mb-3">
        <div>
          <ActionsDropdown disabled={!canUpdateWeekendSchedules()}>
            <Dropdown.Menu>
              <Dropdown.Item onClick={autoFill}>
                <ArrowClockwise /> {t("schedules.fill.auto")}
              </Dropdown.Item>
              <Dropdown.Item onClick={sendAssignments} disabled={showSpinner || isBeforeToday(props.to)}>
                {t("schedules.assignment.send")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </ActionsDropdown>
          {showSpinner && <Spinner className="ms-2" size="sm" animation="border" />}
        </div>
        <MonthScheduleMenu
          date={props.month}
          setDate={props.setMonth}
          dateSaveKey={CongSettingTypes.WM}
          months={props.monthCount}
          setMonths={props.setMonthCount}
        />
        <div />
      </div>
      {needSendAssignments(props.langGroupId, notificationsQuery.data, scheduleQuery.data) && (
        <SendAssignmentsReminder className="w-75" />
      )}
      <div className="d-flex flex-column gap-4">
        {scheduleQuery.data
          .sort((a, b) => dateStringCompare(a.date, b.date))
          .map((s) => (
            <Week
              key={`week${s.date}`}
              schedule={s}
              langGroupId={props.langGroupId}
              refresh={scheduleQuery.refetch}
              userPrivilegeMap={userAssignmentMapQuery.data}
              userMap={userMap}
              notificationDates={notificationDates}
              notifications={notificationsQuery.data}
              scheduleDates={scheduleDates}
              assignmentMap={assignmentMap}
              absences={absencesQuery.data}
              congSettings={congSettingsQuery.data}
            />
          ))}
      </div>
      <div className="mt-4 ms-2">
        <NotificationStatusLegend />
      </div>
    </div>
  );
}

const WMPartTypeFlag = new Map<WMCoreParts, WeekendNotificationFlag>([
  [WMCoreParts.Chairman, WeekendNotificationFlag.WMChairmanFlag],
  [WMCoreParts.WTConductor, WeekendNotificationFlag.WMWTConductorFlag],
  [WMCoreParts.Reader, WeekendNotificationFlag.WMReaderFlag],
  [WMCoreParts.OpeningPrayer, WeekendNotificationFlag.WMOpeningPrayerFlag],
  [WMCoreParts.ClosingPrayer, WeekendNotificationFlag.WMClosingPrayerFlag],
  [WMCoreParts.Host, WeekendNotificationFlag.WMHospitalityFlag],
  [WMCoreParts.Interpreter, WeekendNotificationFlag.WMInterpreterFlag],
]);

function CRHDropdown(props: {
  part: WMCoreParts;
  schedule: WMSchedule;
  langGroupId: number;
  userPrivilegeMap: UserPrivilegeMap;
  userMap: Map<number, User>;
  refresh: () => void;
  notificationDates: NotificationDates;
  notifications?: AssignmentNotification[];
  scheduleDates: DateRange;
  assignmentMap: Map<number, AssignmentSummary[]>;
  meetingDate: ISODateString;
  absences?: Absence[];
  congSettings: CongSettings;
  noAutoFill?: boolean;
  disabled?: boolean;
}) {
  type AutoPart = {
    date: string;
    part: WMCoreParts;
  };
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const setScheduleMutation = useMutation({
    mutationFn: (wmsInput: WMScheduleInput) => weekendApi.setSchedule(wmsInput, props.langGroupId),
  });
  const autoFillSingleMutation = useMutation({
    mutationFn: (autoPart: AutoPart) => weekendApi.autoSingleCRH(autoPart.date, autoPart.part, props.langGroupId),
  });
  const queryClient = useQueryClient();
  const [showAssignmentModal, setShowAssignmentModal] = useState(false);
  const [assigneeFilter, setAssigneeFilter] = useState("");
  const [needSetFocus, setNeedSetFocus] = useState(false);
  const fsGroupsQuery = useFSGroups({ enabled: props.congSettings.hospitality_by_group });
  const groupInfo = sortedFSGroups(fsGroupsQuery.data ?? [], props.userMap);

  const getAssigneeId = (): number => {
    switch (props.part) {
      case WMCoreParts.Chairman:
        return props.schedule.wm_chairman;
      case WMCoreParts.Reader:
        return props.schedule.wm_reader;
      case WMCoreParts.Host:
        return props.schedule.host;
      case WMCoreParts.WTConductor:
        return props.schedule.wt_conductor ?? props.congSettings.wt_conductor ?? 0;
      case WMCoreParts.OpeningPrayer:
        return props.schedule.openprayer ?? 0;
      case WMCoreParts.ClosingPrayer:
        return props.schedule.closeprayer ?? 0;
      case WMCoreParts.Interpreter:
        return props.schedule.interpreter ?? 0;
    }
  };
  const assignedUserId = getAssigneeId();
  const coTalkMod = props.schedule.talk_mod === TalkMod.CO || props.schedule.event?.event === Events.co;
  const hasSpeaker = !!props.schedule.talk_mod || !!props.schedule.speaker?.id;
  const isSpeakerClosingPrayer =
    props.part === WMCoreParts.ClosingPrayer &&
    props.congSettings.wm_speaker_closing_prayer &&
    !assignedUserId &&
    hasSpeaker;

  const getDDMValue = (): string => {
    const assigneeName = props.userMap.get(assignedUserId)?.displayName ?? "";
    switch (props.part) {
      case WMCoreParts.ClosingPrayer:
        const speakerName = coTalkMod ? talkModSpeaker(props.schedule, t) : nameOfUser(props.schedule.speaker).trim();
        const speakerForClosingPrayer = isSpeakerClosingPrayer ? speakerName : "";
        return assigneeName || speakerForClosingPrayer;
      case WMCoreParts.Host:
        const groupDdmValue = props.congSettings.hospitality_by_group
          ? groupInfo.find((g) => g.overseerId === assignedUserId)?.displayName
          : undefined;
        return groupDdmValue ?? assigneeName;
      default:
        return assigneeName;
    }
  };

  const ddmValue = getDDMValue();

  const showClear = !(props.part === WMCoreParts.WTConductor && assignedUserId === props.congSettings.wt_conductor);
  // we didn't want a whole new privilege for WM prayer. So we re-use "closeprayer" but it does need to be distinct from mm closing prayer
  // for things like searching for overlapping assignments
  const getWMPart = () => {
    switch (props.part) {
      case WMCoreParts.OpeningPrayer:
        return "openprayer";
      case WMCoreParts.ClosingPrayer:
        return "closeprayer";
      default:
        return props.part;
    }
  };
  const wmPart = getWMPart();

  const userIds = props.userPrivilegeMap[wmPart] || [];
  const possibleUsers: User[] = userIds
    .filter((userId) => userId !== assignedUserId)
    .flatMap((userId) => props.userMap.get(userId) || [])
    .sort(userCompare);

  const getNotification = (): AssignmentNotification | undefined => {
    const flag = WMPartTypeFlag.get(props.part);
    if (flag === undefined) return;
    return props.notifications
      ?.filter((n) => n.type === NotificationType.WeekendCRH)
      .find((n) => n.flag === flag && n.part === props.schedule.id);
  };

  const notification = getNotification();
  const notificationState = notificationStatePart(assignedUserId, notification);

  const assignPart = async (userId: number) => {
    setSaving(true);
    const input: WMScheduleInput = {
      date: props.schedule.date,
      opening_song: props.schedule.opening_song?.number ?? null,
      wm_chairman: props.schedule.wm_chairman,
      temp_cong: props.schedule.temp_cong?.id ?? 0,
      speaker: props.schedule.speaker?.id ?? 0,
      speaker2: props.schedule.speaker2 ?? null,
      public_talk: props.schedule.public_talk?.number ?? 0,
      talk_mod: props.schedule.talk_mod ?? "",
      confirmed: props.schedule.confirmed,
      wt_conductor: props.schedule.wt_conductor ?? null,
      wm_reader: props.schedule.wm_reader,
      openprayer: props.schedule.openprayer ?? null,
      closeprayer: props.schedule.closeprayer ?? null,
      interpreter: props.schedule.interpreter,
      host: props.schedule.host,
    };
    // set to null the nullable fields
    if (userId === 0) {
      switch (wmPart) {
        case WMCoreParts.WTConductor:
        case "openprayer":
        case "closeprayer":
          input[wmPart] = null;
          break;
        default:
          input[wmPart] = userId;
      }
    } else {
      input[wmPart] = userId;
    }

    try {
      const resp = await setScheduleMutation.mutateAsync(input);
      updateWMSchedulesCache(queryClient, props.langGroupId, resp);
    } catch (err: any) {
      console.error("wm crh: error setting assignment", err);
      HGBugsnagNotify("wmCRHSetAssignment", err, input);
    } finally {
      setSaving(false);
    }
  };

  const autoAssignPart = async () => {
    setSaving(true);
    const autoSingle: AutoPart = { date: props.schedule.date, part: props.part };
    try {
      const resp = await autoFillSingleMutation.mutateAsync(autoSingle);
      updateWMSchedulesCache(queryClient, props.langGroupId, resp);
      props.refresh();
    } catch (err: any) {
      console.error("wm crh: error auto-assigning single", err);
      HGBugsnagNotify("wmCRHAutoAssignSingle", err);
    } finally {
      setSaving(false);
    }
  };

  const filteredUsers = possibleUsers.filter((u) =>
    assigneeFilter ? containsFuzzy(u.displayName, assigneeFilter) : true,
  );

  // this returns the localization key for the type of assignment causing the overlap, or undefined if there is no overlap
  const hasOverlappingAssignment = (): string | undefined => {
    // someone with an AV/Attendant assignment has an overlap if they have any other assignment except hospitality
    const avaAssignment = haveAVAAssignment(
      assignedUserId,
      props.schedule.date,
      props.congSettings,
      "weekend",
      props.assignmentMap,
    );
    if (wmPart !== WMCoreParts.Host && avaAssignment) {
      return avaAssignment;
    }

    // someone with an incoming or outgoing speaking assignment should show a conflict if they have any other assignment
    if (props.schedule.speaker?.userId === assignedUserId) return "schedules.weekend.speaker.0";
    if (props.schedule.out && props.schedule.out.some((pta) => pta.speaker?.userId === assignedUserId))
      return "schedules.weekend.outgoing";

    // if the wt conductor has any other weekend assignment besides hospitality, show it as a conflict
    const conductor = props.schedule.wt_conductor ?? props.congSettings.wt_conductor;
    if (conductor === assignedUserId && wmPart !== WMCoreParts.WTConductor && wmPart !== WMCoreParts.Host)
      return "schedules.weekend.wt-conductor";
  };

  const overlappingAssignment = hasOverlappingAssignment();
  const isPastWeek = getDayjs(props.schedule.date).isBefore(weekOf(new Date()), "day");

  return (
    <>
      <CRHAssignmentModal
        date={props.meetingDate}
        assignee={props.userMap.get(assignedUserId)}
        type={props.part}
        possibleAssignees={possibleUsers}
        show={showAssignmentModal}
        setShow={setShowAssignmentModal}
        assign={assignPart}
        assignmentMap={props.assignmentMap}
        userMap={props.userMap}
      />

      <Form.Group className="d-flex flex-column">
        <Form.Label className={"fw-bolder text-muted mb-1" + (props.disabled ? "text-muted" : "")}>
          {t(WMCorePartTitleAsset[props.part])}
        </Form.Label>
        <DropdownButton
          onToggle={(nextShow: boolean) => {
            if (!nextShow) setAssigneeFilter("");
            setNeedSetFocus(nextShow);
          }}
          title={
            <>
              {saving ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <span>
                  {!!ddmValue && !isPastWeek && !isSpeakerClosingPrayer && (
                    <>
                      <NotificationIcon
                        nstate={notificationState}
                        hasAbsence={hasAbsence(assignedUserId, stringToDate(props.meetingDate), props.absences)}
                      />
                      {!!overlappingAssignment && (
                        <OverlappingAssignment className="ms-1" label={t(overlappingAssignment)} />
                      )}
                    </>
                  )}
                </span>
              )}
              <span className="ms-1 me-1">{ddmValue || <i>{t("general.none-selected")}</i>}</span>
            </>
          }
          variant="secondary"
          className="dropdown-bounded"
          disabled={!canUpdateWeekendSchedules() || saving || props.disabled}
        >
          <NotificationOptions
            startDate={props.notificationDates.start}
            endDate={props.notificationDates.end}
            nstate={notificationState}
            notification={notification}
          />
          {!!overlappingAssignment && (
            <Dropdown.Item disabled>
              <OverlappingAssignment className="me-1" label={t(overlappingAssignment)} /> {t(overlappingAssignment)}
            </Dropdown.Item>
          )}
          {!ddmValue && !props.noAutoFill && (
            <Dropdown.Item onClick={autoAssignPart}>
              <ArrowClockwise />️ <i>{t("schedules.fill.auto")}</i>
            </Dropdown.Item>
          )}
          {!!ddmValue && showClear && (
            <Dropdown.Item onClick={() => assignPart(0)}>
              ❌ <i>{t("schedules.fill.clear")}</i>
            </Dropdown.Item>
          )}
          <Dropdown.Item onClick={() => setShowAssignmentModal(true)}>
            <Search /> <i>{t("schedules.assignment.list-title")}</i>
          </Dropdown.Item>
          <Dropdown.Divider />
          <DropdownFilter key={0} filter={assigneeFilter} setFilter={setAssigneeFilter} setFocus={needSetFocus} />
          {props.part === WMCoreParts.Host && props.congSettings.hospitality_by_group ? (
            groupInfo.map((fsg) => (
              <Dropdown.Item key={fsg.overseerId} onClick={() => assignPart(fsg.overseerId)}>
                {fsg.displayName}
              </Dropdown.Item>
            ))
          ) : (
            <UserAssignmentDropdowns
              users={filteredUsers}
              assignDate={props.meetingDate}
              langGroupId={props.langGroupId}
              assign={assignPart}
              assigneeFilter={assigneeFilter}
              privilegeAsset={WMCorePartTitleAsset[props.part]}
            />
          )}
        </DropdownButton>
      </Form.Group>
    </>
  );
}

function talkModSpeaker(schedule: WMSchedule, t: TFunction): string {
  //if it's a CO and the name isn't set, return the translation for circuit overseer
  if (schedule.talk_mod === "tbd") return t("schedules.weekend.tbd");
  if (schedule.talk_mod === "stream") return t("schedules.stream");
  if (schedule.speaker.firstname) return schedule.speaker.firstname;
  if (schedule.event?.event === Events.co) return t("general.circuit-overseer");
  return t("schedules.weekend.tbd");
}

const Week = (props: {
  schedule: WMSchedule;
  langGroupId: number;
  refresh: () => void;
  userPrivilegeMap: UserPrivilegeMap;
  userMap: Map<number, User>;
  notificationDates: NotificationDates;
  notifications?: AssignmentNotification[];
  scheduleDates: DateRange;
  assignmentMap: Map<number, AssignmentSummary[]>;
  absences?: Absence[];
  congSettings: CongSettings;
}): JSX.Element => {
  const { t, i18n } = useTranslation();
  const ctx = useContext(HGContext);
  const hostCong = ctx.globals.cong;
  const myCong = selectedCong(props.langGroupId) ?? hostCong;

  const event = props.schedule.event?.event;
  // if there's an event that cancels meetings, and we don't have any language groups, then don't show the option to schedule the meeting
  const noMeeting = event && noMeetingEvents.has(event) && ctx.globals.language_groups.length === 0;
  const chairmanOnly = event === Events.co;

  const speakerName = (speaker: Speaker): string => {
    if (speaker.firstname === "tbd") return t("schedules.weekend.tbd");
    return nameOfUser(speaker);
  };
  const meetingDate = wmDate(props.schedule.date, myCong, props.schedule.event ? [props.schedule.event] : undefined);

  const speakerHasConflictingAssignment = (userId: number): string | undefined => {
    // the incoming or outgoing speaker has a potential conflict if they have any other WM assignments or an AVA assignment
    const avaAssignment = haveAVAAssignment(
      userId,
      props.schedule.date,
      props.congSettings,
      "weekend",
      props.assignmentMap,
    );
    if (avaAssignment) {
      return avaAssignment;
    }

    const sunday = weekOfString(meetingDate, 7);
    if (
      props.assignmentMap
        .get(userId)
        ?.some((as) => as.date === sunday && Object.values(WMCoreParts).includes(as.type as WMCoreParts))
    ) {
      return "conganalysis.attendance.weekend";
    }
  };

  const incomingConflict = !!props.schedule.speaker?.userId
    ? speakerHasConflictingAssignment(props.schedule.speaker?.userId)
    : undefined;

  return (
    <Card key={props.schedule.date}>
      <Card.Body className="d-flex flex-row gap-4">
        <div>
          <Card className="calendar-day">
            <Card.Header className="bg-primary text-white text-center text-uppercase fw-bold py-1 px-4">
              {Month.fromDateString(meetingDate).toLocaleMonthName(i18n.language)}
            </Card.Header>
            <Card.Body className="py-1 px-4 text-center">
              <span className="fs-1">{stringToDate(meetingDate).getDate()}</span>
            </Card.Body>
          </Card>
        </div>
        <div className="d-flex flex-wrap align-self-start flex-grow-1">
          <EventBadge className="ms-1" context="wm" event={props.schedule.event} />

          {!noMeeting && (
            <>
              <div className="col-12">
                <div className="row mx-0 px-0 mb-3 justify-content-between">
                  <div className="col-12 col-lg-4 pb-3 d-flex p-0">
                    <div>
                      <CRHDropdown
                        part={WMCoreParts.Chairman}
                        schedule={props.schedule}
                        langGroupId={props.langGroupId}
                        userPrivilegeMap={props.userPrivilegeMap}
                        userMap={props.userMap}
                        refresh={props.refresh}
                        notificationDates={props.notificationDates}
                        notifications={props.notifications}
                        scheduleDates={props.scheduleDates}
                        assignmentMap={props.assignmentMap}
                        meetingDate={meetingDate}
                        absences={props.absences}
                        congSettings={props.congSettings}
                      />
                    </div>

                    {props.schedule.talk_mod !== TalkMod.TBD && (
                      <div className="ms-2">
                        <span>
                          <p className="fw-bold mb-1 text-muted">{t("schedules.song %d").replace("%d", "")}</p>
                          <SongNumberDropdown
                            scheduleDates={props.scheduleDates}
                            schedule={props.schedule}
                            langGroupId={props.langGroupId}
                          />
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-lg-4 mb-3 p-0">
                    <CRHDropdown
                      part={WMCoreParts.OpeningPrayer}
                      schedule={props.schedule}
                      langGroupId={props.langGroupId}
                      userPrivilegeMap={props.userPrivilegeMap}
                      userMap={props.userMap}
                      refresh={props.refresh}
                      notificationDates={props.notificationDates}
                      notifications={props.notifications}
                      scheduleDates={props.scheduleDates}
                      assignmentMap={props.assignmentMap}
                      meetingDate={meetingDate}
                      absences={props.absences}
                      congSettings={props.congSettings}
                      noAutoFill
                    />
                  </div>
                </div>

                <div className="row px-0 mx-0 pt-3 pb-3 border-top">
                  {((!!props.schedule.speaker?.id || !!props.schedule.talk_mod) && (
                    <>
                      <div className="col-12 col-lg-4 mb-3 p-0 d-flex flex-row">
                        <div className="d-flex flex-column flex-grow-1">
                          <p className="fw-bold m-0 text-muted">
                            {t("schedules.weekend.speaker.0")}
                            {!!incomingConflict && (
                              <OverlappingAssignment className="ms-1" label={t(incomingConflict)} />
                            )}
                          </p>
                          <h4>
                            {props.schedule.talk_mod
                              ? talkModSpeaker(props.schedule, t)
                              : speakerName(props.schedule.speaker)}
                          </h4>
                          {!props.schedule.talk_mod &&
                            props.schedule.speaker.congregation &&
                            CongName(props.schedule.speaker.congregation)}
                        </div>
                      </div>
                      {props.congSettings.show_interpreter && (
                        <div className="col-12 col-lg-4 mb-3 p-0">
                          <CRHDropdown
                            part={WMCoreParts.Interpreter}
                            schedule={props.schedule}
                            langGroupId={props.langGroupId}
                            userPrivilegeMap={props.userPrivilegeMap}
                            userMap={props.userMap}
                            refresh={props.refresh}
                            notificationDates={props.notificationDates}
                            notifications={props.notifications}
                            scheduleDates={props.scheduleDates}
                            assignmentMap={props.assignmentMap}
                            meetingDate={meetingDate}
                            absences={props.absences}
                            congSettings={props.congSettings}
                            noAutoFill
                          />
                        </div>
                      )}
                    </>
                  )) || <p className="p-0">{t("schedules.weekend.no-talk-scheduled")}</p>}
                  {props.schedule.out.length > 0 && (
                    <div className="col-12 col-lg-4 mb-3 p-0">
                      <p className="fw-bold m-0 text-muted">{t("schedules.weekend.outgoing")}</p>
                      {props.schedule.out.map((o) => {
                        if (!o.speaker) return null;
                        const conflict = !!o.speaker.userId
                          ? speakerHasConflictingAssignment(o.speaker.userId)
                          : undefined;
                        return (
                          <p className="m-0" key={o.id}>
                            {speakerName(o.speaker)}
                            {!!conflict && <OverlappingAssignment className="ms-1" label={t(conflict)} />}
                          </p>
                        );
                      })}
                    </div>
                  )}
                </div>

                <div className="row p-0 mx-0 pt-3 pb-1 border-top">
                  <div className="col-12 col-lg-4 mb-3 p-0">
                    <CRHDropdown
                      part={WMCoreParts.WTConductor}
                      schedule={props.schedule}
                      langGroupId={props.langGroupId}
                      userPrivilegeMap={props.userPrivilegeMap}
                      userMap={props.userMap}
                      refresh={props.refresh}
                      notificationDates={props.notificationDates}
                      notifications={props.notifications}
                      scheduleDates={props.scheduleDates}
                      assignmentMap={props.assignmentMap}
                      meetingDate={meetingDate}
                      absences={props.absences}
                      congSettings={props.congSettings}
                      noAutoFill
                    />
                  </div>
                  {(!chairmanOnly || !!props.schedule.wm_reader) && (
                    <div className="col-12 col-lg-4 mb-3 p-0">
                      <CRHDropdown
                        disabled={!props.congSettings.show_wt_reader}
                        part={WMCoreParts.Reader}
                        schedule={props.schedule}
                        langGroupId={props.langGroupId}
                        userPrivilegeMap={props.userPrivilegeMap}
                        userMap={props.userMap}
                        refresh={props.refresh}
                        notificationDates={props.notificationDates}
                        notifications={props.notifications}
                        scheduleDates={props.scheduleDates}
                        assignmentMap={props.assignmentMap}
                        meetingDate={meetingDate}
                        absences={props.absences}
                        congSettings={props.congSettings}
                      />
                    </div>
                  )}
                  <div className="col-12 col-lg-4 mb-3 p-0">
                    <CRHDropdown
                      part={WMCoreParts.ClosingPrayer}
                      schedule={props.schedule}
                      langGroupId={props.langGroupId}
                      userPrivilegeMap={props.userPrivilegeMap}
                      userMap={props.userMap}
                      refresh={props.refresh}
                      notificationDates={props.notificationDates}
                      notifications={props.notifications}
                      scheduleDates={props.scheduleDates}
                      assignmentMap={props.assignmentMap}
                      meetingDate={meetingDate}
                      absences={props.absences}
                      congSettings={props.congSettings}
                      noAutoFill
                    />
                  </div>
                </div>

                {((!chairmanOnly && props.congSettings.enable_hospitality) || !!props.schedule.host) && (
                  <div className="row p-0 m-0 mt-3 pt-3 pb-2 border-top">
                    <div className="col-12 col-lg-4 mb-3 p-0">
                      <CRHDropdown
                        part={WMCoreParts.Host}
                        schedule={props.schedule}
                        langGroupId={props.langGroupId}
                        userPrivilegeMap={props.userPrivilegeMap}
                        userMap={props.userMap}
                        refresh={props.refresh}
                        notificationDates={props.notificationDates}
                        notifications={props.notifications}
                        scheduleDates={props.scheduleDates}
                        assignmentMap={props.assignmentMap}
                        meetingDate={meetingDate}
                        absences={props.absences}
                        congSettings={props.congSettings}
                      />
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

function CRHAssignmentModal(props: {
  date: string;
  assignee?: User;
  type: WMCoreParts;
  possibleAssignees: User[];
  show: boolean;
  setShow: (show: boolean) => void;
  assign: (userId: number) => Promise<void>;
  assignmentMap: Map<number, AssignmentSummary[]>;
  userMap: Map<number, User>;
}) {
  const { t } = useTranslation();

  return (
    <SearchAssigneeModal
      date={props.date}
      assignee={props.assignee}
      possibleAssignees={props.possibleAssignees}
      partType={props.type}
      show={props.show}
      setShow={props.setShow}
      assign={props.assign}
      assignmentMap={props.assignmentMap}
      userMap={props.userMap}
      heading={
        <>
          <div>
            <div className="mx-auto">
              <h5>{t(WMCorePartTitleAsset[props.type])}</h5>
            </div>
          </div>
        </>
      }
    />
  );
}

function SongNumberDropdown(props: { scheduleDates: DateRange; schedule: WMSchedule; langGroupId: number }) {
  const [saving, setSaving] = useState(false);
  const setScheduleMutation = useMutation({
    mutationFn: (wmsInput: WMScheduleInput) => weekendApi.setSchedule(wmsInput, props.langGroupId),
  });
  const queryClient = useQueryClient();

  const assignSong = async (songNumber: number | null) => {
    setSaving(true);
    const input: WMScheduleInput = {
      ...props.schedule,
      opening_song: songNumber,
      temp_cong: props.schedule.temp_cong?.id ?? 0,
      speaker: props.schedule.speaker.id,
      speaker2: props.schedule.speaker2 ?? null,
      public_talk: props.schedule.public_talk?.number ?? 0,
      talk_mod: props.schedule.talk_mod ?? "",
      wt_conductor: props.schedule.wt_conductor ?? null,
      openprayer: props.schedule.openprayer ?? null,
      closeprayer: props.schedule.closeprayer ?? null,
    };
    try {
      const resp = await setScheduleMutation.mutateAsync(input);
      updateWMSchedulesCache(queryClient, props.langGroupId, resp);
    } catch (err: any) {
      console.error("wm crh: error setting song", err);
      HGBugsnagNotify("setWMSong", err);
    } finally {
      setSaving(false);
    }
  };

  const songNumbers = Array(158).fill(0);

  return (
    <Dropdown className="dropdown-bounded">
      <Dropdown.Toggle variant="secondary" disabled={!canUpdateWeekendSchedules() || saving}>
        {saving ? <Spinner animation="border" size="sm" /> : props.schedule.opening_song?.number || " "}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {!!props.schedule.opening_song && <Dropdown.Item onClick={() => assignSong(null)}>&nbsp;</Dropdown.Item>}
        {songNumbers.map((_, i) => (
          <Dropdown.Item key={i} onClick={() => assignSong(i + 1)}>
            {i + 1}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
