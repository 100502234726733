import Report from "../types/report";
import QueryKeys from "../api/queryKeys";
import { blankReport } from "../helpers/report";
import { QueryClient, QueryKey } from '@tanstack/react-query';
import User from "../types/user";

export function updateReportsCache(queryClient: QueryClient, oldReport: Report, newReport: Report) {
  if (oldReport.month !== newReport.month || oldReport.year !== newReport.year) {
    console.error("updateReportsCache: mismatch month and year", oldReport, newReport);
    return;
  }

  queryClient.setQueryData<Report[]>(reportsQueryKey(newReport.user.id), (old) => {
    if (!old) return [];
    return old.map((rpt) => (rpt.month === oldReport.month && rpt.year === oldReport.year ? newReport : rpt));
  });
}

export function deleteReportFromCache(queryClient: QueryClient, report: Report, user: User): Report {
  const blank = blankReport(user, report.month, report.year);
  queryClient.setQueryData<Report[]>(reportsQueryKey(user.id), (old) => {
    if (!old) return [];
    return old.map((rpt) => (rpt.id === report.id ? blank : rpt));
  });
  return blank;
}

export function reportsQueryKey(userId: number): QueryKey {
  return [QueryKeys.Reports, userId];
}
