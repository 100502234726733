import { RegisterUser } from "./registerUser";
import { RegisterCong } from "./registerCong";

export enum Step {
  Init,
  AlreadyRegistered,
  Info,
  Finish,
  CongDuplicate,
  CongDuplicateEmailSent,
  EmailDuplicate,
}

export enum RegistrationType {
  UserPass = "userpass",
  Google = "google",
  Apple = "apple",
}

export type RegistrationState = {
  user: RegisterUser;
  cong: RegisterCong;
};
