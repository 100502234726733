import { Button, Modal } from "react-bootstrap";
import ReactRouterPrompt from "../@adapters/react-router-prompt";

type RouteLeavingProps = {
  when?: boolean | undefined;
  modalTitle: string;
  modalBody: string;
  modalConfirm: string;
  modalCancel: string;
};

function RouteLeavingGuard(props: RouteLeavingProps) {
  return (
    <ReactRouterPrompt when={props.when!}>
      {({ isActive, onConfirm, onCancel }) => (
        <WarningDialog
          show={isActive}
          title={props.modalTitle}
          content={props.modalBody}
          cancelButtonText={props.modalCancel}
          confirmButtonText={props.modalConfirm}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      )}
    </ReactRouterPrompt>
  );
}

type WarningProps = {
  show: boolean;
  title: string;
  content: string;
  cancelButtonText: string;
  confirmButtonText: string;
  onCancel: () => void;
  onConfirm: () => void;
};

export function WarningDialog(props: WarningProps) {
  return (
    <Modal show={props.show}>
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.content}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onConfirm}>
          {props.confirmButtonText}
        </Button>
        <Button variant="primary" onClick={props.onCancel}>
          {props.cancelButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RouteLeavingGuard;
