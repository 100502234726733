import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Nav, NavDropdown } from "react-bootstrap";
import HourglassGlobals from "../../helpers/globals";
import { Permission } from "../../types/permission";
import React from "react";

export function NavReports() {
  const { t } = useTranslation();

  return (
    <Nav className="flex-column hg-nav">
      <NavDropdown.Item as={Link} to="/missing">
        {t("chart.legend.missing")}
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Header>{t("nav.reports.monthly.0")}</NavDropdown.Header>

      <NavDropdown.Item as={Link} to="/monthSummary">
        {t("nav.reports.monthly.summary")}
      </NavDropdown.Item>

      {(HourglassGlobals.permissions.has(Permission.ViewSummaryDetails) ||
        HourglassGlobals.permissions.has(Permission.ViewSummaryDetailsGroup)) && (
        <NavDropdown.Item as={Link} to="/monthDetail">
          {t("nav.reports.monthly.detail")}
        </NavDropdown.Item>
      )}
      <NavDropdown.Item as={Link} to="/monthStatistics">
        {t("nav.reports.monthly.statistics")}
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Header>{t("nav.reports.averages.0")}</NavDropdown.Header>
      <NavDropdown.Item as={Link} to="/congAvg">
        {t("list.congregation.title")}
      </NavDropdown.Item>
      {(HourglassGlobals.permissions.has(Permission.ViewSummaryDetails) ||
        HourglassGlobals.permissions.has(Permission.ViewSummaryDetailsGroup)) && (
        <NavDropdown.Item as={Link} to="/groupAvg">
          {t("nav.reports.averages.group")}
        </NavDropdown.Item>
      )}
      {HourglassGlobals.permissions.has(Permission.ViewReportsAll) && (
        <NavDropdown.Item as={Link} to="/pubAvg">
          {t("list.publishers.title")}
        </NavDropdown.Item>
      )}
      {/*only show this divider if we will show rp or ap report items*/}
      {HourglassGlobals.permissions.has(Permission.ViewSummaryRegpio) ||
        (HourglassGlobals.permissions.has(Permission.ViewSummaryDetails) && <NavDropdown.Divider />)}
      {HourglassGlobals.permissions.has(Permission.ViewSummaryRegpio) && (
        <NavDropdown.Item as={Link} to="/rpSummary">
          {t("recordcard.svcyeartotals.regular-pioneers")}
        </NavDropdown.Item>
      )}
      {HourglassGlobals.permissions.has(Permission.ViewSummaryDetails) && (
        <NavDropdown.Item as={Link} to="/apSummary">
          {t("recordcard.svcyeartotals.auxiliary-pioneers")}
        </NavDropdown.Item>
      )}
      <NavDropdown.Divider />
      <NavDropdown.Item as={Link} to="/congAnalysis">
        {t("nav.reports.cong-analysis")}
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to="/svcYearTotals">
        {t("nav.reports.svcyeartotals")}
      </NavDropdown.Item>
      {HourglassGlobals.permissions.has(Permission.ViewReportsAll) &&
        HourglassGlobals.permissions.has(Permission.ViewUsers) && (
          <NavDropdown.Item as={Link} to="/coReports">
            {t("general.circuit-overseer")}
          </NavDropdown.Item>
        )}
    </Nav>
  );
}
