export type Job = {
  id: string;
  userID: number;
  type: string;
  status: JobStatus;
  result: string;
  createdAt: string;
  expiresAt: string;
};

export type JobsResponse = {
  jobs: Job[];
  nextExpires: number;
};

export enum JobStatus {
  Pending = "pend",
  Failed = "fail",
  Done = "done",
}

export type ReminderResult = {
  push_count: number;
  email_count: number;
  email_sent: number;
};
