import { Alert } from "react-bootstrap";
import { CheckCircle, ExclamationOctagonFill, ExclamationTriangle } from "react-bootstrap-icons";

export const Warning = (props: { text: string | JSX.Element; className?: string }) => {
  return (
    <Alert variant={`warning ${props.className ?? ""}`}>
      <ExclamationTriangle className="me-3" size="2em" />
      {props.text}
    </Alert>
  );
};

export const Danger = (props: { text: string | JSX.Element; className?: string }) => {
  return (
    <Alert variant={`danger ${props.className ?? ""}`}>
      <ExclamationOctagonFill color="red" className="me-3" size="2em" />
      {props.text}
    </Alert>
  );
};

export const Success = (props: { text: string | JSX.Element; className?: string }) => {
  return (
    <Alert variant={`success ${props.className ?? ""}`}>
      <CheckCircle className="me-3" size="2em" />
      {props.text}
    </Alert>
  );
};
