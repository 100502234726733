import PasswordValidator from "password-validator";

export const FORCE_DECRYPT_HEADER = { headers: { "FORCE-DECRYPTED": "true" } };
const E2EPrefix = "e2e_";
export const E2E_IV = E2EPrefix + "iv";

export class EncryptionError extends Error {
  cause?: Error;

  constructor(message: string, cause?: Error) {
    super(message);
    this.cause = cause;
    this.name = "EncryptionError";
  }
}

//derive a PBKDF2 key from the supplied password
async function deriveKey(password: string) {
  const enc = new TextEncoder();
  return crypto.subtle.importKey("raw", enc.encode(password), { name: "PBKDF2" }, false, ["deriveBits", "deriveKey"]);
}

export type WrappedKey = {
  key: ArrayBuffer;
  salt: Uint8Array;
};

//Wrap the given key using AES-KW
export async function wrapCryptoKey(keyToWrap: CryptoKey, password: string): Promise<WrappedKey> {
  const salt = crypto.getRandomValues(new Uint8Array(16));
  const wrappingKey = await getWrappingKey(password, salt);

  const key = await crypto.subtle.wrapKey("raw", keyToWrap, wrappingKey, "AES-KW");
  return {
    key: key,
    salt: salt,
  };
}

export function arrayBufferToBase64(arrayBuffer: ArrayBuffer) {
  const byteArray = new Uint8Array(arrayBuffer);
  return uint8ArrayToBase64(byteArray);
}

export function uint8ArrayToBase64(arr: Uint8Array) {
  let byteString = "";

  for (let i = 0; i < arr.byteLength; i++) {
    // @ts-ignore noUncheckedIndexedAccess
    byteString += String.fromCharCode(arr[i]);
  }

  return btoa(byteString);
}

export function base64ToUint8Array(b64: string): Uint8Array {
  const decoded = atob(b64);
  const byteArray = new Uint8Array(decoded.length);
  for (let i = 0; i < decoded.length; i++) {
    byteArray[i] = decoded.charCodeAt(i);
  }
  return byteArray;
}

export function base64urlToArrayBuffer(b64url: string): ArrayBuffer {
  // Replace non-url compatible chars with base64 standard chars
  let input = b64url.replace(/-/g, "+").replace(/_/g, "/");

  // Pad out with standard base64 required padding characters
  const pad = input.length % 4;
  if (pad) {
    if (pad === 1) {
      throw new Error("InvalidLengthError: Input base64url string is the wrong length to determine padding");
    }
    input += new Array(5 - pad).join("=");
  }

  return base64ToArrayBuffer(input);
}

export function arrayBufferToBase64Url(buf: ArrayBuffer): string {
  return btoa(Array.from(new Uint8Array(buf), (b) => String.fromCharCode(b)).join(""))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

export function base64ToArrayBuffer(b64: string): ArrayBuffer {
  return bytesToArrayBuffer(base64ToUint8Array(b64));
}

// Convert an array of byte values to an ArrayBuffer.
function bytesToArrayBuffer(bytes: Uint8Array) {
  const bytesAsArrayBuffer = new ArrayBuffer(bytes.length);
  const bytesUint8 = new Uint8Array(bytesAsArrayBuffer);
  bytesUint8.set(bytes);
  return bytesAsArrayBuffer;
}

type WrappedAndSecretKey = {
  wrappedKey: WrappedKey;
  secretKey: CryptoKey;
  fingerprint: ArrayBuffer;
};

//Generate a secret key, then wrap it with the supplied password
export async function createSecretKey(password: string): Promise<WrappedAndSecretKey> {
  try {
    const secretKey = await crypto.subtle.generateKey(
      {
        name: "AES-GCM",
        length: 256,
      },
      true,
      ["encrypt", "decrypt"],
    );
    const wrappedKey = await wrapCryptoKey(secretKey, password);

    //create a fingerprint for this key. future attempts to change the key must prove that they have access
    //to the key by sending the same fingerprint.
    const fingerprint = await keyFingerprint(secretKey);

    return {
      secretKey: secretKey,
      wrappedKey: wrappedKey,
      fingerprint: fingerprint,
    };
  } catch (error: any) {
    throw new EncryptionError("create key", error);
  }
}

//generate a fingerprint for the provided key
export async function keyFingerprint(key: CryptoKey): Promise<ArrayBuffer> {
  //encrypt zeroes with the secret key, then hash the result
  const zeroIV = new Uint8Array(12);
  const zeroes = new Uint8Array(32);
  const encryptedZeroes = await crypto.subtle.encrypt(gcmAlgorithm(zeroIV), key, zeroes);
  return await crypto.subtle.digest("SHA-256", encryptedZeroes);
}

//Unwrap an AES secret key from an ArrayBuffer containing the raw bytes.
export async function unwrapKey(wrappedKey: WrappedKey, password: string): Promise<CryptoKey> {
  try {
    const unwrappingKey = await getWrappingKey(password, wrappedKey.salt);
    return await crypto.subtle.unwrapKey(
      "raw", // import format
      wrappedKey.key, // ArrayBuffer representing key to unwrap
      unwrappingKey, // CryptoKey representing key encryption key
      "AES-KW", // algorithm identifier for key encryption key
      "AES-GCM", // algorithm identifier for key to unwrap
      true, // extractability of key to unwrap
      ["encrypt", "decrypt"], // key usages for key to unwrap
    );
  } catch (error: any) {
    throw new EncryptionError("unwrap", error);
  }
}

function gcmAlgorithm(iv: Uint8Array): AesGcmParams {
  return {
    name: "AES-GCM",
    iv: iv,
  };
}

//decrypt the base64-encoded ciphertext using the supplied key and IV
export async function decrypt(b64data: string, key: CryptoKey, iv: Uint8Array): Promise<string> {
  const decoder = new TextDecoder();
  try {
    const plainBuffer = await crypto.subtle.decrypt(gcmAlgorithm(iv), key, base64ToArrayBuffer(b64data));
    return decoder.decode(plainBuffer);
  } catch (error: any) {
    throw new EncryptionError("decrypt", error);
  }
}

//encrypt the plaintext with the supplied key and IV, returning a promise of the base64-encoded ciphertext
export async function encrypt(plaintext: string, key: CryptoKey, iv: Uint8Array): Promise<string> {
  const encoder = new TextEncoder();
  try {
    const cipherBuffer = await crypto.subtle.encrypt(gcmAlgorithm(iv), key, encoder.encode(plaintext));
    return arrayBufferToBase64(cipherBuffer);
  } catch (error: any) {
    throw new EncryptionError("encrypt", error);
  }
}

//Derive an AES-KW key using PBKDF2.
async function getWrappingKey(password: string, salt: Uint8Array) {
  const saltBuffer = bytesToArrayBuffer(salt);
  return crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt: saltBuffer,
      iterations: 100000,
      hash: "SHA-256",
    },
    await deriveKey(password),
    { name: "AES-KW", length: 256 },
    true,
    ["wrapKey", "unwrapKey"],
  );
}

type EncryptedObject = {
  [key: string]: any;
  //base64-encoded IV
  e2e_iv: string;
};

type UnknownObj = {
  [key: string]: any;
};

//takes a regular object with all-plaintext properties, encrypts the properties in the propsToEncrypt set with the
//supplied key, and returns an object with the plaintext properties replaced with the encrypted ones, along with the
//iv used.
export async function encryptObject(
  plain: UnknownObj,
  key: CryptoKey,
  propsToEncrypt: Set<string>,
): Promise<EncryptedObject> {
  if (propsToEncrypt.size < 1) {
    throw new EncryptionError("no props to encrypt");
  }
  //96 bits / 8 bits/byte = 12 bytes
  const iv = crypto.getRandomValues(new Uint8Array(12));
  const enc: EncryptedObject = {
    e2e_iv: uint8ArrayToBase64(iv),
  };

  try {
    for (const [k, v] of Object.entries(plain)) {
      if (k.startsWith(E2EPrefix)) continue;
      const enc_key = E2EPrefix + k;
      if (!propsToEncrypt.has(k)) {
        enc[k] = v;
      } else {
        if (v === null || v === "") enc[enc_key] = null;
        else enc[enc_key] = await encrypt(v, key, iv);
      }
    }
    return enc;
  } catch (error: any) {
    throw new EncryptionError("encrypt object", error);
  }
}

//takes an encrypted object and decrypts all the properties which start with e2e_, returning the object with all
//decrypted properties as well as all properties not starting with e2e_ untouched
export async function decryptObject(enc: EncryptedObject, key: CryptoKey): Promise<UnknownObj> {
  if (!enc.e2e_iv) {
    throw new EncryptionError("no IV; cannot decrypt");
  }

  const iv = base64ToUint8Array(enc.e2e_iv);
  const encProps = e2eProps(enc);
  const plain: UnknownObj = {};
  try {
    for (const [k, v] of Object.entries(enc)) {
      if (k === E2E_IV) continue;
      if (encProps.has(k)) {
        const plain_key = k.replace(/^e2e_/, "");
        if (v === null) plain[plain_key] = null;
        else plain[plain_key] = await decrypt(v, key, iv);
      } else if (!encProps.has(E2EPrefix + k)) {
        //if there's no e2e version of this prop, just copy it over
        plain[k] = v;
      }
    }
    return plain;
  } catch (error: any) {
    throw new EncryptionError("decrypt object", error);
  }
}

export function e2eProps(obj: UnknownObj): Set<string> {
  return new Set(Object.keys(obj).filter((key) => key.startsWith(E2EPrefix) && key !== E2E_IV));
}

//see if the supplied password meets our minimum standards
export function e2ePasswordStrong(password: string): boolean {
  const schema = new PasswordValidator();
  schema.is().min(8).is().max(100).has().uppercase().has().lowercase().has().digits(1);

  return !!schema.validate(password);
}

export function encodeUnicode(str: string) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(_match, p1) {
      return String.fromCharCode(Number(`0x${p1}`));
    }),
  );
}

export function decodeUnicode(str: string) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );
}
