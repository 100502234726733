import { useQuery } from "@tanstack/react-query";
import { E2E_IV, FORCE_DECRYPT_HEADER, decryptObject, encryptObject } from "../helpers/e2e";
import HourglassGlobals from "../helpers/globals";
import Address from "../types/address";
import User from "../types/user";
import { api, fsReportPath } from "./api";
import QueryKeys from "./queryKeys";

import { UnknownCharacter } from "./const";

export type AddrMutate = {
  address: Address;
  user?: User;
};

export class AddressAPI {
  static base = fsReportPath("/addresses");
  static E2EProps = new Set<keyof Address>(["line1", "line2", "city", "state", "postalcode", "country"]);

  static urlFromId(id: number): string {
    return `${AddressAPI.base}/${id}`;
  }

  static async addressFromResponse(addr: any): Promise<Address> {
    if (HourglassGlobals.e2eKey && addr[E2E_IV]) {
      try {
        addr = await decryptObject(addr, HourglassGlobals.e2eKey);
      } catch (err) {
        console.error("failure to decrypt address", addr.id, err);
      }
    }
    if (!addr.line1) addr.line1 = UnknownCharacter;
    return addr;
  }

  async getAll(): Promise<Address[]> {
    const addresses = await api.get(AddressAPI.base).json<Address[]>();
    return Promise.all(addresses.map((a: Address) => AddressAPI.addressFromResponse(a)));
  }

  //we use update when we know we're doing a PUT
  async update(address: Address, forceDecrypted = false): Promise<Address> {
    if (!address.id) throw new Error("Address must have non-zero ID");

    let savedAddr: any = address;
    if (HourglassGlobals.e2eKey && !forceDecrypted) {
      savedAddr = await encryptObject(address, HourglassGlobals.e2eKey, AddressAPI.E2EProps);
    }

    const headers = forceDecrypted ? FORCE_DECRYPT_HEADER.headers : {};
    const data = await api
      .put(AddressAPI.urlFromId(address.id), {
        json: savedAddr,
        headers,
      })
      .json<Address>();
    return await AddressAPI.addressFromResponse(data);
  }

  //use save when you might be updating or creating new
  async save({ address, user }: AddrMutate): Promise<Address> {
    if (!address.id && !user) throw new Error("new addresses require a user");
    //when posting a new address, it goes to /addresses/{userId}
    const url = address.id ? AddressAPI.urlFromId(address.id) : AddressAPI.urlFromId(user?.id || 0);

    let savedAddr: any = address;
    if (HourglassGlobals.e2eKey) {
      savedAddr = await encryptObject(address, HourglassGlobals.e2eKey, AddressAPI.E2EProps);
    }

    const method = address.id ? "put" : "post";
    const data = await api(url, { method, json: savedAddr }).json();
    return AddressAPI.addressFromResponse(data);
  }
}

export default function useAddresses() {
  return useQuery({
    queryKey: [QueryKeys.Addresses],
    queryFn: () => addressApi.getAll(),
  });
}

export const addressApi = new AddressAPI();
