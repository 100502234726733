import { t } from "i18next";
import { InputGroup, Form } from "react-bootstrap";
import { XLg } from "react-bootstrap-icons";

export const SearchFilter = (props: { filter: string; setFilter: (set: string) => void; className?: string }) => {
  return (
    <InputGroup className={props.className}>
      <Form.Control
        size="sm"
        placeholder={t("search.hint")}
        onChange={(e) => {
          props.setFilter(e.target.value);
        }}
        value={props.filter}
      />
      <InputGroup.Text onClick={() => props.setFilter("")} title={t("schedules.fill.clear")}>
        <XLg />
      </InputGroup.Text>
    </InputGroup>
  );
};
