const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID || "571833021877.apps.googleusercontent.com";

export function getNonce() {
  const charset = "0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._";
  const result: Array<string> = [];
  const random = window.crypto.getRandomValues(new Uint8Array(32));

  random.forEach(function (c) {
    // @ts-ignore noUncheckedIndexedAccess
    result.push(charset[c % charset.length]);
  });

  return result.join("");
}

export function getGoogleAuthUrl(
  state: string,
  redirectUri: string,
  nonce: string,
  prompt: string | null = "select_account",
  login_hint: string | null = null,
  profile = false
): string {
  const base = new URL("https://accounts.google.com/o/oauth2/v2/auth");

  const scopes = ["email", "openid", "https://www.googleapis.com/auth/userinfo.email"];
  const params: any = {
    client_id: clientId,
    response_type: "code",
    redirect_uri: redirectUri,
    state: state,
    nonce: nonce,
  };
  if (prompt !== null) {
    params.prompt = prompt;
  }
  if (login_hint !== null) {
    params.login_hint = login_hint;
  }
  if (profile) {
    scopes.push("profile");
  }
  params.scope = scopes.join(" ");
  base.search = new URLSearchParams(params).toString();
  return base.toString();
}
