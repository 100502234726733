import Mustache from "mustache";
import User from "../types/user";
import { InviteResponse } from "../types/invite";
import { ISODateString } from "../types/date";
import { MidweekMeetingPart, MidweekMeetingPartAsset, MidweekMeetingPartType } from "../types/scheduling/midweek";
import i18n from "i18next";
import { stringToLongLocaleDate } from "./dateHelpers";

export function mailtoFromInviteResponse(inviteResponse: InviteResponse, user: User): string {
  const body = Mustache.render(inviteResponse.emailPlainTemplate, {
    firstname: user.firstname,
  });
  return `mailto:${user.email}?subject=${encodeURIComponent(inviteResponse.emailSubject)}&body=${encodeURIComponent(
    body,
  )}`;
}

export function mailtoMMCoreAssignment(
  user: User,
  meetingDate: ISODateString,
  partType: MidweekMeetingPartType,
): string {
  const subject = encodeURIComponent(i18n.t("schedules.assignment.title"));

  const bodyLine0 = `${user.firstname},\n`;
  const bodyLine1 = i18n.t("schedules.assignment.reminder").replace("%@", i18n.t("conganalysis.attendance.midweek"));
  const bodyLine2 = i18n.t("general.date") + `: ${stringToLongLocaleDate(meetingDate)}`;
  const bodyLine3 = i18n.t("schedules.assignment.title") + ": " + i18n.t(MidweekMeetingPartAsset[partType]);

  const body = encodeURIComponent([bodyLine0, bodyLine1, bodyLine2, bodyLine3].join("\n"));
  return `mailto:${user.email}?subject=${subject}&body=${body}`;
}

export function mailtoMMAssignment(
  user: User,
  meetingDate: ISODateString,
  partData: MidweekMeetingPart,
  assistant?: User,
  student?: User,
): string {
  const subject = encodeURIComponent(i18n.t("schedules.assignment.title"));

  const bodyLine0 = `${user.firstname},\n`;
  const bodyLine1 =
    i18n.t("schedules.assignment.reminder").replace("%@", i18n.t("conganalysis.attendance.midweek")) + "\n";
  const bodyLine2 = i18n.t("general.date") + `: ${stringToLongLocaleDate(meetingDate)}`;
  const assignmentTitle = (): string => {
    if (user.id === assistant?.id) return i18n.t("schedules.midweek.assistant");
    return i18n.t(MidweekMeetingPartAsset[partData.type]);
  };
  const bodyLine3 = i18n.t("schedules.assignment.title") + ": " + assignmentTitle();
  let bodyLine4 = `${partData.title} (${partData.time})`;
  if (partData.info) bodyLine4 += `: ${partData.info}`;
  const bodyLine5 = !!partData.counsel_point
    ? i18n.t("schedules.midweek.counsel-point") + ` #${partData.counsel_point.number} – ${partData.counsel_point.title}`
    : "";
  const assistantLine = (): string => {
    if (user.id === assistant?.id && !!student?.displayName)
      return i18n.t("schedules.midweek.student") + `: ${student.displayName}`;
    return !!assistant ? i18n.t("schedules.midweek.assistant") + `: ${assistant.displayName}` : "";
  };
  const bodyLine6 = assistantLine();

  const body = encodeURIComponent(
    [bodyLine0, bodyLine1, bodyLine2, bodyLine3, bodyLine4, bodyLine5, bodyLine6].join("\n"),
  );
  return `mailto:${user.email}?subject=${subject}&body=${body}`;
}

export function isAppleICloudDomain(email: string): boolean {
  const trimmed = email.trim().toLowerCase();
  return trimmed.endsWith("@icloud.com") || trimmed.endsWith("@mac.com") || trimmed.endsWith("@me.com");
}
