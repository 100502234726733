import { MonthDetail } from "../types/summary";
import QueryKeys from "../api/queryKeys";
import { QueryClient } from '@tanstack/react-query';
import Report from "../types/report";

// when we use the month detail view to alter a report, we need to update it in the month detail cache
export function updateMonthDetailReports(
  queryClient: QueryClient,
  month: string,
  oldReport: Report,
  newReport: Report
) {
  queryClient.setQueryData<MonthDetail>([QueryKeys.SummaryMonthDetail, month], (old) => {
    if (!old) return { month: month, otherMonths: [month], reports: [newReport] };
    return {
      month: month,
      otherMonths: old.otherMonths,
      reports: old.reports.map((rpt) =>
        rpt.user.id === oldReport.user.id && rpt.month === oldReport.month && rpt.year === oldReport.year
          ? newReport
          : rpt
      ),
    };
  });
}
