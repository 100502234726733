export type AVAttendantAssignment = {
  id: number;
  type: AVAttendantAssignmentType;
  assignee?: number;
  date: string;
  slot: number;
};

export enum AVAttendantAssignmentType {
  Console = "console",
  Video = "video",
  Mics = "mics",
  Stage = "stage",
  Attendant = "attendant",
  ZoomAttendant = "zoom_attendant",
  SecurityAttendant = "security_attendant",
}

//AVAttendantAsset maps an AVA assignment to the translation asset describing it
export const AVAttendantAsset: Record<AVAttendantAssignmentType, string> = {
  console: "schedules.console.audio",
  video: "schedules.console.video",
  mics: "schedules.privileges.microphones",
  stage: "schedules.privileges.stage",
  attendant: "schedules.privileges.attendant",
  zoom_attendant: "schedules.zoom_attendant.default_label",
  security_attendant: "schedules.entrance-attendant.title",
};
