import React from "react";
import retryChunkLoader from "../../helpers/chunkLoadRetry";

export const MissingReportLazy = React.lazy(() =>
  retryChunkLoader(() =>
    import(/* webpackPrefetch: true */ "./index" /* webpackChunkName: "reports" */).then((m) => ({
      default: m.MissingReportsSummary,
    }))
  )
);
export const MonthSummaryLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.MonthSummaryReport })))
);

export const MonthDetailLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.MonthDetailReport })))
);

export const CongregationAveragesLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.CongregationAveragesReport })))
);

export const GroupAveragesLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.GroupAveragesReport })))
);

export const PublisherAveragesLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.PublisherAveragesReport })))
);

export const MonthStatisticsLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.MonthStatisticsReport })))
);

export const CongregationAnalysisLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.CongregationAnalysis })))
);

export const RegPioReportLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.RegPioReport })))
);

export const AuxPioReportLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.AuxPioReport })))
);

export const ServiceYearTotalsLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.ServiceYearTotals })))
);

export const COReportsLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.COReports })))
);
