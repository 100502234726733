import User, { UserGender, UserStatus } from "../types/user";
import { NameFormat } from "../types/cong";
import { CollatorSingleton } from "./locale";
import { RedactedTransferUser } from "../types/transfer";
import { DelegateUser } from "../types/whoami";
import NPUser from "../types/npuser";
import { Speaker } from "../types/scheduling/weekend";
import HourglassGlobals from "./globals";
import { Permission } from "../types/permission";
import { TFunction } from "i18next";
import { UnknownCharacter } from "../api/const";

export function nameOfUser(
  user: User | RedactedTransferUser | DelegateUser | NPUser | Speaker,
  nameFmt?: NameFormat,
  full = false,
): string {
  const descriptor = !!user && "descriptor" in user && user.descriptor?.trim() ? user.descriptor.trim() : "";
  if (!user || !(user.firstname || user.lastname || user.middlename)) {
    return descriptor;
  }
  if (typeof nameFmt !== "number" || nameFmt < 0) nameFmt = HourglassGlobals.nameFmt;
  let name;
  if (!user.lastname) user.lastname = "";
  const fullMiddleName = !!user.middlename ? user.middlename.trim() : "";
  const shortMiddleName = abbreviatedName(user.middlename || "");
  const middleName = full ? fullMiddleName : shortMiddleName;
  if (!user.suffix) user.suffix = "";

  const canUseDescriptor = !HourglassGlobals.isE2E() || HourglassGlobals.labelAsPreferredName;
  if (descriptor && !full && canUseDescriptor) return descriptor;

  if (!user.firstname && !user.lastname && !descriptor) {
    if ("descriptor" in user) name = UnknownCharacter;
    else return "";
  } else {
    if (nameFmt === NameFormat.First) {
      name = `${user.firstname} ${middleName} ${user.lastname} ${user.suffix}`;
    } else {
      if (HourglassGlobals.cong?.locale.code === "hu" || HourglassGlobals.cong?.locale.code === "ko") {
        // hungarian/korean names, no comma
        name = `${user.lastname} ${user.firstname} ${middleName} ${user.suffix}`;
      } else name = `${!!user.lastname ? `${user.lastname}, ` : ""}${user.firstname} ${middleName} ${user.suffix}`;
    }
  }

  //remove multiple spaces and any leading/trailing whitespace
  return name.replace(/ {2,}/g, " ").trim();
}

// abbreviatedName takes a string like "John Doe" and returns "J. D.", or "Michael" and returns "M.".
// As a special case, if it starts with "(" an empty string is returned.
export function abbreviatedName(name: string): string {
  // trim any whitespace and remove anything contained in ()
  name = name.replace(/\(.*?\)/, "").trim();

  // split on whitespace
  const parts = name.split(/\s+/);
  if (parts.length === 0) return "";

  let abbrName = "";
  parts.forEach((p) => {
    if (!p) return;
    if (p.toLowerCase() === "de") abbrName += p;
    else abbrName += p.charAt(0) + ".";
    abbrName += " ";
  });

  return abbrName.trim();
}

//sort users based on display name
export function userCompare(a?: Pick<User, "displayName">, b?: Pick<User, "displayName">): number {
  return CollatorSingleton.getInstance().compare(a?.displayName || "", b?.displayName || "");
}

//convenience method to take an existing array of users & sort it
export function sortUsers(users: User[]): User[] {
  return [...users].sort(userCompare);
}

//sort users based on group
//fsGroupOverseers maps a group ID to the displayName of the overseer
export function sortUsersByGroup(users: User[], fsGroupOverseers: Map<number, string>): User[] {
  return [...users].sort((a, b) => {
    if (!a.group_id && b.group_id) return -1;
    if (a.group_id && !b.group_id) return 1;

    const nameCompare = CollatorSingleton.getInstance().compare(a.displayName, b.displayName);
    if (!a.group_id && !b.group_id) return nameCompare;

    if (a.group_id && b.group_id) {
      //both are in a group
      if (a.group_id === b.group_id) return nameCompare;
      const sgoA = fsGroupOverseers.get(a.group_id) || "?";
      const sgoB = fsGroupOverseers.get(b.group_id) || "?";
      return CollatorSingleton.getInstance().compare(sgoA, sgoB);
    }

    //we should never get here
    return 0;
  });
}

export function userIdMap(users: User[]): Map<number, User> {
  const userMap = new Map<number, User>();
  users.forEach((user) => userMap.set(user.id, user));
  return userMap;
}

// given the api key of a field, return the localized name. e.g. for "sex" we would return "gender"
export function localizedUserField(field: string, t: TFunction): string {
  switch (field) {
    case "sex":
      return t("userinfo.gender.0");
    default:
      return field;
  }
}

export function canViewReports(user: User): boolean {
  if (user.status === UserStatus.StudentOnly) return false;
  if (HourglassGlobals.delegateFor?.some((du) => du.id === user.id)) return true;

  return (
    HourglassGlobals.permissions.has(Permission.ViewReportsAll) ||
    (HourglassGlobals.permissions.has(Permission.ViewReportsGroup) &&
      !!HourglassGlobals.authUser.group_id &&
      HourglassGlobals.authUser.group_id === user.group_id)
  );
}

// Given a string containing the first and last name of the user, see if we can find a match.
// If more than one user matches, undefined is returned.
export function findUserByName(users: User[], name: string): User | undefined {
  const parts = name.trim().split(/\s+/);
  if (parts.length < 2 || !parts[0]) return;
  const first = parts[0].toLocaleLowerCase();
  const last = parts[parts.length - 1]?.toLocaleLowerCase();
  if (!last) return;
  const matched = users.filter(
    (u) => u.firstname.toLocaleLowerCase() === first && u.lastname.toLocaleLowerCase() === last,
  );
  if (matched.length === 1) return matched[0];
}

export function userFromDelegate(du: DelegateUser): User {
  return {
    id: du.id,
    firstname: du.firstname ?? "",
    middlename: du.middlename,
    lastname: du.lastname ?? "",
    suffix: du.suffix,
    descriptor: du.descriptor,
    displayName: nameOfUser(du),
    // dummy values:
    sex: UserGender.Male,
    language_group_id: null,
    familycontact: false,
    reportstobranch: false,
    sgo: false,
    revoked: false,
    mobilepush: false,
    googleauth: false,
    appleauth: false,
    tags: [],
  };
}
