import { UserPrivileges } from "./privileges";

export type UserIDsList = number[];

export type MMSection = "schedule" | "stats";

export type UserPrivilegeMap = {
  [key in keyof UserPrivileges]: UserIDsList;
};

export type UpdateStatus = {
  Updated: boolean;
};

export type DeleteStatus = {
  Deleted: boolean;
};

export enum NotificationType {
  Midweek = "mm",
  WeekendCRH = "wm",
  PT = "pt",
  PTOut = "pt_out",
  AVAttendant = "ava",
  Cleaning = "clean",
  FieldMinistry = "fm",
  PublicWitnessing = "pubwit",
  Memorial = "memorial",
  Maintenance = "maint",
}

export enum NotificationStatus {
  Pending = "pending",
  Declined = "declined",
  Complete = "complete",
}

export type AssignmentNotification = {
  id: number;
  congregationId: number;
  date: string;
  classroom: number;
  part: number;
  type: NotificationType;
  flag: number;
  status?: NotificationStatus;
  assignee: number;
};

export enum NotificationState {
  Mismatch, // notification exists, but for a different assignee
  Created, // notification created, but not sent
  Pending, // waiting for assignee to accept/decline
  Declined,
  Accepted,
  Conflicting,
}
