import ky, { AfterResponseHook, BeforeRequestHook, Options } from "ky";
import HourglassGlobals from "../helpers/globals";
import { getCookie } from "../helpers/util";
import { ISODateString } from "../types/date";
import { XSRFHeader, baseUrl, fsReportUrl, schedulingUrl } from "./const";

export type QueryDateRange = {
  fromDate?: ISODateString;
  toDate?: ISODateString;
};

export function fsReportPath(path: string): string {
  return fsReportUrl + path;
}

export function schedulingPath(path: string): string {
  return schedulingUrl + path;
}

const afterResponse: AfterResponseHook[] = [
  (_, __, response) => {
    if (response.status === 401) {
      window.location.href = HourglassGlobals.AppBase;
    }
    return response;
  },
];

const beforeRequest: BeforeRequestHook[] = [
  (request) => {
    if (import.meta.env.VITE_DEV_USER && import.meta.env.VITE_DEV_PASSWORD) {
      request.headers.set(
        "Authorization",
        `Basic ${btoa(import.meta.env.VITE_DEV_USER + ":" + import.meta.env.VITE_DEV_PASSWORD)}`,
      );
    }
    request.headers.set(XSRFHeader, getCookie(XSRFHeader));
    return request;
  },
];

const apiOptions: Options = {
  prefixUrl: baseUrl,
  timeout: 20 * 1000,
};

export const apiNoRedirect = ky.create({ ...apiOptions, hooks: { beforeRequest } });
export const api = ky.create({ ...apiOptions, hooks: { beforeRequest, afterResponse } });
