import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { assignmentNotificationQueryKey } from "../query/notification";
import { ActivityEvent } from "../types/activity";
import { Job } from "../types/job";
import { ScheduledEvent, ScheduledEventInput } from "../types/scheduling/events";
import { AssignmentNotification, DeleteStatus, NotificationType, UserPrivilegeMap } from "../types/scheduling/meetings";
import { UserPrivileges } from "../types/scheduling/privileges";
import { api, schedulingPath } from "./api";
import QueryKeys from "./queryKeys";

class MeetingsApi {
  static userBase = schedulingPath("/users");
  static eventBase = schedulingPath("/events");
  static activityBase = schedulingPath("/activity");
  static notificationsBase = schedulingPath("/notifications");

  async getActivityLogs(from: string, to: string): Promise<ActivityEvent[]> {
    const url = `${MeetingsApi.activityBase}/${from}_${to}`;
    return api.get(url).json();
  }

  async getUserPrivilegeMap(lgroup: number): Promise<UserPrivilegeMap> {
    const url = `${MeetingsApi.userBase}/privileges`;
    const searchParams = lgroup > 0 ? { lgroup: lgroup } : undefined;
    return api.get(url, { searchParams }).json();
  }

  async getEvents(from: string, to: string): Promise<ScheduledEvent[]> {
    const url = `${MeetingsApi.eventBase}/${from}_${to}`;
    return api.get(url).json();
  }

  async setEvent(input: ScheduledEventInput): Promise<ScheduledEvent> {
    return api.put(MeetingsApi.eventBase, { json: input }).json();
  }

  async deleteEvent(date: string): Promise<DeleteStatus> {
    const url = `${MeetingsApi.eventBase}/${date}`;
    return api.delete(url).json();
  }

  async getUserPrivileges(id: number, lgroup: number): Promise<UserPrivileges> {
    const url = `${MeetingsApi.userBase}/${id}`;
    const searchParams = lgroup > 0 ? { lgroup: lgroup } : undefined;
    return api.get(url, { searchParams }).json();
  }

  async setUserPrivileges(input: UserPrivileges, lgroup: number): Promise<UserPrivileges> {
    const config = lgroup > 0 ? { searchParams: { lgroup: lgroup }, json: input } : { json: input };
    return api.put(MeetingsApi.userBase, config).json();
  }

  async setUserPrivilegesBulk(privileges: UserPrivileges[], lgroup: number): Promise<UserPrivileges[]> {
    const config = lgroup > 0 ? { searchParams: { lgroup: lgroup }, json: privileges } : { json: privileges };
    return api.put(`${MeetingsApi.userBase}/bulk`, config).json();
  }

  async getNotifications(from: string, to: string, type: NotificationType): Promise<AssignmentNotification[]> {
    return api.get(`${MeetingsApi.notificationsBase}/${from}_${to}/${type}`).json();
  }

  async sendAllNotifications(from: string, to: string, type: NotificationType, lgroup: number = 0): Promise<Job> {
    const searchParams = lgroup > 0 ? { lgroup: lgroup } : undefined;
    return api.put(`${MeetingsApi.notificationsBase}/${from}_${to}/${type}`, { searchParams }).json();
  }

  async updateNotificationStatus(notifications: AssignmentNotification[]): Promise<AssignmentNotification[]> {
    return api.put(`${MeetingsApi.notificationsBase}/status`, { json: notifications }).json();
  }
}

export function useEvents(year: number, options?: Partial<UseQueryOptions<ScheduledEvent[], Error>>) {
  return useQuery({
    queryKey: [QueryKeys.SchedulingEvents, year],
    queryFn: () => meetingsApi.getEvents(`${year}-01-01`, `${year}-12-31`),
    ...options,
  });
}

export function useNotifications(
  from: string,
  to: string,
  type: NotificationType,
  options?: UseQueryOptions<AssignmentNotification[], Error>,
) {
  return useQuery({
    queryKey: assignmentNotificationQueryKey(type, from, to),
    queryFn: () => meetingsApi.getNotifications(from, to, type),
    ...options,
  });
}

export function usePrivilegeMap(lgroup: number, options?: UseQueryOptions<UserPrivilegeMap, Error>) {
  return useQuery({
    queryKey: [QueryKeys.UserPrivilegesAll, lgroup],
    queryFn: () => meetingsApi.getUserPrivilegeMap(lgroup),
    ...options,
  });
}

export const meetingsApi = new MeetingsApi();
