import { ISODateString } from "../date";

export type ScheduledEvent = {
  id: number;
  date: ISODateString;
  week: ISODateString;
  event: Events;
  global: boolean;
  custom_title: string;
  custom_body: string;
  customShowOnMeetingSchedule: boolean;
  customNewWeekday: number;
};

export type ScheduledEventInput = {
  id: number;
  date: string;
  event: string;
  custom_title: string;
  custom_body: string;
  customShowOnMeetingSchedule: boolean;
  customNewWeekday: number;
};

export enum Events {
  ca = "ca",
  rc = "rc",
  am = "am",
  mem = "mem",
  mmem = "mmem",
  wmem = "wmem",
  co = "co",
  custom = "custom",
}

//maps an event type to the localized asset. call t() on the asset to get the actual text.
export const EventTypes: Record<Events, string> = {
  ca: "schedules.events.circuit-assembly",
  rc: "schedules.events.regional-convention",
  am: "schedules.events.annual-meeting",
  mem: "schedules.events.memorial", // Merlot
  mmem: "schedules.events.memorial", // Cabernet Sauvignon
  wmem: "schedules.events.memorial", // Pinot Noir
  co: "schedules.events.co-visit",
  custom: "schedules.events.custom",
};
