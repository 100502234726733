import { useContext, useEffect, useRef } from "react";
import { InputGroup } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { Option } from "react-bootstrap-typeahead/types/types";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useUsers } from "../api/user";
import HourglassGlobals, { HGContext } from "../helpers/globals";
import { userCompare } from "../helpers/user";
import { Permission } from "../types/permission";
import User from "../types/user";
import { QueryStatus } from "./queryStatus";

export function UserSearch(props: { className?: string }) {
  const { t } = useTranslation();
  const usersQuery = useUsers();
  const navigate = useNavigate();
  const location = useLocation();
  // @ts-ignore Typeahead is valid here
  const typeaheadRef = useRef<Typeahead>(null);
  const ctx = useContext(HGContext).globals;

  const downHandler = (e: KeyboardEvent) => {
    if (e.ctrlKey && e.key === "/") {
      e.preventDefault();
      e.stopPropagation();
      typeaheadRef.current?.focus();
      typeaheadRef.current?.clear();
    }
  };
  useEffect(() => {
    document.body.addEventListener("keydown", downHandler);
    return () => {
      document.body.removeEventListener("keydown", downHandler);
    };
  }, []);

  const queryStatus = QueryStatus(usersQuery);
  if (queryStatus !== null) return queryStatus;
  if (!usersQuery.data) return null;

  const selectUser = (selected: Option[]) => {
    const selectedUser = selected[0] as User;
    if (!selectedUser) return;
    typeaheadRef.current?.clear();
    if (location.pathname.startsWith("/user/privileges")) {
      // if we're in the privileges view, go to the selected user's privileges to reduce clicks
      navigate(`/user/privileges/${selectedUser.id}`);
    } else {
      navigate(`/user/${selectedUser.id}`);
    }
  };

  //if the user can't see everyone's reports, but can see their group's, only show the group members in the list
  const groupOnly =
    HourglassGlobals.authUser.group_id &&
    !HourglassGlobals.permissions.has(Permission.ViewReportsAll) &&
    HourglassGlobals.permissions.has(Permission.ViewReportsGroup);

  //include any delegates in the list
  const delegateUserIds = new Set<number>(ctx.delegateFor?.map((du) => du.id));
  const users = usersQuery.data.filter((u) =>
    groupOnly ? u.group_id === HourglassGlobals.authUser.group_id || delegateUserIds.has(u.id) : true,
  );

  return (
    <div className="col col-lg-5 user-search me-auto">
      <InputGroup>
        <Typeahead
          id="user_select"
          className={props.className + " border-0"}
          ref={typeaheadRef}
          placeholder={t("list.publishers.title")}
          options={users.sort(userCompare)}
          labelKey="displayName"
          onChange={selectUser}
          maxResults={500}
          highlightOnlyResult
        />
        <InputGroup.Text className="d-none d-md-flex px-1">
          <kbd className="keyboard-button">Ctrl</kbd>+<kbd className="keyboard-button">/</kbd>
        </InputGroup.Text>
      </InputGroup>
    </div>
  );
}
