import React from "react";
import retryChunkLoader from "../../helpers/chunkLoadRetry";

export const RecordCardsModalLazy = React.lazy(() =>
  retryChunkLoader(() =>
    import(/* webpackPrefetch: true */ "./index" /* webpackChunkName: "publishers" */).then((m) => ({
      default: m.RecordCardsModal,
    })),
  ),
);

export const ContactListLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.HourglassContactList }))),
);

export const ManageDelegatesLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.ManageDelegates }))),
);

export const EmergencyContactsListLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.EmergencyContactsList }))),
);

export const ManageEmergencyContactsLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.ManageEmergencyContacts }))),
);

export const NotPublishingLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.HourglassNotPublishing }))),
);

export const PublisherMainLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.PublisherMain }))),
);

export const ManagePublisherLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.ManagePublisher }))),
);

export const ManageUserAuthLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.ManageUserAuth }))),
);

export const PubTransferLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.PubTransfer }))),
);

export const PublisherActionsButtonLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.PublisherActionsButton }))),
);

export const PublisherImportLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.PublisherImport }))),
);

export const UserTagsLazy = React.lazy(() =>
  retryChunkLoader(() => import("./index").then((m) => ({ default: m.UserTags }))),
);
