import { useContext, useState } from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import HourglassGlobals, { HGContext } from "../../helpers/globals";
import { Permission } from "../../types/permission";
import { ExportModalLazy } from "../congregation/async";

export function NavCongregation() {
  const { t } = useTranslation();
  const ctx = useContext(HGContext);
  const [showExportModal, setShowExportModal] = useState(false);

  const showActivity = HourglassGlobals.isAdmin();

  const showImport =
    HourglassGlobals.permissions.has(Permission.UpdateUsers) &&
    HourglassGlobals.permissions.has(Permission.UpdateReports) &&
    HourglassGlobals.permissions.has(Permission.UpdateAttendance);

  const showProfile =
    HourglassGlobals.permissions.has(Permission.UpdateCongregation) ||
    HourglassGlobals.permissions.has(Permission.ManagePermissions);

  const showExportMenu =
    HourglassGlobals.permissions.has(Permission.ViewUsers) &&
    HourglassGlobals.permissions.has(Permission.ViewReportsAll) &&
    HourglassGlobals.permissions.has(Permission.ViewAddresses) &&
    HourglassGlobals.permissions.has(Permission.ViewEmergencycontacts);

  const showAttendance =
    HourglassGlobals.permissions.has(Permission.ViewSummary) ||
    HourglassGlobals.permissions.has(Permission.UpdateAttendance);

  const showTerritory =
    HourglassGlobals.permissions.has(Permission.ViewSchedules) ||
    HourglassGlobals.permissions.has(Permission.UpdateTerritory);

  return (
    <Nav className="flex-column hg-nav">
      {showExportModal && <ExportModalLazy show={showExportModal} setShow={setShowExportModal} />}
      <NavDropdown.Item as={Link} to="/manageGroups">
        {t("nav.congregation.fsgroups")}
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to="/manageLangGroups">
        {t("schedules.language-groups.title")}
      </NavDropdown.Item>
      {/*view summary is required to get the attendance list*/}
      {showAttendance && (
        <NavDropdown.Item as={Link} to="/attendance">
          {t("nav.congregation.attendance")}
        </NavDropdown.Item>
      )}
      {showTerritory && (
        <NavDropdown.Item as={Link} to="/congregation/territory">
          {t("schedules.territory.territory")}
        </NavDropdown.Item>
      )}
      {(showProfile || showImport || showExportModal) && <NavDropdown.Divider />}
      {showProfile && (
        <NavDropdown.Item as={Link} to="/congProfile">
          {t("nav.congregation.profile-permissions")}
        </NavDropdown.Item>
      )}
      {showActivity && (
        <NavDropdown.Item as={Link} to="/activity">
          {t("nav.activity")}
        </NavDropdown.Item>
      )}
      {showImport && (
        <NavDropdown.Item as={Link} to="/import">
          {t("nav.congregation.import")}
        </NavDropdown.Item>
      )}
      {showExportMenu && (
        <NavDropdown.Item onClick={() => setShowExportModal(true)}>{t("nav.congregation.export")}</NavDropdown.Item>
      )}
      {HourglassGlobals.isAdmin() && !!ctx.globals.e2eKey && (
        <>
          <NavDropdown.Divider />
          <NavDropdown.Item as={Link} to="/e2eMigration">
            {t("nav.e2e")}
          </NavDropdown.Item>
        </>
      )}
      {HourglassGlobals.isAdmin() && (
        <>
          <NavDropdown.Item as={Link} to="/settings">
            {t("Jcy-FH-fbr.title")}
          </NavDropdown.Item>
        </>
      )}
    </Nav>
  );
}
