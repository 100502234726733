import User from "../../types/user";
import { hasAbsence } from "../../helpers/absence";
import { stringToDate } from "../../helpers/dateHelpers";
import { languageGroupName, myCongLanguageGroupName } from "../../helpers/langGroups";
import React, { useContext } from "react";
import { Dropdown } from "react-bootstrap";
import { useAbsence } from "../../api/absence";
import { ISODateString } from "../../types/date";
import { HGContext } from "../../helpers/globals";
import { useTranslation } from "react-i18next";
import { NoOneWithPrivileges } from "./common";
import { Airplane } from "react-bootstrap-icons";
import { userCompare } from "../../helpers/user";

export function UserAssignmentDropdowns(props: {
  users: User[];
  assignDate: ISODateString;
  langGroupId: number;
  assign: (userId: number) => void;
  currentAssignee?: number;
  assigneeFilter: string;
  privilegeAsset: string;
  hasAbsence?: (userId: number) => boolean;
  disableLangGroup?: boolean;
  disableAbsence?: boolean;
}) {
  const { t } = useTranslation();
  const absences = useAbsence();
  const myCong = useContext(HGContext).globals.cong;

  const langGroupIsCong = !props.langGroupId || props.langGroupId === myCong.id;
  const lgPossibleUsers = props.users.filter((u) =>
    langGroupIsCong ? !u.language_group_id : u.language_group_id === props.langGroupId,
  );
  const lgIds = new Set<number>(lgPossibleUsers.map((u) => u.id));
  const nonLgPossibleUsers = props.users.filter((u) => !lgIds.has(u.id));

  const showTwoUserLists = lgPossibleUsers.length > 0 && nonLgPossibleUsers.length > 0 && !props.disableLangGroup;

  const checkAbsence = (userId: number): boolean => {
    if (props.disableAbsence) return false;
    if (!!props.hasAbsence) return props.hasAbsence(userId);
    return !!absences.data && hasAbsence(userId, stringToDate(props.assignDate), absences.data);
  };

  const userDropdowns = (users: User[]) => {
    return users.sort(userCompare).map((u) => {
      const isAbsent = checkAbsence(u.id);
      return (
        <Dropdown.Item
          key={u.id}
          disabled={isAbsent}
          onClick={() => props.assign(u.id)}
          className="d-flex justify-content-between"
        >
          {u.displayName}
          {isAbsent && <Airplane className="ms-1" />}
        </Dropdown.Item>
      );
    });
  };

  if (showTwoUserLists) {
    const secondHeader = props.langGroupId === myCong.id ? myCongLanguageGroupName() : myCong.name;
    return (
      <>
        <Dropdown.Header>{languageGroupName(props.langGroupId)}</Dropdown.Header>
        {userDropdowns(lgPossibleUsers)}
        <Dropdown.Divider />
        <Dropdown.Header>{secondHeader}</Dropdown.Header>
        {userDropdowns(nonLgPossibleUsers)}
      </>
    );
  }

  if (!props.users.length) {
    if (!props.currentAssignee && props.assigneeFilter === "" && props.privilegeAsset) {
      return <NoOneWithPrivileges key={0} priv={t(props.privilegeAsset)} />;
    }
    return null;
  }

  // if all the possible users are from the non-selected language group, show that with a header
  return (
    <>
      {lgPossibleUsers.length === 0 && (
        <Dropdown.Header>{langGroupIsCong ? myCongLanguageGroupName() : myCong.name}</Dropdown.Header>
      )}
      {userDropdowns(props.users)}
    </>
  );
}
