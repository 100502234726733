import { Absence } from "../types/scheduling/absence";
import { dateIsBetween, getDayjs } from "./dateHelpers";

// see if any of the absences is for the supplied userId and date
export function hasAbsence(userId: number, date: Date, absences?: Absence[], includePWOnly?: boolean): boolean {
  if (!absences) return false;

  return absences
    .filter((a) => a.userId === userId)
    .filter((a) => (includePWOnly === true ? true : !a.pw_only))
    .some((a) => {
      // use UTC if we are dealing with public witnessing, since it's in UTC
      return dateIsBetween(
        date,
        getDayjs(a.start, includePWOnly).toDate(),
        getDayjs(a.end, includePWOnly).endOf("day").toDate(),
      );
    });
}
