import { Node, mergeAttributes } from "@tiptap/core";

export interface TemplateVariable {
  name: string;
  label: string;
}

export const TemplateVariableNode = Node.create({
  name: "templateVariable",

  inline: true,
  group: "inline",
  atom: true,

  addAttributes() {
    return {
      name: {
        default: null,
      },
      label: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "span[data-template-variable]",
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(HTMLAttributes, {
        "data-template-variable": node.attrs.name,
        class: "template-variable-chip",
      }),
      `${node.attrs.label}`,
    ];
  },

  renderText(props) {
    return `{{${props.node.attrs.name}}}`;
  },

  addNodeView() {
    return ({ node }) => {
      const dom = document.createElement("span");
      dom.setAttribute("data-template-variable", node.attrs.name);
      dom.classList.add("template-variable-chip");
      dom.innerHTML = node.attrs.label;
      return {
        dom,
      };
    };
  },
});

export const parseTemplateVariables = (content: string, variables: TemplateVariable[]) => {
  const regex = /{{([^}]+)}}|([^{{}}]+)/g;

  const doc = {
    type: "doc",
    content: [],
  };

  content.split(/\n\n/).forEach((line) => {
    const nodes: any[] = [];
    let match;
    while ((match = regex.exec(line)) !== null) {
      if (match[1]) {
        const name = match[1];
        const label = variables.find((v) => v.name === name)?.label ?? name;
        nodes.push({
          type: "templateVariable",
          attrs: { name, label },
        });
      } else if (match[2]) {
        nodes.push({
          type: "text",
          text: match[2],
        });
      }
    }
    //@ts-ignore typescript does not like the untyped structure.
    doc.content.push({ type: "paragraph", content: nodes });
  });
  return doc;
};
