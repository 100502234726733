import { PaperSize } from "../paper";
import { TagDefault } from "./tags";

export type CongSettings = {
  school_size: number;
  number_of_mic_assignments: number;
  number_of_attendant_assignments: number;
  number_of_zoom_attendant_assignments: number;
  number_of_security_attendant_assignments: number;
  number_of_stage_assignments: number;
  number_of_console_assignments: number;
  number_of_video_assignments: number;
  console_label_1: string;
  console_label_2: string;
  console_label_3: string;
  video_label_1: string;
  video_label_2: string;
  video_label_3: string;
  mic_label_1: string;
  mic_label_2: string;
  mic_label_3: string;
  mic_label_4: string;
  mic_label_5: string;
  mic_label_6: string;
  stage_label_1: string;
  stage_label_2: string;
  stage_label_3: string;
  attendant_label_1: string;
  attendant_label_2: string;
  attendant_label_3: string;
  attendant_label_4: string;
  attendant_label_5: string;
  attendant_label_6: string;
  zoom_attendant_label_1: string;
  zoom_attendant_label_2: string;
  zoom_attendant_label_3: string;
  security_attendant_label_1: string;
  security_attendant_label_2: string;
  security_attendant_label_3: string;
  security_attendant_label_4: string;
  security_attendant_label_5: string;
  security_attendant_label_6: string;
  enable_hospitality: boolean;
  hospitality_by_group: boolean;
  congregation_groups_use_label: boolean;
  congregation_display_name: string;
  congregation_pt_contact_info: string;
  circuit_overseer_name: string;
  circuit_overseer_wm_talk_title: string;
  circuit_overseer_wm_svc_talk_title: string;
  circuit_overseer_mm_talk_title: string;
  circuit_overseer_mm_closing_song: number;
  circuit_overseer_wm_closing_song: number;
  circuit_overseer_contact_info: string | undefined;
  other_speaker_name: string;
  other_speaker_cong: string;
  other_speaker_talk_title: string;
  //default to assignments for each meeting.
  //if ava_schedule_weekly is true, just have one set of assignments for all meetings in a week
  ava_schedule_weekly: boolean;
  number_of_cleaning_assignments: number;
  cleaning_label_1: string;
  cleaning_label_2: string;
  cleaning_label_3: string;
  cleaning_label_4: string;
  cleaning_label_5: string;
  cleaning_label_6: string;
  cleaning_label_7: string;
  cleaning_label_8: string;
  cleaning_notify_group: boolean;
  pw_enable_open_scheduling: boolean;
  pw_everyone_schedule_anyone: boolean;
  pw_pairing_behavior: "sex" | "any";
  pw_my_availability: boolean;
  terr_display_count: number;
  terr_allow_requests: boolean;
  terr_address_hide_type: boolean;
  terr_checkout_hide_more: boolean;
  terr_tile_provider: number;
  terr_address_h2h: boolean;
  terr_pub_update_address: boolean;
  terr_pub_delete_address: boolean;
  terr_pub_view_all: boolean;
  terr_custom_mobile_tiles: boolean;
  terr_sort_by_locality: boolean;
  terr_sharing: boolean;
  terr_pub_no_return: boolean;
  terr_max_requests: number;
  wt_conductor: number | undefined;
  name_format: string | undefined;
  paper_size: PaperSize | undefined;
  wm_print_date: "weekof" | "dayof";
  mm_print_date: "weekof" | "dayof";
  show_wt_reader: boolean;
  show_interpreter: boolean;
  date_format: string | undefined;
  generate_assistant_s89: boolean;
  info_board_url: string | undefined;
  disable_app_credit_hours: boolean;
  show_my_contact_info: boolean;
  show_my_emergency_contacts: boolean;
  edit_my_contact_info: boolean;
  edit_my_emergency_contacts: boolean;
  hide_outgoing_speakers: boolean;
  disable_decline_assignment: boolean;
  wm_speaker_closing_prayer: boolean;
  label_as_preferred_name: boolean;
  terr_checkout_default_tag: TagDefault[] | null;
  terr_checkout_duration_days: number;
  wm_reminder_email_template: string | null;
  hide_reports: boolean;
};

export enum CongSetting {
  SchoolSize = "school_size",
  NumberOfMicAssignments = "number_of_mic_assignments",
  NumberOfAttendantAssignments = "number_of_attendant_assignments",
  NumberOfZoomAttendantAssignments = "number_of_zoom_attendant_assignments",
  NumberOfSecurityAttendantAssignments = "number_of_security_attendant_assignments",
  NumberOfStageAssignments = "number_of_stage_assignments",
  NumberOfConsoleAssignments = "number_of_console_assignments",
  NumberOfVideoAssignments = "number_of_video_assignments",
  ConsoleLabel1 = "console_label_1",
  ConsoleLabel2 = "console_label_2",
  ConsoleLabel3 = "console_label_3",
  VideoLabel1 = "video_label_1",
  VideoLabel2 = "video_label_2",
  VideoLabel3 = "video_label_3",
  MicLabel1 = "mic_label_1",
  MicLabel2 = "mic_label_2",
  MicLabel3 = "mic_label_3",
  MicLabel4 = "mic_label_4",
  MicLabel5 = "mic_label_5",
  MicLabel6 = "mic_label_6",
  StageLabel1 = "stage_label_1",
  StageLabel2 = "stage_label_2",
  StageLabel3 = "stage_label_3",
  AttendantLabel1 = "attendant_label_1",
  AttendantLabel2 = "attendant_label_2",
  AttendantLabel3 = "attendant_label_3",
  AttendantLabel4 = "attendant_label_4",
  AttendantLabel5 = "attendant_label_5",
  AttendantLabel6 = "attendant_label_6",
  ZoomAttendantLabel1 = "zoom_attendant_label_1",
  ZoomAttendantLabel2 = "zoom_attendant_label_2",
  ZoomAttendantLabel3 = "zoom_attendant_label_3",
  SecurityAttendantLabel1 = "security_attendant_label_1",
  SecurityAttendantLabel2 = "security_attendant_label_2",
  SecurityAttendantLabel3 = "security_attendant_label_3",
  SecurityAttendantLabel4 = "security_attendant_label_4",
  SecurityAttendantLabel5 = "security_attendant_label_5",
  SecurityAttendantLabel6 = "security_attendant_label_6",
  EnableHospitality = "enable_hospitality",
  HospitalityByGroup = "hospitality_by_group",
  CongregationGroupsUseLabels = "congregation_groups_use_label",
  CongregationDisplayName = "congregation_display_name",
  CongregationPTContactInfo = "congregation_pt_contact_info",
  CircuitOverseerName = "circuit_overseer_name",
  CircuitOverseerWMTalkTitle = "circuit_overseer_wm_talk_title",
  CircuitOverseerWMServiceTalkTitle = "circuit_overseer_wm_svc_talk_title",
  CircuitOverseerMMTalkTitle = "circuit_overseer_mm_talk_title",
  CircuitOverseerMMClosingSong = "circuit_overseer_mm_closing_song",
  CircuitOverseerWMClosingSong = "circuit_overseer_wm_closing_song",
  CircuitOverseerContactInfo = "circuit_overseer_contact_info",
  OtherSpeakerName = "other_speaker_name",
  OtherSpeakerCong = "other_speaker_cong",
  OtherSpeakerTalkTitle = "other_speaker_talk_title",
  AVAScheduleWeekly = "ava_schedule_weekly",
  NumberOfCleaningAssignments = "number_of_cleaning_assignments",
  CleaningLabel1 = "cleaning_label_1",
  CleaningLabel2 = "cleaning_label_2",
  CleaningLabel3 = "cleaning_label_3",
  CleaningLabel4 = "cleaning_label_4",
  CleaningLabel5 = "cleaning_label_5",
  CleaningLabel6 = "cleaning_label_6",
  CleaningLabel7 = "cleaning_label_7",
  CleaningLabel8 = "cleaning_label_8",
  CleaningNotifyGroup = "cleaning_notify_group",
  PWEnableOpenScheduling = "pw_enable_open_scheduling",
  PWEveryoneScheduleAnyone = "pw_everyone_schedule_anyone",
  PWPairingBehavior = "pw_pairing_behavior",
  PWMyAvailability = "pw_my_availability",
  TerrDisplayCount = "terr_display_count",
  TerrAllowRequests = "terr_allow_requests",
  TerrAddressHideType = "terr_address_hide_type",
  TerrCheckoutHideMore = "terr_checkout_hide_more",
  TerrTileProvider = "terr_tile_provider",
  TerrCustomMobileTileProvider = "terr_custom_mobile_tiles",
  TerrAddressH2H = "terr_address_h2h",
  TerrPubUpdateAddresses = "terr_pub_update_address",
  TerrPubDeleteAddresses = "terr_pub_delete_address",
  TerrPubViewAll = "terr_pub_view_all",
  TerrSortByLocality = "terr_sort_by_locality",
  TerrSharing = "terr_sharing",
  TerrPubNoReturn = "terr_pub_no_return",
  TerrMaxRequests = "terr_max_requests",
  WTConductor = "wt_conductor",
  WMPrintDate = "wm_print_date",
  MMPrintDate = "mm_print_date",
  ShowWTReader = "show_wt_reader",
  ShowInterpreter = "show_interpreter",
  PaperSize = "paper_size",
  NameFormat = "name_format",
  DateFormat = "date_format",
  GenerateAssistantS89 = "generate_assistant_s89",
  InfoBoardURL = "info_board_url",
  DisableAppCreditHours = "disable_app_credit_hours",
  ShowMyContactInfo = "show_my_contact_info",
  ShowMyEmergencyContacts = "show_my_emergency_contacts",
  EditMyContactInfo = "edit_my_contact_info",
  EditMyEmergencyContacts = "edit_my_emergency_contacts",
  HideOutgoingSpeakers = "hide_outgoing_speakers",
  DisableDeclineAssignment = "disable_decline_assignment",
  WMSpeakerClosingPrayer = "wm_speaker_closing_prayer",
  LabelAsPreferredName = "label_as_preferred_name",
  TerrCheckoutDefaultTag = "terr_checkout_default_tag",
  WMReminderEmailTemplate = "wm_reminder_email_template",
  TerrCheckoutDurationDays = "terr_checkout_duration_days",
  HideReports = "hide_reports",
}

export enum CongSettingTypes {
  MM = "mm",
  WM = "wm",
  AVA = "ava",
  Terr = "terr",
}

//this maps a setting to the asset ID, which can then be used to get the localized string
export const CongSettingMap: { [k in keyof CongSettings]: string } = {
  school_size: "schedules.settings.number-classrooms",
  number_of_mic_assignments: "schedules.settings.number-mics",
  number_of_attendant_assignments: "schedules.settings.number-attendants",
  number_of_zoom_attendant_assignments: "schedules.settings.number-zoom-attendants",
  number_of_security_attendant_assignments: "schedules.settings.number-entrance-attendants",
  number_of_stage_assignments: "schedules.settings.number-stage",
  number_of_console_assignments: "schedules.settings.number-console",
  number_of_video_assignments: "schedules.settings.number-video",
  console_label_1: "schedules.settings.console-label",
  console_label_2: "schedules.settings.console-label",
  console_label_3: "schedules.settings.console-label",
  video_label_1: "schedules.settings.video-label",
  video_label_2: "schedules.settings.video-label",
  video_label_3: "schedules.settings.video-label",
  mic_label_1: "schedules.settings.mics-label",
  mic_label_2: "schedules.settings.mics-label",
  mic_label_3: "schedules.settings.mics-label",
  mic_label_4: "schedules.settings.mics-label",
  mic_label_5: "schedules.settings.mics-label",
  mic_label_6: "schedules.settings.mics-label",
  stage_label_1: "schedules.settings.stage-label",
  stage_label_2: "schedules.settings.stage-label",
  stage_label_3: "schedules.settings.stage-label",
  attendant_label_1: "schedules.settings.attendant-label",
  attendant_label_2: "schedules.settings.attendant-label",
  attendant_label_3: "schedules.settings.attendant-label",
  attendant_label_4: "schedules.settings.attendant-label",
  attendant_label_5: "schedules.settings.attendant-label",
  attendant_label_6: "schedules.settings.attendant-label",
  zoom_attendant_label_1: "schedules.settings.zoom-attendant-label",
  zoom_attendant_label_2: "schedules.settings.zoom-attendant-label",
  zoom_attendant_label_3: "schedules.settings.zoom-attendant-label",
  security_attendant_label_1: "schedules.settings.entrance-attendant-label",
  security_attendant_label_2: "schedules.settings.entrance-attendant-label",
  security_attendant_label_3: "schedules.settings.entrance-attendant-label",
  security_attendant_label_4: "schedules.settings.entrance-attendant-label",
  security_attendant_label_5: "schedules.settings.entrance-attendant-label",
  security_attendant_label_6: "schedules.settings.entrance-attendant-label",
  enable_hospitality: "schedules.settings.enable-hospitality",
  hospitality_by_group: "schedules.settings.hospitality.by-group",
  congregation_groups_use_label: "congregation.settings.groups-use-label",
  congregation_display_name: "schedules.settings.cong-name",
  congregation_pt_contact_info: "schedules.settings.ptc-contact",
  circuit_overseer_name: "schedules.settings.co-name",
  circuit_overseer_wm_talk_title: "schedules.settings.co-wm-talk-title",
  circuit_overseer_wm_svc_talk_title: "schedules.settings.co-wm-service-talk-title",
  circuit_overseer_mm_talk_title: "schedules.settings.co-mm-talk-title",
  circuit_overseer_mm_closing_song: "schedules.settings.co-mm-closing-song",
  circuit_overseer_wm_closing_song: "schedules.settings.co-wm-closing-song",
  circuit_overseer_contact_info: "schedules.settings.co-contact-info",
  other_speaker_name: "schedules.settings.other-speaker-name",
  other_speaker_cong: "schedules.settings.other-speaker-cong",
  other_speaker_talk_title: "schedules.settings.other-speaker-talk-title",
  ava_schedule_weekly: "schedules.settings.ava-weekly",
  number_of_cleaning_assignments: "schedules.settings.number-cleaning",
  cleaning_label_1: "schedules.settings.cleaning-label",
  cleaning_label_2: "schedules.settings.cleaning-label",
  cleaning_label_3: "schedules.settings.cleaning-label",
  cleaning_label_4: "schedules.settings.cleaning-label",
  cleaning_label_5: "schedules.settings.cleaning-label",
  cleaning_label_6: "schedules.settings.cleaning-label",
  cleaning_label_7: "schedules.settings.cleaning-label",
  cleaning_label_8: "schedules.settings.cleaning-label",
  cleaning_notify_group: "schedules.settings.cleaning.notify-group",
  pw_enable_open_scheduling: "schedules.settings.pw-enable-open-scheduling",
  pw_everyone_schedule_anyone: "schedules.settings.pw-everyone-schedule-anyone",
  pw_pairing_behavior: "schedules.public-witnessing.auto-fill-behavior",
  pw_my_availability: "schedules.settings.pw-my-availability",
  terr_display_count: "schedules.settings.terr-display-count",
  terr_allow_requests: "schedules.settings.territory.allow-requests",
  terr_address_hide_type: "schedules.settings.territory.address.hide-type",
  terr_checkout_hide_more: "schedules.settings.territory.checkout.hide-more",
  terr_address_h2h: "schedules.settings.territory.cong-h2h",
  terr_pub_update_address: "schedules.settings.territory.pub-update-address",
  terr_pub_delete_address: "schedules.settings.territory.pub-delete-address",
  terr_pub_view_all: "schedules.territory.everyone-view",
  terr_tile_provider: "schedules.settings.territory.maps-provider",
  terr_custom_mobile_tiles: "schedules.settings.territory.mobile-tiles",
  terr_sort_by_locality: "schedules.settings.territory.sort-by-locality",
  terr_sharing: "schedules.settings.territory.sharing",
  terr_pub_no_return: "schedules.settings.territory.prevent-pub-return",
  terr_max_requests: "settings.territory.disable-requests-after-checkout",
  wt_conductor: "schedules.weekend.wt-conductor",
  name_format: "schedules.settings.name-format",
  paper_size: "schedules.settings.paper-size",
  wm_print_date: "schedules.settings.print-date",
  mm_print_date: "schedules.settings.print-date",
  show_wt_reader: "schedules.settings.show-wt-reader",
  show_interpreter: "schedules.settings.show-interpreter",
  date_format: "general.date",
  generate_assistant_s89: "schedules.settings.generate-assistant-s89",
  info_board_url: "general.information-board",
  disable_app_credit_hours: "settings.disable-credit-hours",
  show_my_contact_info: "settings.show-my-contact-info",
  show_my_emergency_contacts: "settings.show-my-emergency-contacts",
  edit_my_contact_info: "settings.edit-my-contact-info",
  edit_my_emergency_contacts: "settings.edit-my-emergency-contacts",
  hide_outgoing_speakers: "schedules.settings.hide-outgoing-speakers",
  disable_decline_assignment: "settings.disable-decline-assignments",
  wm_speaker_closing_prayer: "schedules.settings.wm-speaker-closing-prayer",
  label_as_preferred_name: "settings.label-as-preferred-name",
  terr_checkout_default_tag: "settings.terr-checkout-default-tag",
  wm_reminder_email_template: "schedules.settings.wm-reminder-email-template",
  terr_checkout_duration_days: "settings.territory.checkout-duration-days",
  hide_reports: "settings.hide-reports-app",
};
