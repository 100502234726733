export const appleClientId: string = import.meta.env.VITE_APPLE_CLIENT_ID || "com.hourglass-app.hourglasstime";

export function getAppleAuthUrl(state: string, redirectUri: string, nonce: string): string {
  const base = new URL("https://appleid.apple.com/auth/authorize");

  const scopes = ["email"];
  const params: any = {
    client_id: appleClientId,
    response_type: "code id_token",
    redirect_uri: redirectUri,
    response_mode: "form_post",
    state: state,
    nonce: nonce,
  };

  params.scope = scopes.join(" ");
  base.search = new URLSearchParams(params).toString();
  return base.toString();
}
