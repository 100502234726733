import { halfInch, newPDFWithHeader, normalFont } from "../../../helpers/pdf";
import { jsPDF } from "jspdf";
import autoTable, { CellHookData, RowInput } from "jspdf-autotable";
import { Month, stringToLocaleDate, wmDate } from "../../../helpers/dateHelpers";
import { TalkMod, WMSchedule } from "../../../types/scheduling/weekend";
import { nameOfUser } from "../../../helpers/user";
import { Cong } from "../../../types/cong";
import { t } from "../../../helpers/locale";
import { CongName } from "../common";
import { wmScheduleColStyles, wmScheduleHeader } from "./wm_schedule_pdf";
import User from "../../../types/user";
import { CongSettings } from "../../../types/scheduling/settings";
import { ScheduledEvent } from "../../../types/scheduling/events";

const margin = halfInch;
const bodyFontSize = 8;

export async function weekendPortraitPDF(
  wms: WMSchedule[],
  myCong: Cong,
  useHost: boolean,
  userMap: Map<number, User>,
  startMonth: string,
  settings: CongSettings,
  events: ScheduledEvent[],
) {
  const { doc, initY } = await newPDFWithHeader(t("conganalysis.attendance.weekend"), myCong.name, myCong.locale.code);
  const curY = initY + 0.05;

  await weekendSchedule(doc, curY, userMap, wms, useHost, myCong, settings, events);

  const month = Month.fromDateString(startMonth);
  doc.save(`weekend_${month.toString()}.pdf`);
}

async function weekendSchedule(
  doc: jsPDF,
  initialY: number,
  userMap: Map<number, User>,
  schedule: WMSchedule[],
  useHost: boolean,
  myCong: Cong,
  settings: CongSettings,
  events: ScheduledEvent[],
): Promise<number> {
  const userNameFromMap = (userId: number): string => {
    return userMap.get(userId)?.displayName || "";
  };

  const heading = wmScheduleHeader(settings, "portrait");

  let lastEndY = 0;
  const headerFontSize = 8;
  const tableWidth = doc.internal.pageSize.width - margin * 2;
  const colStyles = wmScheduleColStyles(tableWidth, useHost, "portrait");

  //TODO DRY this up, it's a duplicate
  const drawTable = (
    doc: jsPDF,
    startY: number,
    head: RowInput[],
    body: RowInput[],
    willDrawCell?: (hookData: CellHookData) => void,
  ) => {
    autoTable(doc, {
      theme: "striped",
      tableLineColor: "#aaa",
      startY: startY,
      head: head,
      body: body,
      margin: {
        left: margin,
        bottom: 0,
        right: margin,
      },
      pageBreak: "avoid",
      rowPageBreak: "avoid",
      styles: {
        fontSize: bodyFontSize,
        font: normalFont,
        cellPadding: { top: 0.04, bottom: 0.04, left: 0.05, right: 0.05 },
        halign: "center",
        valign: "middle",
        minCellHeight: 0.45,
      },
      headStyles: {
        font: normalFont,
        fillColor: "black",
        fontSize: headerFontSize,
        cellPadding: { top: 0.05, bottom: 0.05 },
        halign: "center",
        valign: "middle",
      },
      columnStyles: colStyles,
      willDrawCell: willDrawCell,
      didDrawPage: (d) => {
        if (d.cursor) lastEndY = d.cursor.y;
      },
    });
  };

  const theBody: RowInput[] = schedule.map((s) => {
    const cong = s.speaker.congregation?.id
      ? `\n${CongName(s.speaker.congregation)}`
      : s.speaker.congregation_name
        ? `\n${s.speaker.congregation_name}`
        : s.temp_cong
          ? `\n${CongName(s.temp_cong)}`
          : s.talk_mod === TalkMod.TBD && s.speaker.firstname !== "tbd"
            ? `\n${t("schedules.weekend.tbd")}`
            : s.talk_mod === TalkMod.Stream
              ? `\nstream.jw.org`
              : "";

    // this is a bit of a hack, but it prevents the unknown character from being shown when there's no entry for a week
    if (!s.speaker.id && !s.speaker.firstname) s.speaker.firstname = " ";

    const speakerNames = (): string => {
      const speaker1 = nameOfUser(s.speaker);
      if (s.speaker2) {
        const s2User = userMap.get(s.speaker2);
        if (s2User) return `${speaker1}\n${nameOfUser(s2User)}`;
      }
      return speaker1;
    };

    const cols = [
      stringToLocaleDate(
        settings.wm_print_date === "dayof" ? wmDate(s.date, myCong, events) : s.date,
        myCong.country.datefmt,
      ),
      userNameFromMap(s.wm_chairman),
      `${
        s.speaker.firstname === "tbd"
          ? t("schedules.weekend.tbd")
          : s.talk_mod === TalkMod.Stream
            ? t("schedules.stream")
            : speakerNames()
      }${cong}`,
      s.public_talk?.title === "tbd"
        ? t("schedules.weekend.tbd")
        : s.talk_mod === TalkMod.Stream
          ? t("schedules.stream")
          : (s.public_talk?.title ?? ""),
      settings.show_wt_reader && settings.show_interpreter && s.interpreter
        ? `${userNameFromMap(s.wm_reader)}\n${userNameFromMap(s.interpreter)}`
        : settings.show_wt_reader
          ? userNameFromMap(s.wm_reader)
          : settings.show_interpreter && s.interpreter
            ? userNameFromMap(s.interpreter)
            : "",
    ];
    if (useHost) cols.push(userNameFromMap(s.host));
    return cols;
  });

  drawTable(doc, initialY, heading, theBody);
  return lastEndY;
}
