import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { Badge, Card, Col, Form, ListGroup, Row, Table } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { Option } from "react-bootstrap-typeahead/types/types";
import { useTranslation } from "react-i18next";
import { useCongSettings } from "../../../api/cong";
import { usePrivilegeMap } from "../../../api/meetings";
import QueryKeys from "../../../api/queryKeys";
import { useUsers } from "../../../api/user";
import { useWMSchedules, useWMSpeakers, weekendApi } from "../../../api/weekend";
import {
  ISODateFormat,
  dateToLocaleFormat,
  dateToString,
  getDayjs,
  stringToLocaleDate,
  wmDate,
} from "../../../helpers/dateHelpers";
import dayjs from "../../../helpers/dayjs";
import { GlobalContext, HGContext } from "../../../helpers/globals";
import { nameOfUser, userIdMap } from "../../../helpers/user";
import { UserPrivilegeMap } from "../../../types/scheduling/meetings";
import { CongSettings } from "../../../types/scheduling/settings";
import { Congregation, PublicTalkAssignment, TalkMod, WMSchedule } from "../../../types/scheduling/weekend";
import User from "../../../types/user";
import { QueryStatus } from "../../queryStatus";
import { sortedWMCongregations } from "../common";

export function Stats(props: { langGroupId: number }): JSX.Element {
  return <WMStatistics langGroupId={props.langGroupId} />;
}

function WMStatistics(props: { langGroupId: number }): JSX.Element {
  const { t } = useTranslation();
  const ctx = useContext(HGContext);
  const congSettingsQuery = useCongSettings(props.langGroupId);

  const now = new Date();
  const yearAgo = getDayjs(now).add(-1, "year").toDate();
  const wmsQuery = useWMSchedules(dateToString(yearAgo), dateToString(now), props.langGroupId);

  const privsQuery = usePrivilegeMap(props.langGroupId);

  const usersQuery = useUsers();
  const queryStatus = QueryStatus(wmsQuery, usersQuery, privsQuery, congSettingsQuery);
  if (queryStatus !== null) return queryStatus;
  if (!usersQuery.data || !privsQuery.data || !congSettingsQuery.data) {
    return <h2 className="center">{t("error.heading")}</h2>;
  }
  const userMap = userIdMap(usersQuery.data);

  const congNames = Array.from(
    new Set(
      (wmsQuery.data ?? []).flatMap((s) => {
        const data: string[] = [];
        if (
          s.speaker.congregation?.id !== ctx.globals.cong.id &&
          s.talk_mod !== TalkMod.CO &&
          !!s.speaker.congregation
        ) {
          data.push(s.speaker.congregation.name);
        }
        s.out.forEach((out) => {
          if (!out.congregation) return;
          data.push(out.congregation?.custom_name ?? out.congregation.name);
        });
        return data;
      }),
    ),
  );

  return (
    <>
      <Row className="mt-3">
        <Col lg>
          <h3 className="center">
            {dateToLocaleFormat(getDayjs(now).add(-1, "year").toDate())} — {dateToLocaleFormat(now)}
          </h3>
        </Col>
        <Col lg />
      </Row>
      <Row>
        <Col lg className="mt-3">
          <Card className="p-3 h-100">
            <h5>{t("schedules.weekend.stats.number-talks-given")}</h5>
            <NumberOfTalksRows ctx={ctx} wmSchedules={wmsQuery.data ?? []} userMap={userMap} />
          </Card>
        </Col>
        <Col lg className="mt-3">
          <Card className="p-3 h-100">
            <h5>{t("schedules.weekend.stats.speaker-talk-history")}</h5>
            <SpeakerTalkHistory
              settings={congSettingsQuery.data}
              ctx={ctx}
              wmSchedules={wmsQuery.data ?? []}
              userMap={userMap}
              privsQuery={privsQuery.data}
              langGroupId={props.langGroupId}
            />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg className="mt-3">
          <Card className="p-3 h-100">
            <div className="d-flex justify-content-between align-items-start">
              <h5>{t("schedules.weekend.stats.congregations-traded-with")}</h5>
              <Badge pill bg="secondary">
                {congNames.length}
              </Badge>
            </div>
            <NumberOfCongsTradedWith ctx={ctx} congNames={congNames} />
          </Card>
        </Col>
        <Col lg className="mt-3">
          <Card className="p-3 h-100">
            <h5>{t("schedules.privileges.title")}</h5>
            <OtherStats ctx={ctx} privsQuery={privsQuery.data} />
          </Card>
        </Col>
      </Row>
    </>
  );
}

const SpeakerTalkHistory = (props: {
  settings: CongSettings;
  ctx: GlobalContext;
  wmSchedules: WMSchedule[];
  userMap: Map<number, User>;
  privsQuery: UserPrivilegeMap;
  langGroupId: number;
}) => {
  const { t } = useTranslation();
  const congregationsQuery = useQuery({
    queryKey: [QueryKeys.WMCongregations, props.langGroupId],

    queryFn: () => weekendApi.getCongregations(props.langGroupId),
  });

  const [selectedSpeakerId, setSelectedSpeakerId] = useState(0);

  const myCongId = props.ctx.globals.cong.id;
  const [selectedCongId, setSelectedCongId] = useState(myCongId);
  const speakersQuery = useWMSpeakers(selectedCongId, props.langGroupId);
  const selectedCong = congregationsQuery.data?.find((c) => c.id === selectedCongId);

  const [schedules, setSchedules] = useState<PublicTalkAssignment[]>(schedulesToTalkAssignment(props.wmSchedules));
  const fiveYearsAgo = dayjs().subtract(5, "year").format(ISODateFormat);
  const tradesQuery = useQuery({
    queryKey: ["wm_stats_speaker_talk_history", selectedCongId],
    queryFn: () => weekendApi.getCongTrades(selectedCongId, props.langGroupId, fiveYearsAgo),
    enabled: selectedCongId !== myCongId,
  });

  useEffect(() => {
    if (selectedCong?.id === myCongId || !selectedCong) {
      setSchedules(schedulesToTalkAssignment(props.wmSchedules, selectedCong));
      return;
    }
    if (tradesQuery.data?.inbound) {
      setSchedules(tradesQuery.data?.inbound);
    }
  }, [selectedCong, myCongId, props.wmSchedules, tradesQuery.data]);

  type TalkRecord = {
    date: string;
    talk?: number;
    congName?: string;
  };

  const local: TalkRecord[] = schedules
    .filter((s) => s.speaker?.id === selectedSpeakerId)
    .map((s) => {
      return {
        date: wmDate(s.date, props.ctx.globals.cong),
        talk: s.public_talk?.number,
        congName: props.settings.congregation_display_name || props.ctx.globals.cong.name,
      };
    });

  // only populate outs if we're looking at our own congregation
  const outs: TalkRecord[] =
    selectedCongId === myCongId
      ? props.wmSchedules
          .map((s) =>
            s.out
              .filter((out) => out.speaker?.id === selectedSpeakerId)
              .map((out) => {
                return {
                  date: out.congregation ? wmDate(out.date, out.congregation) : out.date,
                  talk: out.public_talk?.number,
                  congName: out.congregation?.custom_name ?? out.congregation?.name,
                };
              }),
          )
          .flat()
      : [];

  const data = [local, outs]
    .flat()
    .sort((a, b) => (a.date > b.date ? 1 : -1))
    .map((s, i) => (
      <ListGroup.Item key={i} className="p-0">
        <Table borderless className="p-0 m-0">
          <tbody>
            <tr>
              <td className="font-monospace" style={{ width: "1%" }}>
                {stringToLocaleDate(s.date)}
              </td>
              <td className="text-end font-monospace" style={{ width: "4em" }}>
                #{s.talk}
              </td>
              <td>{s.congName}</td>
            </tr>
          </tbody>
        </Table>
      </ListGroup.Item>
    ));

  const empty = (
    <ListGroup.Item className="p-0 text-muted">
      <Table borderless className="p-0 m-0">
        <tbody>
          <tr>
            <td className="font-monospace" style={{ width: "1%" }}>
              {t("general.none")}
            </td>
            <td className="text-end font-monospace" style={{ width: "4em" }}></td>
            <td></td>
          </tr>
        </tbody>
      </Table>
    </ListGroup.Item>
  );

  const selectCong = (selected: Option[]) => {
    const selectedCong = selected[0] as Congregation;
    if (!selectedCong) return;
    setSelectedCongId(selectedCong.id);
    setSelectedSpeakerId(0);
  };

  return (
    <>
      <Typeahead
        filterBy={(option, state) => {
          // @ts-ignore name exists
          const name = option.name;
          if (state.selected.length || !name) return true;
          return name.toLocaleLowerCase().indexOf(state.text.toLocaleLowerCase()) !== -1;
        }}
        placeholder={t("list.congregation.title")}
        id="cong_for_speaker_history"
        flip
        options={sortedWMCongregations(congregationsQuery.data)}
        labelKey="name"
        onChange={selectCong}
        maxResults={100}
      />

      <Form.Group className="mb-4" controlId="floatingSelect">
        <Form.Label>{t("schedules.weekend.speaker.0")}</Form.Label>
        <Form.Select value={selectedSpeakerId} onChange={(e) => setSelectedSpeakerId(Number(e.target.value))}>
          <option />
          {speakersQuery.data?.map((s) => (
            <option key={s.id} value={s.id}>
              {nameOfUser(s)}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <ListGroup variant="flush">{!selectedSpeakerId ? "" : data.length ? data : empty}</ListGroup>
    </>
  );
};

const NumberOfTalksRows = (props: { ctx: GlobalContext; wmSchedules: WMSchedule[]; userMap: Map<number, User> }) => {
  const data = new Map<number, number>();
  props.wmSchedules.forEach((s) => {
    if (s.speaker.congregation?.id === props.ctx.globals.cong.id && !!s.speaker.userId) {
      data.set(s.speaker.userId, (data.get(s.speaker.userId) ?? 0) + 1);
    }

    s.out.forEach((out) => {
      if (!out.speaker?.userId) return;
      data.set(out.speaker.userId, (data.get(out.speaker.userId) ?? 0) + 1);
    });
  });

  const out: JSX.Element[] = [];
  data.forEach((count, uid) => {
    out.push(
      <ListGroup.Item key={uid} className="d-flex justify-content-between align-items-start">
        <div>{props.userMap.get(uid)?.displayName ?? "na"}</div>
        <Badge pill bg="secondary">
          {count}
        </Badge>
      </ListGroup.Item>,
    );
  });

  return <ListGroup variant="flush">{out}</ListGroup>;
};

const NumberOfCongsTradedWith = (props: { ctx: GlobalContext; congNames: string[] }) => {
  const out = props.congNames
    .sort((a, b) => (a > b ? 1 : -1))
    .map((congName, i) => {
      return (
        <ListGroup.Item key={i} className="d-flex justify-content-between">
          <div>{congName}</div>
        </ListGroup.Item>
      );
    });

  return <ListGroup variant="flush">{out}</ListGroup>;
};

const OtherStats = (props: { ctx: GlobalContext; privsQuery: UserPrivilegeMap }) => {
  const { t } = useTranslation();
  const things = [
    { title: t("schedules.privileges.public-talks"), count: props.privsQuery.pt.length },
    { title: t("schedules.privileges.public-talks-away"), count: props.privsQuery.pt_out.length },
    { title: t("schedules.chairman"), count: props.privsQuery.wm_chairman.length },
    { title: t("schedules.privileges.hospitality"), count: props.privsQuery.host.length },
    { title: t("schedules.weekend.wt-reader"), count: props.privsQuery.wm_reader.length },
    { title: t("schedules.weekend.wt-conductor"), count: props.privsQuery.wt_conductor.length },
  ];

  return (
    <ListGroup variant="flush">
      {things.map((thing, i) => (
        <ListGroup.Item key={i} className="d-flex justify-content-between align-items-start">
          <div>{thing.title}</div>
          <Badge pill bg="secondary">
            {thing.count}
          </Badge>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

const schedulesToTalkAssignment = (schedules: WMSchedule[], selectedCong?: Congregation): PublicTalkAssignment[] => {
  return schedules.map((s) => {
    return {
      id: s.id,
      date: s.date,
      congregation: selectedCong,
      speaker: s.speaker,
      public_talk: s.public_talk ?? undefined,
    };
  });
};
