import { QueryClient, QueryKey } from "@tanstack/react-query";
import QueryKeys from "../api/queryKeys";
import { AssignmentNotification, NotificationType } from "../types/scheduling/meetings";

export function assignmentNotificationQueryKey(type: NotificationType, from: string, to: string): QueryKey {
  return [QueryKeys.AssignmentNotifications, from, to, type];
}

export function updateAssignmentNotificationCache(
  queryClient: QueryClient,
  from: string,
  to: string,
  type: NotificationType,
  oldAssignment: AssignmentNotification,
  newAssignment: AssignmentNotification,
) {
  const key = assignmentNotificationQueryKey(type, from, to);
  if (oldAssignment.id === 0) {
    //we have a new group to add
    queryClient.setQueryData<AssignmentNotification[]>(key, (old) => (old ? [...old, newAssignment] : [newAssignment]));
    return;
  }
  queryClient.setQueryData<AssignmentNotification[]>(key, (old) =>
    old ? old.map((n) => (n.id === newAssignment.id ? newAssignment : n)) : [newAssignment],
  );
}
