import "core-js/stable";
import "core-js/stable/string/match-all";

import { ResizeObserver as ResizeObserverPolyfill } from "@juggle/resize-observer";
import { createRoot } from "react-dom/client";
import React from "react";
import App from "./App";
import "./i18n";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { onBugsnagBreadcrumb } from "./helpers/bugsnag";
import { ErrorFallback } from "./errorFallback";

Bugsnag.start({
  apiKey: "972bfc1b50c1b6315e35ebf04d26a88b",
  collectUserIp: false,
  enabledReleaseStages: ["production"],
  releaseStage: process.env.NODE_ENV,
  plugins: [new BugsnagPluginReact()],
  onBreadcrumb: onBugsnagBreadcrumb,
});

if (typeof window !== "undefined") {
  window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
}

// we know this plugin is loaded
// eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React);

// Render the app
const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");
createRoot(rootElement).render(
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
