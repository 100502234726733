import { getStatusCode } from "../helpers/errors";

export function rqShouldRetry<TError = unknown>(failureCount: number, error: TError): boolean {
  switch (getStatusCode(error)) {
    case 400:
    case 401:
    case 403:
      return false;
    case 404:
      return failureCount < 1;
  }

  return failureCount < 3;
}
