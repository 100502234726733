import { E2EKey, E2EMigrationSummary } from "../types/e2e";
import { api, fsReportPath } from "./api";

class E2EAPI {
  static path = fsReportPath("/e2e");
  static keyPath = E2EAPI.path + "/key";

  async save(key: E2EKey): Promise<E2EKey> {
    const url = key.id ? `${E2EAPI.keyPath}/${key.id}` : E2EAPI.keyPath;
    const method = key.id ? "put" : "post";
    return api(url, { method, json: key }).json();
  }

  async delete(): Promise<number> {
    const resp = await api.delete(E2EAPI.keyPath);
    return resp.status;
  }

  async migrationSummary(): Promise<E2EMigrationSummary> {
    const url = E2EAPI.path + "/migration";
    return api.get(url).json();
  }
}

export const e2eApi = new E2EAPI();
