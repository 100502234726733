import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { ISODateString } from "../types/date";
import { OtherSchedule, OtherScheduleKind } from "../types/scheduling/other";
import { api, QueryDateRange, schedulingPath } from "./api";
import QueryKeys from "./queryKeys";

export class OtherScheduleAPI {
  static base = schedulingPath("/other");

  async get(kind: OtherScheduleKind, from?: ISODateString, to?: ISODateString): Promise<OtherSchedule[]> {
    const queryParams: QueryDateRange = {};
    if (from) queryParams.fromDate = from;
    if (to) queryParams.toDate = to;

    return api.get(`${OtherScheduleAPI.base}/${kind}`, { searchParams: queryParams }).json();
  }

  async save(os: OtherSchedule): Promise<OtherSchedule> {
    const url = os.id ? `${OtherScheduleAPI.base}/${os.id}` : OtherScheduleAPI.base;
    const method = os.id ? "put" : "post";
    return api(url, { json: os, method }).json();
  }

  async delete(os: OtherSchedule) {
    await api.delete(`${OtherScheduleAPI.base}/${os.id}`);
  }
}

export function useOtherSchedule(
  kind: OtherScheduleKind,
  from?: ISODateString,
  to?: ISODateString,
  options?: UseQueryOptions<OtherSchedule[], Error>,
) {
  return useQuery({
    queryKey: [QueryKeys.OtherSchedules, kind, from, to],
    queryFn: () => otherScheduleApi.get(kind, from, to),
    ...options,
  });
}

export const otherScheduleApi = new OtherScheduleAPI();
