import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import { FormEvent, useContext, useState } from "react";
import { Button, Col, Dropdown, Form, Modal, Nav, Navbar, Row } from "react-bootstrap";
import {
  Clipboard2Check,
  ExclamationTriangleFill,
  FileEarmarkPdf,
  FileEarmarkWord,
  FileSpreadsheet,
  FileText,
} from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { congregationApi, useCongSettings } from "../../../api/cong";
import { useEvents, useNotifications } from "../../../api/meetings";
import QueryKeys from "../../../api/queryKeys";
import { useWMSchedules, weekendApi } from "../../../api/weekend";
import { ISODateFormat, Month, getDayjs, weekOfString } from "../../../helpers/dateHelpers";
import { HGContext } from "../../../helpers/globals";
import { CongSettings } from "../../../types/scheduling/settings";
import { WMSection } from "../../../types/scheduling/weekend";
import User from "../../../types/user";
import { QueryStatus } from "../../queryStatus";
import { SaveResult, SaveStatus } from "../../saveStatus";
import { createCombinedDOCX } from "../combined_docx";
import { UserMap } from "../common";
import { SettingsWM, canUpdateCongSettings } from "../settings/settings";
import { WMDocx } from "./wm_docx";
import { weekendLandscapePDF } from "./wm_schedule_landscape_pdf";
import { weekendMeetingPDF, wmsEvents } from "./wm_schedule_pdf";
import { weekendPortraitPDF } from "./wm_schedule_portrait_pdf";
import { upcomingSpeakersDocx } from "./wm_upcoming_speakers_docx";
import { upcomingSpeakersPDF } from "./wm_upcoming_speakers_pdf";
import { LanguageGroupDDM } from "../languageGroup";
import { canUpdateWeekendSchedules, needSendAssignments } from "./wm_common";
import useFSGroups from "../../../api/fsgroup";
import { languageGroupName, selectedCong } from "../../../helpers/langGroups";
import { isNoPDFLocale } from "../../../helpers/locale";
import { HGBugsnagNotify } from "../../../helpers/bugsnag";
import { HGPageTitle } from "../../utils";
import { PrintDropdown, SettingsButton } from "../../buttons";
import { ISODateString, NotificationDates } from "../../../types/date";
import { NotificationType } from "../../../types/scheduling/meetings";

enum WMPDFType {
  PortraitGrid,
  LandscapeGrid,
  Portrait,
  UpcomingSpeakers,
}

enum WMDocxType {
  Schedule,
  UpcomingSpeakers,
  Combined,
}

export default function WMMenu(props: {
  WM: WMSection;
  from: ISODateString;
  to: ISODateString;
  setWM: (show: WMSection) => void;
  month: Date;
  monthsCount: number;
  langGroupId: number;
  setLangGroupId: (set: number) => void;
}) {
  const { t, i18n } = useTranslation();
  const ctx = useContext(HGContext);
  const hostCong = ctx.globals.cong;
  const workingCong = selectedCong(props.langGroupId) ?? hostCong;
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  // settings & events for PDF schedule
  const congSettingsQuery = useCongSettings(props.langGroupId);
  const eventsQuery = useEvents(props.month.getFullYear());
  const userMap = UserMap();
  const fsGroupsQuery = useFSGroups();
  const scheduleQuery = useWMSchedules(props.from, props.to, props.langGroupId);
  const notificationDates: NotificationDates = { start: weekOfString(props.from), end: props.to };
  const notificationsQuery = useNotifications(
    notificationDates.start,
    notificationDates.end,
    NotificationType.WeekendCRH,
  );

  const queryStatus = QueryStatus(congSettingsQuery, eventsQuery);
  if (queryStatus !== null) return queryStatus;
  if (!congSettingsQuery.data || !eventsQuery.data || !fsGroupsQuery.data) return null;

  const disablePDFSchedule = isNoPDFLocale(i18n.language, props.langGroupId);

  const createDOCX = async (docxType: WMDocxType) => {
    const fromDay = getDayjs(props.month).startOf("month");
    const from = fromDay.format(ISODateFormat);
    const to = fromDay.add(2, "month").endOf("month").format(ISODateFormat);

    const wms = await weekendApi.getSchedules(from, to, props.langGroupId);

    switch (docxType) {
      case WMDocxType.Schedule:
        const wmDocx = new WMDocx(userMap, workingCong, congSettingsQuery.data, eventsQuery.data, fsGroupsQuery.data);
        await wmDocx.publish(wms, props.month, props.monthsCount);
        break;
      case WMDocxType.UpcomingSpeakers:
        await upcomingSpeakersDocx(wms, workingCong, congSettingsQuery.data);
        break;
      case WMDocxType.Combined:
        const eom = getDayjs(props.month).endOf("month");
        const to = eom.subtract(eom.day(), "day").format(ISODateFormat); //end of month excluding last partial week.
        await createCombinedDOCX(
          from,
          to,
          workingCong,
          congSettingsQuery.data,
          userMap,
          fsGroupsQuery.data,
          eventsQuery.data,
          props.langGroupId,
        );
        break;
    }
  };

  const createPDF = async (pdfType: WMPDFType, userMap: Map<number, User>) => {
    const fromDay = getDayjs(props.month).startOf("month");
    const from = fromDay.format(ISODateFormat);
    const to =
      pdfType === WMPDFType.UpcomingSpeakers
        ? fromDay.endOf("month").format(ISODateFormat)
        : fromDay.add(2, "month").endOf("month").format(ISODateFormat);
    const hospitality = congSettingsQuery.data.enable_hospitality;
    const wms = await weekendApi.getSchedules(from, to, props.langGroupId);

    const cong = selectedCong(props.langGroupId) ?? hostCong;

    switch (pdfType) {
      case WMPDFType.LandscapeGrid:
        wmsEvents(wms, eventsQuery.data);
        await weekendLandscapePDF(
          wms,
          cong,
          hospitality,
          userMap,
          i18n,
          from,
          congSettingsQuery.data,
          eventsQuery.data,
        );
        break;
      case WMPDFType.PortraitGrid:
        wmsEvents(wms, eventsQuery.data);
        await weekendPortraitPDF(wms, cong, hospitality, userMap, from, congSettingsQuery.data, eventsQuery.data);
        break;
      case WMPDFType.Portrait:
        await weekendMeetingPDF(
          wms,
          eventsQuery.data,
          cong,
          hospitality,
          userMap,
          congSettingsQuery.data,
          Month.fromDate(props.month),
          fsGroupsQuery.data,
        );
        break;
      case WMPDFType.UpcomingSpeakers:
        await upcomingSpeakersPDF(wms, cong);
        break;
    }
  };

  return (
    <>
      <div className="d-flex flex-row justify-content-between align-items-end mb-4">
        <HGPageTitle title={t("conganalysis.attendance.weekend")} />
        <div>
          <LanguageGroupDDM langGroupId={props.langGroupId} setLangGroupId={props.setLangGroupId} />
          {(canUpdateCongSettings() || canUpdateWeekendSchedules()) && (
            <SettingsButton onClick={() => setShowSettingsModal(true)} />
          )}
          <PrintDropdown>
            <Dropdown.Menu>
              {!disablePDFSchedule && (
                <Dropdown.Item onClick={() => createPDF(WMPDFType.Portrait, userMap)}>
                  <FileEarmarkPdf color="red" size="1.2em" /> {t("schedules.schedule")} <FileText size="1.2em" />
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={() => createDOCX(WMDocxType.Schedule)}>
                <FileEarmarkWord color="blue" size="1.2em" /> {t("schedules.schedule")} <FileText size="1.2em" />
              </Dropdown.Item>
              {!disablePDFSchedule && (
                <Dropdown.Item onClick={() => createPDF(WMPDFType.LandscapeGrid, userMap)}>
                  <FileEarmarkPdf color="red" size="1.2em" /> {t("schedules.schedule")}{" "}
                  <FileSpreadsheet size="1.3em" className="rotate-90" />
                </Dropdown.Item>
              )}
              {!disablePDFSchedule && (
                <Dropdown.Item onClick={() => createPDF(WMPDFType.PortraitGrid, userMap)}>
                  <FileEarmarkPdf color="red" size="1.2em" /> {t("schedules.schedule")} <FileSpreadsheet size="1.2em" />
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={() => createDOCX(WMDocxType.Combined)}>
                <FileEarmarkWord color="blue" size="1.2em" /> {t("schedules.combined")}{" "}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => createDOCX(WMDocxType.UpcomingSpeakers)}>
                <FileEarmarkWord color="blue" size="1.2em" /> {t("nav.publishers.contact-lists")}{" "}
                <Clipboard2Check size="1.2em" />
              </Dropdown.Item>
              {!disablePDFSchedule && (
                <Dropdown.Item onClick={() => createPDF(WMPDFType.UpcomingSpeakers, userMap)}>
                  <FileEarmarkPdf color="red" size="1.2em" /> {t("nav.publishers.contact-lists")}{" "}
                  <Clipboard2Check size="1.2em" />
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </PrintDropdown>
        </div>
      </div>

      <Nav variant="tabs" className="d-flex align-items-end mt-4 mb-1">
        <Navbar.Brand
          onClick={() => {
            props.setWM("incoming");
          }}
        ></Navbar.Brand>

        <Nav.Item>
          <Nav.Link
            onClick={() => {
              props.setWM("incoming");
            }}
            eventKey="schedule"
            active={props.WM === "incoming"}
          >
            {t("schedules.weekend.incoming")}
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            onClick={() => {
              props.setWM("outgoing");
            }}
            eventKey="outgoing"
            active={props.WM === "outgoing"}
          >
            {t("schedules.weekend.outgoing")}
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            onClick={() => {
              props.setWM("crh");
            }}
            eventKey="crh"
            active={props.WM === "crh"}
          >
            <span>
              {needSendAssignments(props.langGroupId, notificationsQuery.data, scheduleQuery.data) && (
                <ExclamationTriangleFill
                  className="warning-color me-1"
                  title={t("schedules.assignments.need-to-send")}
                />
              )}
              {t("schedules.weekend.crh")}
            </span>
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            onClick={() => {
              props.setWM("cong_speakers");
            }}
            eventKey="cong_speakers"
            active={props.WM === "cong_speakers"}
          >
            {t("schedules.weekend.cong-speakers")}
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            onClick={() => {
              props.setWM("stats");
            }}
            eventKey="stats"
            active={props.WM === "stats"}
          >
            {t("nav.reports.monthly.statistics")}
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            onClick={() => {
              props.setWM("talks");
            }}
            eventKey="talks"
            active={props.WM === "talks"}
          >
            {t("schedules.weekend.outlines")}
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            onClick={() => {
              props.setWM("data");
            }}
            eventKey="data"
            active={props.WM === "data"}
          >
            {t("schedules.weekend.data")}
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <SettingsModal show={showSettingsModal} setShow={setShowSettingsModal} langGroupId={props.langGroupId} />
    </>
  );
}

const SettingsModal = (props: { show: boolean; setShow: (show: boolean) => void; langGroupId: number }) => {
  const { t } = useTranslation();
  const handleClose = () => props.setShow(false);

  const settingsQuery = useCongSettings(props.langGroupId);
  const queryClient = useQueryClient();

  const queryStatus = QueryStatus(settingsQuery);
  if (queryStatus !== null) return queryStatus;
  if (!settingsQuery.data) return null;

  return (
    <Modal show={props.show} onHide={handleClose} scrollable>
      <Modal.Header closeButton>
        <Modal.Title>
          {t("conganalysis.attendance.weekend")}
          <p>{languageGroupName(props.langGroupId, settingsQuery.data)}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <WMSettings settings={settingsQuery.data} queryClient={queryClient} langGroupId={props.langGroupId} />
      </Modal.Body>
    </Modal>
  );
};

const WMSettings = (props: { settings: CongSettings; queryClient: QueryClient; langGroupId: number }) => {
  const { t } = useTranslation();
  const myCong = useContext(HGContext).globals.cong;
  const [settings, setSettings] = useState(props.settings);
  const [saveResult, setSaveResult] = useState(SaveResult.None);
  const saveSettingsMutation = useMutation({
    mutationFn: (settings: CongSettings) => congregationApi.setSettings(settings, props.langGroupId),
  });
  const queryClient = useQueryClient();

  const handleReset = () => {
    setSettings(props.settings);
  };

  const handleUpdate = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    if (!form.checkValidity()) return;

    try {
      const resp = await saveSettingsMutation.mutateAsync(settings);
      setSaveResult(SaveResult.Success);
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.WMCSchedules],
        }),
        queryClient.setQueryData([QueryKeys.CongregationSettings, props.langGroupId], resp),
      ]);
    } catch (err: any) {
      setSaveResult(SaveResult.Failure);
      console.error("error saving scheduling wm settings", err);
      HGBugsnagNotify("wmSettingsSave", err);
    }
  };

  return (
    <div className="ms-2">
      <Form onSubmit={handleUpdate}>
        <SettingsWM settings={settings} setSettings={setSettings} myCong={myCong} lgroup={props.langGroupId} />
        <Row className="mt-3">
          <Col>
            <Button variant="secondary" onClick={handleReset} disabled={!canUpdateWeekendSchedules()}>
              {t("general.reset")}
            </Button>
            <Button type="submit" variant="primary" className="ms-2" disabled={!canUpdateWeekendSchedules()}>
              {t("general.save")}
            </Button>
            <SaveStatus saveResult={saveResult} saveKey="wm_settings" setSaveResult={setSaveResult} />
          </Col>
        </Row>
      </Form>
    </div>
  );
};
