import { QueryClient } from "@tanstack/react-query";
import QueryKeys from "../api/queryKeys";
import {
  MidweekMeetingPart,
  MidweekScheduleAssignment,
  MidweekScheduleInfo,
  MMCoreInput,
  MMs,
} from "../types/scheduling/midweek";
import { Congregation, Speaker, WMSchedule } from "../types/scheduling/weekend";

export function updateWMSchedulesCache(queryClient: QueryClient, langGroupId: number, newSchedule: WMSchedule) {
  queryClient.setQueriesData<WMSchedule[]>({ queryKey: [QueryKeys.WMCSchedules, langGroupId] }, (old) => {
    const updatedCache: WMSchedule[] = [newSchedule];
    //if we have old, add them in
    if (old && Array.isArray(old)) {
      old.forEach((wmSched) => {
        if (newSchedule.date !== wmSched.date) {
          //add old in, unless it has the same date as the new one
          updatedCache.push(wmSched);
        }
      });
    }
    return updatedCache;
  });
}

export function updateMMAssignmentsCache(
  queryClient: QueryClient,
  date: string,
  langGroupId: number,
  assignment: MidweekScheduleAssignment,
) {
  if (!assignment) return;
  queryClient.setQueriesData<MMs>({ queryKey: [QueryKeys.MidweekInfo, langGroupId] }, (old) => {
    if (!old || !Array.isArray(old.meetings) || !Array.isArray(old.schedules))
      return {
        schedules: [],
        meetings: [],
      };

    const meeting = old.meetings.find((m) => m.date === date);
    const schedule = old.schedules.find((s) => s.date === date);
    if (!meeting || !schedule) return old;

    const newSched = (sched: MidweekScheduleAssignment[], existing: boolean): MidweekScheduleAssignment[] => {
      if (!existing) return [...sched, assignment];
      return sched.map((s) =>
        s.part === assignment.part && s.classroom === assignment.classroom
          ? {
              ...assignment,
              assignee: assignment.assignee,
              assistant: assignment.assistant,
            }
          : s,
      );
    };

    const newSchedules = old.schedules.map((s) => {
      if (s.date !== date) return s;

      // is this assignment already there, and we're just changing it?
      const existing = (sa: MidweekScheduleAssignment): boolean =>
        sa.part === assignment.part && sa.classroom === assignment.classroom;

      const hasPart = (mp: MidweekMeetingPart): boolean => mp.id === assignment.part;

      // we need to see if this assignment is in tgw, fm, or lac
      if (meeting.tgw.some(hasPart)) {
        return { ...s, tgw: newSched(s.tgw, s.tgw.some(existing)) };
      } else if (meeting.fm.some(hasPart)) {
        return { ...s, fm: newSched(s.fm, s.fm.some(existing)) };
      } else if (meeting.lac.some(hasPart)) {
        return { ...s, lac: newSched(s.lac, s.lac.some(existing)) };
      } else {
        console.error("cannot find part to update cache", assignment);
      }

      return { ...s };
    });

    return { schedules: newSchedules, meetings: old.meetings, not_available: old.not_available };
  });
}

export function updateMMCoreCache(queryClient: QueryClient, date: string, langGroupId: number, core: MMCoreInput) {
  queryClient.setQueriesData<MMs>({ queryKey: [QueryKeys.MidweekInfo, langGroupId] }, (old) => {
    if (!old || !old.schedules || !old.meetings)
      return {
        schedules: [],
        meetings: [],
      };

    const newSchedules = old.schedules.map((s) => {
      if (s.date !== date) return s;
      return { ...s, [core.part_type]: core.assignee };
    });

    return {
      meetings: old.meetings,
      schedules: newSchedules,
      not_available: old.not_available,
    };
  });
}

export function updateMMScheduleCache(queryClient: QueryClient, langGroupId: number, schedule: MidweekScheduleInfo) {
  queryClient.setQueriesData<MMs>({ queryKey: [QueryKeys.MidweekInfo, langGroupId] }, (old) => {
    if (!old || !old.meetings || !old.schedules)
      return {
        meetings: [],
        schedules: [],
      };
    return {
      meetings: old.meetings,
      schedules: old.schedules.map((s) => (s.date === schedule.date ? schedule : s)),
      not_available: old.not_available,
    };
  });
}

export function updateSpeakerCache(
  queryClient: QueryClient,
  congId: number,
  langGroupId: number,
  oldSpeaker: Speaker,
  newSpeaker: Speaker,
) {
  queryClient.setQueryData<Speaker[]>([QueryKeys.WMSpeakers, congId, langGroupId], (old) => {
    if (!oldSpeaker.id) {
      // new speaker being added
      return old ? [...old, newSpeaker] : [newSpeaker];
    }
    return old ? old.map((s) => (s.id === newSpeaker.id ? newSpeaker : s)) : [newSpeaker];
  });
}

export function updateWMCongregationCache(
  queryClient: QueryClient,
  oldCong: Congregation,
  newCong: Congregation,
  langGroupId: number,
) {
  queryClient.setQueryData<Congregation[]>([QueryKeys.WMCongregations, langGroupId], (old) => {
    if (!oldCong.id) return old ? [...old, newCong] : [newCong];
    // it's possible to have multiple entries with the same congregation ID in a pending situation, so we first filter them out
    return old ? [...old.filter((c) => c.id !== newCong.id), newCong] : [newCong];
  });
}
