import { VerifyEmailResponse } from "../types/auth";
import {
  InviteAcceptRequest,
  InviteAcceptResponse,
  InviteDetails,
  InviteRequest,
  InviteResponse,
  InviteType,
  ValidateInviteCodeRequest,
} from "../types/invite";
import { api, fsReportPath } from "./api";

class InviteAPI {
  static path = fsReportPath("/invite");

  async createInvite(userId: number, inviteType?: InviteType): Promise<InviteResponse> {
    const req: InviteRequest | undefined = inviteType ? { inviteType: inviteType } : undefined;
    return api.post(`${InviteAPI.path}/${userId}`, { json: req }).json();
  }

  async getInviteDetails(code: string): Promise<InviteDetails> {
    return api.get(`${InviteAPI.path}/details/${code}`).json();
  }

  async acceptInvite(accept: InviteAcceptRequest): Promise<InviteAcceptResponse> {
    return api.post(`${InviteAPI.path}/accept`, { json: accept }).json();
  }

  async validateInviteCode(req: ValidateInviteCodeRequest): Promise<VerifyEmailResponse> {
    return api.post(`${InviteAPI.path}/validcode`, { json: req }).json();
  }
}

export const inviteAPI = new InviteAPI();
