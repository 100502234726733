import { Alert, Badge } from "react-bootstrap";
import { Events, EventTypes, ScheduledEvent } from "../../types/scheduling/events";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { HGContext } from "../../helpers/globals";

export function EventAlert(props: { context: string; event?: ScheduledEvent | null; className?: string}) {
  const { t } = useTranslation();
  const ctx = useContext(HGContext);

  // don't show the alert if we have language groups; a language group's convention doesn't cancel host meetings for example
  if (!props.event || ctx.globals.language_groups.length > 0) {
    return null;
  }

  let level = "danger"; // red
  let msg = "";
  const noMeetingMSG = t("schedules.warning.no-meeting");

  switch (props.context) {
    case "mm":
      switch (props.event.event) {
        case Events.mmem:
          msg += noMeetingMSG;
          break;
        case Events.co:
          level = "info";
          break;
        case Events.ca:
          msg += noMeetingMSG;
          break;
        case Events.rc:
          msg += noMeetingMSG;
          break;
        // eslint-disable-next-line
        case Events.wmem:
        case Events.am:
        default:
          return null;
      }
      break;

    case "wm":
      switch (props.event.event) {
        case Events.co:
          level = "info";
          break;
        case Events.wmem:
        case Events.am:
        case Events.ca:
        case Events.rc:
          msg += noMeetingMSG;
          break;
        case Events.mmem:
        default:
          return null;
      }
      break;

    default:
      break;
  }

  return msg ? <Alert variant={level} className={props.className}>{msg}</Alert> : null;
}

export function EventBadge(props: { context: string; event?: ScheduledEvent | null; className?: string }) {
  const { t } = useTranslation();
  if (!props.event) {
    return null;
  }

  let show = false;

  switch (props.context) {
    case "mm":
      switch (props.event.event) {
        case "mmem":
        case "co":
        case "ca":
        case "rc":
          show = true;
          break;
        case "custom":
          show = props.event.customShowOnMeetingSchedule;
          break;
        case "wmem":
        case "am":
        default:
          show = false;
      }
      break;

    case "wm":
      switch (props.event.event) {
        case "co":
        case "wmem":
        case "am":
        case "ca":
        case "rc":
          show = true;
          break;
        case "mmem":
          show = false;
          break;
        case "custom":
          show = props.event.customShowOnMeetingSchedule;
          break;
        default:
          show = false;
      }
      break;
    case "fm":
      show = props.event.event === Events.co;
      break;

    default:
      break;
  }

  if (!show) return null;
  const eventText = props.event.event === Events.custom ? props.event.custom_title : t(EventTypes[props.event.event]);

  return (
    <Badge bg="light" text="dark" className={props.className}>
      {(props.event.event === Events.mmem || props.event.event === Events.wmem) && "🍷 "}
      {eventText}
    </Badge>
  );
}

export function noWeekendMeeting(event?: ScheduledEvent | null): boolean {
  switch (event?.event) {
    case "wmem":
    case "ca":
    case "rc":
      return true;
  }

  return false;
}
