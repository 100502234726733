import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Dropdown } from "react-bootstrap";
import { t } from "../../../helpers/locale";
import { TemplateVariable, TemplateVariableNode, parseTemplateVariables } from "./TemplateVariable";

export function TemplateTextEditor(props: {
  value: string;
  variables: TemplateVariable[];
  onChange: (val: string) => void;
}) {
  const editor = useEditor({
    extensions: [StarterKit, TemplateVariableNode],
    content: parseTemplateVariables(props.value, props.variables),
    onUpdate: () => {
      props.onChange(editor!.getText());
    },
  })!;

  const insertVariable = (variable: TemplateVariable) => {
    const { state, view } = editor;
    const { schema } = state;
    const { name, label } = variable;
    const node = schema.nodes.templateVariable!.create({ name, label });
    const transaction = state.tr.replaceSelectionWith(node);
    view.dispatch(transaction);
  };

  return (
    <div>
      <div className="toolbar mb-1">
        <Dropdown>
          <Dropdown.Toggle size="sm" variant="secondary">
            {t("general.insert")}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {props.variables.map((variable) => (
              <Dropdown.Item key={variable.name} onClick={() => insertVariable(variable)}>
                {variable.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <EditorContent editor={editor} className="template-text-editor" />
    </div>
  );
}
