import { HourglassTour } from "./tour";


export default function MainContent(props: any) {
  return (
    <div className="flex-grow-1" id="mainContent">
      
      <HourglassTour />

      {props.children}

    </div>
  );
}
