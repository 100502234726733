import { ISODateString } from "../types/date";
import {
  PublicWitnessingAllUsers,
  PublicWitnessingAvailability,
  PublicWitnessingDefault,
  PublicWitnessingLocation,
  PublicWitnessingPairing,
  PublicWitnessingPreference,
  PublicWitnessingSchedule,
  PublicWitnessingShareLink,
} from "../types/scheduling/publicWitnessing";
import { api, schedulingPath } from "./api";
import QueryKeys from "./queryKeys";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { nameOfUser } from "../helpers/user";
import HourglassGlobals from "../helpers/globals";
import { decryptObject, E2E_IV } from "../helpers/e2e";
import { DelegateUser } from "../types/whoami";

export class PublicWitnessingAPI {
  static base = schedulingPath("/pw");
  static schedulePath = `${PublicWitnessingAPI.base}/schedule`;
  static locationPath = `${PublicWitnessingAPI.base}/locations`;
  static availabilityPath = `${PublicWitnessingAPI.base}/availabilities`;
  static defaultPath = `${PublicWitnessingAPI.base}/defaults`;
  static preferencesPath = `${PublicWitnessingAPI.base}/preferences`;
  static pairingsPath = `${PublicWitnessingAPI.base}/pairings`;
  static allUsersPath = `${PublicWitnessingAPI.base}/users/all`;
  static shareLocationPath = `${PublicWitnessingAPI.base}/share/location`;
  static sharePath = `${PublicWitnessingAPI.base}/share`;

  static async delegateUserFromResponse(du: any): Promise<DelegateUser> {
    if (HourglassGlobals.e2eKey && du[E2E_IV]) {
      try {
        du = await decryptObject(du, HourglassGlobals.e2eKey);
      } catch (err) {
        console.error("failure to decrypt pw delegate user", du.id, err);
      }
    }

    if (!du.firstname) du.firstname = "";
    if (!du.lastname) du.lastname = "";
    du.displayName = nameOfUser(du);
    return du;
  }

  async getSchedules(from: ISODateString, to: ISODateString): Promise<PublicWitnessingSchedule[]> {
    return api.get(`${PublicWitnessingAPI.schedulePath}/${from}_${to}`).json();
  }

  async autoFill(from: ISODateString, to: ISODateString, generate: boolean): Promise<PublicWitnessingSchedule[]> {
    return api
      .post(`${PublicWitnessingAPI.schedulePath}/${from}_${to}`, {
        searchParams: { generate_schedule: generate },
      })
      .json();
  }

  async generateEmpty(from: ISODateString, to: ISODateString): Promise<PublicWitnessingSchedule[]> {
    return api.put(`${PublicWitnessingAPI.schedulePath}/${from}_${to}`).json();
  }

  async setSchedule(schedule: PublicWitnessingSchedule): Promise<PublicWitnessingSchedule> {
    return api.put(PublicWitnessingAPI.schedulePath, { json: schedule }).json();
  }

  async deleteSchedule(schedule: PublicWitnessingSchedule) {
    await api.delete(`${PublicWitnessingAPI.schedulePath}/${schedule.id}`);
  }

  async clearSchedule(month: ISODateString, assignments_only: boolean): Promise<PublicWitnessingSchedule[]> {
    return api
      .delete(`${PublicWitnessingAPI.schedulePath}/clear/${month}${assignments_only ? "?assignments_only=true" : ""}`)
      .json();
  }

  async getLocations(): Promise<PublicWitnessingLocation[]> {
    return api.get(PublicWitnessingAPI.locationPath, { searchParams: { disabled: true } }).json();
  }

  async setLocation(loc: PublicWitnessingLocation): Promise<PublicWitnessingLocation> {
    return api.put(PublicWitnessingAPI.locationPath, { json: loc }).json();
  }

  async deleteLocation(loc: PublicWitnessingLocation) {
    await api.delete(`${PublicWitnessingAPI.locationPath}/${loc.id}`);
  }

  async getAvailabilities(): Promise<PublicWitnessingAvailability[]> {
    return api.get(PublicWitnessingAPI.availabilityPath).json();
  }

  async setAvailabilities(avails: PublicWitnessingAvailability[]): Promise<PublicWitnessingAvailability[]> {
    return api.put(PublicWitnessingAPI.availabilityPath, { json: avails }).json();
  }

  async getDefaults(): Promise<PublicWitnessingDefault[]> {
    return api.get(PublicWitnessingAPI.defaultPath).json();
  }

  async setDefault(def: PublicWitnessingDefault): Promise<PublicWitnessingDefault> {
    return api.put(PublicWitnessingAPI.defaultPath, { json: def }).json();
  }

  async deleteDefault(def: PublicWitnessingDefault) {
    await api.delete(`${PublicWitnessingAPI.defaultPath}/${def.id}`);
  }

  async getPreferences(): Promise<PublicWitnessingPreference[]> {
    return api.get(PublicWitnessingAPI.preferencesPath).json();
  }

  async setPreference(pref: PublicWitnessingPreference): Promise<PublicWitnessingPreference> {
    return api.put(PublicWitnessingAPI.preferencesPath, { json: pref }).json();
  }

  async deletePreference(pref: PublicWitnessingPreference) {
    await api.delete(`${PublicWitnessingAPI.preferencesPath}/${pref.id}`);
  }

  async getPairings(): Promise<PublicWitnessingPairing[]> {
    return api.get(PublicWitnessingAPI.pairingsPath).json();
  }

  async setPairings(pr: PublicWitnessingPairing[]): Promise<PublicWitnessingPairing[]> {
    return api.put(PublicWitnessingAPI.pairingsPath, { json: pr }).json();
  }

  async getAllRedactedUsers(): Promise<PublicWitnessingAllUsers> {
    const resp: PublicWitnessingAllUsers = await api.get(PublicWitnessingAPI.allUsersPath).json();
    resp.users = await Promise.all(
      resp.users.map((du: DelegateUser) => PublicWitnessingAPI.delegateUserFromResponse(du)),
    );
    return resp;
  }

  async shareLocation(locationId: number): Promise<PublicWitnessingLocation> {
    const path = `${PublicWitnessingAPI.shareLocationPath}/${locationId}`;
    return api.post(path).json();
  }

  async linkSharedLocation(code: string): Promise<PublicWitnessingLocation> {
    const shareLink: PublicWitnessingShareLink = { code: code };
    return api.put(PublicWitnessingAPI.shareLocationPath, { json: shareLink }).json();
  }

  async deleteShare(shareId: number) {
    const path = `${PublicWitnessingAPI.sharePath}/${shareId}`;
    await api.delete(path);
  }

  async deleteReceivingShare(locationId: number) {
    const path = `${PublicWitnessingAPI.shareLocationPath}/${locationId}`;
    await api.delete(path);
  }
}

export function useSharedCongUsers(options?: Partial<UseQueryOptions<PublicWitnessingAllUsers, Error>>) {
  return useQuery({
    queryKey: [QueryKeys.PublicWitnessingAllUsers],
    queryFn: () => publicWitnessingApi.getAllRedactedUsers(),
    ...options,
  });
}

export const publicWitnessingApi = new PublicWitnessingAPI();
