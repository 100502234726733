import { DropdownButton, Dropdown } from "react-bootstrap";
import { HGContext } from "../../helpers/globals";
import { useContext } from "react";

import { storeLangGroupId } from "../../helpers/langGroups";

export function LanguageGroupDDM(props: {
  langGroupId: number;
  setLangGroupId: (set: number) => void;
  className?: string;
}) {
  const ctx = useContext(HGContext);
  const groups = [ctx.globals.cong, ...ctx.globals.language_groups];
  if (groups.length < 2) return null;
  // allows a null/zero/undefined to be passed in and mean "my cong"
  const groupId = props.langGroupId || ctx.globals.cong.id;

  const setLangGroup = (lgroup: number) => {
    storeLangGroupId(lgroup);
    props.setLangGroupId(lgroup);
  };

  return (
    <DropdownButton
      variant="secondary"
      id="lg-group-select"
      title={groups.find((lgroup) => lgroup.id === groupId)?.name || "🤷‍♂️"}
      className={props.className ?? "lg-group-select"}
    >
      {groups.map((lgroup, i) => {
        return (
          <Dropdown.Item key={i} onClick={() => setLangGroup(lgroup.id)}>
            {lgroup.name} — {lgroup.locale.symbol}
          </Dropdown.Item>
        );
      })}
    </DropdownButton>
  );
}
