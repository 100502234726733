import { useTranslation } from "react-i18next";
import { Container, Button } from "react-bootstrap";

export function ErrorFallback(props: { clearError: () => void }) {
  const { t } = useTranslation();
  return (
    <Container>
      <h5>{t("inviteaccept.unknown-error.heading")}</h5>
      <Button variant="primary" onClick={props.clearError}>
        {t("popup.error.button.ok")}
      </Button>
    </Container>
  );
}
