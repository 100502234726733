import {
  AuthenticationPublicKeyCredential,
  CredentialCreationOptionsJSON,
  CredentialRequestOptionsJSON,
  RegistrationPublicKeyCredential,
  parseCreationOptionsFromJSON,
  parseRequestOptionsFromJSON,
} from "@github/webauthn-json/browser-ponyfill";
import {
  AppleChangeResponse,
  AuthState,
  ChangeEmailRequest,
  ChangePassword,
  GoogleChangeResponse,
  LoginCredentials,
  LoginResponse,
  TwoFactorChallenge,
  TwoFactorChallengeResponse,
  TwoFactorChallengeStatusRequest,
  TwoFactorChallengeStatusResponse,
  VerifyEmailRequest,
  VerifyEmailResponse,
} from "../types/auth";
import { ForgotPWChangeRequest, ForgotPWRequest, ForgotPWResponse } from "../types/forgotpw";
import { PasskeyMetadata } from "../types/passkeys";
import { api, apiNoRedirect } from "./api";

export const AuthErrorCurrentPassword = "current_password";
export const AuthErrorGoogleInUse = "googleInUse";

class AuthAPI {
  static base = `auth`;

  async changePassword(curPass: string, newPass: string, verifyNewPass: string) {
    const url = `${AuthAPI.base}/password`;
    const changePass: ChangePassword = {
      curPassword: curPass,
      newPassword: newPass,
      verifyNewPassword: verifyNewPass,
    };
    await api.post(url, { json: changePass });
  }

  async changeEmail(curPass: string, newEmail: string) {
    const url = `${AuthAPI.base}/email`;
    const newEmailReq: ChangeEmailRequest = {
      curPassword: curPass,
      newEmail: newEmail,
    };
    await api.post(url, { json: newEmailReq });
  }

  async validateEmailCode(code: string): Promise<VerifyEmailResponse> {
    const url = `${AuthAPI.base}/email/code`;
    const verifyEmailReq: VerifyEmailRequest = { code: code };
    return api.post(url, { json: verifyEmailReq }).json();
  }

  async createOauthState(nonce: string): Promise<AuthState> {
    const url = `${AuthAPI.base}/state`;
    const newState = { nonce: nonce };
    return api.post(url, { json: newState }).json();
  }

  async changeGoogle(state: string): Promise<GoogleChangeResponse> {
    const url = `${AuthAPI.base}/google`;
    const authState: AuthState = { state: state };
    return api.post(url, { json: authState }).json();
  }

  async changeApple(state: string): Promise<AppleChangeResponse> {
    const url = `${AuthAPI.base}/apple`;
    const authState: AuthState = { state: state };
    return api.post(url, { json: authState }).json();
  }

  async forgotPassword(req: ForgotPWRequest): Promise<ForgotPWResponse> {
    const url = `${AuthAPI.base}/forgotpw`;
    return api.post(url, { json: req }).json();
  }

  async forgotPasswordChange(req: ForgotPWChangeRequest) {
    const url = `${AuthAPI.base}/forgotpw/newpassword`;
    await api.post(url, { json: req });
  }

  async getPasskeys(): Promise<PasskeyMetadata[]> {
    const url = `${AuthAPI.base}/webauthn/passkeys`;
    return api.get(url).json();
  }

  async updatePasskey(pkInfo: PasskeyMetadata): Promise<PasskeyMetadata> {
    const url = `${AuthAPI.base}/webauthn/passkeys`;
    return api.put(url, { json: pkInfo }).json();
  }

  async deletePasskey(pkId: number) {
    const url = `${AuthAPI.base}/webauthn/passkeys/${pkId}`;
    await api.delete(url);
  }

  async webauthnBeginRegister(): Promise<CredentialCreationOptions> {
    const url = `${AuthAPI.base}/webauthn/register/begin`;
    const data: CredentialCreationOptionsJSON = await apiNoRedirect.get(url).json();
    return parseCreationOptionsFromJSON(data);
  }

  async webauthnFinishRegister(cred: RegistrationPublicKeyCredential) {
    const url = `${AuthAPI.base}/webauthn/register/finish`;
    await apiNoRedirect.post(url, { json: cred });
  }

  async webauthnBeginLogin(): Promise<CredentialRequestOptions> {
    const url = `${AuthAPI.base}/webauthn/login/begin`;
    const data: CredentialRequestOptionsJSON = await apiNoRedirect.get(url).json();
    return parseRequestOptionsFromJSON(data);
  }

  async webauthnFinishLogin(credential: AuthenticationPublicKeyCredential) {
    const url = `${AuthAPI.base}/webauthn/login/finish`;
    await apiNoRedirect.post(url, { json: credential });
  }

  async passwordLogin(credentials: LoginCredentials): Promise<LoginResponse> {
    return apiNoRedirect.post(`${AuthAPI.base}/login`, { json: credentials }).json();
  }

  async twoFactorValidateEmail(tfResp: TwoFactorChallengeResponse): Promise<LoginResponse> {
    return apiNoRedirect.post(`${AuthAPI.base}/2fa/validate/email`, { json: tfResp }).json();
  }

  async twoFactorChallengeCompleted(req: TwoFactorChallengeStatusRequest): Promise<TwoFactorChallengeStatusResponse> {
    return apiNoRedirect.post(`${AuthAPI.base}/2fa/challenge/completed`, { json: req }).json();
  }

  async twoFactorChallengeEmail(challenge: TwoFactorChallenge): Promise<TwoFactorChallenge> {
    return apiNoRedirect.post(`${AuthAPI.base}/2fa/challenge/email`, { json: challenge }).json();
  }
}

export const authApi = new AuthAPI();
