import { useQuery } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { FormEvent, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { CheckCircleFill } from "react-bootstrap-icons";
import { isAndroid, isIOS, isIPad13, isIPhone13, isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { inviteAPI } from "../api/invite";
import QueryKeys from "../api/queryKeys";
import { getAppleAuthUrl } from "../helpers/appleSignin";
import { HGBugsnagNotify } from "../helpers/bugsnag";
import { isAppleICloudDomain } from "../helpers/email";
import { getStatusCode } from "../helpers/errors";
import HourglassGlobals, { PythonI18nInterpolation } from "../helpers/globals";
import { getGoogleAuthUrl } from "../helpers/googleSignin";
import { checkPassword } from "../helpers/password";
import { InviteAcceptRequest, InviteAcceptResponse, InviteDetails, ValidateInviteCodeRequest } from "../types/invite";
import { Warning } from "./alerts";
import { LoginLocationState } from "./login";
import { HGOauthPopup } from "./oauthPopup";
import { QueryStatus } from "./queryStatus";
import { AppleIDIcon, AppleSignInButton } from "./utils";

type InviteAcceptParams = {
  code: string;
};

const appleStoreLink = "https://itunes.apple.com/app/hourglass-time/id927752129?mt=8";
const playStoreLink = "https://play.google.com/store/apps/details?id=com.hourglass_app.hourglasstime";

function iOSOriPadOS(): boolean {
  //@ts-ignore: userAgentData or platform will be there, and we use optional chaining
  const platform: string = navigator.userAgentData?.platform ? navigator.userAgentData.platform : navigator.platform;
  const iPad = platform.toLowerCase().startsWith("mac") && navigator.maxTouchPoints > 1;
  return isIOS || isIPad13 || isIPhone13 || iPad;
}

export function InviteAccept(props: { admin?: boolean }) {
  const { t } = useTranslation();
  const { code } = useParams<InviteAcceptParams>();
  const [notFound, setNotFound] = useState(false);
  const [limitExceeded, setLimitExceeded] = useState(false);
  const [forceBrowser, setForceBrowser] = useState(false);
  const [alreadyHaveLogin, setAlreadyHaveLogin] = useState(false);

  const inviteQuery = useQuery({
    queryKey: [QueryKeys.InviteCode, code],
    queryFn: () => inviteAPI.getInviteDetails(code!),
    gcTime: Infinity,
    staleTime: Infinity,
    retry: (failureCount: number, error: Error): boolean => {
      if (error instanceof HTTPError) {
        switch (error.response.status) {
          case 404:
            setNotFound(true);
            return false;
          case 409:
            setAlreadyHaveLogin(true);
            break;
          case 429:
            setLimitExceeded(true);
            return false;
          default:
            return error.response.status >= 500;
        }
      }
      return true;
    },
  });

  if (notFound) {
    return (
      <Container fluid="md">
        <h3>{t("invite-error.heading")}</h3>
        <h5>{t("invite-error.body")}</h5>
      </Container>
    );
  }

  if (alreadyHaveLogin) {
    return (
      <Container fluid="md">
        <h5>{t("inviteaccept.error.already-have-login")}</h5>
        <Button className="mt-2" variant="primary" href="/">
          {t("login.message.signin")}
        </Button>
      </Container>
    );
  }

  if (limitExceeded) {
    return (
      <Container fluid="md">
        <h3>{t("form.error.try-later")}</h3>
      </Container>
    );
  }

  const queryStatus = QueryStatus(inviteQuery);
  if (queryStatus !== null) return queryStatus;
  if (!inviteQuery.data) return null;

  const mobile = iOSOriPadOS() || isMobile;

  return (
    <Container fluid="md">
      <Row className="justify-content-center mt-3">
        <Col>
          <h3>
            {t("inviteaccept.welcome %(firstname)s", {
              interpolation: PythonI18nInterpolation,
              firstname: inviteQuery.data.firstname,
            })}
          </h3>
          {mobile && !props.admin && !forceBrowser ? (
            <InviteAcceptMobile inviteDetails={inviteQuery.data} code={code!} setForceBrowser={setForceBrowser} />
          ) : (
            <InviteAcceptBrowser inviteDetails={inviteQuery.data} code={code!} />
          )}
        </Col>
      </Row>
    </Container>
  );
}

function InviteAcceptMobile(props: {
  inviteDetails: InviteDetails;
  code: string;
  setForceBrowser: (fb: boolean) => void;
}) {
  const { t } = useTranslation();

  const storeLink = iOSOriPadOS() ? appleStoreLink : playStoreLink;
  const androidStep2 = `intent://app.hourglass-app.com/#Intent;scheme=hourglassfsreports;package=com.hourglass_app.hourglasstime;S.invitetoken=${props.code};end`;
  const appleStep2 = `hourglassfsreports://app.hourglassapp.com/mobile?invitetoken=${props.code}`;
  const inviteLink = isAndroid ? androidStep2 : appleStep2;

  return (
    <div>
      <h4>{t("inviteaccept.step-1.heading")}</h4>
      <Button variant="primary" href={storeLink} className="mt-2 mb-3">
        {t("inviteaccept.step-1.button")}
      </Button>
      <h4>{t("inviteaccept.step-2.heading")}</h4>
      <Button variant="primary" href={inviteLink}>
        {t("inviteaccept.step-2.button")}
      </Button>
      <div>
        <Button variant="primary" className="mt-4" size="sm" onClick={() => props.setForceBrowser(true)}>
          {t("inviteaccept.use-browser")}
        </Button>
      </div>
    </div>
  );
}

enum AuthType {
  Google,
  Apple,
  Password,
}

function InviteAcceptBrowser(props: { inviteDetails: InviteDetails; code: string }) {
  const { t } = useTranslation();
  const [authType, setAuthType] = useState(AuthType.Google);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [weakPassword, setWeakPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successResponse, setSuccessResponse] = useState<InviteAcceptResponse>();
  const [emailCode, setEmailCode] = useState("");
  const [codeError, setCodeError] = useState("");
  const [requireGoogle, setRequireGoogle] = useState(false);
  const [requireApple, setRequireApple] = useState(false);
  const navigate = useNavigate();
  const state = window.HGGlobal?.state || "state";

  const sendAccept = async () => {
    const inviteAccept: InviteAcceptRequest = {
      state: state,
      code: props.code,
      googleAuth: authType === AuthType.Google,
      appleAuth: authType === AuthType.Apple,
      email: email ? email : undefined,
      password: password ? password : undefined,
    };
    try {
      const resp = await inviteAPI.acceptInvite(inviteAccept);
      setSuccessResponse(resp);
    } catch (err: any) {
      console.error("error accepting invite", err);
      const statusCode = getStatusCode(err);
      switch (statusCode) {
        case 404:
          setErrorMessage(t("invite-error.body"));
          break;
        case 409:
          // if it's a 409, they already have a login (or there's already a login with the email they chose)
          setErrorMessage(t("inviteaccept.error.already-have-login"));
          break;
        default:
          setErrorMessage(t("inviteaccept.unknown-error.body"));
          HGBugsnagNotify("inviteAccept", err);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const submitEmailForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!e.currentTarget.checkValidity()) return;

    const score = await checkPassword(password);
    if (score < 2) {
      setWeakPassword(true);
      return;
    }

    await sendAccept();
  };

  const submitValidationCode = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!e.currentTarget.checkValidity()) return;

    const inviteReq: ValidateInviteCodeRequest = { inviteCode: props.code, emailCode: emailCode };
    try {
      const resp = await inviteAPI.validateInviteCode(inviteReq);
      setEmailCode("");
      setSuccessResponse({ email: resp.loginemail, requireValidation: false, googleAuth: false, appleAuth: false });
    } catch (err: any) {
      let errorMessage = t("userauth.feedback.validate-email-failure");
      const statusCode = getStatusCode(err);
      switch (statusCode) {
        case 404:
          break;
        case 409:
          errorMessage = t("userauth.feedback.duplicate-email");
          break;
        default:
          console.error("error verifying email", err);
          HGBugsnagNotify("inviteUserAuthEmail", err);
      }
      setCodeError(errorMessage);
    }
  };

  if (successResponse) {
    if (successResponse.requireValidation) {
      // they used an unverified email address. tell them to enter the code.
      return (
        <Row xs="auto">
          <Form onSubmit={submitValidationCode} className="mt-3">
            <p>
              <b>{successResponse.email}</b>
              <br />
              {t("userauth.feedback.email-code-sent")}
            </p>
            <Form.Group>
              <Form.Control
                type="text"
                required
                className="mt-3"
                value={emailCode}
                autoComplete="off_emailCode"
                placeholder={t("userauth.form.label.email-code")}
                onChange={(e) => setEmailCode(e.currentTarget.value)}
              />
              <Button className="mt-2" variant="primary" type="submit">
                {t("userauth.form.button.validate-email")}
              </Button>
              {!!codeError && <Warning className="mt-3" text={codeError} />}
            </Form.Group>
          </Form>
        </Row>
      );
    }

    const authMethod = (): JSX.Element => {
      if (successResponse.googleAuth) return <b>{t("userauth.current-auth.method.google")}</b>;
      if (successResponse.appleAuth) return <AppleIDIcon />;
      return <b>{t("userauth.current-auth.method.password")}</b>;
    };

    const locState: LoginLocationState = {
      email: successResponse.email,
      googleAuth: successResponse.googleAuth,
      appleAuth: successResponse.appleAuth,
    };
    return (
      <div>
        <div>
          {t("userauth.current-auth.heading")} <b>{successResponse.email}</b>
        </div>
        <div>
          {t("userauth.current-auth.method.0")} {authMethod()}
        </div>
        <Button className="mt-2 me-2" variant="primary" onClick={() => navigate("/", { state: locState })}>
          {t("login.message.signin")}
        </Button>
        <CheckCircleFill color="green" size={24} />
      </div>
    );
  }

  if (errorMessage) {
    return (
      <div>
        <h5>{t("inviteaccept.unknown-error.heading")}</h5>
        <p>{errorMessage}</p>
      </div>
    );
  }

  if (props.inviteDetails.loginemail) {
    return (
      <div>
        <h5>{t("login.message.validated")}</h5>
        <Button variant="primary" onClick={() => navigate("/")}>
          {t("login.message.signin")}
        </Button>
      </div>
    );
  }

  return (
    <div>
      <p>{t("register.login-setup")}</p>
      {authType !== AuthType.Password ? (
        <div>
          <Row>
            <Col xs="auto">
              <div id="googleSignInButton">
                <HGOauthPopup
                  url={getGoogleAuthUrl(state, HourglassGlobals.oAuthRedirectURI, window.HGGlobal?.nonce || "nonce")}
                  title="Google"
                  onClick={() => setAuthType(AuthType.Google)}
                  onComplete={sendAccept}
                  height={500}
                  width={400}
                >
                  <span className="googleSignInIcon" />
                  <span className="googleSignInButtonText">{t("userauth.current-auth.method.google")}</span>
                </HGOauthPopup>
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs="auto">
              <div id="appleSignInButton">
                <HGOauthPopup
                  url={getAppleAuthUrl(state, HourglassGlobals.appleAuthRedirectURI, window.HGGlobal?.nonce || "nonce")}
                  title="Apple"
                  onClick={() => setAuthType(AuthType.Apple)}
                  onComplete={sendAccept}
                  height={500}
                  width={400}
                  //completeOnClose works around the issue where Safari pops up the apple ID native popup but doesn't handle the postMessage flow to detect completion
                  completeOnClose={true}
                >
                  <AppleSignInButton noLink />
                </HGOauthPopup>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="auto">
              <Button className="mt-2" variant="outline" size="sm" onClick={() => setAuthType(AuthType.Password)}>
                {t("register.email-and-password")}
              </Button>
            </Col>
          </Row>
        </div>
      ) : (
        <div>
          <Form className="hg-user-edit" onSubmit={submitEmailForm}>
            <Row>
              <Col xs={8} md={6} xl={4}>
                <Form.Group controlId="email">
                  <Form.Label>{t("form.label.email-address")}</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    value={email}
                    name="email"
                    onChange={(e) => {
                      const newEmail = e.currentTarget.value;
                      setEmail(newEmail);
                      setRequireGoogle(newEmail.trim().toLowerCase().endsWith("@gmail.com"));
                      setRequireApple(isAppleICloudDomain(newEmail));
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={8} md={6} xl={4}>
                <Form.Group controlId="password">
                  <Form.Label>{t("form.label.password")}</Form.Label>
                  <Form.Control
                    required
                    type="password"
                    name="password"
                    value={password}
                    maxLength={56}
                    onChange={(e) => {
                      setPassword(e.currentTarget.value);
                      setWeakPassword(false);
                    }}
                    isInvalid={weakPassword}
                  />
                  {weakPassword && <Form.Text className="text-danger">{t("password-error.insecure")}</Form.Text>}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs="auto">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={!email || !password || weakPassword || requireGoogle || requireApple}
                >
                  {t("inviteaccept.button.go")}
                </Button>
              </Col>
            </Row>
            {requireGoogle && (
              <Row className="mt-2">
                <Col xs="auto">
                  <p className="text-danger bold">{t("login.modal.body.reset-password.google")} </p>
                </Col>
              </Row>
            )}
            {requireApple && (
              <Row className="mt-2">
                <Col xs="auto">
                  <p className="text-danger bold">{t("login.sign-in-apple")} </p>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs="auto" className="mt-4">
                <Button className="mt-2" variant="info" size="sm" onClick={() => setAuthType(AuthType.Google)}>
                  {t("userauth.form.label.use-google")}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs="auto" className="mt-2">
                <Button className="mt-2" variant="dark" size="sm" onClick={() => setAuthType(AuthType.Apple)}>
                  {t("userauth.form.label.use-apple")}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </div>
  );
}
