export type Tag = {
  id: number;
  name: string;
  category: TagCategory;
  color: string | null;
  allow_multi: boolean;
  global: boolean;
};

export type TagDefault = {
  id: number;
  global: boolean;
  pseudo: boolean;
};

export enum PseudoTag {
  untagged = -1,
  all = -2,
  non_business = -3,
}

export const TagColors = [
  { name: "primary", className: "w100 bg-primary", value: "#9B8C64" },
  { name: "secondary", className: "w100 bg-secondary", value: "#6E757C" },
  { name: "success", className: "w100 bg-success", value: "#408558" },
  { name: "danger", className: "w100 bg-danger", value: "#CB444A" },
  { name: "warning", className: "w100 bg-warning", value: "#F6C344" },
  { name: "info", className: "w100 bg-info", value: "#17a2b8" },
  { name: "light", className: "w100 bg-light", value: "#C0C0C0" },
  { name: "dark", className: "w100 bg-dark", value: "#5C115D" },
];

export type TagLink = {
  tag_id?: number | null;
  global_tag_id?: number | null;
  terr_id?: number | null;
  user_id?: number | null;
  addr_id?: number | null;
  trec_id?: number | null;
};

export type TagLinkUnlink = {
  link: TagLink[];
  unlink: TagLink[];
};

export enum TagCategory {
  All = "all",
  Territory = "terr",
  User = "user",
  Address = "addr",
  TerritoryRecord = "trec",
}

// GlobalTagMap stores localization strings for global tag namess
export const GlobalTagMap: Map<string, string> = new Map([
  ["business", "schedules.territory.business"],
  ["keyholder", "tags.keyholder"],
  ["dnc", "schedules.territory.do-not-call"],
  ["nt", "schedules.territory.no-trespassing"],
  ["foreign_lang", "schedules.territory.foreign_lang"],
  ["campaign", "tags.campaign"],
]);

// some tags are pseudo linked by other means
// thus the user should not link/unlink them
export const GlobalTagsNotUserLinkable = ["dnc"];
