import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AVAttendantAssignment } from "../types/scheduling/avAttendant";
import { api, schedulingPath } from "./api";
import QueryKeys from "./queryKeys";
import { ISODateString } from "../types/date";

class AVAttendantAPI {
  static base = schedulingPath("/av_attendant");

  async getAssignments(date: string, lgroup: number): Promise<AVAttendantAssignment> {
    return api.get(`${AVAttendantAPI.base}/${date}`, { searchParams: { lgroup: lgroup } }).json();
  }

  async getAssignmentsRange(from: string, to: string, lgroup: number): Promise<AVAttendantAssignment[]> {
    return api.get(`${AVAttendantAPI.base}/${from}_${to}`, { searchParams: { lgroup: lgroup } }).json();
  }

  async deleteAssignment(assignmentId: number, lgroup: number) {
    await api.delete(`${AVAttendantAPI.base}/${assignmentId}`, { searchParams: { lgroup: lgroup } });
  }

  async saveAssignment(assignment: AVAttendantAssignment, lgroup: number): Promise<AVAttendantAssignment> {
    const url = assignment.id ? `${AVAttendantAPI.base}/${assignment.id}` : AVAttendantAPI.base;
    const method = assignment.id ? "put" : "post";

    return api(url, { method, json: assignment, searchParams: { lgroup: lgroup } }).json();
  }

  async autoDates(dates: ISODateString[], lgroup: number): Promise<AVAttendantAssignment[]> {
    return api.post(`${AVAttendantAPI.base}/auto/dates`, { json: dates, searchParams: { lgroup: lgroup } }).json();
  }

  async deleteDates(dates: ISODateString[], lgroup: number) {
    return api.post(`${AVAttendantAPI.base}/delete/dates`, { json: dates, searchParams: { lgroup: lgroup } }).json();
  }
}

export function useAVAssignments(
  from: string,
  to: string,
  lgroup: number,
  options?: Partial<UseQueryOptions<AVAttendantAssignment[], Error>>,
) {
  return useQuery({
    queryKey: [QueryKeys.AVAttendantAssignment, lgroup, from, to],
    queryFn: () => avAttendantApi.getAssignmentsRange(from, to, lgroup),
    ...options,
    meta: { clearStoredLangGroupOn404: true },
  });
}

export const avAttendantApi = new AVAttendantAPI();
