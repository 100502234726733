import Expire from "./expire";
import { CheckCircleFill, XCircleFill } from "react-bootstrap-icons";

export enum SaveResult {
  None,
  Success,
  Failure,
}

type SaveStatusProps = {
  saveResult: SaveResult;
  saveKey: string | number;
  setSaveResult: (result: SaveResult) => void;
  noSpace?: boolean;
  size?: number;
};

export function SaveStatus(props: SaveStatusProps) {
  const spaceClass = props.noSpace ? "" : "ms-2";
  const size = props.size ?? 24;

  switch (props.saveResult) {
    case SaveResult.Success:
      return (
        <Expire removeDelay={4000} beginFadeDelay={2000} onRemoved={() => props.setSaveResult(SaveResult.None)}>
          <CheckCircleFill color="green" size={size} className={spaceClass} />
        </Expire>
      );
    case SaveResult.Failure:
      return <XCircleFill color="red" size={size} className={spaceClass} />;
    default:
      return null;
  }
}
